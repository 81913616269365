import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { ApplicationState } from '../ApplicationState';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';

export class StripeUtils {
    public static squeegeePublishableKey = GlobalFlags.isDevServer ? 'pk_test_ykBIcieTCrQcL6sXy2mAkXid' : 'pk_live_GHUE6EWJ9g79RIfJ7NemQ7sT';

    private static _squeegeeStripe: Promise<Stripe | undefined>;
    private static _paramsSet = false;
    private static async init(stripePublishableKey: string) {
        try {
            try {
                if (!StripeUtils._paramsSet) loadStripe.setLoadParameters({ advancedFraudSignals: false });
            } catch (error) {
                Logger.warn('Unable to set load params for stripe', error);
            } finally {
                StripeUtils._paramsSet = true;
            }

            const stripe = await loadStripe(stripePublishableKey);

            if (!stripe) throw new Error('Unable to load stripe: StripeUtils loadStripe returned null');

            return stripe;
        } catch (error) {
            Logger.error('Stripe failed to initialize');
        }
    }

    public static async getUserStripeInstance(): Promise<Stripe | undefined> {
        if (!ApplicationState.account.stripePublishableKey) return;

        const stripePublishableKeyResponse = await Api.get<string>(null, '/api/payments/stripe-publishable-key');
        if (!stripePublishableKeyResponse || !stripePublishableKeyResponse.data) {
            new NotifyUserMessage('Unable to get the Stripe Key for your account' as TranslationKey);
            throw 'Unable to get the Stripe Key for your account';
        }

        const userStripe = StripeUtils.init(stripePublishableKeyResponse.data);
        return userStripe;
    }

    public static async squeegeeStripe(): Promise<Stripe | undefined> {
        if (!StripeUtils._squeegeeStripe) this._squeegeeStripe = StripeUtils.init(StripeUtils.squeegeePublishableKey);
        return StripeUtils._squeegeeStripe;
    }
}
