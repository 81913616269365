import type { Customer, JobOccurrence, Service, StoredObject, Tag } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus, TagType } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';
import { LoaderEvent } from '../Events/LoaderEvent';
import { JobService } from '../Jobs/JobService';
import { TagData } from '../Tags/TagData';
export class ServicesService {
    public static getServicesData(serviceTags?: Array<Tag>) {
        const services = serviceTags || Data.all<Tag>('tags', { type: TagType.SERVICE });

        const allJobs = JobService.getJobs();

        const servicesData = [];
        for (let i = 0; i < services.length; i++) {
            const serviceTag = services[i];
            const jobsOnService = allJobs.filter(job => job.services.filter(service => service._id === serviceTag._id).length > 0);
            let active = 0;
            for (const job of jobsOnService) {
                if (job.state === 'inactive') continue;
                const customer = Data.get<Customer>(job.customerId);
                if (!customer || customer.state === 'inactive') continue;
                active++;
            }
            const serviceData: TagData = new TagData(serviceTag, jobsOnService, active);

            servicesData.push(serviceData);
        }

        return servicesData;
    }

    public static getServiceData = (serviceId: string) => {
        const serviceTag = Data.get<Tag>(serviceId);
        if (!serviceTag) return;
        const allJobs = JobService.getJobs();

        const jobsOnService = allJobs.filter(job => job.services.filter(service => service._id === serviceTag._id).length > 0);
        let active = 0;
        for (const job of jobsOnService) {
            if (job.state === 'inactive') continue;
            const customer = Data.get<Customer>(job.customerId);
            if (!customer || customer.state === 'inactive') continue;
            active++;
        }
        const serviceData: TagData = new TagData(serviceTag, jobsOnService, active);

        return serviceData;
    };

    public static async deleteService(id: string) {
        const service = Data.get<Service>(id);
        const cantRemoveService: string[] = [];

        if (service) {
            const allCustomers = Data.all<Customer>('customers');
            for (let i = 0; i < allCustomers.length; i++) {
                const customer = allCustomers[i];
                let customerUpdated = false;
                if (customer.jobs) {
                    for (let i = 0; i < Object.keys(customer.jobs).map(jobId => customer.jobs[jobId]).length; i++) {
                        const job = Object.keys(customer.jobs).map(jobId => customer.jobs[jobId])[i];
                        if (job.services) {
                            let index = job.services.length;
                            while (index--) {
                                if (job.services[index]._id === id) {
                                    // if this is the only service, skip
                                    if (job.services.length === 1) {
                                        cantRemoveService.push(
                                            job.location?.addressDescription || customer.address.addressDescription || customer.name
                                        );
                                    } else {
                                        job.services.splice(index, 1);
                                        customerUpdated = true;
                                    }
                                }
                            }
                        }
                    }
                }
                if (customerUpdated && !cantRemoveService.length) await Data.put(customer);
            }
            if (!cantRemoveService.length) await Data.delete(service);
            return cantRemoveService;
        }
    }

    public static async renameService(oldDescription: string, newDescription: string, service: Service) {
        if (service) {
            try {
                if (service.showOnDirectory) {
                    service.description = newDescription;
                    await Data.put(service);
                    return;
                }

                const advancedServices = Data.all<Service>('tags', { type: TagType.SERVICE, showOnDirectory: true }).reduce(
                    (acc, service) => {
                        acc[service._id] = service;
                        return acc;
                    },
                    {} as Record<string, Service>
                );

                new LoaderEvent(true, true, 'dialogs.services-rename-title');
                service.description = newDescription;
                const updates = [service] as Array<StoredObject>;
                const replacedServiceIds = [] as Array<string>;

                const services = Data.all<Service>('tags', { type: TagType.SERVICE }).filter(
                    s => s._id !== service._id && !s.showOnDirectory
                );

                for (const service of services) {
                    if (service.description === newDescription || service.description === oldDescription) {
                        replacedServiceIds.push(service._id);
                        service._deleted = true;
                        updates.push(service);
                    }
                }

                for (const customer of Data.all<Customer>('customers')) {
                    for (const jobId in customer.jobs) {
                        const job = customer.jobs[jobId];
                        if (job.services) {
                            const occurrences = Data.all<JobOccurrence>('joboccurrences', {
                                jobId: job._id,
                                status: JobOccurrenceStatus.NotDone,
                            });

                            for (const occurrence of occurrences) {
                                for (const occurrenceService of occurrence.services) {
                                    if (
                                        !advancedServices[occurrenceService._id] &&
                                        (occurrenceService.description === newDescription ||
                                            occurrenceService.description === oldDescription ||
                                            replacedServiceIds.includes(occurrenceService._id))
                                    ) {
                                        occurrenceService.description = newDescription;
                                        occurrenceService._id = service._id;
                                        updates.push(occurrence);
                                    }
                                }
                            }

                            for (const jobService of job.services) {
                                if (
                                    !advancedServices[jobService._id] &&
                                    (jobService.description === newDescription ||
                                        jobService.description === oldDescription ||
                                        replacedServiceIds.includes(jobService._id))
                                ) {
                                    jobService.description = newDescription;
                                    jobService._id = service._id;
                                    if (!updates.includes(customer)) updates.push(customer);
                                }
                            }
                        }
                    }
                }

                await Data.put(updates);
            } catch (error) {
                console.error(error);
            } finally {
                new LoaderEvent(false);
            }
        }
    }
    public static async getServices(): Promise<Array<Service>> {
        let services = Data.all<Service>('tags', tag => tag.type === TagType.SERVICE).slice();
        services = ServicesService.checkForAndRemoveDuplicates(services);
        return services;
    }

    private static checkForAndRemoveDuplicates(array: any[]): any[] {
        for (let j = 0; j < array.length; j++) {
            for (let k = j + 1; k < array.length; k++) {
                if (k !== j && array[k].description === array[j].description) {
                    array.splice(k, 1);
                }
            }
        }
        return array;
    }

    public static async updateService(oldService: Service, service: Service) {
        await Data.delete(oldService);
        await Data.put(service);
    }
}
