import type { LngLat } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { LocationUtilities } from '../../Location/LocationUtilities';
import type { ScheduleItem } from './ScheduleItem';

export class ScheduleItemGroup {
    public readonly isGroup = true;
    constructor(public readonly group: string, public readonly label: string, public items: Array<ScheduleItem>) {}

    private _groupPrice: string;
    public get groupPrice() {
        if (this._groupPrice === undefined) {
            let groupPrice = 0;
            for (let i = 0; i < this.items.length; i++) {
                groupPrice += this.items[i].job.price || 0;
            }
            this._groupPrice = groupPrice.toFixed(2);
        }
        return this._groupPrice;
    }

    private _totalDistance: string;
    public get totalDistance() {
        if (this._totalDistance === undefined) {
            let totalDistance = 0;
            const currentPosition = ApplicationState.position;

            let start: LngLat | undefined = undefined;

            let from: LngLat | undefined = undefined;
            if (currentPosition) {
                start = [currentPosition.coords.longitude, currentPosition.coords.latitude];
                from = start;
            }

            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                if (item.occurrence && item.occurrence.location && item.occurrence.location.lngLat) {
                    if (from) {
                        totalDistance += LocationUtilities.getDistanceBetweenPoints(
                            from,
                            item.occurrence.location.lngLat,
                            ApplicationState.distanceUnits
                        );
                    }
                    from = item.occurrence.location.lngLat;
                }
            }

            if (from && start && from !== start) {
                totalDistance += LocationUtilities.getDistanceBetweenPoints(from, start, ApplicationState.distanceUnits);
            }

            this._totalDistance = totalDistance.toFixed(2) + ' ' + ApplicationState.distanceUnits;
        }
        return this._totalDistance;
    }
}
