import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { TextDialog } from '../../Dialogs/TextDialog';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { validateEmailList } from '../../Utilities';
import type { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import type { IScheduleItemAction } from './IScheduleItemAction';

export class SendJobNotification implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {
        if (scheduleItem.occurrence.paymentStatus === 'paid') {
            this.tooltip = 'send.job-receipt';
            this.actionType = <ItemActionType>'action-send-job-complete';
            this._notificationType = 'receipt';
        } else {
            this.tooltip = 'send.job-complete-notice';
            this.actionType = <ItemActionType>'action-send-job-receipt';
            this._notificationType = 'complete';
        }
    }

    private _notificationType: 'receipt' | 'complete';

    public actionType: ItemActionType;

    public async handler() {
        const emailPrompt = new TextDialog(
            'customers.email',
            'enter.email-to-send-job-completion-notification',
            this.scheduleItem.customer.email || '',
            '',
            validateEmailList,
            false,
            'email',
            'general.send'
        );

        const email = await emailPrompt.show();

        if (emailPrompt.cancelled) return;

        const sent = await ScheduleService.sendJobNotification(this._notificationType, this.scheduleItem, email);

        const paid = this.scheduleItem.occurrence.paymentStatus === 'paid';

        new NotifyUserMessage(
            <TranslationKey>(
                (sent
                    ? `Job ${paid ? 'receipt' : 'complete notice'} sent`
                    : `Failed to send job ${paid ? 'receipt' : 'complete notice'}, customer has no email address.`)
            )
        );
    }
    public tooltip: TranslationKey;
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker', 'Creator', 'Planner'];
}
