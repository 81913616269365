import BackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { isAreaSettingsKey, type Area, type AreaSettings } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { prompt } from '../../Dialogs/ReactDialogProvider';
export type AreaPriceModiferEditDialogProps = {
    areas: Array<Area>;
    selectedArea: Area;
    onSave: (area: Area) => void;
    onDelete: (areaId: string) => void;
    onClose: () => void;
    hidden?: boolean;
};

const AreaPriceModifierEditDialog = ({ areas, selectedArea, onSave, onDelete, onClose, hidden }: AreaPriceModiferEditDialogProps) => {
    // const { t } = useTranslation();

    const [updatedArea, setUpdatedArea] = useState<Area>(selectedArea);

    useEffect(() => {
        setUpdatedArea(selectedArea);
    }, [selectedArea]);

    const [error, setError] = useState<string>();
    const validateOutwardCode = (code: string) => {
        const regex = /^[A-Z]{1,2}[0-9R][0-9A-Z]?$/;
        const validCode = regex.test(code);
        setError(validCode ? '' : 'Invalid area code');

        if (areas.find(a => a.code === code && a.id !== updatedArea.id)) {
            setError('Area modifier already exists');
        }
    };

    const handleAreaChange = (key: keyof Area | keyof AreaSettings, value: string | boolean) => {
        if (isAreaSettingsKey(key)) {
            const newArea = { ...updatedArea, settings: { ...updatedArea.settings, [key]: value } };
            setUpdatedArea(newArea);
            return;
        }
        if (key === 'code') {
            validateOutwardCode(value as string);
        }
        const newArea = { ...updatedArea, [key]: value };
        setUpdatedArea(newArea);
    };

    const handlePriceModifierTypeChange = (_event: React.MouseEvent<HTMLElement>, newValue: string) => {
        if (!newValue) return;
        handleAreaChange('effectType', newValue);
    };

    const handleSaveClick = () => {
        onSave(updatedArea);
        onClose();
    };

    const handleDeleteClick = async () => {
        const warning = prompt(
            'area-price-modifiers.delete-confirmation',
            'area-price-modifiers.delete-confirmation-description',
            { okLabel: 'general.delete', cancelLabel: 'general.cancel' },
            { okButton: { variant: 'contained', color: 'error' } }
        );

        await warning.show();

        if (warning.cancelled) return;

        onDelete(selectedArea.id);
    };

    return (
        <Stack gap={1} hidden={hidden}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                <IconButton onClick={onClose}>
                    <BackIcon />
                </IconButton>
                <Button onClick={handleSaveClick}>Save</Button>
            </Stack>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6">Edit Area Settings</Typography>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} my={1}>
                    <Typography>Enable Price Modifier</Typography>
                    <Switch
                        checked={updatedArea.settings.isEnabled}
                        onChange={event => handleAreaChange('isEnabled', event.target.checked)}
                    />
                </Stack>
                <TextField
                    label="Area Name"
                    value={updatedArea.name}
                    onChange={event => {
                        if (event.target.value.length > 58) return;
                        handleAreaChange('name', event.target.value);
                    }}
                    margin="dense"
                    size="small"
                    disabled={!updatedArea.settings.isEnabled}
                />
                {updatedArea.name.length > 40 && (
                    <Typography
                        variant="caption"
                        color={updatedArea.name.length > 50 ? 'error.main' : updatedArea.name.length > 40 ? 'warning.dark' : 'textPrimary'}
                        fontWeight={500}
                        gutterBottom
                    >
                        {updatedArea.name.length}/58 characters
                    </Typography>
                )}
                <TextField
                    label="Area Code"
                    value={updatedArea.code}
                    onChange={event => {
                        handleAreaChange('code', event.target.value);
                    }}
                    margin="dense"
                    size="small"
                    disabled={!updatedArea.settings.isEnabled}
                    required
                />
                {error && (
                    <Typography variant="caption" color="error.main" fontWeight={500} gutterBottom>
                        {error}
                    </Typography>
                )}
                <TextField
                    label="Price Modifier"
                    value={updatedArea.settings.priceModifier}
                    onChange={event => handleAreaChange('priceModifier', event.target.value)}
                    onBlur={event => handleAreaChange('priceModifier', event.target.value || '0')}
                    margin="normal"
                    size="small"
                    disabled={!updatedArea.settings.isEnabled}
                    InputProps={{
                        startAdornment: updatedArea.settings.effectType === 'fixed' && <InputAdornment position="start">£</InputAdornment>,
                        endAdornment: updatedArea.settings.effectType === 'percentage' && <InputAdornment position="end">%</InputAdornment>,
                    }}
                    type="number"
                    required
                />
                <Stack gap={2}>
                    <ToggleButtonGroup
                        value={updatedArea.settings.effectType}
                        onChange={handlePriceModifierTypeChange}
                        disabled={!updatedArea.settings.isEnabled}
                        color="primary"
                        exclusive
                        fullWidth
                    >
                        <ToggleButton value="percentage" onClick={() => handleAreaChange('effectType', 'percentage')} fullWidth>
                            Percentage
                        </ToggleButton>
                        <ToggleButton value="fixed" onClick={() => handleAreaChange('effectType', 'fixed')} fullWidth>
                            Fixed
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Button onClick={handleDeleteClick} color="error" variant="outlined" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Stack>
            </DialogPaper>
        </Stack>
    );
};

export default AreaPriceModifierEditDialog;
