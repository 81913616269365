import { EventAggregator } from 'aurelia-event-aggregator';
import { inject, transient } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';

import { LoaderEvent } from '../../Events/LoaderEvent';
import { ScheduleView } from './ScheduleView';

@inject(EventAggregator, Router, BindingSignaler)
@transient()
export class AllJobsView extends ScheduleView {
    public viewName = 'allJobs_';

    chartMode: 'chart' | 'users' | 'list' = 'list';

    constructor(public eventAggregator: EventAggregator, public router: Router, protected signaler: BindingSignaler) {
        super(eventAggregator, router, signaler);
        this.chartMode = 'list';
    }

    public async onRefresh() {
        this.chartMode = 'list';
        new LoaderEvent(true);
        await this.updateWideData();
        await this.filterForDay();
        new LoaderEvent(false);
    }
}
