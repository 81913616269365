import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { InvoiceMessageModel } from '../Invoices/InvoiceMessageModel';
import { PriceChangeMessageModel } from '../Schedule/PriceChangeMessageModel';
import { ScheduleItemMessageModel } from '../Schedule/ScheduleItemMessageModel';
import { StandardMessageModel } from '../StandardMessageModel';
const standardMessageModelTokens = Object.keys(new StandardMessageModel()).map(name => `[${name}]`);
export const standardMessageModelTokenList = standardMessageModelTokens.join(' ');

const invoiceMessageModelTokens = Object.keys(new InvoiceMessageModel()).map(name => `[${name}]`);
const invoiceMessageModelTokenList = `${standardMessageModelTokenList} ${invoiceMessageModelTokens.join(' ')}`;

const scheduleItemMessageModelTokens = Object.keys(new ScheduleItemMessageModel()).map(name => `[${name}]`);
const scheduleItemMessageModelTokenList = `${standardMessageModelTokenList} ${scheduleItemMessageModelTokens.join(' ')}`;

const priceChangeMessageModelTokens = Object.keys(new PriceChangeMessageModel()).map(name => `[${name}]`);
const priceChangeMessageModelTokenList = `${standardMessageModelTokenList} ${priceChangeMessageModelTokens.join(' ')}`;

const bookingConfirmationTokens = Object.keys({ items: [] }).map(name => `[${name}]`);
export const bookingConfirmationTokenList = `${standardMessageModelTokenList} ${bookingConfirmationTokens.join(' ')}`;

interface IMessageTemplateDictionaryEntry {
    title: TranslationKey;
    instructions: TranslationKey;
    default: string;
}

export class MessageTemplates {
    private static _instance: MessageTemplates;
    public static get instance() {
        if (!MessageTemplates._instance) MessageTemplates._instance = new MessageTemplates();
        return MessageTemplates._instance;
    }
    public emailAppointmentReminder: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.email-appointment-reminder-title'),
        instructions: `${ApplicationState.localise(
            'message-templates.instructions'
        )} ${scheduleItemMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.localise('message-templates.appointment-reminder-default-body', {
                date: '[formattedDate]',
                services: '[serviceList]',
            })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly smsAppointmentReminder: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.sms-appointment-reminder-title'),
        instructions: `${ApplicationState.localise(
            'message-templates.instructions'
        )} ${scheduleItemMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.localise('message-templates.appointment-reminder-default-body', {
                date: '[formattedDate]',
                services: '[serviceList]',
            })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly emailPaymentRequest: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.email-payment-request-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${standardMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.localise('message-templates.payment-request-default-body', { balance: '[balance]' })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly smsPaymentRequest: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.sms-payment-request-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${standardMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.localise('message-templates.payment-request-default-body', { balance: '[balance]' })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly emailInvoice: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('settings.templates-email-invoice-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${invoiceMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.localise('message-templates.invoice-default-body', {
                reference: '[balance]',
                total: '[total]',
                url: '[url]',
            })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly smsInvoice: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('settings.templates-sms-invoice-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${invoiceMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.localise('message-templates.invoice-default-body', {
                reference: '[balance]',
                total: '[total]',
                url: '[url]',
            })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly smsAutomaticPaymentInvite: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.sms-automatic-payment-invite-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${standardMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}\n` +
            `${
                ApplicationState.account.businessName || ApplicationState.account.name
            } has invited you to setup payment details to simplify taking payments:\n\n[inviteLink]` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly emailAutomaticPaymentInvite: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.email-automatic-payment-invite-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${standardMessageModelTokenList} ` as TranslationKey,
        default: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                <html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
                  <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" />
                    <!--[if (gte mso 9)|(IE)]>
                    <xml>
                    <o:OfficeDocumentSettings>
                    <o:AllowPNG/>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                    </o:OfficeDocumentSettings>
                    </xml>
                    <![endif]-->
                    <!--[if (gte mso 9)|(IE)]>
                    <style type="text/css">
                      body {width: 600px;margin: 0 auto;}
                      table {border-collapse: collapse;}
                      table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
                      img {-ms-interpolation-mode: bicubic;}
                    </style>
                    <![endif]-->
                
                    <style type="text/css">
                      body, p, div {
                        font-family: verdana,geneva,sans-serif;
                        font-size: 14px;
                      }
                      body {
                        color: #000000;
                      }
                      body a {
                        color: #1188E6;
                        text-decoration: none;
                      }
                      p { margin: 0; padding: 0; }
                      table.wrapper {
                        width:100% !important;
                        table-layout: fixed;
                        -webkit-font-smoothing: antialiased;
                        -webkit-text-size-adjust: 100%;
                        -moz-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                      }
                      img.max-width {
                        max-width: 100% !important;
                      }
                      .column.of-2 {
                        width: 50%;
                      }
                      .column.of-3 {
                        width: 33.333%;
                      }
                      .column.of-4 {
                        width: 25%;
                      }
                      @media screen and (max-width:480px) {
                        .preheader .rightColumnContent,
                        .footer .rightColumnContent {
                            text-align: left !important;
                        }
                        .preheader .rightColumnContent div,
                        .preheader .rightColumnContent span,
                        .footer .rightColumnContent div,
                        .footer .rightColumnContent span {
                          text-align: left !important;
                        }
                        .preheader .rightColumnContent,
                        .preheader .leftColumnContent {
                          font-size: 80% !important;
                          padding: 5px 0;
                        }
                        table.wrapper-mobile {
                          width: 100% !important;
                          table-layout: fixed;
                        }
                        img.max-width {
                          height: auto !important;
                          max-width: 480px !important;
                        }
                        a.bulletproof-button {
                          display: block !important;
                          width: auto !important;
                          font-size: 80%;
                          padding-left: 0 !important;
                          padding-right: 0 !important;
                        }
                        .columns {
                          width: 100% !important;
                        }
                        .column {
                          display: block !important;
                          width: 100% !important;
                          padding-left: 0 !important;
                          padding-right: 0 !important;
                          margin-left: 0 !important;
                          margin-right: 0 !important;
                        }
                      }
                    </style>
                    <!--user entered Head Start-->
                    
                     <!--End Head user entered-->
                  </head>
                  <body>
                <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size: 14px; font-family: verdana,geneva,sans-serif; color: #000000; background-color: #ffffff;">
                <div class="webkit">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#ffffff">
                <tbody>
                <tr>
                <td valign="top" bgcolor="#ffffff" width="100%">
                <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                <tbody>
                <tr>
                <td width="100%">
                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                <tbody>
                <tr>
                <td><!-- [if mso]>
                                          <center>
                                          <table><tr><td width="600">
                                          <![endif]-->
                <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width: 100%; max-width: 600px;" align="center">
                <tbody>
                <tr>
                <td role="modules-container" style="padding: 0px 0px 0px 0px; color: #000000; text-align: left;" bgcolor="#ffffff" width="100%" align="left">
                <table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
                <tbody>
                <tr>
                <td role="module-content">
                <p></p>
                </td>
                </tr>
                </tbody>
                </table>
                <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" role="module" data-type="columns" data-version="2" style="padding: 16px 0px 16px 0px; background-color: #4f699e; box-sizing: border-box;" bgcolor="#4f699e">
                <tbody>
                <tr role="module-content">
                <td height="100%" valign="top"><!-- [if (gte mso 9)|(IE)]>
                              <center>
                                <table cellpadding="0" cellspacing="0" border="0" width="100%" style="border-spacing:0;border-collapse:collapse;table-layout: fixed;" >
                                  <tr>
                            <![endif]--> <!-- [if (gte mso 9)|(IE)]>
                      <td width="600.000px" valign="top" style="padding: 0px 0px 0px 0px;border-collapse: collapse;" >
                    <![endif]-->
                <table width="600.000" style="width: 600.000px; border-spacing: 0; border-collapse: collapse; margin: 0px 0px 0px 0px;" cellpadding="0" cellspacing="0" align="left" border="0" bgcolor="#4f699e" class="column column-0 of-1
                                  empty">
                <tbody>
                <tr>
                <td style="padding: 0px; margin: 0px; border-spacing: 0;">
                <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="font-size: 6px; line-height: 10px; padding: 0px 0px 0px 0px;" valign="top" align="center"><img class="max-width" border="0" style="display: block; color: #000000; text-decoration: none; font-family: Helvetica, arial, sans-serif; font-size: 16px; max-width: 45% !important; width: 45%; height: auto !important;" src="https://marketing-image-production.s3.amazonaws.com/uploads/42ecac8bafcb8f9aad73830e6e598725cd55a74a325f865b8352541dc7e4800414a37b7f4cc707d1a7f03a68c101e71e1c989c7b9afe03ae771744f664650a8d.png" alt="" width="420" /></td>
                </tr>
                </tbody>
                </table>
                <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="padding: 16px 0px 0px 0px; line-height: 22px; text-align: inherit;" height="100%" valign="top" bgcolor="">
                <h2 style="text-align: center;"><strong><span style="color: #ffffff;">You Are Invited</span></strong></h2>
                </td>
                </tr>
                </tbody>
                </table>
                </td>
                </tr>
                </tbody>
                </table>
                <!-- [if (gte mso 9)|(IE)]>
                      </td>
                    <![endif]--> <!-- [if (gte mso 9)|(IE)]>
                                  <tr>
                                </table>
                              </center>
                            <![endif]--></td>
                </tr>
                </tbody>
                </table>
                <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="padding: 34px 0px 24px 0px; line-height: 22px; text-align: justify;" height="100%" valign="top" bgcolor="">
                <p style="text-align: center;"><span style="color: #444444;"><span style="font-size: 16px;">Make payments easier by setting up an automatic payment method</span></span></p>
                </td>
                </tr>
                </tbody>
                </table>
                <table border="0" cellpadding="0" cellspacing="0" class="module" data-role="module-button" data-type="button" role="module" style="table-layout: fixed;" width="100%">
                <tbody>
                <tr>
                <td align="center" class="outer-td" style="padding: 0px 0px 0px 0px;">
                <table border="0" cellpadding="0" cellspacing="0" class="button-css__deep-table___2OZyb wrapper-mobile" style="text-align: center;">
                <tbody>
                <tr>
                <td align="center" bgcolor="#4F699E" class="inner-td" style="border-radius: 6px; font-size: 16px; text-align: center; background-color: inherit;"><a href="[inviteLink]" target="_blank" style="background-color: #4f699e; border: 1px solid #333333; border-color: #4b6595; border-radius: 6px; border-width: 1px; color: #ffffff; display: inline-block; font-family: arial,helvetica,sans-serif; font-size: 14px; font-weight: normal; letter-spacing: 0px; line-height: 12px; padding: 012px 18px 12px 18px; text-align: center; text-decoration: none;" rel="noopener">Setup Now</a></td>
                </tr>
                </tbody>
                </table>
                </td>
                </tr>
                </tbody>
                </table>
                <table class="module" role="module" data-type="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="padding: 16px 0px 16px 0px;" role="module-content" height="100%" valign="top" bgcolor="">
                <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" height="2px" style="line-height: 2px; font-size: 2px;">
                <tbody>
                <tr>
                <td style="padding: 0px 0px 2px 0px;" bgcolor="#e4e4e4"></td>
                </tr>
                </tbody>
                </table>
                </td>
                </tr>
                </tbody>
                </table>
                <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="padding: 18px 0px 18px 0px; line-height: 22px; text-align: inherit;" height="100%" valign="top" bgcolor="">
                <div><span style="color: #333333;"><strong>Benefits</strong></span></div>
                <p style="color: #333333;">You will no longer need to remember to pay invoices after each appointment.</p>
                <p style="color: #333333;">You will only be charged once your appointment has been completed, so if for any reason it can&rsquo;t go ahead you won&rsquo;t be charged.</p>
                <p style="color: #333333;">You won&rsquo;t receive any reminders to pay so no additional texts or emails from us asking for payment.</p>
                <p style="color: #333333;">All sensitive details are encrypted, and only communicated securely.</p>
                <div>&nbsp;</div>
                </td>
                </tr>
                </tbody>
                </table>
                <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="padding: 18px 0px 0px 0px; line-height: 22px; text-align: inherit;" height="100%" valign="top" bgcolor="">
                <div style="text-align: center;"><strong><span style="color: #666666;"><span style="font-size: 14px;">Powered By</span></span></strong></div>
                </td>
                </tr>
                </tbody>
                </table>
                <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                <tr>
                <td style="font-size: 6px; line-height: 10px; padding: 0px 0px 0px 4px;" valign="top" align="center"><a href="https://squeeg.ee"><img class="max-width" border="0" style="display: block; color: #000000; text-decoration: none; font-family: Helvetica, arial, sans-serif; font-size: 16px; max-width: 24% !important; width: 24%; height: auto !important;" src="https://marketing-image-production.s3.amazonaws.com/uploads/122de1d515f642f95917fe967a918bf4f0d4ecb96abeb85d57b1cf45d5f35023abcebd9a8e2abc58f76b017fb1592d529a07c1c0c34acf5ee81550c2e52f056d.png" alt="Squeegee &reg;" width="144" /></a></td>
                </tr>
                </tbody>
                </table>
                </td>
                </tr>
                </tbody>
                </table>
                <!-- [if mso]>
                                          </td></tr></table>
                                          </center>
                                          <![endif]--></td>
                </tr>
                </tbody>
                </table>
                </td>
                </tr>
                </tbody>
                </table>
                </td>
                </tr>
                </tbody>
                </table>
                </div>
                </center>
                </body>
                </html>`,
    };
    public readonly emailMessage: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.email-message-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${standardMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };
    public readonly smsMessage: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.sms-message-title'),
        instructions: `${ApplicationState.localise('message-templates.instructions')} ${standardMessageModelTokenList} ` as TranslationKey,
        default:
            `${ApplicationState.localise('message-templates.default-intro', { name: '[firstname]' })}` +
            `\n\n${ApplicationState.account.businessName || ApplicationState.account.name}`,
    };

    public readonly emailPriceChange: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.email-price-change-title'),
        instructions: `${ApplicationState.localise(
            'message-templates.instructions'
        )} ${priceChangeMessageModelTokenList} ` as TranslationKey,
        default: `<!DOCTYPE html>
            <html>
            <head>
            </head>
            <body>
            <p>Hi [firstname],</p>
            <p>Due to market pressure, we are increasing the price of your services from [formattedPlannedDate].</p>
            <p>Services<br />[serviceList]</p>
            <p>Old Price<br />[formattedOldPrice]</p>
            <p>New Price<br />[formattedNewPrice]</p>
            <p>Difference<br />[formattedDifference]</p>
            <p>[businessName]</p>
            </body>
            </html>`,
    };
    public readonly smsPriceChange: IMessageTemplateDictionaryEntry = {
        title: ApplicationState.localise('message-templates.sms-price-change-title'),
        instructions: `${ApplicationState.localise(
            'message-templates.instructions'
        )} ${priceChangeMessageModelTokenList} ` as TranslationKey,
        default: `Hi [firstname],

            Due to market pressure, we are increasing the price of your services from [formattedPlannedDate].
            
            Services
            [serviceList]
            
            Old Price
            [formattedOldPrice]
            
            New Price
            [formattedNewPrice]
            
            Difference
            [formattedDifference]
            
            [businessName]`,
    };
}
