import type { Transaction } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';

export class TransactionTagsCustomElement {
    @bindable() forceStatusCheck = false;
    @bindable() transaction: Transaction;
    @bindable() includeCustomerData = false;
    private initialised = false;
    protected isDevModeEnabled = ApplicationState.stateFlags.devMode;

    protected notificationTags: Array<{ text: string; status: string }> = [];

    async transactionChanged() {
        if (!this.initialised) return;
        if (!this.transaction) return;
        this.notificationTags = await TransactionUtils.getTransactionNotificationStatusTags(this.transaction, this.forceStatusCheck);
    }

    @computedFrom('transaction')
    protected get tags() {
        if (!this.transaction) return;
        return TransactionUtils.getTagsForTransaction(this.transaction, this.includeCustomerData);
    }

    @computedFrom('transaction')
    protected get providers() {
        const providers = Object.entries(this.transaction?.externalIds || {}).filter(([, value]) => !!value);

        return providers.map(([key]) => {
            const parts = key.split('.');
            return parts[0];
        });
    }

    async attached() {
        if (!this.transaction) return;
        this.notificationTags = await TransactionUtils.getTransactionNotificationStatusTags(this.transaction, this.forceStatusCheck);
        this.initialised = true;
    }
}
