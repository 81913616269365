import type { Directions } from '@nexdynamic/squeegee-common';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from './Api';

export class DirectionsApi {
    private static async post<TReturnType>(url: string, data: any) {
        try {
            return await Api.post<TReturnType>(Api.apiEndpoint, url, data, undefined, undefined, { timeout: 120000 });
        } catch (error) {
            Logger.error(`Error during post to the directions API`, { url, data, error });
        }
    }

    public static async getOptimisedDirections(directions: Directions): Promise<Directions | undefined> {
        if (!directions || !directions.wayPoints?.length) return directions;
        const start = directions.startPoint;
        const end = directions.endPoint;
        const allSameAsStart = directions.wayPoints.every(d => d.lngLat[0] === start[0] && d.lngLat[1] === start[1]);
        const noEndOrEndIsSameAsStart = !end || (end[0] === start[0] && end[1] === start[1]);

        if (allSameAsStart && noEndOrEndIsSameAsStart) {
            return directions;
        }

        if (directions.wayPoints.length > 201) {
            new NotifyUserMessage('notifications.over-max-waypoints-for-optimisation', { count: '202' });
            return directions;
        }

        return new Promise<Directions | undefined>(async (resolve, reject) => {
            try {
                const response = await DirectionsApi.post<Directions>('/api/directions', { directions: directions, optimise: true });
                if (response && response.status === 200) {
                    Logger.info('Optimised waypoints directions returned');
                    return resolve(response.data);
                } else {
                    return reject(`Problem getting optimised waypoints`);
                }
            } catch (error) {
                Logger.error('Error in getDirections() on DirectionsApi', { directions, error });
                return reject(`Problem getting optimised waypoints: ${JSON.stringify(error)}`);
            }
        });
    }

    public static async getFixedDirections(directions: Directions): Promise<Directions | undefined> {
        if (directions.wayPoints.length > 201) {
            new NotifyUserMessage('notifications.over-max-waypoints-for-optimisation', { count: '202' });
            return directions;
        }

        return new Promise<Directions | undefined>(async (resolve, reject) => {
            if (!directions) return resolve(undefined);
            const url = '/api/directions';
            try {
                const response = await DirectionsApi.post<Directions>(url, { directions: directions, optimise: false });
                if (response && response.status === 200) {
                    Logger.info('Fixed waypoints directions returned');
                    return resolve(response.data);
                } else {
                    return reject(`Problem getting fixed waypoints`);
                }
            } catch (error) {
                Logger.error('Error in getDirectionsFixedWaypoints() on DirectionsApi', { directions, error });
                return reject(`Problem getting fixed waypoints: ${JSON.stringify(error)}`);
            }
        });
    }
}
