import type { AutomaticPaymentTransactionSubType, PaymentAccount, PaymentTransactionSubType } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { Data } from '../../Data/Data';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { Logger } from '../../Logger';

type PmtType = PaymentTransactionSubType | AutomaticPaymentTransactionSubType;

export class PaymentAccountSettingsCustomElement {
    paymentAccountSettings?: Record<PmtType, PaymentAccount | undefined>;
    static loadPaymentAccountSettings(): Record<PmtType, PaymentAccount | undefined> {
        return {
            'payment.bank-transfer': this.loadSetting('payment.bank-transfer'),
            'payment.card': this.loadSetting('payment.card'),
            'payment.cash': this.loadSetting('payment.cash'),
            'payment.cheque': this.loadSetting('payment.cheque'),
            'payment.imported': this.loadSetting('payment.imported'),
            'payment.other': this.loadSetting('payment.other'),
            'payment.refund': this.loadSetting('payment.refund'),
            'payment.square': this.loadSetting('payment.square'),
            'payment.stripe': this.loadSetting('payment.stripe'),
            'payment.tip.bank-transfer': this.loadSetting('payment.tip.bank-transfer'),
            'payment.tip.card': this.loadSetting('payment.tip.card'),
            'payment.tip.cash': this.loadSetting('payment.tip.cash'),
            'payment.tip.cheque': this.loadSetting('payment.tip.cheque'),
            'payment.tip.other': this.loadSetting('payment.tip.other'),
            'auto.other': this.loadSetting('auto.other'),
        };
    }

    static async clearSetting(setting: PmtType) {
        await ApplicationState.setSetting<string, PmtType>('global.payment-account', '', setting);
    }

    static loadSetting(setting: PmtType): PaymentAccount | undefined {
        const paymentId = ApplicationState.getSetting<string, PmtType>('global.payment-account', undefined, setting);
        if (!paymentId) return;
        return Data.get<PaymentAccount>(paymentId);
    }

    attached() {
        this.paymentAccountSettings = PaymentAccountSettingsCustomElement.loadPaymentAccountSettings();

        this.subscription = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (Object.keys(event.updatedObjects).find(k => k.startsWith('global.payment-account'))) {
                this.paymentAccountSettings = PaymentAccountSettingsCustomElement.loadPaymentAccountSettings();
            }
        });
    }

    private subscription?: Subscription;
    detached() {
        this.subscription?.dispose();
    }

    async selectAccount(paymentType: PmtType) {
        if (!this.paymentAccountSettings) return Logger.error('Unable to set PaymentAccount settings');
        if (this.paymentAccountSettings[paymentType]) {
            this.paymentAccountSettings[paymentType] = undefined;
            await ApplicationState.setSetting<string, PmtType>('global.payment-account', '', paymentType);
            return;
        }
        const paymentAccount = await AccountsLedgerService.selectAccount();
        if (!paymentAccount) return;
        this.paymentAccountSettings[paymentType] = paymentAccount;
        await ApplicationState.setSetting<string, PmtType>('global.payment-account', paymentAccount._id, paymentType);
    }

    get keys() {
        if (!this.paymentAccountSettings) return [];
        return Object.keys(this.paymentAccountSettings);
    }
}
