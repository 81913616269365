import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Prompt } from '../../Dialogs/Prompt';
import type { ReminderSendResult } from '../ReminderSendResult';

export const showReminderResultPrompt = async (messageType: 'sms' | 'email', result: ReminderSendResult) => {
    if (result.status === 'cancelled') return;

    const localisationParams = {
        total: result.total.toString(),
        sent: result.sent.toString(),
        scheduled: result.sent.toString(),
        failed: result.failed.toString(),
        missingEmail: result.missingSmsOrEmail.toString(),
        missingSms: result.missingSmsOrEmail.toString(),
        x: result.sent.toString(),
        y: result.total.toString(),
    };

    let message: TranslationKey;
    const allMissingEmailOrNumber = result.missingSmsOrEmail === result.failed;
    const deliveryType = result.status;
    switch (result.failed) {
        case 0: {
            switch (messageType) {
                case 'sms': {
                    switch (deliveryType) {
                        case 'sent': {
                            message = 'prompts.sms-reminders-sent-successfully-text';
                            break;
                        }
                        case 'scheduled': {
                            message = 'prompts.sms-reminders-scheduled-successfully-text';
                            break;
                        }
                    }
                    break;
                }
                case 'email': {
                    switch (deliveryType) {
                        case 'sent': {
                            message = 'prompts.email-reminders-sent-successfully-text';
                            break;
                        }
                        case 'scheduled': {
                            message = 'prompts.email-reminders-scheduled-successfully-text';
                            break;
                        }
                    }
                    break;
                }
            }
            break;
        }
        case result.total: {
            if (allMissingEmailOrNumber) {
                switch (messageType) {
                    case 'sms': {
                        message = 'prompts.sms-reminders-all-x-missing-sms';
                        break;
                    }
                    case 'email': {
                        message = 'prompts.email-reminders-all-x-missing-email';
                        break;
                    }
                }
            } else {
                switch (messageType) {
                    case 'sms': {
                        message = 'prompts.sms-reminders-all-x-failed';
                        break;
                    }
                    case 'email': {
                        message = 'prompts.email-reminders-all-x-failed';
                        break;
                    }
                }
            }
            break;
        }
        default: {
            switch (messageType) {
                case 'sms': {
                    switch (deliveryType) {
                        case 'sent': {
                            if (allMissingEmailOrNumber) {
                                message = 'prompts.sms-reminders-sent-successfully-with-failures-no-number-text';
                            } else {
                                message = 'prompts.sms-reminders-sent-successfully-with-failures-various-text';
                            }
                            break;
                        }
                        case 'scheduled': {
                            if (allMissingEmailOrNumber) {
                                message = 'prompts.sms-reminders-scheduled-successfully-with-failures-no-number-text';
                            } else {
                                message = 'prompts.sms-reminders-scheduled-successfully-with-failures-various-text';
                            }
                            break;
                        }
                    }
                    break;
                }
                case 'email': {
                    switch (deliveryType) {
                        case 'sent': {
                            if (allMissingEmailOrNumber) {
                                message = 'prompts.email-reminders-sent-successfully-with-failures-no-email-text';
                            } else {
                                message = 'prompts.email-reminders-sent-successfully-with-failures-various-text';
                            }
                            break;
                        }
                        case 'scheduled': {
                            if (allMissingEmailOrNumber) {
                                message = 'prompts.email-reminders-scheduled-successfully-with-failures-no-email-text';
                            } else {
                                message = 'prompts.email-reminders-scheduled-successfully-with-failures-various-text';
                            }
                            break;
                        }
                    }
                    break;
                }
            }
            break;
        }
    }

    const title: TranslationKey =
        result.status === 'sent' ? `prompts.reminders-sent-x-of-y-${messageType}` : `prompts.reminders-scheduled-x-of-y-${messageType}`;

    await new Prompt(title, message, { cancelLabel: '', localisationParams }).show();
};
