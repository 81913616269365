import type { AutomaticPaymentStatus } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Api } from './Api';

export class MandateApi {
    public static async checkCustomerMandate(customerId: string): Promise<AutomaticPaymentStatus> {
        const url: string = '/api/mandate/gocardless/' + customerId;
        const response = await Api.post<AutomaticPaymentStatus>(Api.apiEndpoint, url);
        if (response && response.data) {
            return response.data;
        } else {
            throw `Could not get ${ApplicationState.dd} status for customer`;
        }
    }
}
