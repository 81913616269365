import type { JobOrderData, Service } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Data } from '../Data/Data';
import { Api } from '../Server/Api';
import type { ServiceType } from './ServiceItem';

@inject(Router)
export class VisualisationService {
    @bindable() service?: Service;
    @bindable() showChildren?: boolean;

    thumbnail?: string;

    private _childServices: Array<Service> = [];
    constructor(protected router: Router) { }
    @computedFrom('service')
    get childServices() {
        if (!this.service) return;
        if (this.service.imageInfo?.id) {
            this.thumbnail = `${Api.currentHostAndScheme}/v/${this.service.imageInfo.id}`;
        }
        const orderData = Data.all<JobOrderData>('joborderdata', x => x.type === 'services')[0]?.data;

        const results = Data.all<Service>('tags', tag => tag.type === TagType.SERVICE && tag.parentId === this.service?._id).slice();

        results.sort((a: Service, b: Service) => {
            let orderA = a.order === undefined ? orderData?.indexOf(a._id) : a.order;
            let orderB = b.order === undefined ? orderData?.indexOf(b._id) : b.order;

            orderA = orderA > -1 ? orderA : 0;
            orderB = orderB > -1 ? orderB : 0;
            if (orderA < orderB) return -1;
            else if (orderA > orderB) return 1;
            else return 0;
        });
        this._childServices = results;
        this.serviceType = this.getServiceType();
        return results;
    }

    @computedFrom('service')
    get title() {
        return this.service?.description;
    }

    protected serviceType: string;
    getServiceType(): ServiceType {
        if (!this.service) return '';
        if (!this._childServices || !this._childServices.length) return '';
        if (this.service.minChildren === 0 && this.service.maxChildren === 0) return 'select-none';
        if (this.service.minChildren === 0 && this.service.maxChildren === 1) return 'select-one';
        if (this.service.minChildren === 1 && this.service.maxChildren === 1) return 'required-select-one';
        if (this._childServices.every(x => x.min)) return 'select-all';
        if (this.serviceType === 'custom' || (this.service.minChildren || 0) > 1) return 'custom';
        if (this.service.maxChildren === undefined || this.service.maxChildren > 1) return 'select-multiple';
        return '';
    }
    protected selectItem = async () => {
        if (!this.service) return;

        this.router.navigate(`/services-advanced/${this.service._id}/`);
    };
}
