import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { CreateOrEditInvoiceTransactionDialog } from '../../Invoices/CreateOrEditInvoiceTransactionDialog';
import { ScheduleItem } from '../Components/ScheduleItem';
import type { IScheduleItemAction } from './IScheduleItemAction';
import { ScheduleActionEvent } from './ScheduleActionEvent';
export class CreateInvoice implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {}
    public actionType = <ItemActionType>'action-invoice';
    public async handler() {
        const dialog = CreateOrEditInvoiceTransactionDialog.createDialog(this.scheduleItem.customer, this.scheduleItem.occurrence);
        if (!dialog) return;

        const transaction = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled && transaction) {
            new ScheduleActionEvent(this.actionType, this.scheduleItem.occurrence);
            ScheduleItem.refresh(this.scheduleItem);
            return Promise.resolve(this.scheduleItem);
        } else {
            return Promise.reject(this.scheduleItem);
        }
    }
    public featureCheck = 'invoiceGeneration';
    public tooltip: TranslationKey = 'actions.create-invoice';
    public roles: Array<UserRole> = ['Owner', 'Admin'];
}
