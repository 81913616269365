import type { CustomerAdditionalDetailsMap } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { TransactionService } from '../Transactions/TransactionService';
import type { ScheduleItem } from './Components/ScheduleItem';
import { getNoteText } from './getNoteText';

export const getAdditionalInfoForScheduleItems = (scheduleItems: Array<ScheduleItem>): CustomerAdditionalDetailsMap | undefined => {
    const today = moment().format('YYYY-MM-DD');
    const additional = scheduleItems.reduce<CustomerAdditionalDetailsMap>((map, s) => {
        map[s.customer._id] = {
            balance: -(TransactionService.getCustomerBalance(s.customer._id, today).amount || 0),
            text: getNoteText(s.customer._id),
        };
        return map;
    }, {});

    return additional;
};
