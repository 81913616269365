import type { ICustomerPaymentProvidersData } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { InvoiceService } from '../../Invoices/InvoiceService';
import type { ScheduleItem } from '../Components/ScheduleItem';
import { Assign } from './Assign';
import { CreateInvoice } from './CreateInvoice';
import { Done } from './Done';
import { DoneAndPaid } from './DoneAndPaid';
import type { IScheduleItemAction } from './IScheduleItemAction';
import { NotDone } from './NotDone';
import { PrintJobNotification } from './PrintJobNotification';
import { Replan } from './Replan';
import { SendJobNotification } from './SendJobNotification';
import { Skip } from './Skip';
import { TakePayment } from './TakePayment';

export class ScheduleItemActions {
    private isArchived() {
        return !!this.scheduleItem.occurrence._archived;
    }
    public actions: Array<IScheduleItemAction> = [];

    public primaryAction?: IScheduleItemAction;
    public secondaryAction?: IScheduleItemAction;

    constructor(public scheduleItem: ScheduleItem) {
        this.updateActions();
    }

    public get moreActions(): Array<IScheduleItemAction> {
        return this.actions.slice(2);
    }

    public updateActions() {
        const isAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
        const isPlanner = ApplicationState.isInAnyRole(['Planner']);
        const autoInvoice =
            (this.scheduleItem.customer.autoInvoiceMethod !== 'manual' &&
                ApplicationState.account.invoiceSettings.defaultInvoiceGenerationMethod !== 'manual') ||
            this.scheduleItem.customer.autoInvoiceMethod === 'auto';

        let hasAutoPayment = InvoiceService.hasCustomAutomaticPaymentMethod(this.scheduleItem.customer);

        if (!hasAutoPayment) {
            hasAutoPayment =
                !!this.scheduleItem.customer.automaticPaymentMethod &&
                ['active', 'pending'].indexOf(
                    this.scheduleItem.customer.paymentProviderMetaData?.[
                        this.scheduleItem.customer.automaticPaymentMethod as keyof ICustomerPaymentProvidersData
                    ]?.status || ''
                ) !== -1;
        }

        const canReplan = ApplicationState.isInAnyRole(['Admin', 'Creator', 'JobEditor', 'Owner', 'Planner']);
        const canAssign = ApplicationState.isInAnyRole(['Admin', 'Creator', 'JobEditor', 'Owner', 'Planner']);

        const showPricing =
            isAdmin ||
            this.scheduleItem.customer.hideJobPricesFromWorker === false ||
            ((this.scheduleItem.customer.hideJobPricesFromWorker === undefined ||
                this.scheduleItem.customer.hideJobPricesFromWorker === null) &&
                ApplicationState.account.hidePricesFromWorkerRole !== true);

        //both these features show pricing so can not allow.
        const canPrintComplete = showPricing;

        switch (this.scheduleItem.occurrence.status) {
            case JobOccurrenceStatus.NotDone: {
                if (this.isArchived()) return;

                const doneAction = new Done(this.scheduleItem);
                const skipAction = new Skip(this.scheduleItem);

                this.actions = [doneAction];

                if (
                    !ApplicationState.getSetting('global.hide-done-and-paid', false) &&
                    autoInvoice &&
                    !hasAutoPayment &&
                    this.scheduleItem.price !== 0
                ) {
                    this.actions.push(new DoneAndPaid(this.scheduleItem));
                }

                const replanAction = new Replan(this.scheduleItem);
                if (canReplan) this.actions.push(replanAction);

                this.actions.push(skipAction);

                if (canAssign) this.actions.push(new Assign(this.scheduleItem));

                this.primaryAction = doneAction;
                this.secondaryAction = isAdmin || isPlanner ? replanAction : skipAction;

                break;
            }
            case JobOccurrenceStatus.Done: {
                this.primaryAction = undefined;
                const notDoneAction = new NotDone(this.scheduleItem);
                this.actions = this.isArchived() ? [] : [notDoneAction];

                switch (this.scheduleItem.occurrence.paymentStatus) {
                    case undefined: {
                        if (!this.scheduleItem.occurrence.invoiceTransactionId) {
                            const createInvoiceAction = new CreateInvoice(this.scheduleItem);
                            this.actions.push(createInvoiceAction);
                            this.primaryAction = createInvoiceAction;
                        }

                        break;
                    }
                    case 'invoiced': {
                        const takePayment = new TakePayment(this.scheduleItem);
                        this.actions.push(takePayment);
                        this.primaryAction = takePayment;
                        break;
                    }
                    case 'paid': {
                        if (isAdmin) {
                            // if user has invoice generate then they get the extra step;)
                            notDoneAction.tooltip = 'actions.cancel-invoice'; // Action cancel invoice when invoiced.
                        }
                        break;
                    }
                }

                this.secondaryAction = this.isArchived() ? undefined : notDoneAction;

                if (canPrintComplete) {
                    this.actions.push(new PrintJobNotification(this.scheduleItem));
                }

                this.actions.push(new SendJobNotification(this.scheduleItem));

                break;
            }
            case JobOccurrenceStatus.Skipped: {
                if (this.isArchived()) return;
                const replanAction = new Replan(this.scheduleItem);
                const notDoneAction = new NotDone(this.scheduleItem);
                notDoneAction.tooltip = 'actions.not-skipped';
                notDoneAction.actionType = 'action-not-skipped';
                const doneAction = new Done(this.scheduleItem);
                this.actions = [doneAction, notDoneAction];

                if (canReplan) this.actions.push(replanAction);

                this.primaryAction = this.actions[0];
                this.secondaryAction = notDoneAction;
                break;
            }
        }
    }
}
