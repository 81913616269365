import type { Service, TranslationKey } from '@nexdynamic/squeegee-common';
import { Utilities } from '../../Utilities';

export const getServiceListLong = (services: Array<Service>) => {
    return Utilities.localiseList(
        services.map(s => {
            return `${s.description} - ${s.longDescription}` as TranslationKey;
        })
    );
};
