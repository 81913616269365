import type { JobOccurrence, StoredEvent } from '@nexdynamic/squeegee-common';
import { sortEvents } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { Data } from '../Data/Data';
import { TimeDialog } from '../Dialogs/TimeOrDuration/TimeDialog';

export class StoredEventService {
    public static getTimerEntriesForJobOccurrence(
        occurrenceId?: string,
        assignee?: string,
        from?: string,
        to?: string,
        descending?: boolean
    ): Array<StoredEvent> {
        const fromTS = from ? moment(from).valueOf() : undefined;
        const toTS = to ? moment(to).valueOf() : undefined;

        const query: { cancelled: boolean; occurrenceId?: string; allocateTimeTo?: string } = { cancelled: false };
        if (occurrenceId) query.occurrenceId = occurrenceId;
        if (assignee) query.allocateTimeTo = assignee;

        const storedEvents = Data.all<StoredEvent>('storedevents', query).filter(
            event => (!fromTS || event.eventTimestamp >= fromTS) && (!toTS || event.eventTimestamp <= toTS)
        );

        return sortEvents(storedEvents, descending);
    }

    public static getJobOccurrenceTimerEntries(jobOccurrences: Array<JobOccurrence>) {
        const timeData: Array<{ jobOccurrence: JobOccurrence; entries: ReadonlyArray<StoredEvent> }> = [];

        for (const jobOccurrence of jobOccurrences) {
            const storedEvents = Data.all<StoredEvent>('storedevents', { occurrenceId: jobOccurrence._id, cancelled: false });
            timeData.push({ jobOccurrence, entries: storedEvents });
        }

        return timeData;
    }

    public static getTimerEntriesForWorker(workerId: string, descending?: boolean): Array<StoredEvent> {
        const data = Data.all<StoredEvent>('storedevents', { allocateTimeTo: workerId, cancelled: false });
        return sortEvents(data as Array<StoredEvent>, descending);
    }

    public static getLastEventForWorker(workerId: string, eventTime: number): StoredEvent | undefined {
        const data = Data.all<StoredEvent>('storedevents', { allocateTimeTo: workerId, cancelled: false }).filter(
            x => x.eventTimestamp <= eventTime
        );

        const sortedData = sortEvents(data, true);
        return sortedData[0];
    }

    public static getEventsInPeriodForWorker(workerId: string, from: number, to: number): Array<StoredEvent> {
        return Data.all<StoredEvent>('storedevents', { allocateTimeTo: workerId, cancelled: false }).filter(
            x => x.eventTimestamp >= from && x.eventTimestamp <= to
        );
    }

    public static async getDateAndTime(eventTimestamp?: Date) {
        if (!eventTimestamp) eventTimestamp = new Date();
        const dateDialog = new DateTimePicker(false, moment(eventTimestamp).format('YYYY-MM-DD'));
        dateDialog.init();
        await dateDialog.open();
        const pickedDate = dateDialog.selectedDate;
        if (!pickedDate || dateDialog.canceled) return { cancelled: true };

        const time = eventTimestamp ? moment(eventTimestamp).format('HH:mm:ss') : moment().format('HH:mm:ss');
        const timeDialog = new TimeDialog(time, false, undefined, undefined, false);
        const pickedTime = await timeDialog.show();
        const cancelled = timeDialog.cancelled || dateDialog.canceled;

        return {
            cancelled,
            pickedTime,
            pickedDate,
        };
    }
}
