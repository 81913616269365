import type { SkipReason, TranslationKey } from '@nexdynamic/squeegee-common';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';


export const enterSkipReasonText = async (reason?: SkipReason): Promise<{ action: 'text', text: string } | { action: 'cancel' } | { action: 'delete' }> => {
    if (reason) {
        const deleteReasonDialog = await new Prompt('general.delete', 'settings.skip-reason-delete-check', { altLabel: 'general.delete', okLabel: 'general.update' });
        const deleteReason = await deleteReasonDialog.show();
        if (deleteReasonDialog.cancelled) return { action: 'cancel' };

        if (!deleteReason) return { action: 'delete' };
    }

    const title: TranslationKey = reason ? 'settings.skip-reason-text-title' : 'settings.skip-reason-text-title-new';
    const reasonTextDialog = new TextDialog(title, '', reason?.name || '', '');
    const reasonText = await reasonTextDialog.show();

    if (reasonTextDialog.cancelled || !reasonText) return { action: 'cancel' };

    return { action: 'text', text: reasonText };
};
