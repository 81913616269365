import { DialogRoot } from '@nexdynamic/nex-ui-react';
import type { Area, AreaPriceModifier } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../../Dialogs/AureliaReactComponentDialogComponent';
import AreaPriceModifierEditDialog from './AreaPriceModifierEditDialog';
import AreaPriceModifiersList from './AreaPriceModifiersList';

export type AvailableViews = 'AreaPriceModifiersList' | 'AreaPriceModifierEditDialog';

export type AreaPriceModifiersDialogOptions = {
    storedAreas: Array<Area>;
};

type Props = AureliaReactComponentDialogComponentStandardProps<Array<AreaPriceModifier>> & AreaPriceModifiersDialogOptions;

const AreaPriceModifiersDialog: AureliaReactComponentDialogComponent<AreaPriceModifiersDialogOptions> = ({
    okCallback,
    storedAreas,
}: Props) => {
    const [view, setView] = useState<AvailableViews>('AreaPriceModifiersList');
    const [selectedArea, setSelectedArea] = useState<Area>();
    const [areas, setAreas] = useState<Array<Area>>(storedAreas);

    const handleAddArea = (value: Area) => {
        if (!value.code) return;
        setAreas([...areas, value]);
    };

    const handleDeleteArea = (areaId: string) => {
        const newAreas = areas.filter(a => a.id !== areaId);
        setAreas(newAreas);
        setView('AreaPriceModifiersList');
    };

    const handleSaveArea = (area: Area) => {
        const index = areas.findIndex(a => a.id === area.id);
        if (index === -1) return;
        const newAreas = [...areas];
        newAreas[index] = area;
        setAreas(newAreas);
    };

    const handleSave = () => {
        okCallback(areas);
    };

    const openEditDialog = (area: Area) => {
        setSelectedArea(area);
        setView('AreaPriceModifierEditDialog');
    };

    return (
        <DialogRoot sx={{ height: '100%' }}>
            <AreaPriceModifiersList
                storedAreas={storedAreas}
                areas={areas}
                onAddArea={handleAddArea}
                openEditDialog={openEditDialog}
                onSave={handleSave}
                hidden={view !== 'AreaPriceModifiersList'}
            />
            {selectedArea && (
                <AreaPriceModifierEditDialog
                    areas={areas}
                    selectedArea={selectedArea}
                    onSave={handleSaveArea}
                    onDelete={handleDeleteArea}
                    onClose={() => setView('AreaPriceModifiersList')}
                    hidden={view !== 'AreaPriceModifierEditDialog'}
                />
            )}
        </DialogRoot>
    );
};

export default AreaPriceModifiersDialog;
