import type { LngLat, Location } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from './Api';

export class LocationApi {
    public static async getGeocodedAddresses(searchText: string): Promise<Array<Location>> {
        if (!searchText || !searchText.length) return [];
        try {
            const predictedLocale = ApplicationState.predictLocale();
            const countryCode =
                ApplicationState.account.language && ApplicationState.account.language.length >= 5
                    ? ApplicationState.account.language.substring(ApplicationState.account.language.length - 2)
                    : (predictedLocale && predictedLocale.countryCode) || '';
            const near = ApplicationState.position?.coords || ApplicationState.account.businessAddress?.lngLat || undefined;
            const response = await Api.post<Array<Location>>(
                null,
                '/api/address/geocode-search',
                { searchText, countryCode, near },
                false,
                false
            );
            if (response) return response.data;
        } catch (error) {
            Logger.error('Error in getAddressesBySearchTerm() on LocationApi', { searchText, error });
            new NotifyUserMessage('notification.failed-to-search-for-search', { search: searchText });
        }
        return [];
    }

    public static async getLngLatForLoqateAddress(id: string): Promise<LngLat | undefined> {
        try {
            const response = await Api.get<LngLat>(null, `/api/address/loqate-lng-lat?id=${id}`);
            return response?.data || undefined;
        } catch (error) {
            Logger.error('Error in getLngLatForLoqateAddress() on LocationApi', { id, error });
        }
    }

    public static async getDetailsForGetAddressId(id: string): Promise<Location | undefined> {
        try {
            const response = await Api.get<Location>(null, `/api/address/get-address-details?id=${id}`);
            return response?.data || undefined;
        } catch (error) {
            Logger.error('Error in getDetailsForGetAddressId() on LocationApi', { id, error });
        }
    }

    public static getThreeWordsFromText(input: string) {
        const w3wRegex =
            /\/{0,}[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}/;
        const matches = input.match(w3wRegex);
        return matches?.[0] || undefined;
    }

    public static async getW3WAddresses(input: string | LngLat, exact = false): Promise<Array<Location>> {
        const threeWords = typeof input === 'string' ? input : undefined;
        const lngLat = typeof input !== 'string' ? input : undefined;
        const lng = lngLat?.[0];
        const lat = lngLat?.[1];
        if (!threeWords && !lngLat) return [];
        if (typeof input !== 'string' && (input[0] === undefined || input[1] === undefined)) return [];
        try {
            const predictedLocale = ApplicationState.predictLocale();
            const countryCode =
                ApplicationState.account.language && ApplicationState.account.language.length >= 5
                    ? ApplicationState.account.language.substring(ApplicationState.account.language.length - 2)
                    : (predictedLocale && predictedLocale.countryCode) || '';
            if (exact) {
                const response = await Api.post<Array<Location>>(
                    null,
                    '/api/address/what3words-get-location',
                    { threeWords, lng, lat, countryCode },
                    false,
                    false
                );
                if (response) return response.data;
            } else {
                const currentLocation = ApplicationState.position;
                const focusLng = ApplicationState.account.businessAddress?.lngLat?.[0] || currentLocation?.coords?.longitude;
                const focusLat = ApplicationState.account.businessAddress?.lngLat?.[1] || currentLocation?.coords?.latitude;

                const response = await Api.post<Array<Location>>(
                    null,
                    '/api/address/what3words-search',
                    { threeWords, countryCode, focusLng, focusLat },
                    false,
                    false
                );
                if (response) return response.data;
            }
        } catch (error) {
            Logger.error('Error in getAddressesBySearchTerm() on LocationApi', { searchText: input, error });
            new NotifyUserMessage('notification.failed-to-search-for-input', { input: input.toString() });
        }
        return [];
    }

    public static async getAddresses(searchText: string): Promise<Array<Location>> {
        const w3wMatch = LocationApi.getThreeWordsFromText(searchText);
        // BETA FEATURE: HIDE BEHIND DEV TOOLS, KEEP HAS ADVANCED OR ABOVE WHEN LIVE
        const useW3W = ApplicationState.stateFlags.devMode && ApplicationState.hasAdvancedOrAbove;
        if (w3wMatch && useW3W) return await this.getW3WAddresses(w3wMatch);
        else return await this.getGeocodedAddresses(searchText);
    }
}
