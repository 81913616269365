import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { getNumber } from './getNumber';


export const getSkipAmount = async (type: 'fixed' | 'percentage', value?: number) => {

    const isFixed = type === 'fixed';
    const title: TranslationKey = type === 'fixed' ? 'settings.skip-reason-charge-fixed' : 'settings.skip-reason-charge-percentage';
    const newValue = await getNumber({ title, value, integer: !isFixed, prefix: isFixed ? ApplicationState.currencySymbol() : '', suffix: isFixed ? '' : '%' });
    if (!newValue || newValue <= 0) return;

    return newValue;
};
