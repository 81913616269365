import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, Divider, List, ListItem, ListItemButton, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import type { Area } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import useTranslation from '../../ReactUI/hooks/useTranslation';

export type Props = {
    storedAreas: Array<Area>;
    areas: Array<Area>;
    onAddArea: (value: Area) => void;
    openEditDialog: (area: Area) => void;
    onSave: (areas: Array<Area>) => void;
    hidden?: boolean;
};

const AreaPriceModifiersList = ({ storedAreas, areas, onAddArea, openEditDialog, onSave, hidden }: Props) => {
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [value, setValue] = useState<Area>({
        id: uuid(),
        code: '',
        name: '',
        settings: { isEnabled: false, priceModifier: 0, effectType: 'percentage' },
    });
    const [error, setError] = useState<string>();

    const validateOutwardCode = (code: string) => {
        const regex = /^[A-Z]{1,2}[0-9R][0-9A-Z]?$/;
        const validCode = regex.test(code);
        setError(validCode ? '' : 'Invalid area code');

        if (areas.find(a => a.code === code)) {
            setError('Area modifier already exists');
        }
    };

    const handleOutwardCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        validateOutwardCode(event.target.value);
        setValue({
            ...value,
            code: event.target.value,
            name: event.target.value,
        });
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (error) return;
        if (event.key === 'Enter') {
            onAddArea(value);
        }
    };

    const handleAreaClick = async (area: Area) => {
        openEditDialog(area);
    };

    const handleAddArea = () => {
        setValue({ id: uuid(), code: '', name: '', settings: { isEnabled: false, priceModifier: 0, effectType: 'percentage' } });
        if (!value.code) {
            setError('Area code is required');
            return;
        }
        if (areas.find(a => a.code === value.code)) {
            setError('Area modifier already exists');
            return;
        }
        onAddArea(value);
    };

    const handleSave = () => {
        onSave(areas);
    };

    useEffect(() => {
        console.log('areas changed in list', areas);
    }, [areas]);

    useEffect(() => {
        console.log(storedAreas.map(a => a.id).join(','), areas.map(a => a.id).join(','));
        if (storedAreas.map(a => a.id).join(',') !== areas.map(a => a.id).join(',')) {
            setUnsavedChanges(true);
        } else {
            setUnsavedChanges(false);
        }
    }, [areas]);

    const { t } = useTranslation();

    return (
        <Stack gap={1} hidden={hidden}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
                {unsavedChanges && (
                    <Box display="flex" alignItems="center" gap={0.5}>
                        <WarningIcon color="warning" />
                        <Typography fontWeight="bold">{t('warning.unsaved-changes')}</Typography>
                    </Box>
                )}
                <Button onClick={handleSave}>Save</Button>
            </Stack>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6">Create Area Price Modifiers</Typography>
                <Divider />
                <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 2, mb: 1 }}>
                    <TextField
                        placeholder="Enter an outward code... (e.g. 'SW1A')"
                        value={value.code}
                        onChange={handleOutwardCodeChange}
                        error={!!error}
                        onKeyDown={handleKeyDown}
                        size="small"
                        fullWidth
                        required
                    />
                    <Button variant="contained" onClick={handleAddArea} sx={{ textWrap: 'nowrap' }} disabled={!value.code || !!error}>
                        Add Area
                    </Button>
                </Stack>
                {error && (
                    <Typography variant="caption" color="error" fontWeight={500}>
                        {error}
                    </Typography>
                )}
                <Typography variant="caption" mt={1}>
                    {t('area-price-modifiers.outward-code-explanation')}
                </Typography>
            </DialogPaper>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6">Edit Area Modifiers</Typography>
                <Divider sx={{ mb: 2 }} />
                <List disablePadding>
                    {areas.map((area, index) => (
                        <ListItem
                            key={area.id}
                            secondaryAction={
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <Typography>
                                        {area.settings.effectType === 'fixed' && '£'}
                                        {area.settings.priceModifier}
                                        {area.settings.effectType === 'percentage' && '%'}
                                    </Typography>
                                    <ChevronRightIcon />
                                </Stack>
                            }
                            disablePadding
                            divider={index !== areas.length - 1}
                        >
                            <ListItemButton onClick={() => handleAreaClick(area)}>
                                <ListItemText
                                    primary={`${storedAreas.some(a => a.id === area.id) ? '' : '*'}${area.code} ${
                                        area.code !== area.name ? `(${area.name.slice(0, 20)}${area.name.length > 20 ? '...' : ''})` : ''
                                    }`}
                                    secondary="Edit area price modifier"
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    {areas.length === 0 && <Typography color="textSecondary">No area modifiers added yet</Typography>}
                </List>
            </DialogPaper>
        </Stack>
    );
};

export default AreaPriceModifiersList;
