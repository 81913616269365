import type { TranslationKey } from '@nexdynamic/squeegee-common';
import Mousetrap from 'mousetrap';
import { ApplicationState } from './ApplicationState';
import { DialogAnimation } from './Dialogs/DialogAnimation';
import { Prompt } from './Dialogs/Prompt';
import { CloseDialogKeyboardEvent } from './Events/CloseDialogKeyboardEvent';
import { Launcher } from './Launcher/Launcher';
import { Logger } from './Logger';
import { NotifyUserMessage } from './Notifications/NotifyUserMessage';
import { SettingsDialog } from './Settings/SettingsDialog';
import type { ShortCutKey } from './ShortCutKey';
import { ShortCutKeys } from './ShortCutKeys';

export class Shortcuts {
    static fireShortcut(name: keyof typeof Shortcuts) {
        if (name === 'fireShortcut') return;
        const method = (Shortcuts as any)[name];
        try {
            if (method) method();
        } catch (ex) {
            new NotifyUserMessage(`${name} failed` as TranslationKey);
            Logger.error(`${name} failed`, ex);
        }
    }

    static 'Open the launcher'(): void {
        if (ApplicationState.stateFlags.devMode || ApplicationState.hasAdvancedOrAbove) Launcher.toggle();
    }

    static 'Show settings dialog'(): void {
        const dialog = new SettingsDialog();
        dialog.show(DialogAnimation.SLIDE);
    }

    static 'Activate cheat mode'(): void {
        ApplicationState.stateFlags.devMode = !ApplicationState.stateFlags.devMode;
        ApplicationState.stateFlags.showDevModeCounter = 10;
        new Prompt(
            ApplicationState.stateFlags.devMode ? 'developer.cheat-mode-enabled' : 'developer.cheat-mode-disabled',
            'developer.cheat-mode-title',
            {
                okLabel: ApplicationState.stateFlags.devMode
                    ? 'developer.cheat-mode-enabled-ok'
                    : 'developer.cheat-mode-disabled-ok',
                cancelLabel: '',
            }
        ).show();
    }

    static 'Close dialog'(): void {
        new CloseDialogKeyboardEvent();
    }

    static registerShortcuts(shortCutKeys: Array<ShortCutKey>) {
        for (const shortCutKey of shortCutKeys) {
            try {
                if (shortCutKey.roles && !ApplicationState.isInAnyRole(shortCutKey.roles)) continue;

                if (shortCutKey.minimumSubscriptionLevel && !ApplicationState.hasMinimumSubscription(shortCutKey.minimumSubscriptionLevel))
                    continue;

                Mousetrap.bind(shortCutKey.keys, () => {
                    Shortcuts.fireShortcut(shortCutKey.function);
                });
            } catch (ex) {
                Logger.error('Unexpected Error in registerShortcuts', ex);
            }
        }
    }

    static init() {
        Shortcuts.registerShortcuts(ShortCutKeys);
        Launcher.initGestureControl();
    }
}
