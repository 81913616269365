import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { computedFrom, inject, transient } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { ApplicationState } from '../../ApplicationState';
import { ScheduleView } from './ScheduleView';

@inject(EventAggregator, Router, BindingSignaler)
@transient()
export class MyWorkView extends ScheduleView {
    public viewName = 'myWork_';
    constructor(public eventAggregator: EventAggregator, public router: Router, protected signaler: BindingSignaler) {
        super(eventAggregator, router, signaler);
    }

    @computedFrom('actionMode')
    protected get actionModeInstructions(): TranslationKey {
        return this.actionMode === 'assign'
            ? ApplicationState.localise('work-view.select-assignee')
            : ApplicationState.localise('work-view.select-day-replan');
    }
}
