import { ApiDefinition, RemoteCallData, TypedEvent } from '@nexdynamic/squeegee-common';
import SocketIO from 'socket.io-client';

export class ClientSocketApi<TClientApi extends { [key: string]: any }, TServerApiDefinition extends ApiDefinition> {
    private _socket: SocketIOClient.Socket;
    private _server: TServerApiDefinition;
    public get server(): TServerApiDefinition {
        return this._server;
    }

    public close() {
        this._socket.close();
    }

    public open() {
        this._socket.open();
    }

    public get isConnected() {
        return this._socket.connected;
    }

    public constructor(
        private readonly _clientApi: TClientApi,
        serverApiDefinition: TServerApiDefinition,
        url?: string,
        connectWhenReady = true,
        options?: Partial<SocketIOClient.ConnectOpts>
    ) {
        const composedOptions: Partial<SocketIOClient.ConnectOpts> = {
            autoConnect: false,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionAttempts: 100000, // todo: how can we make this infinite
        };

        if (options) Object.assign(composedOptions, options);

        this._socket = SocketIO(url || '/', composedOptions);
        this._socket.on('connect', () => {
            this._server = <TServerApiDefinition>new ApiDefinition(this._socket.id);
            this.initServerApi(serverApiDefinition);
            this.connectionStateChanged.fire(true);
        });
        this._socket.on('disconnect', () => this.connectionStateChanged.fire(false));
        this._socket.on(ApiDefinition.API_EVENT_KEY, (data: RemoteCallData) => this.handleRemoteMethodCall(data));

        if (connectWhenReady) this.connect();
    }

    private initServerApi(serverApiDefinition: TServerApiDefinition) {
        for (const methodName of Object.keys(serverApiDefinition)) {
            if (typeof serverApiDefinition[methodName] === 'function') {
                (<any>this._server)[<string>methodName] = (...args: Array<any>) =>
                    this.makeRemoteCall(new RemoteCallData(methodName, args));
            }
        }
    }

    private makeRemoteCall(remoteCallData: RemoteCallData) {
        this._socket.emit(ApiDefinition.API_EVENT_KEY, remoteCallData);
    }

    public connect() {
        this._socket.connect();
    }

    private handleRemoteMethodCall(data: RemoteCallData) {
        const method = this._clientApi[data.methodName];
        if (method && typeof method === 'function') {
            const parameters = data.paramaters && Object.keys(data.paramaters).map(key => data.paramaters[key]);
            method.apply(this._clientApi, parameters);
        }
    }

    public readonly connectionStateChanged = new TypedEvent<boolean>();
}
