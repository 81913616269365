import type {
    AccountUser,
    Customer,
    CustomerNotificationMethodTypes,
    JobOccurrence,
    Linker,
    Tag,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    CapacityBenchmarkType,
    CapacityMeasurementType,
    FrequencyType,
    Job,
    JobOccurrenceStatus,
    Location,
    Team,
    formatTimestampDurationAsTime,
    getSummedTimestampOfEntryDurations,
    isTimerRunningForEntries,
    wait,
} from '@nexdynamic/squeegee-common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, computedFrom, inject, transient } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import type { Moment } from 'moment';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { ChartData } from '../../Components/Chart/ChartData';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import type { IFabAction } from '../../Components/Fabs/IFabAction';
import { KEEP_OCCURRENCES_AFTER_X_WEEKS } from '../../Customers/ClientArchiveService';
import { CustomerFormDialog } from '../../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../../Customers/Components/SelectCustomerDialog';
import { Data } from '../../Data/Data';
import { SqueegeeLocalStorage } from '../../Data/SqueegeeLocalStorage';
import type { IDayPilotOptions } from '../../DayPilot/Components/IDayPilotOptions';
import { RouteOptimisationService } from '../../DayPilot/RouteOptimisationService';
import { DaynoteActions } from '../../Daynotes/Daynote.actions';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { CapacityMeasurementSavedMessage } from '../../Dialogs/CapacityMeasurement/CapacityMeasurementMessage';
import { DayPilotSettingsDialog } from '../../Dialogs/DayPilotSettingsDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { OpenInCalendarDialog } from '../../Dialogs/OpenInCalendarDialog';
import { Prompt } from '../../Dialogs/Prompt';
import { ViewDialog } from '../../Dialogs/ViewDialog';
import { ActionBarEvent } from '../../Events/ActionBarEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { MenuBarActionsEvent } from '../../Events/MenuBarActionsEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { FabWithActions } from '../../FabWithActions';
import type { Filter } from '../../Filters/Filter';
import type { IScheduleFilterItemDictionary, IScheduleSortItemDictionary, IScheduleViewFilterArgs } from '../../Filters/Filters';
import { Filters } from '../../Filters/Filters';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import { GlobalFlags } from '../../GlobalFlags';
import { HashtagEditor } from '../../HashTags/HashtagEditor';
import { getHashtags } from '../../HashTags/getHashtags';
import { NewJobDialog } from '../../Jobs/Components/NewJobDialog';
import { SelectAppointmentsForNotification } from '../../Jobs/Components/SelectAppointmentsForNotification';
import { ShiftJobScheduleDialog } from '../../Jobs/Components/ShiftJobScheduleDialog';
import { ShiftOrResetScheduleEvent } from '../../Jobs/Components/ShiftOrResetScheduleEvent';
import { DateFilter } from '../../Jobs/Filters/DateFilter';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import { JobService } from '../../Jobs/JobService';
import { PositionUpdatedEvent } from '../../Location/PositionUpdatedEvent';
import { Logger } from '../../Logger';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { LegacyQuote } from '../../Quotes/LegacyQuote';
import { QuoteActions } from '../../Quotes/QuoteActions';
import { ScheduleMessageDialogComponent } from '../../ReactUI/scheduleMessages/ScheduleMessagesDialog';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import { Api } from '../../Server/Api';
import { StoredEventService } from '../../Tracking/StoredEventService';
import { TimerService } from '../../Tracking/TimerService';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { TeamFormDialog } from '../../Users/TeamFormDialog';
import { UserService } from '../../Users/UserService';
import type { Workload } from '../../Users/Workload';
import { Utilities, animate } from '../../Utilities';
import type { ScheduleActionEvent } from '../Actions/ScheduleActionEvent';
import type { ScheduleByGroup } from '../Components/ScheduleByGroup';
import { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import { ViewOptionsDialog } from './ViewOptionsDialog';
import { showReminderResultPrompt } from './showReminderResultPrompt';

export interface ScheduleView {
    onRefresh?(): any;
}
@transient()
@inject(EventAggregator, Router, BindingSignaler)
export class ScheduleView {
    public hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    public viewName = '';
    @bindable public searchText: string;
    protected currencySymbol = ApplicationState.currencySymbol();

    protected isAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected dailyTotalJobs = 0;
    protected dailyTotalPrice = '0.00';
    protected dailyTotalTime = '0h';

    protected dailyTotalActualTime = '';
    protected dateFilter: DateFilter = new DateFilter();
    protected currentDate = moment();
    protected chartTitleText: string;
    protected chartTitle: HTMLDivElement;
    protected stickyChart: boolean = ApplicationState.account.stickyChartArea || false;
    protected scheduleDataWide: ScheduleByGroup;
    protected scheduleData: ScheduleByGroup;
    protected scheduleDataDayUnfilteredCount = 0;
    protected scheduleDataDay: Array<ScheduleItem> = [];
    protected scheduleItemsGroupedByRound?: Array<{ key: string; items: Array<ScheduleItem> }>;
    protected chartDataFormatter = ChartData.fromScheduleByDayAndStatus;
    protected chartSelectedColumnIndex: number | undefined;
    runningRefresh: Promise<void>;
    protected multiUserEnabled = ApplicationState.multiUserEnabled;
    protected roundsTagList: Array<Tag> = [];
    protected selectedRoundFilter: Tag = <any>{ _id: '', description: ScheduleView.ALL_ROUNDS_TEXT };
    protected selectedWorkLoad: Workload | undefined;
    protected allRoundsText: string;
    protected maxY: number;
    protected currentMonthYear: string;
    protected currentChartMonthYear: string;
    protected viewOptions = ApplicationState.viewOptions;
    protected loading = true;
    protected chartMode: 'chart' | 'users' | 'list' | 'teams' = 'chart';
    protected workloads: Array<Workload>;
    private static CACHE_SIZE_WEEKS = 3;
    public currentFilter: Filter<IScheduleFilterItemDictionary | IScheduleSortItemDictionary>;
    private static ALL_ROUNDS_TEXT = 'All';

    protected currentUser: AccountUser | undefined;
    protected isMyWorkView: boolean;
    protected isOnlyWorker: boolean =
        ApplicationState.isInAnyRole('Worker') && !ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner']);
    private _applicationStateChangedSubscription: Subscription;

    private _dataRefreshedSub: Subscription;
    private _scheduleChangedSub: Subscription;
    private _scheduleShiftedOrResetSub: Subscription;
    private _capacityMeasurementSavedSub: Subscription;
    private _routeChangedSubscription: Subscription;
    private _actionBarEventSub: Subscription;
    private _positionUpdatedSub: Subscription;
    private listContainer: HTMLDivElement;

    private activescheduleItem?: ScheduleItem;
    protected isCordova = !GlobalFlags.isHttp && (GlobalFlags.isAppleMobileDevice || GlobalFlags.isAndroidMobileDevice);
    protected actionBarEnabled = false;
    protected actionMode: undefined | 'assign' | 'replan';
    protected isMobile = GlobalFlags.isMobile || GlobalFlags.isAppleMobileDevice || GlobalFlags.isAndroidMobileDevice;
    protected promptPaymentProvider = false;

    protected canSeePricing: boolean;

    protected restrictedViewableDays = ApplicationState.getSetting<number | undefined>(
        'global.scheduling.mywork-days-visible-length',
        undefined
    );

    protected isRestrictedDay = false;

    // Keeps a count of the amount of loaded items in each schedule list item
    public loadedListItems: { [key: string]: number } = {};

    protected _loadedJobIds: Array<string> = [];
    private allData: ScheduleItem[];

    constructor(public eventAggregator: EventAggregator, protected router: Router, protected signaler: BindingSignaler) {}

    public created() {
        new LoaderEvent(true);
    }

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) return Promise.reject(null);
        return await target.scheduleItemActions.primaryAction.handler();
    }

    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return await target.scheduleItemActions.secondaryAction.handler();
    }

    protected timerRunning = false;
    protected timerUpdater: NodeJS.Timeout;
    protected timedDuration: string | undefined;
    public updateTimerState() {
        if (!this.currentUser) return;
        const timerEntries = StoredEventService.getTimerEntriesForWorker(this.currentUser._id);
        const totalDuration = getSummedTimestampOfEntryDurations(timerEntries);
        this.timerRunning = isTimerRunningForEntries(timerEntries);
        this.timedDuration =
            totalDuration === undefined
                ? 'Unpaired Entries'
                : (timerEntries.length && totalDuration && formatTimestampDurationAsTime(totalDuration)) || 'No Recorded Time';
    }

    protected async toggleTimer() {
        await TimerService.toggleTimerForCurrentUser();
        this.updateTimerState();
    }

    showTimer = ApplicationState.getSetting('global.jobs.worker-clock', false) && this.hasAdvancedOrAbove;

    private _countTimer: any;
    private _itemCount = 0;

    @computedFrom('searchText')
    public get itemCount() {
        clearTimeout(this._countTimer);
        const listContainer = this.listContainer;
        this._countTimer = setTimeout(() => {
            this._itemCount = listContainer && listContainer.querySelectorAll('li').length;
        }, 100);
        return this._itemCount;
    }
    protected jobCountText: string;
    protected updateJobCountText() {
        if (this.scheduleDataDayUnfilteredCount === 0) {
            this.jobCountText = ApplicationState.localise('jobs.group-none');
        } else {
            this.jobCountText = this.scheduleDataDay.length.toString();
        }
    }

    @computedFrom('isMyWorkView', 'multiUserEnabled', 'actionMode')
    get chartModeBarHeight() {
        return !this.isMyWorkView && this.multiUserEnabled && !this.actionMode ? 56 : 0;
    }

    @computedFrom('actionMode')
    protected get actionModeScheduler(): TranslationKey {
        return this.actionMode === 'assign'
            ? ApplicationState.localise('work-view.select-assignee')
            : ApplicationState.localise('work-view.select-day-replan');
    }

    @computedFrom('chartMode')
    get menuBarTop() {
        let height = this.chartModeBarHeight;

        if (this.stickyChart && !this.viewOptions.minimiseChartData)
            switch (this.chartMode) {
                case 'chart':
                    height += 252.67;
                    break;
                case 'users':
                case 'teams':
                    height += 196;
                    break;
            }
        return height + 'px';
    }

    @computedFrom('viewOptions.showTemperatures')
    get showTemperatures() {
        return ApplicationState.viewOptions.showTemperatures;
    }

    private getScheduleViewActions() {
        const fabActions: Array<IFabAction> = [];

        const isAllJobsView = document.location.href.indexOf('alljobs') > -1;

        if (!isAllJobsView) {
            fabActions.push({
                tooltip: ApplicationState.localise('add-work.fab-tooltip'),
                actionType: 'action-add-work',
                handler: () => this.handleAddWorkBtnClick(),
                roles: ['Owner', 'Admin', 'Creator', 'JobEditor'],
                icon: 'add',
            });
            fabActions.push({
                tooltip: ApplicationState.localise('daynotes.fab-tooltip'),
                actionType: 'action-add-notes',
                handler: () => DaynoteActions.update(this.currentDateIso, ''),
                roles: ['Owner', 'Admin', 'Creator', 'Planner'],
            });
        }

        fabActions.push({
            tooltip: 'actions.add-job',
            actionType: 'action-new-job',
            handler: this._delegateAddJobForCustomer,
            roles: ['Owner', 'Admin', 'Creator', 'JobEditor'],
        });

        fabActions.push({
            tooltip: 'actions.create-quote',
            actionType: 'action-new-quote',
            handler: this._delegateNewQuote,
            roles: ['Owner', 'Admin', 'Creator'],
        });
        fabActions.push({
            tooltip: 'actions.create-appointment',
            actionType: 'action-new-appointment',
            handler: this._delegateNewAppointment,
            roles: ['Owner', 'Admin', 'Creator'],
        });
        if (!isAllJobsView && (!ApplicationState.multiUserEnabled || this.isMyWorkView || this.selectedWorkLoad)) {
            fabActions.push({
                tooltip: 'sorting.by-optimised-drive',
                actionType: 'action-optimise-route',
                handler: () => {
                    this.optimiseDay();
                },
                roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'],
            });
        }

        if (!isAllJobsView) {
            if (moment().isSameOrBefore(this.currentDate, 'day')) {
                const emailCount = this.getReminderCount('email');
                if (emailCount && emailCount.total) {
                    fabActions.push({
                        tooltip: 'actions.send-email-reminders',
                        tooltipParams: { sent: emailCount.sent.toString(), total: emailCount.total.toString() },
                        actionType: 'action-send-reminders-email',
                        icon: 'email',
                        handler: this._delegateSendEmailReminders,
                        roles: ['Owner', 'Admin', 'Creator', 'Planner'],
                    });
                }

                const smsCount = this.getReminderCount('sms');
                const sms2Count = this.getReminderCount('sms2');
                const smsAndEmailCount = this.getReminderCount('email-and-sms');
                const sms2AndEmailCount = this.getReminderCount('email-and-sms2');

                if ((smsCount && smsCount.total) || (sms2Count && sms2Count.total)) {
                    fabActions.push({
                        tooltip: 'actions.send-sms-reminders',
                        tooltipParams: {
                            sent: (smsCount.sent + sms2Count.sent).toString(),
                            total: (smsCount.total + sms2Count.total).toString(),
                        },
                        actionType: 'action-send-reminders-sms',
                        icon: 'sms',
                        handler: this._delegateSendSmsReminders,
                        roles: ['Owner', 'Admin', 'Creator', 'Planner'],
                    });
                }

                if ((smsAndEmailCount && smsAndEmailCount.total) || (sms2AndEmailCount && sms2AndEmailCount.total))
                    fabActions.push({
                        tooltip: 'actions.send-sms-and-email-reminders',
                        tooltipParams: {
                            sent: (smsAndEmailCount.sent + sms2AndEmailCount.sent).toString(),
                            total: (smsAndEmailCount.total + sms2AndEmailCount.total).toString(),
                        },
                        actionType: 'action-send-reminders-sms-email',
                        icon: 'sms',
                        handler: this._delegateSendSmsEmailReminders,
                        roles: ['Owner', 'Admin', 'Creator', 'Planner'],
                    });

                const callCount = this.getReminderCount();
                if (callCount && callCount.total)
                    fabActions.push({
                        tooltip: 'actions.send-manual-reminders',
                        tooltipParams: { sent: callCount.sent.toString(), total: callCount.total.toString() },
                        actionType: 'action-send-reminders-call',
                        icon: 'call',
                        handler: this._delegateSendManualReminders,
                        roles: ['Owner', 'Admin', 'Creator', 'Planner'],
                    });
            }
        }

        return fabActions;
    }

    private _delegateAddJobForCustomer = () => this.addJobForCustomer();

    private _delegateNewQuote = () => this.newQuote('quote');
    private _delegateNewAppointment = () => this.newQuote('appointment');

    public getDateAndTimeForSchedule = async () => {
        try {
            const aureliaReactComponentDialog = new AureliaReactComponentDialog<number>({
                dialogTitle: 'general.datetime',
                isSecondaryView: true,
                component: ScheduleMessageDialogComponent,
            });
            const sendAtMillisecondsTimestamp = await aureliaReactComponentDialog.show();
            if (aureliaReactComponentDialog.cancelled) return;
            const sendAtSecondsTimestamp = sendAtMillisecondsTimestamp / 1000;
            return sendAtSecondsTimestamp;
        } catch {
            Logger.error('Error in scheduleReminders() on SelectAppointmentForNotification');
        }
    };

    private _delegateSendEmailReminders = async () => {
        let result: undefined | Awaited<ReturnType<typeof ScheduleService.sendAppointmentRemindersByEmail>>;
        try {
            const emailReminderScheduleItems = this.getRemindersByType('email');
            const emailRemindersNotSent = emailReminderScheduleItems.filter(item => !item.occurrence.reminderSent);
            const appointmentSelectorDialog = new SelectAppointmentsForNotification(emailRemindersNotSent, emailReminderScheduleItems);

            const selectedItems = await appointmentSelectorDialog.show(DialogAnimation.SLIDE_UP);

            if (appointmentSelectorDialog.cancelled) return;

            if (selectedItems.length === 0) {
                return await new Prompt('prompts.error-title', 'prompts.no-appointments-selected', { cancelLabel: '' }).show();
            }

            result = await ScheduleService.sendAppointmentRemindersByEmail(selectedItems);
            new LoaderEvent(true);
        } catch (error) {
            Logger.error('Error in delegateSendEmailReminders() on ScheduleView', error);
            return void new Prompt('prompts.error-title', 'prompts.email-reminders-send-error-text', { cancelLabel: '' }).show();
        }
        new LoaderEvent(false);
        if (!result) return;

        await showReminderResultPrompt('email', result);

        this.buildFab();
    };

    private _delegateSendSmsReminders = async () => {
        if (!ApplicationState.account.smsAPI) {
            new Prompt('prompts.sms-not-configured-title', 'prompts.sms-not-configured-text', { cancelLabel: '' }).show();
        } else {
            let smsResult: undefined | Awaited<ReturnType<typeof ScheduleService.sendAppointmentRemindersBySms>>;
            try {
                const smsReminderScheduleItems = this.getRemindersByType('sms').concat(this.getRemindersByType('sms2'));
                const smsRemindersNotSent = smsReminderScheduleItems.filter(item => !item.occurrence.reminderSent);
                const appointmentSelectorDialog = new SelectAppointmentsForNotification(smsRemindersNotSent, smsReminderScheduleItems);
                const selectedItems = await appointmentSelectorDialog.show(DialogAnimation.SLIDE_UP);
                if (appointmentSelectorDialog.cancelled) return;
                new LoaderEvent(true);
                smsResult = await ScheduleService.sendAppointmentRemindersBySms(selectedItems);
            } catch (error) {
                Logger.error(`Error during send sms delegate`, error);
                return void new NotifyUserMessage('reminders.general-error');
            }
            new LoaderEvent(false);

            await showReminderResultPrompt('sms', smsResult);

            this.buildFab();
        }
    };

    private _delegateSendSmsEmailReminders = async () => {
        if (!ApplicationState.account.smsAPI) {
            new Prompt('prompts.sms-not-configured-title', 'prompts.sms-not-configured-text', { cancelLabel: '' }).show();
        } else {
            let smsResult: undefined | Awaited<ReturnType<typeof ScheduleService.sendAppointmentRemindersBySms>>;
            let emailResult: undefined | Awaited<ReturnType<typeof ScheduleService.sendAppointmentRemindersByEmail>>;

            try {
                const smsReminderScheduleItems = this.getRemindersByType('email-and-sms').concat(this.getRemindersByType('email-and-sms2'));
                const smsRemindersNotSent = smsReminderScheduleItems.filter(item => !item.occurrence.reminderSent);
                const appointmentSelectorDialog = new SelectAppointmentsForNotification(smsRemindersNotSent, smsReminderScheduleItems);
                const selectedItems = await appointmentSelectorDialog.show(DialogAnimation.SLIDE_UP);
                if (appointmentSelectorDialog.cancelled) return;
                new LoaderEvent(true);
                smsResult = await ScheduleService.sendAppointmentRemindersBySms(selectedItems);
                if (smsResult.status === 'cancelled') {
                    new NotifyUserMessage('reminders.all-cancelled');
                    this.buildFab();
                    return;
                }

                emailResult = await ScheduleService.sendAppointmentRemindersByEmail(selectedItems);
                if (emailResult?.status === 'cancelled') new NotifyUserMessage('reminders.emails-cancelled');

                new LoaderEvent(false);

                await showReminderResultPrompt('sms', smsResult);
                await showReminderResultPrompt('email', emailResult);
            } catch (error) {
                Logger.error(`Error during send sms delegate`, error);
                return new NotifyUserMessage('reminders.general-error');
            } finally {
                this.buildFab();
            }
        }
    };

    private _delegateSendManualReminders = async () => {
        try {
            const manualReminderScheduleItems = this.getRemindersByType(undefined);
            const manualRemindersNotSent = manualReminderScheduleItems.filter(item => item && !item.occurrence.reminderSent);

            const appointmentSelectorDialog = new SelectAppointmentsForNotification(
                manualRemindersNotSent,
                manualReminderScheduleItems,
                true
            );
            const selectedItems = await appointmentSelectorDialog.show(DialogAnimation.SLIDE_UP);
            if (appointmentSelectorDialog.cancelled) return;
            new LoaderEvent(true);
            await ScheduleService.markAppointmentRemindersSent(selectedItems);
        } catch (error) {
            Logger.error('Error in _delegateSendManualReminders() on ScheduleView', error);
        }
        new LoaderEvent(false);
        await new Prompt('prompts.success-title', 'prompts.manual-reminders-send-success-text', { cancelLabel: '' }).show();
        this.buildFab();
    };

    private getReminderCount(notificationType?: CustomerNotificationMethodTypes) {
        const reminders = this.getRemindersByType(notificationType);
        const total = reminders.length;
        const sent = total - reminders.filter(item => item && !item.occurrence.reminderSent).length;
        return { total, sent };
    }

    private getRemindersByType(notificationType?: CustomerNotificationMethodTypes) {
        return this.scheduleDataDay.filter(
            item =>
                item &&
                ((!notificationType && !item.customer.defaultNotificationMethod) ||
                    item.customer.defaultNotificationMethod === notificationType) &&
                item.occurrence.status === JobOccurrenceStatus.NotDone
        );
    }
    private buildFab() {
        FabWithActions.register(this.getScheduleViewActions());
    }

    private _searchDebounce: any;
    protected searchTextChanged() {
        clearTimeout(this._searchDebounce);
        this._searchDebounce = setTimeout(() => this.filterForDay(), 250);
    }

    public async activate(params: { date?: string; scheduleItemId?: string; selectedItems?: Array<ScheduleItem> }, routeConfig: any) {
        if (routeConfig) this.router = routeConfig.navModel.router;

        animate().then(async () => {
            if (params.scheduleItemId) {
                const occurrence = Data.get<JobOccurrence>(params.scheduleItemId);
                if (!occurrence) return;
                const scheduleItem = ScheduleService.getScheduleItemForJobOccurrence(occurrence);
                if (!scheduleItem) return;

                this.activescheduleItem = scheduleItem;
                if (!params.date) await this.updateStartingWeek(scheduleItem.date);
            }

            if (params.date) {
                await this.updateStartingWeek(params.date);
            }

            if (params.scheduleItemId || params.date) window.history.replaceState(undefined, document.title, `/schedule`);
        });
    }

    private hidePricing = !!ApplicationState.account.hidePricesFromWorkerRole;
    public async attached() {
        new LoaderEvent(true);

        this.currentUser = UserService.getUser();

        if (this.showTimer) {
            this.updateTimerState();
            this.timerUpdater = setInterval(() => {
                this.updateTimerState();
            }, 5000);
        }
        this.buildFab();
        await this.updateWideData();
        this.updateCurrentMonthYear();
        this.updateWeekData();
        this.updateChartTitle();

        const allFilters = ScheduleService.getFilters(this.scheduleDataDay);
        this.regenerateFilters(allFilters);
        this.filterForDay();

        this._scheduleShiftedOrResetSub = ShiftOrResetScheduleEvent.subscribe<ScheduleActionEvent>(() =>
            this.refreshScheduleItemsList(false)
        );

        this._dataRefreshedSub = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (!event.updatedObjects) return;

            const hidePricingChanged =
                event.hasAnyType('applicationstate') && this.hidePricing !== !!ApplicationState.account.hidePricesFromWorkerRole;
            if (!hidePricingChanged && !event.hasAnyType('joboccurrences', 'customers', 'occurrencesbyday', 'joborderdata', 'linkers'))
                return;

            this.hidePricing = !!ApplicationState.account.hidePricesFromWorkerRole;

            if (Object.keys(event.updatedObjects).every(x => event.updatedObjects[x]?.resourceType === 'joborderdata')) {
                for (const s of this.scheduleDataDay) s.refreshPlannedOrder();
                this.filterForDay();
                return;
            }

            let dayOnly = true;
            for (const id in event.updatedObjects) {
                const updatedObject = event.updatedObjects[id];
                if (!updatedObject) continue;

                if (['customers', 'occurrencesbyday', 'joborderdata'].indexOf(updatedObject.resourceType) > -1) {
                    if (updatedObject.resourceType === 'customers')
                        Object.values((updatedObject as Customer).jobs || {}).forEach(e => {
                            if (!this._loadedJobIds.some(id => id === e._id)) this._loadedJobIds.push(e._id);
                        });
                    dayOnly = false;
                    break;
                }

                if (updatedObject.resourceType === 'linkers' && (updatedObject as Linker).linkerType === 'assignment') {
                    dayOnly = true;
                    continue;
                }

                const updatedAsOcc = updatedObject as JobOccurrence;
                if (
                    updatedObject.resourceType === 'joboccurrences' &&
                    this.currentDate.isSame(updatedAsOcc.isoCompletedDate || updatedAsOcc.isoPlannedDate || updatedAsOcc.isoDueDate, 'day')
                )
                    continue;

                dayOnly = false;
                break;
            }
            this.refreshScheduleItemsList(dayOnly);
        });
        this._routeChangedSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            if (this.chartMode !== 'list') this.refreshScheduleItemsList();
        });
        this._capacityMeasurementSavedSub = CapacityMeasurementSavedMessage.subscribe(async () => this.refreshScheduleItemsList());

        this._actionBarEventSub = ActionBarEvent.subscribe(async (event: ActionBarEvent) => {
            if (!ApplicationState.isInAnyRole(['Admin', 'Owner', 'Planner'])) return;
            this.actionBarEnabled = event.enable;

            if (
                this.actionBarEnabled &&
                this.chartMode === 'chart' &&
                !this.scheduleDataDay.find(x => x.selected && x.status !== JobOccurrenceStatus.NotDone)
            ) {
                this.actionMode = 'replan';
            } else {
                this.actionMode = undefined;
            }
        });

        this._positionUpdatedSub = PositionUpdatedEvent.subscribe(() => {
            this.scheduleDataDay.forEach(x => x.refreshDistanceFromMe());
            if (this.isDistanceFromMe) this.sortBasedOnFilter(this.scheduleDataDay);
        });

        this.allRoundsText = ScheduleView.ALL_ROUNDS_TEXT;

        this.configureActionMenu();

        this.promptPaymentProvider =
            ApplicationState.isInAnyRole(['Admin', 'Owner']) &&
            (!ApplicationState.account.goCardlessPublishableKey || !ApplicationState.account.stripePublishableKey) &&
            (!ApplicationState.account.lastPromptedForPaymentSetup ||
                moment().diff(moment(ApplicationState.account.lastPromptedForPaymentSetup), 'days') > 45);

        if (this.activescheduleItem) {
            const dialog = new ScheduleDetailsDialog(this.activescheduleItem);
            await dialog.show();
        }

        new LoaderEvent(false);
        new ViewResizeEvent();
        this.loading = false;
    }

    private configureActionMenu() {
        const actions = [] as Array<IMenuBarAction>;
        if (!document.location.pathname.endsWith('/multi-planner')) {
            actions.push({
                tooltip: 'dates.select-date',
                handler: async () => {
                    await this.changeStartingWeek();
                },
                roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'],
            });
        }

        if (!document.location.pathname.endsWith('/multi-planner')) {
            actions.push({
                tooltip: 'dates.manage-notes',
                handler: async () => {
                    await ApplicationState.manageDayNotes();
                },
                roles: ['Owner', 'Admin', 'Creator', 'Planner'],
            });
        }

        actions.push({
            tooltip: 'view-options.title',
            handler: async () => {
                const viewOptions = Utilities.copyObject(this.viewOptions);
                const dialog = new ViewOptionsDialog(viewOptions, !this.viewName || this.viewName.startsWith('myWork_'));
                const viewOptionsResult = await dialog.show(DialogAnimation.SLIDE);
                if (!dialog.cancelled) {
                    this.viewOptions.hideDoneAndSkippedItems = viewOptionsResult.hideDoneAndSkippedItems;
                    this.viewOptions.groupSchedule = viewOptionsResult.groupSchedule;
                    this.viewOptions.showTemperatures = viewOptionsResult.showTemperatures;
                    this.viewOptions.minimiseChartData = viewOptionsResult.minimiseChartData;
                    this.viewOptions.hidePricesForPrivacy = viewOptionsResult.hidePricesForPrivacy;
                    ApplicationState.saveViewOptions();
                    await this.refreshScheduleItemsList();
                }
            },
            roles: ['Owner', 'Admin', 'Creator', 'Planner', 'Worker'],
        });

        actions.push({
            tooltip: 'shift-job-schedule.title',
            handler: async () => {
                const dialog = new ShiftJobScheduleDialog();
                await dialog.show(DialogAnimation.SLIDE);
            },
            roles: ['Owner', 'Admin'],
        });

        actions.push({
            tooltip: 'reset-job-schedule.title',
            handler: async () => {
                const datePickerDialog = new DateTimePicker(false, moment().format(), 'reset-job-schedule.from-date-title', false);
                datePickerDialog.init();
                await datePickerDialog.open();
                if (!datePickerDialog.canceled) {
                    await JobService.hardResetSchedules(moment(datePickerDialog.selectedDate));
                }
            },
            roles: ['Owner', 'Admin'],
        });

        if (ApplicationState.stateFlags.devMode) {
            actions.push({
                tooltip: 'calendar.generate-calendar.title',
                handler: async () => {
                    let activeUserEmailOrTeamId;

                    if (this.isMyWorkView) {
                        activeUserEmailOrTeamId = this.currentUser?._id;
                    } else {
                        activeUserEmailOrTeamId = this.activeUserEmailOrTeamId;
                    }

                    if (!activeUserEmailOrTeamId) return new NotifyUserMessage('calendar.generate-calendar.no-user-selected');

                    const result = await Api.get<Array<string>>(null, '/api/authentication/apikeys');
                    const apiKeys = result && result.data;
                    const iCalKeys = apiKeys?.filter(key => {
                        return key.substring(0, 4) === 'ICAL';
                    });
                    let apiKey;
                    if (!iCalKeys?.length) {
                        apiKey = await this.newICalAPIKey();
                    } else {
                        apiKey = iCalKeys[0];
                    }

                    const calendarDialog = new OpenInCalendarDialog(
                        'calendar.open-in-calendar',
                        'calendar.sub-url',
                        `${Api.apiEndpoint}/api/ical/${activeUserEmailOrTeamId}?apiKey=${apiKey}`
                    );
                    await calendarDialog.show(DialogAnimation.SLIDE);
                },
                roles: ['Owner', 'Admin', 'Worker', 'Planner'],
            });
        }

        new MenuBarActionsEvent(actions);
    }

    private async newICalAPIKey() {
        try {
            const prefix = 'ICAL';
            const result = await Api.post<{ apiKey: string }>(null, '/api/authentication/apikey', { prefix });
            if (!result?.data?.apiKey) {
                new Prompt('general.warning', 'problem.creating-api-key-contact-sqg-support', {
                    okLabel: 'general.ok',
                    cancelLabel: '',
                }).show();
                return;
            }
            return result?.data.apiKey;
        } catch (error) {
            Logger.error('Error generating API key for ICAL', error);
        }
    }

    public detached() {
        FabWithActions.unregister();
        this.unselectAllItems();
        this._applicationStateChangedSubscription && this._applicationStateChangedSubscription.dispose();
        this._dataRefreshedSub && this._dataRefreshedSub.dispose();
        this._scheduleChangedSub && this._scheduleChangedSub.dispose();
        this._capacityMeasurementSavedSub && this._capacityMeasurementSavedSub.dispose();
        this._routeChangedSubscription && this._routeChangedSubscription.dispose();
        this._actionBarEventSub && this._actionBarEventSub.dispose();
        this._scheduleShiftedOrResetSub && this._scheduleShiftedOrResetSub.dispose();
        this._positionUpdatedSub && this._positionUpdatedSub.dispose();
        new MenuBarActionsEvent();
        clearInterval(this.timerUpdater);
    }

    public async addJobForCustomer() {
        let jobDate: string | undefined;
        let customer: Customer | undefined;
        try {
            jobDate = this.currentDate.format('YYYY-MM-DD');
            const pickCustomerDialog = new SelectCustomerDialog(undefined);
            customer = await pickCustomerDialog.show(DialogAnimation.SLIDE_UP);
            if (pickCustomerDialog.cancelled || !customer) return;

            const frequency = ApplicationState.defaultJobFrequency;
            const type = (frequency && frequency.type) || FrequencyType.NoneRecurring;
            const interval = (frequency && frequency.interval) || 0;

            const newJob = new Job(
                customer._id,
                undefined,
                undefined,
                jobDate,
                Utilities.copyObject(customer.address),
                undefined,
                undefined,
                undefined,
                undefined,
                interval,
                type,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                ApplicationState.account.defaultJobDuration
            );
            const dialog = new NewJobDialog(customer, newJob);

            if (pickCustomerDialog.customerIsNew) {
                const customerFormDialog = new CustomerFormDialog(customer);
                const originalCustomer = customer;
                customerFormDialog.beforeNextDialog = async () => {
                    dialog.job.location = Utilities.copyObject(originalCustomer.address);
                };

                customerFormDialog.nextDialog = dialog;
                customer = await customerFormDialog.show();
            } else {
                await dialog.show();
            }
        } catch (error) {
            Logger.error('Error in addJobForCustomer() on ScheduleView', { jobDate, customer, error });
        }
    }

    @computedFrom('selectedWorkLoad')
    protected get activeUserEmailOrTeamId() {
        return (
            (!!this.selectedWorkLoad &&
                !!this.selectedWorkLoad.userOrTeam &&
                this.selectedWorkLoad.userOrTeam._id !== 'UNASSIGNED' &&
                this.selectedWorkLoad.userOrTeam._id) ||
            null
        );
    }

    public async newQuote(type: 'quote' | 'appointment') {
        try {
            if (type === 'quote') {
                await QuoteActions.create();
            } else {
                const jobDate = this.currentDate.format('YYYY-MM-DD');

                const pickCustomerDialog = new SelectCustomerDialog(undefined);
                const customer = await pickCustomerDialog.show(DialogAnimation.SLIDE_UP);
                if (pickCustomerDialog.cancelled || !customer) return;

                const dialog = new LegacyQuote(customer, type, jobDate);
                if (pickCustomerDialog.customerIsNew) {
                    const customerFormDialog = new CustomerFormDialog(customer);
                    const originalCustomer = customer;
                    customerFormDialog.beforeNextDialog = async () => {
                        dialog.job.location = Utilities.copyObject(originalCustomer.address);
                    };
                    customerFormDialog.nextDialog = dialog;
                    await customerFormDialog.show();
                } else {
                    await dialog.show(DialogAnimation.SLIDE_UP);
                }
            }
        } catch (error) {
            Logger.error('Error in newQuote() on ScheduleView', { type, error });
        }
    }

    protected updateChartTitle() {
        let chartTitleText = ApplicationState.localise('general.week-starting') + ' ' + this._weekStart.format('Do MMM YY');

        const route = Array.isArray(this.router.currentInstruction.config.route)
            ? this.router.currentInstruction.config.route[0] || ''
            : this.router.currentInstruction.config.route;

        if (route.startsWith('schedule')) {
            const jobsAndIncome = this.getWeeksIncomeAndJobCount();
            if (jobsAndIncome) {
                chartTitleText += ` - ${jobsAndIncome.jobs} ${ApplicationState.localise('general.jobs')}, ${
                    this.currencySymbol
                }${jobsAndIncome.income.toFixed(2)}`;
            }
        }

        this.chartTitleText = chartTitleText;
    }

    protected async selectUser(workload: Workload) {
        const items = this.scheduleDataDay.filter(x => x.selected);
        if (items.length) {
            new LoaderEvent(true);

            if (workload.userOrTeam?._id === 'UNASSIGNED') {
                await ScheduleService.bulkUnassign(items);
            } else if (workload.userOrTeam?._id) {
                await ScheduleService.bulkAssign(items, workload.userOrTeam);
            }

            this.unselectAllItems();
            await this.filterForDay();
            this.updateDailyTotals();
            this.updateWorkloads();
            new LoaderEvent(false);

            return;
        }

        this.selectedWorkLoad = workload;
        await this.filterForDay();
        this.updateDailyTotals();

        for (const w of this.workloads) w.selected = false;
        workload.selected = true;
    }

    protected updateCurrentMonthYear() {
        if (this.scheduleData && Object.keys(this.scheduleData).length) {
            this.currentMonthYear = Object.keys(this.scheduleData)[0].substr(0, 7);
        }
    }

    private updateMaxY() {
        if (ApplicationState.capacityMeasurementBenchmark === CapacityBenchmarkType.WEEK) {
            // get info from week data
            if (ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS)
                this.maxY = this.getMaxJobsPerDay(this.scheduleData);
            if (ApplicationState.capacityMeasurementType === CapacityMeasurementType.VALUE_OF_JOBS)
                this.maxY = this.getMaxIncomePerDay(this.scheduleData);
        } else {
            // get info from schedule data (only if chart monthyear differs from current monthyear)
            //if (!this.currentChartMonthYear || this.currentMonthYear !== this.currentChartMonthYear) {
            const scheduleGroupCurrentMonth = this.getScheduleByGroupForCurrentMonth();
            if (ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS)
                this.maxY = this.getMaxJobsPerDay(scheduleGroupCurrentMonth);
            if (ApplicationState.capacityMeasurementType === CapacityMeasurementType.VALUE_OF_JOBS)
                this.maxY = this.getMaxIncomePerDay(scheduleGroupCurrentMonth);
            //}
        }
    }

    private getScheduleByGroupForCurrentMonth() {
        const scheduleGroupCurrentMonth: ScheduleByGroup = {};
        if (this.scheduleDataWide && Object.keys(this.scheduleDataWide).length) {
            for (let i = 0; i < Object.keys(this.scheduleDataWide).length; i++) {
                const day = this.scheduleDataWide[Object.keys(this.scheduleDataWide)[i]];
                if (Object.keys(this.scheduleDataWide)[i].substring(0, 7) === this.currentMonthYear) {
                    if (!scheduleGroupCurrentMonth[Object.keys(this.scheduleDataWide)[i]])
                        scheduleGroupCurrentMonth[Object.keys(this.scheduleDataWide)[i]] = {};
                    for (let itemsIndex = 0; itemsIndex < Object.keys(day).length; itemsIndex++) {
                        scheduleGroupCurrentMonth[Object.keys(this.scheduleDataWide)[i]][Object.keys(day)[itemsIndex]] =
                            day[Object.keys(day)[itemsIndex]];
                    }
                }
            }
        }
        this.currentChartMonthYear = this.currentMonthYear;
        return scheduleGroupCurrentMonth;
    }

    private getMaxJobsPerDay(scheduleGroup?: ScheduleByGroup): number {
        let onlyThisWeek = false;
        // means this has been called to check jobs just for this week
        if (!scheduleGroup) {
            scheduleGroup = this.scheduleData;
            onlyThisWeek = true;
        }
        let maxJobsPerDay = 0;
        for (let i = 0; i < Object.keys(scheduleGroup).length; i++) {
            if (Object.keys(scheduleGroup[Object.keys(scheduleGroup)[i]]).length > maxJobsPerDay)
                maxJobsPerDay = Object.keys(scheduleGroup[Object.keys(scheduleGroup)[i]]).length;
        }
        // break out with total if only checking this week
        if (onlyThisWeek) return maxJobsPerDay;
        // otherwise find out if the max this week (could be start of new month) is higher
        const maxJobsPerDayThisWeek = this.getMaxJobsPerDay();
        if (maxJobsPerDayThisWeek > maxJobsPerDay) return maxJobsPerDayThisWeek;
        return maxJobsPerDay;
    }

    private getMaxIncomePerDay(scheduleGroup?: ScheduleByGroup): number {
        let onlyThisWeek = false;
        // means this has been called to check jobs just for this week
        if (!scheduleGroup) {
            scheduleGroup = this.scheduleData;
            onlyThisWeek = true;
        }
        let maxIncomePerDay = 0;
        for (let i = 0; i < Object.keys(scheduleGroup).length; i++) {
            const day = scheduleGroup[Object.keys(scheduleGroup)[i]];
            let dailyIncome = 0;
            for (let scheduleItemIndex = 0; scheduleItemIndex < Object.keys(day).length; scheduleItemIndex++) {
                const scheduleItem = day[Object.keys(day)[scheduleItemIndex]];
                if (scheduleItem.status !== JobOccurrenceStatus.Skipped && scheduleItem.occurrence.price !== undefined)
                    dailyIncome += scheduleItem.occurrence.price;
            }
            if (dailyIncome > maxIncomePerDay) maxIncomePerDay = dailyIncome;
        }
        // break out with total if only checking this week
        if (onlyThisWeek) return maxIncomePerDay;
        // otherwise find out if the max this week (could be start of new month) is higher
        const maxIncomePerDayThisWeek = this.getMaxIncomePerDay();
        if (maxIncomePerDayThisWeek > maxIncomePerDay) return maxIncomePerDayThisWeek;
        return maxIncomePerDay;
    }
    private getWeeksIncomeAndJobCount(): { jobs: number; income: number } | undefined {
        try {
            if (!ApplicationState.isInAnyRole(['Owner', 'Admin'])) return;

            const scheduleGroup = this.scheduleData;

            let income = 0;
            let jobs = 0;

            for (let i = 0; i < Object.keys(scheduleGroup).length; i++) {
                const day = scheduleGroup[Object.keys(scheduleGroup)[i]];
                for (let scheduleItemIndex = 0; scheduleItemIndex < Object.keys(day).length; scheduleItemIndex++) {
                    const scheduleItem = day[Object.keys(day)[scheduleItemIndex]];
                    if (scheduleItem.status !== JobOccurrenceStatus.Skipped) {
                        income += scheduleItem.occurrence.price || 0;
                        jobs++;
                    }
                }
            }

            return { jobs, income };
        } catch (error) {
            Logger.error('Error getting weeks income and job count', error);
        }
    }
    private _refreshTimer: any;
    private _needsWideRefresh: boolean;
    private async refreshScheduleItemsList(dayOnly = false, debounce = 250) {
        clearTimeout(this._refreshTimer);
        if (!dayOnly) this._needsWideRefresh = true;
        const previousRun = this.runningRefresh;
        this._refreshTimer = setTimeout(async () => {
            this.runningRefresh = new Promise<void>(async resolve => {
                await previousRun;
                try {
                    const selectedItems = this.scheduleDataDay.filter(x => x.selected).map(x => x.occurrence._id);
                    if (this.onRefresh) return await this.onRefresh();

                    if (this._needsWideRefresh) {
                        this._needsWideRefresh = false;
                        new LoaderEvent(true);
                        await this.updateWideData();
                        new LoaderEvent(false);
                    }

                    await this.updateWeekData();
                    await this.filterForDay();
                    await this.refreshDay(selectedItems);
                    await this.updateWorkloads();
                } catch (error) {
                    Logger.info('Error during schedule refresh');
                } finally {
                    resolve();
                }
            });
        }, debounce);
    }

    protected async updateWideData(start?: string, end?: string) {
        if (this.chartMode === 'list') {
            this.scheduleDataDay = await ScheduleService.getAllActiveJobsAsScheduleItems();
            this.allData = this.scheduleDataDay;
            return;
        }

        let startDate: moment.Moment;
        if (!start) {
            startDate = this._weekStart.clone().subtract(ScheduleView.CACHE_SIZE_WEEKS, 'weeks');
            start = startDate.format();
        } else {
            startDate = moment(start);
        }

        if (!end)
            end = this._weekStart
                .clone()
                .add(ScheduleView.CACHE_SIZE_WEEKS + 1, 'weeks')
                .subtract(1, 'd')
                .format();

        this.isMyWorkView = this.router.currentInstruction.config.route === 'mywork';

        if (this.isMyWorkView) {
            this.scheduleDataWide = await ScheduleService.getScheduledJobsForCurrentUserByDay(
                startDate,
                new DateFilterOption('week', start, end),
                []
            );
            return;
        }

        this.scheduleDataWide = await ScheduleService.getScheduledJobsByDay(startDate, new DateFilterOption('week', start, end), []);
    }

    protected updateWeekData() {
        if (this.chartMode === 'list') return;
        const newScheduleData: ScheduleByGroup = {};
        const weekStart = this._weekStart.clone();
        let count = 7;
        while (count--) {
            const key = weekStart.format('YYYY-MM-DD');
            newScheduleData[key] = this.scheduleDataWide[key] || {};
            weekStart.add(1, 'day');
        }
        this.setActiveDay();
        this.scheduleData = newScheduleData;
        this.updateChartTitle();
        this.updateCurrentMonthYear();
        this.updateMaxY();
    }

    protected _weekStart = moment().startOf('isoWeek');
    private _moves = 0;
    private _updateTimer: any;
    protected async loadScheduleStats(weeksFromNow = 0, loadAll = false) {
        this._moves += weeksFromNow;
        this._weekStart.add(weeksFromNow, 'weeks');
        if (loadAll) await this.updateWideData();
        else this.scheduleUpdateWideData();
        this.updateWeekData();
    }

    private scheduleUpdateWideData() {
        clearTimeout(this._updateTimer);
        if (Math.abs(this._moves) > Math.ceil(ScheduleView.CACHE_SIZE_WEEKS / 2)) this._updateTimer = setTimeout(this.doUpdate, 750);
        else this._updateTimer = setTimeout(this.doUpdate, 5000);
    }

    private doUpdate = async () => {
        Logger.info('Schedule Update WideData called');
        await this.updateWideData();
        if (this._moves > ScheduleView.CACHE_SIZE_WEEKS) {
            this.updateWeekData();
        }
        this._moves = 0;
    };

    private getChartSwipeable(): Array<Element> {
        const elements = document.querySelectorAll('.chart-swipeable');
        const htmlElements: Array<Element> = [];
        for (let i = 0; i < elements.length; i++) {
            htmlElements.push(elements[i]);
        }
        return htmlElements;
    }

    private addClass(elements: Array<Element>, ...classNames: Array<string>) {
        for (let i = 0; i < elements.length; i++) {
            for (let j = 0; j < classNames.length; j++) {
                elements[i].classList.add(classNames[j]);
            }
        }
    }

    private removeClass(elements: Array<Element>, ...classNames: Array<string>) {
        for (let i = 0; i < elements.length; i++) {
            for (let j = 0; j < classNames.length; j++) {
                elements[i].classList.remove(classNames[j]);
            }
        }
    }

    protected changeWeek = async (isLeft: boolean) => {
        const before12weeks = this._weekStart
            .clone()
            .isSameOrBefore(this.currentDate.clone().subtract(KEEP_OCCURRENCES_AFTER_X_WEEKS, 'weeks').startOf('weeks'));

        if (before12weeks && !isLeft && ApplicationState.getSetting('global.archive-occurrences-before-days', true))
            return new NotifyUserMessage('schedule.occurrences-are-archived-from-this-point-on');
        this.clearActiveDay();
        const swipeFrom = isLeft ? 'left' : 'right';
        const swipeTo = isLeft ? 'right' : 'left';
        setTimeout(() => {
            const chartElements = this.getChartSwipeable();
            this.addClass(chartElements, 'slide-out-' + swipeFrom, swipeTo);
            setTimeout(async () => {
                this.removeClass(chartElements, 'slide-out-' + swipeFrom);
                await this.loadScheduleStats(isLeft ? 1 : -1);
                this.addClass(chartElements, 'slide-in-' + swipeTo);
                setTimeout(async () => {
                    this.removeClass(chartElements, 'slide-in-' + swipeTo, swipeTo);
                }, 250);
            }, 250);
        }, 25);
    };

    protected async changeStartingWeek() {
        const startingWeekDatePicker = new DateTimePicker(false, undefined);
        startingWeekDatePicker.initialDate = this.currentDate.format('YYYY-MM-DD');
        startingWeekDatePicker.init();

        await startingWeekDatePicker.open();
        if (!startingWeekDatePicker.canceled) {
            const before12weeks = moment().subtract(KEEP_OCCURRENCES_AFTER_X_WEEKS, 'weeks').startOf('week');
            if (
                moment(startingWeekDatePicker.selectedDate).isBefore(before12weeks) &&
                !ApplicationState.stateFlags.devMode &&
                ApplicationState.getSetting('global.archive-occurrences-before-days', true)
            ) {
                return new NotifyUserMessage('day-pilot.date-selected-passed-archive-date');
            }

            this.updateStartingWeek(startingWeekDatePicker.selectedDate);
        }
    }

    protected async updateStartingWeek(selectedDate: string) {
        this.clearActiveDay();
        this.currentDate = moment(selectedDate);
        this.setActiveDay();
        this.updateCurrentMonthYear();
        const selectedDateWeekStart = moment(selectedDate).startOf('isoWeek');
        const weekDiff = selectedDateWeekStart.diff(this._weekStart, 'weeks');
        await this.loadScheduleStats(weekDiff, true);
        this.filterForDay();
    }

    private async _replanSelected(selectedItems: Array<ScheduleItem>, targetDate: Moment) {
        const total = selectedItems.length;

        let assignmentDescription = '';
        if (this.selectedWorkLoad?.userOrTeam?._id) {
            assignmentDescription = 'and assign to ' + this.selectedWorkLoad.userOrTeam.name;
        }

        if (
            !(await new Prompt(
                'general.confirm',
                `Would you like to replan  ${selectedItems.length} job${selectedItems.length > 1 ? 's' : ''} to ${moment(targetDate).format(
                    'ddd, Do MMM'
                )} ${assignmentDescription}?` as TranslationKey,
                {
                    okLabel: 'actions.replan',
                    cancelLabel: 'general.cancel',
                }
            ).show())
        ) {
            return;
        }

        const recurring = selectedItems.filter(
            item =>
                (item.job.frequencyType !== FrequencyType.NoneRecurring &&
                    item.job.frequencyType !== FrequencyType.ExternalCalendar &&
                    item.job.rounds.length === 0) ||
                !item.job.rounds.find(round => round.frequencyData !== undefined)
        ).length;
        let adjustSchedule = false;

        if (
            ApplicationState.account.schedulingBehavior !== 'update-schedule' &&
            selectedItems.some(
                x => x.job.frequencyType !== FrequencyType.NoneRecurring && x.job.frequencyType !== FrequencyType.ExternalCalendar
            )
        ) {
            let adjustScheduleMessage = '';
            if (total === 1 && recurring === 1) {
                adjustScheduleMessage = `Replan appointment to ${targetDate.format('ll')}.
            <br/><br/> Also update schedule for future appointments of this job?`;
            } else if (total > 1 && total === recurring) {
                adjustScheduleMessage = `Replan ${total} appointments to ${targetDate.format('ll')}.
            <br/><br/> Also update schedule for future appointments of ${total} repeating jobs?`;
            } else if (total > 1 && recurring > 0) {
                adjustScheduleMessage = `Replan ${total} appointments to ${targetDate.format('ll')}.
            <br/><br/> ${recurring} jobs are repeating, update schedule for future appointments of these jobs?`;
            } else {
                adjustScheduleMessage = `Replan ${total} appointment(s) to ${targetDate.format('ll')}?`;
            }

            if (adjustScheduleMessage) {
                const prompt = new Prompt('schedule.view-replan-title', <TranslationKey>adjustScheduleMessage, {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.cancel',
                    altLabel: recurring > 0 ? 'general.no' : '',
                });
                adjustSchedule = (await prompt.show()) && recurring > 0;
                if (prompt.cancelled) return;
            }
        } else adjustSchedule = true;

        const targetDateIso = targetDate.format('YYYY-MM-DD');
        const existingScheduleItemsOnDay =
            this.scheduleDataWide[targetDateIso] && Object.keys(this.scheduleDataWide[targetDateIso]).length
                ? Object.keys(this.scheduleDataWide[targetDateIso]).map(id => this.scheduleDataWide[targetDateIso][id])
                : undefined;
        await ScheduleService.replan(selectedItems, targetDateIso, adjustSchedule, existingScheduleItemsOnDay);

        if (this.selectedWorkLoad?.userOrTeam?._id) await ScheduleService.bulkAssign(selectedItems, this.selectedWorkLoad.userOrTeam);

        this.actionBarEnabled = false;
        this.actionMode = undefined;
        new ActionBarEvent(false);
        this.refreshScheduleItemsList();
    }

    protected chartColumnSelected = async (index: number): Promise<{ proceed: boolean }> => {
        const targetDate = this._weekStart.clone().add(index, 'days');

        const maxDate = moment().add(this.restrictedViewableDays, 'days');
        const minDate = moment().subtract(this.restrictedViewableDays, 'days');

        const beforeEndDate = this.restrictedViewableDays && targetDate.isSameOrBefore(maxDate, 'days');
        const afterStartDate = this.restrictedViewableDays && targetDate.isSameOrAfter(minDate, 'days');
        const withinDate = beforeEndDate && afterStartDate;
        if (!this.isOnlyWorker || this.restrictedViewableDays === undefined || withinDate) {
            this.isRestrictedDay = false;
        } else {
            this.isRestrictedDay = true;
        }

        const selectedItems = this.scheduleDataDay.filter(x => x.selected);
        if (this.actionMode === 'replan') {
            await this._replanSelected(selectedItems, targetDate);
            // Don't proceed with changing the column index
            return { proceed: false };
        }

        this.currentDate = this._weekStart.clone().add(index, 'days');
        this.updateCurrentMonthYear();
        this.filterForDay();
        this.updateWorkloads();

        return { proceed: true };
    };

    protected setActiveDay() {
        if (this.currentDate.isSame(this._weekStart, 'isoWeek')) {
            return (this.chartSelectedColumnIndex = this.currentDate.isoWeekday() - 1);
        }
        return this.clearActiveDay();
    }

    protected clearActiveDay() {
        this.chartSelectedColumnIndex = undefined;
    }

    protected getDataForDay(date: string = this.currentDate.format('YYYY-MM-DD')) {
        if (this.chartMode === 'list') return this.scheduleDataDay;

        const day = this.scheduleDataWide[date];
        return day
            ? Object.keys(day)
                  .map(key => day[key])
                  .filter(x => !x.occurrence._deleted)
            : [];
    }

    protected filteringForDay = false;

    protected isSortable = false;
    protected async filterForDay() {
        if (this.filteringForDay) return;
        this.filteringForDay = true;

        try {
            if (this.chartMode === 'list') {
                this.scheduleDataDayUnfilteredCount = this.allData.length;
                this.scheduleDataDay = await this.sortBasedOnFilter(this.allData);
            } else {
                this.scheduleDataDay = this.getDataForDay();
                this.scheduleDataDayUnfilteredCount = this.scheduleDataDay.length;
                this.scheduleDataDay = await this.sortBasedOnFilter(this.scheduleDataDay);
            }

            for (const scheduleItem of this.scheduleDataDay) ScheduleItem.refresh(scheduleItem);

            this.updateDailyTotals();
            this.buildFab();
        } finally {
            this.filteringForDay = false;
        }
    }

    private setSelectedFiltersFromStorage(existingFilter: Filter<IScheduleFilterItemDictionary | IScheduleSortItemDictionary>) {
        const storedFilterOptions = SqueegeeLocalStorage.getItem(this.viewName + 'scheduleFilterOptions');
        const filterOptions = storedFilterOptions?.length ? (JSON.parse(storedFilterOptions) as IScheduleFilterItemDictionary) : null;

        // set selected from local storage
        if (filterOptions?.sortBy) existingFilter.filterOptions.sortBy = filterOptions.sortBy;
        // refresh count
        existingFilter.update();

        return existingFilter;
    }

    protected clearFilters() {
        this.currentFilter = Filters.scheduleViewFilter(ScheduleService.getFilters(this.scheduleDataDay));
        this.filterForDay();
    }
    protected regenerateFilters(allFilters: IScheduleViewFilterArgs, refreshDay = false) {
        // reset filter
        this.currentFilter = Filters.scheduleViewFilter(allFilters, this.currentFilter);

        try {
            this.setSelectedFiltersFromStorage(this.currentFilter);
        } catch (error) {
            Logger.info('Unable to load the schedule filters.', error);
        }

        if (refreshDay) this.filterForDay();
    }

    protected async sortBasedOnFilter(dayArray: Array<ScheduleItem>) {
        if (this.isDistanceFromMe && ApplicationState.positionNotAvailable) {
            new NotifyUserMessage('notification.unable-to-sort-by-distance-location-not-available');
        }

        dayArray = ScheduleService.filter(
            dayArray,
            true,
            this.currentFilter && (this.currentFilter.filterOptions as IScheduleFilterItemDictionary),
            this.searchText,
            this._getUserEmailAndTeamIds()
        );

        // TODO: Dynamic grouping
        if (this.viewOptions.groupSchedule) {
            const roundDictionary: { [round: string]: Array<ScheduleItem> } = {};
            dayArray.reduce((dictionary, next) => {
                if (!dictionary[next.roundName || '']) dictionary[next.roundName || ''] = [];
                dictionary[next.roundName || ''].push(next);
                return dictionary;
            }, roundDictionary);

            for (const key in roundDictionary) {
                roundDictionary[key] = ScheduleService.sort(roundDictionary[key], this.currentFilter && this.currentFilter.filterOptions);
            }

            // TODO: Round ordering
            this.scheduleItemsGroupedByRound = Object.keys(roundDictionary)
                .map(key => {
                    return { key, items: roundDictionary[key] };
                })
                .filter(r => r.items.length)
                .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0));
        } else {
            this.scheduleItemsGroupedByRound = [
                { key: ' ', items: ScheduleService.sort(dayArray, this.currentFilter && this.currentFilter.filterOptions) },
            ];
        }
        return dayArray;
    }

    private _getUserEmailAndTeamIds() {
        if (this.selectedWorkLoad?.userOrTeam?._id) {
            return [this.selectedWorkLoad.userOrTeam._id];
        }

        if (this.selectedWorkLoad?.userOrTeam?._id === 'UNASSIGNED') {
            return ['UNASSIGNED'];
        }
    }

    public handleDragComplete = async (toIndex: number, items: Array<ScheduleItem>, itemsDragged: Array<ScheduleItem>) => {
        await ScheduleService.handleDragComplete(toIndex, items, itemsDragged, false);
    };

    @computedFrom('currentFilter.filterOptions.sortBy.selectedOption')
    protected get isManual() {
        return (
            this.currentFilter &&
            this.currentFilter.filterOptions.sortBy.selectedOption &&
            this.currentFilter.filterOptions.sortBy.selectedOption.value === 1
        );
    }

    @computedFrom('currentFilter.filterOptions.sortBy.selectedOption')
    protected get isDistanceFromMe() {
        return (
            this.currentFilter &&
            this.currentFilter.filterOptions.sortBy.selectedOption &&
            this.currentFilter.filterOptions.sortBy.selectedOption.value === 2
        );
    }

    private async switchToManual() {
        if (!this.currentFilter) return;
        const findManualOption = this.currentFilter.filterOptions.sortBy.options.find(x => x.value === 1);
        this.currentFilter.filterOptions.sortBy.selectedOption = findManualOption;
        this.filterForDay();
    }
    protected skillSummaries: Array<{ skill: string; count: number; duration: number }> = [];

    private updateDailyTotals(selectedItems?: Array<string>) {
        const skillSummaries: Record<string, { count: number; duration: number }> = {
            'No Skills': { count: 0, duration: 0 },
        };
        let totalPrice = 0;
        let totalTime = 0;
        let totalActualTime = 0;
        this.dailyTotalJobs = this.scheduleDataDay.length;
        let canSeePricing = true;

        const selectedItemsObjects: Array<ScheduleItem> = [];
        for (const scheduleItem of this.scheduleDataDay) {
            if (selectedItems && selectedItems.indexOf(scheduleItem.occurrence._id) > -1) {
                scheduleItem.selected = true;
                selectedItemsObjects.push(scheduleItem);
            }
            if (scheduleItem.status === JobOccurrenceStatus.Skipped) continue;
            if (scheduleItem.price === undefined) canSeePricing = false;
            else totalPrice += Number(scheduleItem.occurrence.price) || 0;
            totalTime += Utilities.durationToMinutes(scheduleItem.occurrence.duration);
            totalActualTime += scheduleItem.actualDuration || 0;
            if (!scheduleItem.occurrence.skills?.length) {
                skillSummaries['No Skills'].count++;
                skillSummaries['No Skills'].duration += Utilities.durationToMinutes(scheduleItem.occurrence.duration);
            } else {
                for (const skill of scheduleItem.occurrence.skills) {
                    skillSummaries[skill] = skillSummaries[skill] || { count: 0, duration: 0 };
                    skillSummaries[skill].count++;
                    skillSummaries[skill].duration += Utilities.durationToMinutes(scheduleItem.occurrence.duration);
                }
            }

            scheduleItem.refreshDistanceFromMe();
            scheduleItem.refreshDistance();
        }
        if (selectedItemsObjects && selectedItemsObjects.length) {
            ActionBarEvent.fromSelectedScheduleItems(
                this.unselectAllItems,
                ScheduleService.getBulkActionsForScheduleItem(selectedItemsObjects, this.scheduleDataDay, this.bulkActionWrapperSelected),
                selectedItemsObjects
            );
        }
        this.canSeePricing = canSeePricing;
        this.dailyTotalPrice = totalPrice.toFixed(2).toString();
        if (!totalTime) this.dailyTotalTime = '';
        else {
            const hours = Math.floor(totalTime / 60);
            const minutes = Math.floor(totalTime % 60);
            this.dailyTotalTime = '| ' + hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }
        if (!totalActualTime) this.dailyTotalActualTime = '';
        else this.dailyTotalActualTime = `(${formatTimestampDurationAsTime(totalActualTime, true)})`;
        this.updateJobCountText();
        this.skillSummaries = Object.keys(skillSummaries).map(skill => ({ skill, ...skillSummaries[skill] }));
    }

    private bulkActionWrapperSelected = async (
        action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void, allScheduleItems?: Array<ScheduleItem>) => Promise<any>,
        message: TranslationKey,
        allScheduleItems?: Array<ScheduleItem>
    ) => {
        const selectedItems = this.scheduleDataDay.filter((scheduleItem: ScheduleItem) => {
            return scheduleItem.selected === true;
        });
        if (selectedItems.length) {
            try {
                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));

                const happened = await action(selectedItems, this.unselectAllItems, allScheduleItems);

                await new Promise<void>(resolve => requestAnimationFrame(() => resolve()));

                if (happened && this.scheduleDataDay.length > 1) {
                    requestAnimationFrame(() => {
                        new NotifyUserMessage(<TranslationKey>(selectedItems.length.toString() + ' ' + message), {
                            count: selectedItems.length.toString(),
                        });
                    });
                }
            } catch (error) {
                new NotifyUserMessage('notifications.oh-no');
                Logger.error(`Error during bulk action wrapper in schedule item list custom element`, { action, error });
            } finally {
                new LoaderEvent(false);
            }
        }
    };

    private refreshDay(selectedScheduleItems?: Array<string>) {
        //   if (this.chartMode === 'users' || this.chartMode === 'teams') this.updateWorkloads();
        this.updateDailyTotals(selectedScheduleItems);
        this.buildFab();
        for (const scheduleItem of this.scheduleDataDay) ScheduleItem.refresh(scheduleItem);
    }

    private unselectAllItems = () => {
        for (const scheduleItem of this.scheduleDataDay) {
            scheduleItem.selected = false;
            scheduleItem.selectable = false;
            scheduleItem.disabled = false;
        }
        new ActionBarEvent(false);
    };

    protected async openFilterMenu() {
        const allFilters = ScheduleService.getFilters(this.getDataForDay());
        this.regenerateFilters(allFilters);

        const dialog = new FiltersDialog<IScheduleFilterItemDictionary | IScheduleSortItemDictionary>(
            this.currentFilter,
            Filters.scheduleViewFilter,
            allFilters
        );

        const result = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            this.currentFilter = result;
            try {
                const sortBy = this.currentFilter.filterOptions.sortBy;
                const filterOptions = JSON.stringify({ sortBy });
                SqueegeeLocalStorage.setItem(this.viewName + 'scheduleFilterOptions', filterOptions);
            } catch (error) {
                Logger.info('Unable to cache the schedule sort options.', error);
            }

            this.filterForDay();
        }
    }

    protected optimisationOptions: IDayPilotOptions;
    private refreshViewOptions() {
        const startLocation = SqueegeeLocalStorage.getItem('dayPilotStartLocation');
        const endLocation = SqueegeeLocalStorage.getItem('dayPilotEndLocation');

        this.optimisationOptions = {
            departureTimeMinutes: Number(SqueegeeLocalStorage.getItem('dayPilotDepartureTimeMinutes')) || 0,
            departureTimeHours: Number(SqueegeeLocalStorage.getItem('dayPilotDepartureTimeHours')) || 0,
            startLocation: startLocation ? JSON.parse(startLocation) : new Location(),
            endLocation: endLocation ? JSON.parse(endLocation) : new Location(),
        };
    }

    protected async openOptimiseSettings() {
        this.refreshViewOptions();
        const optimisationOptions = Utilities.copyObject(this.optimisationOptions);
        const dialog = new DayPilotSettingsDialog(optimisationOptions);
        const viewOptionsResult = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            this.optimisationOptions = viewOptionsResult;
            if (
                viewOptionsResult.departureTimeHours &&
                viewOptionsResult.departureTimeMinutes &&
                (isNaN(viewOptionsResult.departureTimeMinutes) || isNaN(viewOptionsResult.departureTimeHours))
            ) {
                // error
            }
            SqueegeeLocalStorage.setItem('dayPilotDepartureTimeMinutes', String(viewOptionsResult.departureTimeMinutes));
            SqueegeeLocalStorage.setItem('dayPilotDepartureTimeHours', String(viewOptionsResult.departureTimeHours));
            SqueegeeLocalStorage.setItem('dayPilotStartLocation', JSON.stringify(viewOptionsResult.startLocation));
            SqueegeeLocalStorage.setItem('dayPilotEndLocation', JSON.stringify(viewOptionsResult.endLocation));

            return true;
        }
        return false;
    }

    protected optimisationSettings: IDayPilotOptions = {};

    protected async optimiseDay() {
        try {
            if (!this.scheduleDataDay.length) return new NotifyUserMessage('notifications.no-jobs-for-this-worker');
            if (!(await this.openOptimiseSettings())) return;

            new LoaderEvent(true);
            let userId: string | undefined;

            if (this.isMyWorkView) {
                const user = UserService.getUser();
                userId = user?._id;
            } else {
                userId = this.selectedWorkLoad?.userOrTeam?._id;
            }

            const optimisedRoute = await RouteOptimisationService.getRouteItemsForScheduleDataDay(
                this.currentDate.format('YYYY-MM-DD'),
                userId,
                this.scheduleDataDay,
                true,
                'optimal',
                true,
                true,
                this.optimisationOptions?.startLocation?.lngLat,
                this.optimisationOptions?.endLocation?.lngLat
            );

            const jobsToOrder = optimisedRoute.routeItems.filter(x => x.scheduleItem).map(x => <ScheduleItem>x.scheduleItem);

            if (ScheduleService.updateListPlannedOrder(1, jobsToOrder.slice(0, 1), jobsToOrder.slice(1), false)) {
                for (const item of this.scheduleDataDay) {
                    item.refreshPlannedOrder();
                }
            }

            this.switchToManual();

            new LoaderEvent(false);
        } catch (ex) {
            Logger.error('Error running optimise day', ex);
        } finally {
            new LoaderEvent(false);
        }
    }

    protected droppedOnWorkLoad = (data: string, workload: Workload) => {
        const result = this.scheduleDataDay.find(scheduleItem => scheduleItem.occurrence._id === data);
        if (result) {
            const userId = workload.userOrTeam ? (<AccountUser>workload.userOrTeam)._id || workload.userOrTeam._id : undefined;
            if (!userId) return;
            AssignmentService.assign(result.occurrence, userId, false, false);
        }
    };

    protected switchChartMode = async (mode: 'chart' | 'users' | 'list' | 'teams') => {
        this.chartMode = mode;
        new LoaderEvent(true);
        await wait(0);
        try {
            this.unselectAllItems();

            if (mode === 'list') {
                this.workloads = [];
                this.selectedWorkLoad = undefined;
                await this.refreshScheduleItemsList(false);
            } else if (mode === 'chart') {
                this.workloads = [];
                this.selectedWorkLoad = undefined;
                await this.refreshScheduleItemsList(true);
            } else if (mode === 'users' || mode === 'teams') {
                await this.updateWorkloads();
                this.selectUser(this.workloads[0]);
            }
        } finally {
            new LoaderEvent(false);
        }
    };

    public async updateWorkloads(changeToUserId?: string) {
        const activeUserOrTeamId = changeToUserId || this.selectedWorkLoad?.userOrTeam?._id;
        this.selectedWorkLoad = undefined;

        const dayData = this.getDataForDay();
        // if (!activeUserEmailOrTeamId) activeUserEmailOrTeamId = this.activeUserEmailOrTeamId || undefined;

        let workloads: Array<Workload> = [];
        const hasDefaultAssignee = !!AssignmentService.getDefaultAssigneeUserOrTeam();
        if (this.chartMode === 'users')
            workloads = UserService.getWorksloadsForUsers(dayData, undefined, !hasDefaultAssignee, activeUserOrTeamId);
        else if (this.chartMode === 'teams')
            workloads = UserService.getWorksloadsForTeams(dayData, this.currentDateIso, activeUserOrTeamId);

        this.selectedWorkLoad = this.workloads?.find(x => x.selected);
        this.workloads = workloads;
    }

    public handleAddWorkBtnClick = async () => {
        if (!this.currentDate) this.currentDate = moment();
        const viewDialog = new ViewDialog('schedule-service.replan-jobs');
        const itemsToReplan = await viewDialog.show(DialogAnimation.SLIDE_UP_IN);
        if (itemsToReplan && itemsToReplan.length) {
            await this._replanSelected(itemsToReplan, this.currentDate);
        }
        this.buildFab();
    };

    protected timeTrackingEnabled = ApplicationState.features.timeTracker;
    handleTimersBtnClick = () => {
        if (!ApplicationState.features.timeTracker) return;

        if (GlobalFlags.isMobile) {
            return new Prompt('general.warning', 'time-tracker.not-available-on-mobile', {
                cancelLabel: '',
            }).show();
        }

        ApplicationState.navigateToRouteFragment('tracker/home');
    };

    @computedFrom('currentDate')
    get currentViewId() {
        return `schedule-view-${this.currentDate.toISOString()}`;
    }

    @computedFrom('currentDate')
    get currentDateIso() {
        return this.currentDate.format('YYYY-MM-DD');
    }

    protected async addTeam() {
        let team = new Team('', []);
        team.activeFrom = this.currentDateIso;
        team.activeTo = this.currentDateIso;
        const dialog = new TeamFormDialog(team);
        team = await dialog.show();
        if (dialog.cancelled) return;

        await Data.put(team).catch(error => {
            Logger.error('Error in newTeam() on Teams', { team, error });
            new NotifyUserMessage('users.save-error');
        });
        await this.updateWorkloads(team._id);
        await this.filterForDay();
        this.updateDailyTotals();
    }
    protected async editTeam() {
        let team = this.selectedWorkLoad?.userOrTeam as Team;
        const dialog = new TeamFormDialog(team);
        team = await dialog.show();
        if (dialog.cancelled) return;

        await Data.put(team).catch(error => {
            Logger.error('Error in newTeam() on Teams', { team, error });
            new NotifyUserMessage('users.save-error');
        });
        await this.updateWorkloads(team._id);
        await this.filterForDay();
        this.updateDailyTotals();
    }

    protected async bulkEditJobTags() {
        const checkUserPermissions = ApplicationState.isInAnyRole(['Owner', 'Admin']);
        if (!checkUserPermissions) return new NotifyUserMessage('user.permissions-no-access-to-bulk-edit-tags');

        if (!this.searchText && !this.currentFilter) {
            const prompt = await new Prompt('general.warning', 'hashtags.bulk-tag-without-filter', {
                cancelLabel: '',
            }).show();

            if (!prompt) return;
        }

        const checkPermission = await new Prompt('general.confirm', 'hashtags.bulk-tag-are-you-sure', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
            localisationParams: {
                count: this.scheduleDataDay.length.toString(),
            },
        }).show();

        if (!checkPermission) return;

        if (!this.scheduleDataDay.length) return;

        const jobs = this.scheduleDataDay.map(x => x.job);

        const { show, dialog } = AureliaReactComponentDialog.show({
            dialogTitle: 'hashtags.bulk-tag-title',
            component: HashtagEditor,
            isSecondaryView: true,
            componentProps: {
                items: this.scheduleDataDay.map(x => x.job),
                availableHashTagMap: getHashtags(jobs),
            },
        });

        await show;

        if (dialog.cancelled) return;

        for (const job of jobs) this.signaler.signal('tags-updated-' + job._id);
    }
}
