import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { ScheduleItem } from '../Components/ScheduleItem';
import type { IScheduleItemAction } from './IScheduleItemAction';
export class NotDone implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {}
    public actionType = <ItemActionType>'action-not-done';
    public async handler() {
        await JobOccurrenceService.revertJobOccurrence(this.scheduleItem.occurrence);
        ScheduleItem.refresh(this.scheduleItem);
        return this.scheduleItem;
    }
    public tooltip: TranslationKey = 'actions.not-done';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker'];
}
