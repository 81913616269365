import moment from 'moment';
import { Logger } from './Logger';

export class Stopwatch {
    private _start: Date = new Date();
    private _end: Date | null;
    private _laps: Array<{ name: string; duration: number }> = [];
    private _lastLapTime: Date | null;
    private static _globalTime = new Date();
    private static colours = [
        '#8f3b1b',
        '#d57500',
        '#dbca60',
        '#668d3c',
        '#4e6172',
        'green',
        'cornflowerblue',
        'orange',
        'yellowgreen',
        'hotpink',
    ];
    private static _lastColour: string;
    private _colour: string;

    constructor(private stopwatchName: string) {
        this._colour = Stopwatch.colours[Stopwatch.colours.indexOf(Stopwatch._lastColour) + 1] || Stopwatch.colours[0];
        Stopwatch._lastColour = this._colour;
        this.start();
    }

    public lap(lapName: string) {
        try {
            const now = new Date();
            const timeStart = this._lastLapTime ? this._lastLapTime.getTime() : this._start.getTime();
            const duration = now.getTime() - timeStart;
            this._lastLapTime = now;
            this._laps.push({
                name: lapName,
                duration: duration,
            });

            this.logMsg(duration, `${lapName} (lap)`, this._colour);
            return duration;
        } catch (error) {
            Logger.error('Unable to record lap due to an unexpected error', error);
        }
    }

    public stop() {
        try {
            this._end = new Date();
            this.logMsg(this.duration() || 0, `${this.stopwatchName} (stop)`, this._colour);
            return this.duration();
        } catch (error) {
            Logger.error('Unable to stop the stopwatch due to an unexpected error', error);
        }
    }

    public clearLaps() {
        try {
            this._lastLapTime = null;
            this._laps.splice(0);
        } catch (error) {
            Logger.error('Unable to clear laps due to an unexpected error', error);
        }
    }

    private start() {
        this._end = null;
        this._start = new Date();
        this.logMsg(0, `${this.stopwatchName} (start)`, this._colour);
    }

    public duration() {
        try {
            const endTime = this._end || new Date();
            return endTime.getTime() - this._start.getTime();
        } catch (error) {
            Logger.error('Unable to get stopwatch duration due to an unexpected error', error);
            return 0;
        }
    }

    public static globalDuration(end?: Date | null) {
        try {
            const endTime = end || new Date();
            return endTime.getTime() - Stopwatch._globalTime.getTime();
        } catch (error) {
            Logger.error('Unable to get stopwatch duration due to an unexpected error', error);
        }
    }

    public get laps() {
        return this._laps;
    }

    private logMsg(duration: number, msg: string, color: string) {
        try {
            const time = `[${moment().format('HH:mm:ss')}]`.padEnd(10, ' ');
            const globalDurationText = `[${Stopwatch.globalDuration(this._end)}ms]*`.padEnd(10, ' ');
            const durationText = `[${duration}ms]`.padEnd(10, ' ');
            Logger.log(`${time}${globalDurationText}${durationText}${msg}`, '', color, '');
        } catch (error) {
            Logger.error('Unable to log stopwatch message due to an unexpected error', error);
        }
    }
}
