import type { Customer, ITab, StoredEvent, TranslationKey } from '@nexdynamic/squeegee-common';
import type { Subscription as EventSub } from 'aurelia-event-aggregator';
import { EventAggregator } from 'aurelia-event-aggregator';
import { computedFrom, inject } from 'aurelia-framework';
import type { RouterConfiguration } from 'aurelia-router';
import { Redirect, Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { ApplicationEnvironment } from './ApplicationEnvironment';
import { ApplicationState } from './ApplicationState';
import { SqueegeeLocalStorage } from './Data/SqueegeeLocalStorage';
import { Prompt } from './Dialogs/Prompt';
import { ActionBarEvent } from './Events/ActionBarEvent';
import { ApplicationStateUpdatedEvent } from './Events/ApplicationStateUpdatedEvent';
import { DataRefreshedEvent } from './Events/DataRefreshedEvent';
import { DeselectEvent } from './Events/DeselectEvent';
import { LoaderEvent } from './Events/LoaderEvent';
import { MenuBarActionsEvent } from './Events/MenuBarActionsEvent';
import { ObfuscatorEvent } from './Events/ObfuscatorEvent';
import { SubscriptionUpdatedEvent } from './Events/SubscriptionUpdatedEvent';
import { FabWithActions } from './FabWithActions';
import { GlobalFlags } from './GlobalFlags';
import type { IRoute } from './IRoute';
import { Logger } from './Logger';
import { NotifyUserMessage } from './Notifications/NotifyUserMessage';
import { AdvertisementAssetCache, runAdvertChecker } from './ReactUI/Marketplace/services/AdvertisementService';
import { matchAndUpdateCustomersToAudience } from './ReactUI/send/features/audiences/AudienceService';
import { RouteUtil } from './Routes';
import { Api } from './Server/Api';
import { SqueegeeClientSocketApi } from './Server/SqueegeeClientSocketApi';
import { Stopwatch } from './Stopwatch';
import { TimerService } from './Tracking/TimerService';
import { UserService } from './Users/UserService';
import { Utilities, animate } from './Utilities';
import { generateStartupAlerts } from './generateStartupAlerts';
import { isDevMode } from './isDevMode';

@inject(Router, EventAggregator, BindingSignaler)
export class Squeegee {
    _routeNavigatingEventSubscription: EventSub;
    _subscriptionUpdatedSubscription: EventSub;
    constructor(public router: Router, public eventAggregator: EventAggregator, signaler: BindingSignaler) {
        ApplicationState.router = router;
        ApplicationStateUpdatedEvent.subscribe(() => signaler.signal('app-state-update'));
        SubscriptionUpdatedEvent.subscribe(() => {
            this.expiredBanner =
                ['overdue', 'unpaid'].some(x => x === ApplicationState.subscription.status) &&
                this.router.currentInstruction.fragment.indexOf('account') === -1;
        });
        ApplicationState.signaler = signaler;
    }

    protected isAndroidMobileDevice = GlobalFlags.isAndroidMobileDevice;
    protected isAppleMobileApp = !ApplicationState.canShowAccountManagement;
    protected isMobile = GlobalFlags.isMobile;
    protected forceWideScreen = GlobalFlags.forceWideScreen;
    protected showObfuscator = false;
    public actions = FabWithActions.actions;
    protected showSignOutBlocker: boolean;
    protected disableScroll = false;
    protected betaBanner = window.sq.betaVersion;
    public expiredBanner = false;
    private _routerConfigured = false;
    private _devRouting = false;
    private _devEndpoints = ['.go.sqg.ee', 'dev.squeeg.ee', 'staging.squeeg.ee', 'localhost'];

    private _isSupportUser: boolean;

    @computedFrom('_isSupportUser')
    private get isSupportUser() {
        if (this._isSupportUser === undefined) {
            this._isSupportUser = ApplicationState.isSupportUser;
        }
        return this._isSupportUser;
    }
    private _isSupportUserOrDev: boolean;

    @computedFrom('_isSupportUserOrDev', 'isSupportUser', '_devEndpoints')
    private get isSupportUserOrDev() {
        if (this._isSupportUserOrDev === undefined) {
            this._isSupportUserOrDev =
                this.isSupportUser ||
                this._devEndpoints.some(x => Api.apiEndpoint.indexOf(x) > -1) ||
                this._devEndpoints.some(x => document.location?.href?.indexOf(x) > -1);
        }
        return this._isSupportUserOrDev;
    }
    public async configureRouter(config: RouterConfiguration, router: Router) {
        if (this._routerConfigured) return;
        config.fallbackRoute('about');
        // config.title = 'Squeegee';

        if (GlobalFlags.isHttp) {
            config.options.pushState = true;
            config.options.root = '/';
        }

        config.addAuthorizeStep({
            run: (instruction, next) => {
                if (!ApplicationState.canNavigateTo(<IRoute>instruction.config)) {
                    return next.cancel(new Redirect(ApplicationState.defaultRoute));
                }
                return next();
            },
        });

        config.mapUnknownRoutes(<any>{ redirect: 'about' });
        await router.configure(config.map(RouteUtil.getRoutes()));

        ApplicationState.routeMapper = (routes: IRoute) => config.map(routes);

        router.transformTitle = (key: TranslationKey) => {
            let value: string;

            if (!this.isSupportUserOrDev) {
                value = ApplicationState.localise(key);
            } else if (this._devRouting) {
                value = '';
            } else {
                setTimeout(() => (this._devRouting = false), 250);
                this._devRouting = true;
                value = `🛠️ ${ApplicationState.dataEmail} (${ApplicationState.localise(key)})`;
            }

            return value;
        };

        ApplicationEnvironment.router = router;
        this.router = router;
        this._routerConfigured = true;
    }

    private _applicationStateChangedSubscription: EventSub;

    private _dataRefreshedEvent: EventSub;
    private _routeChangedEventSubscription: EventSub;
    private _routeChangeProcessingEventSubscription: EventSub;
    private _routeErrorEventSubscription: EventSub;
    private _routeCancelledEventSubscription: EventSub;
    private _obfuscatorEventSubscription: EventSub;
    public static startupNotification?: TranslationKey;
    public async bind() {
        try {
            new LoaderEvent(true);

            Squeegee.showStartupNotification();

            await animate();

            return true;
        } catch (error) {
            Logger.info('Error in bind() in Squeegee', { error });
        } finally {
            new LoaderEvent(false);
        }
    }

    private static signOutTimeoutHandle: any;

    private static showStartupNotification() {
        if (Squeegee.startupNotification) {
            Logger.info(Squeegee.startupNotification);
            delete Squeegee.startupNotification;
        }
    }

    private static async watchForSignOutFromOtherTabs() {
        if (!GlobalFlags.isHttp || GlobalFlags.isAndroidMobileDevice || GlobalFlags.isAppleMobileDevice) return;

        clearTimeout(Squeegee.signOutTimeoutHandle);

        if (SqueegeeLocalStorage.getItem('squeegee-session') === null) {
            new LoaderEvent(true, false, 'general.signing-out');
            return setTimeout(() => {
                Logger.info('Sign Out From Other Tabs Detected');
                Utilities.goToRootUrl({});
            }, 500);
        } else if (GlobalFlags.isHttp) {
            Squeegee.signOutTimeoutHandle = setTimeout(() => Squeegee.watchForSignOutFromOtherTabs(), 2000);
        }
    }

    public async attached() {
        const stopwatch = new Stopwatch('Squeegee root attached');

        await this.setActive();

        stopwatch.lap('Squeegee root check device and init');

        this._routeChangeProcessingEventSubscription = this.eventAggregator.subscribe('router:navigation:processing', () => {
            // reset menus / action bar
            new MenuBarActionsEvent();
            new ActionBarEvent(false);
        });

        this._routeChangedEventSubscription = this.eventAggregator.subscribe('router:navigation:complete', (a: any) => {
            (window as any).view = a?.instruction?.viewPortInstructions?.default?.component?.viewModel;
            if (this.router && this.router.currentInstruction) {
                this.expiredBanner =
                    ['overdue', 'unpaid'].some(x => x === ApplicationState.subscription.status) &&
                    this.router.currentInstruction.fragment.indexOf('account') === -1;

                ApplicationState.lastRoute = this.router.currentInstruction.fragment;
                if (
                    this.router.currentInstruction.fragment.indexOf('day-pilot') > -1 &&
                    this.router.currentInstruction.fragment.indexOf('not-done') > -1
                ) {
                    this.disableScroll = true;
                } else this.disableScroll = false;

                this.setTabs();
            }
        });

        if (!GlobalFlags.isDevServer) {
            this._routeErrorEventSubscription = this.eventAggregator.subscribe('router:navigation:error', (...args: Array<any>) => {
                Logger.error('router:navigation:error', ...args);
                new NotifyUserMessage('notifications.unable-to-navigate-error');
                this.router.navigate(ApplicationState.defaultRoute);
            });
        }

        this._routeCancelledEventSubscription = this.eventAggregator.subscribe('router:navigation:canceled', () => {
            this.router.navigate('about');
        });

        this._obfuscatorEventSubscription = ObfuscatorEvent.subscribe((event: ObfuscatorEvent) => (this.showObfuscator = event.show));
        stopwatch.lap('Squeegee global event handlers attached');

        stopwatch.stop();

        await Api.refreshConnectionMetadata();

        ApplicationState.initPosition();

        this.initMarketplace();

        UserService.cleanupDuplicateUsers();

        generateStartupAlerts();

        this._dataRefreshedEvent = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            const currentUser = UserService.getUser();
            if (!currentUser) return;
            if (
                event.hasAnyType('storedevents') &&
                event.filterByType<StoredEvent>('storedevents').some(se => se.allocateTimeTo === currentUser._id)
            ) {
                this.userHasActiveTimer = TimerService.getTimerRunningForCurrentUser();
            }

            const customers = event.filterByType<Customer>('customers');
            if (customers.length) {
                try {
                    matchAndUpdateCustomersToAudience(customers);
                } catch (error) {
                    console.error('Error matching customers to audiences', error);
                }
            }

            if (event.hasAnyType('settings')) {
                ApplicationState.refreshNavigation();
            }
        });
    }

    private initMarketplace() {
        AdvertisementAssetCache.assetIds = ApplicationState.getSetting<Array<string>>('marketplace.items-listed', []);
        if (!ApplicationState.getSetting('global.marketplace-receive-area-alerts', true)) return;

        if (!ApplicationState.isInAnyRole(['Owner', 'Admin'])) return;

        setTimeout(runAdvertChecker, isDevMode() ? 1000 : 30000);
    }

    protected async detached() {
        this._dataRefreshedEvent && this._dataRefreshedEvent.dispose();
        this._applicationStateChangedSubscription && this._applicationStateChangedSubscription.dispose();
        this._routeChangedEventSubscription && this._routeChangedEventSubscription.dispose();
        this._routeChangeProcessingEventSubscription && this._routeChangeProcessingEventSubscription.dispose();
        this._routeErrorEventSubscription && this._routeErrorEventSubscription.dispose();
        this._routeCancelledEventSubscription && this._routeCancelledEventSubscription.dispose();
        this._obfuscatorEventSubscription && this._obfuscatorEventSubscription.dispose();
    }

    protected goto(location: string) {
        this.router && this.router.navigate(location);
    }

    private _loaded = false;

    private async setActive() {
        if (!this._loaded) {
            await ApplicationState.reauthenticate();
            document.addEventListener(
                'resume',
                () => {
                    ApplicationState.updateSubscription();
                    SqueegeeClientSocketApi.init();
                },
                false
            );
            ApplicationState.weatherUpdate();
            this._loaded = true;
            this.preCheckAndConfirmDetails();
        }
    }

    private async preCheckAndConfirmDetails() {
        if (ApplicationState.account.isOptedInToMarketing !== undefined) return;

        try {
            const version = ApplicationState.version;
            ApplicationState.lastReleaseNotesShown = version;
        } catch (error) {
            Logger.error(`Error during checkAndConfirmDetails`, error);
        }
    }

    public static async postCheckAndConfirmDetails() {
        if (ApplicationState.account.isOptedInToMarketing !== undefined) return;

        try {
            const updates = await new Prompt('general.squeegee-updates', 'sign-up.marketing', {
                okLabel: 'sign-up.marketing-yes',
                cancelLabel: 'sign-up.marketing-no',
            }).show();
            ApplicationState.account.isOptedInToMarketing = updates;
            await ApplicationState.save();
        } catch (error) {
            Logger.error(`Error during checkAndConfirmDetails`, error);
        }
    }

    tabs: Array<ITab>;

    setTabs() {
        this.tabs = (this.router?.currentInstruction?.config?.settings?.tabs || []).filter((t: ITab) => {
            const enabled = !t.enabled || t.enabled();
            if (!enabled) return false;

            const hasMinimumSubscription =
                t.minimumSubscription === undefined || ApplicationState.hasMinimumSubscription(t.minimumSubscription);
            if (!hasMinimumSubscription) return false;
            t.isActive = this.router.currentInstruction.fragment === t.routeFragment;
            return true;
        });
    }

    @computedFrom('tabs.length')
    protected get hasMultipleTabs() {
        return this.tabs.length > 1;
    }

    protected deselect() {
        new DeselectEvent();
        return true;
    }

    protected userHasActiveTimer = TimerService.getTimerRunningForCurrentUser();
    viewActiveTimer() {
        const timer = TimerService.getTimerRunningForCurrentUser();
        if (!timer) return;
        TimerService.showScheduleItemForActiveTimer(timer);
    }

    @computedFrom('router.currentInstruction.config.title')
    protected get menuBarTitle() {
        return this.router?.currentInstruction?.config?.title || '';
    }
}
