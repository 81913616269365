import type { StoredObject } from '@nexdynamic/squeegee-common';
import { SqueegeeClientApiDefinition, SqueegeeServerApiDefinition, SyncMode, wait } from '@nexdynamic/squeegee-common';
import stringify from 'json-stringify-safe';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import { Api } from './Api';
import { ClientSocketApi } from './ClientSocketApi';
import { RethinkDbAuthClient } from './RethinkDbAuthClient';

class ClientLocalApi extends SqueegeeClientApiDefinition {
    static processingInboundRunning: boolean;
    constructor(clientId: string) {
        super(clientId);
    }

    public ping = (message: string) => {
        if (message === 'Socket authenticated.') {
            Logger.info('Socket authenticated, performing full sync');
            Data.fullSync(SyncMode.Partial);
            return;
        }

        Logger.info('The server says: ' + message);
    };
    public syncObjects = (storedObjects: { [id: string]: StoredObject | null }) => {
        const count = Object.keys(storedObjects).length;

        const syncObjectsArray = Object.keys(storedObjects)
            .map(id => storedObjects[id])
            .filter(storedObject => !!storedObject) as Array<StoredObject>;

        if (ApplicationState.stateFlags.devMode) {
            Logger.info(`${count} objects inbound:`);
            for (const storedObject of syncObjectsArray) {
                Logger.info(JSON.parse(JSON.stringify(storedObject)));
            }
        }

        Data.processRemoteStoredObjects(syncObjectsArray, false);
    };

    private execOutput = (requestId: string, output: any) =>
        SqueegeeClientSocketApi.instance && SqueegeeClientSocketApi.instance.server.execOutput(requestId, JSON.parse(stringify(output)));
    public exec = async (requestId: string, exec: string) => {
        try {
            let output = new Function(exec)(this.execOutput);
            if (output.then && output.catch) output = await output;
            this.execOutput(requestId, output);
        } catch (error) {
            this.execOutput(requestId, error);
        }
    };
}
export class SqueegeeClientSocketApi extends ClientSocketApi<ClientLocalApi, SqueegeeServerApiDefinition> {
    private static _instance?: SqueegeeClientSocketApi;
    public static get instance() {
        return SqueegeeClientSocketApi._instance;
    }
    public static async init() {
        try {
            Logger.info('Initialising client socket API');

            await Data.init();
            if (SqueegeeClientSocketApi._instance) {
                SqueegeeClientSocketApi._instance.close();
                (SqueegeeClientSocketApi._instance as any)._socket && (SqueegeeClientSocketApi._instance as any)._socket.disconnect();
            }

            while (!Api.currentHostAndScheme && !Api.currentHostAndScheme?.length)
                await new Promise<void>(resolve => setTimeout(() => resolve(), 50));

            SqueegeeClientSocketApi._instance = new SqueegeeClientSocketApi(
                new ClientLocalApi(''),
                new SqueegeeServerApiDefinition(''),
                Api.currentHostAndScheme,
                false,
                {
                    transports: ['websocket'],
                }
            );

            SqueegeeClientSocketApi._instance.connectionStateChanged.unsubscribe(SqueegeeClientSocketApi.ConnectionStateChanged);
            SqueegeeClientSocketApi._instance.connectionStateChanged.subscribe(SqueegeeClientSocketApi.ConnectionStateChanged);

            SqueegeeClientSocketApi._instance.connect();
            SqueegeeClientSocketApi._instance.open();

            window.removeEventListener('online', SqueegeeClientSocketApi.initHandler);
            window.addEventListener('online', SqueegeeClientSocketApi.initHandler);
        } catch (error) {
            Logger.error(`Error during init of the client socket api`, error);
        }
    }

    private static initHandler = () => SqueegeeClientSocketApi.init();
    public static close() {
        if (!SqueegeeClientSocketApi._instance) return;
        SqueegeeClientSocketApi._instance.close();
    }

    public static get isSocketConnected() {
        return !!SqueegeeClientSocketApi.instance && SqueegeeClientSocketApi.instance.isConnected;
    }
    private static _syncOnReconnect: any;
    private static _latestSocketClientId: string;
    private static wasConnected = false;
    private static async ConnectionStateChanged(isConnected: boolean) {
        const wasConnected = SqueegeeClientSocketApi.wasConnected;
        if (wasConnected === isConnected) return;
        SqueegeeClientSocketApi.wasConnected = isConnected;

        Logger.info('socket went ' + (isConnected ? 'online' : 'offline'));
        clearTimeout(SqueegeeClientSocketApi._syncOnReconnect);
        delete SqueegeeClientSocketApi._syncOnReconnect;

        while (!SqueegeeClientSocketApi.instance || !SqueegeeClientSocketApi.instance.server) await wait(10);

        if (wasConnected && !isConnected) return Api.refreshConnectionMetadata(true);

        if (!isConnected || SqueegeeClientSocketApi.instance.server.clientId === SqueegeeClientSocketApi._latestSocketClientId) return;

        SqueegeeClientSocketApi._latestSocketClientId = SqueegeeClientSocketApi.instance.server.clientId;

        if (!RethinkDbAuthClient.session) return;

        SqueegeeClientSocketApi.instance.server.authenticate(
            RethinkDbAuthClient.session.key,
            RethinkDbAuthClient.session.value,
            ApplicationState.dataEmail || RethinkDbAuthClient.session.email
        );

        await Api.refreshConnectionMetadata(true);
        Logger.info('socket refreshed connection meta data');

        ApplicationState.initIntegrationRoutes();
    }
}
