import type { IQuoteSettings } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { Logger } from '../../Logger';
import { QuoteService } from '../../Quotes/QuoteService';
import { Utilities } from '../../Utilities';
import { SettingsUtils } from '../SettingUtils';

export class QuoteSettingsCustomElement {
    account = ApplicationState.account;
    applicationState = ApplicationState;
    quoteSettings?: IQuoteSettings;

    attached() {
        this.quoteSettings = QuoteService.getQuoteSettings();

        this.subscription = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.updatedObjects[ApplicationState.getSettingId('global.quote')]) this.quoteSettings = QuoteService.getQuoteSettings();
        });
    }

    private subscription?: Subscription;
    detached() {
        this.subscription?.dispose();
    }

    setSetting(field: keyof IQuoteSettings, value: string | number | boolean | undefined) {
        if (!this.quoteSettings) return Logger.error('Unable to set quote settings');
        if (value === undefined) return;

        (this.quoteSettings[field] as string | number | boolean) = value;
        const settings = Utilities.copyObject(this.quoteSettings);

        ApplicationState.setSetting('global.quote', settings);
    }

    changeEmail = async () => this.setSetting('email', await SettingsUtils.setTextField('general.email', this.quoteSettings?.email));
    changePhone = async () => this.setSetting('phone', await SettingsUtils.setTextField('general.phone', this.quoteSettings?.phone));
    changeWebsite = async () =>
        this.setSetting('website', await SettingsUtils.setTextField('general.website', this.quoteSettings?.website));
    changeQuotePrefix = async () =>
        this.setSetting('quotePrefix', await SettingsUtils.setTextField('quote.quote-prefix', this.quoteSettings?.quotePrefix));
    changeDefaultNotes = async () =>
        this.setSetting('defaultNotes', await SettingsUtils.setTextareaField('quote.notes', this.quoteSettings?.defaultNotes));
    changeSmsTemplate = async () =>
        this.setSetting('smsTemplate', await SettingsUtils.setSmsTemplate('quote.sms-template', this.quoteSettings?.smsTemplate));
    changeEmailTemplate = async () =>
        this.setSetting('emailTemplate', await SettingsUtils.setEmailTemplate('quote.email-template', this.quoteSettings?.emailTemplate));
    changeAcceptedMessage = async () =>
        this.setSetting(
            'acceptedMessage',
            await SettingsUtils.setTextareaField('quote.accepted-message', this.quoteSettings?.acceptedMessage)
        );
    protected get autoConvertOnAccept() {
        return !!this.quoteSettings?.autoConvertOnAccept;
    }
    protected set autoConvertOnAccept(value: boolean) {
        this.setSetting('autoConvertOnAccept', value);
    }
    protected get autoConvertSendsConfirmation() {
        return !!this.quoteSettings?.autoConvertSendsConfirmation;
    }
    protected set autoConvertSendsConfirmation(value: boolean) {
        this.setSetting('autoConvertSendsConfirmation', value);
    }
    changeDefaultQuoteExpiryDays = async () =>
        this.setSetting(
            'defaultExpiryDays',
            await SettingsUtils.setIntegerNumberField('quote.default-quote-expiry', this.quoteSettings?.defaultExpiryDays || 30)
        );

    @computedFrom('applicationState')
    get isDevMode() {
        return this.applicationState?.stateFlags?.devMode;
    }
}
