import type {
    Customer,
    ICustomerPaymentProvidersData,
    InvoiceTransaction,
    PaymentAccount,
    Transaction,
    TransferTransaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-binding';
import type { Subscription } from 'aurelia-event-aggregator';
import { ApplicationState } from '../../ApplicationState';
import { ChargeCustomerService } from '../../Charge/ChargeCustomerService';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { CustomerDialog } from '../../Customers/Components/CustomerDialog';
import { CustomerRefundDialog } from '../../Customers/Components/CustomerRefundDialog';
import { getPaymentMethodDescription } from '../../Customers/Components/getPaymentMethodDescription';
import { getProviderExternalLinkSource } from '../../Customers/Components/getProviderExternalLinkSource';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { InvoiceService } from '../../Invoices/InvoiceService';
import { InvoiceTransactionSummaryDialog } from '../../Invoices/InvoiceTransactionSummaryDialog';
import { Logger } from '../../Logger';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { PaymentsApi } from '../../Server/PaymentsApi';
import { Utilities } from '../../Utilities';
import { TransactionService } from '../TransactionService';

export class TransactionDialog extends CustomDialog<boolean> {
    private _dataChangedEvent: Subscription;
    protected model = { transaction: <Transaction>{} };

    protected get gocardlessPaymentLink() {
        if (!this.model.transaction.externalIds?.gocardless) return '';
        const dev = !Api.isProduction;
        const paymentId = this.model.transaction.externalIds?.gocardless || '';
        return `https://manage${dev ? '-sandbox' : ''}.gocardless.com/payments/${paymentId}`;
    }

    protected get stripePaymentLink() {
        if (!this.model.transaction.externalIds?.stripe) return '';
        const dev = !Api.isProduction;
        const paymentId = this.model.transaction.externalIds?.stripe || '';
        return `https://dashboard.stripe.com/${dev ? 'test/' : ''}payments/${paymentId}`;
    }

    protected getProviderLink = (provider: keyof ICustomerPaymentProvidersData): string => {
        switch (provider) {
            case 'gocardless':
                return this.gocardlessPaymentLink;
            case 'stripe':
                return this.stripePaymentLink;
            default:
                return '';
        }
    };

    protected getPaymentMethodDescription = (provider: keyof ICustomerPaymentProvidersData) => {
        const customer = this.model.transaction.customerId && Data.get<Customer>(this.model.transaction.customerId);
        if (!customer) return '';
        return getPaymentMethodDescription(customer, provider);
    };

    protected getProviderExternalLinkSource = (provider: keyof ICustomerPaymentProvidersData) => {
        const customer = this.model.transaction.customerId && Data.get<Customer>(this.model.transaction.customerId);
        if (!customer) return;
        getProviderExternalLinkSource(this.getProviderLink(provider));
    };

    protected summaryTitle: string;
    private unallocatePaymentToInvoices = async () => {
        if (!this.model.transaction.customerId) return Logger.info('No customer ID.');
        if (!this.model.transaction.paymentDetails?.invoiceIds?.length) return Logger.info('Not allocated.');
        if (this.model.transaction.amount >= 0) return Logger.info('Payment is 0 or outbound.');

        for (const invoiceId of this.model.transaction.paymentDetails?.invoiceIds || []) {
            const transaction = Data.get<InvoiceTransaction>(invoiceId);
            if (!transaction?.invoice?.paymentReference) continue;

            transaction.invoice.paymentReference = '';
            await InvoiceService.setInvoicePaymentStatus(transaction, false);
            await Data.put(this.model.transaction);
        }

        this.model.transaction.paymentDetails.invoiceIds = [];

        await Data.put(this.model.transaction);

        await InvoiceService.allocateBalanceToInvoices(this.model.transaction.customerId);

        this.updateFab();

        new NotifyUserMessage('payments.unallocation-complete');
    };

    private selectAccount = async () => {
        const paymentAccount = await AccountsLedgerService.selectAccount();
        if (!paymentAccount) return;
        if (!this.model.transaction.paymentDetails) this.model.transaction.paymentDetails = { paymentAccountId: paymentAccount._id };
        else this.model.transaction.paymentDetails.paymentAccountId = paymentAccount._id;
        await Data.put(this.model.transaction);

        // WTF: This updates the voided transaction payment account
        if (this.model.transaction.voidedId) {
            const voidedTransaction = Data.get<Transaction>(this.model.transaction.voidedId);
            if (voidedTransaction) {
                if (!voidedTransaction.paymentDetails) voidedTransaction.paymentDetails = { paymentAccountId: paymentAccount._id };
                else voidedTransaction.paymentDetails.paymentAccountId = paymentAccount._id;
                await Data.put(voidedTransaction);
            }
        }
    };

    private allocatePaymentToInvoices = async () => {
        if (!this.model.transaction.customerId) return Logger.info('No customer ID.');
        if (this.model.transaction.paymentDetails?.invoiceIds?.length) return Logger.info('Already allocated.');
        if (this.model.transaction.amount >= 0) return Logger.info('Payment is 0 or outbound.');

        await ChargeCustomerService.manualAllocation(this.model.transaction);

        this.updateFab();

        new NotifyUserMessage('payments.allocation-complete');
    };
    protected get canCheckStatus() {
        return (
            this.model.transaction.amount < 0 &&
            (this.model.transaction.transactionSubType === 'auto.go-cardless' ||
                this.model.transaction.transactionSubType === 'payment.stripe' ||
                this.model.transaction.transactionSubType === 'auto.stripe')
        );
    }
    protected get canCancel() {
        if (this.model.transaction.transactionType === TransactionType.Transfer) return false;
        if (this.model.transaction?.transactionSubType?.startsWith('payment.tip')) return false;
        if (this.model.transaction?.voidedId) return false;
        if (this.model.transaction?.paymentDetails?.refundId) return false;

        return true;
    }
    protected get canRefund() {
        if (!this.customer) return false;
        if (this.model.transaction.transactionType === TransactionType.Transfer) return false;
        if (this.model.transaction.amount >= 0) return false;
        if (this.model.transaction.voidedId) return false;
        if (this.model.transaction.paymentDetails?.refundId) return false;
        return (
            this.model.transaction.transactionType === TransactionType.AutomaticPayment ||
            this.model.transaction.transactionType === TransactionType.Payment
        );
    }

    protected async refundPayment() {
        if (!this.canRefund || !this.customer) return;

        const refundDialog = CustomerRefundDialog.createForPayment(this.customer, this.model.transaction);
        if (!refundDialog) return;

        await refundDialog.show();

        this.init();
    }

    @computedFrom('model.transaction.voidedId')
    protected get voidedTransaction() {
        return this.model.transaction.voidedId && Data.get<Transaction>(this.model.transaction.voidedId);
    }

    @computedFrom('model.transaction.targetAccountId')
    protected get otherTransaction() {
        if (this.model.transaction.transactionType !== TransactionType.Transfer) return;
        const tran = this.model.transaction as TransferTransaction;
        if (!tran.targetAccountId) return;
        const targetTran = Data.get<TransferTransaction>(tran.targetTransactionid);
        return targetTran;
    }

    protected getAvatarColour(text: string) {
        return `#${Utilities.hexFromString(text)}`;
    }

    constructor(transaction: Transaction) {
        super('transactionDialog', '../Transactions/Components/TransactionDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'transaction-summary-dialog',
            isSecondaryView: true,
        });
        this.model.transaction = transaction;
        this.summaryTitle = this.getTransTypeDescription() + ' ' + ApplicationState.localise('general.summary');
    }

    protected paymentAccount?: PaymentAccount;

    public async init() {
        this.updateFab();
        this._dataChangedEvent = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (!event.updatedObjects[this.model.transaction._id]) return;

            this.model = {
                transaction: event.updatedObjects[this.model.transaction._id] as Transaction,
            };
        });

        const transferTran = this.model.transaction as TransferTransaction;

        const accountId =
            transferTran.paymentAccountId || transferTran.accountId || this.model.transaction.paymentDetails?.paymentAccountId;
        if (accountId) {
            this.paymentAccount = Data.get<PaymentAccount>(accountId);
        }

        await this.autoCheckPaymentStatus();
    }

    private get customer() {
        return this.model.transaction.customerId && Data.get<Customer>(this.model.transaction.customerId);
    }

    protected get canViewCustomer() {
        return ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
    }

    protected async viewCustomer() {
        if (this.canViewCustomer && this.customer) {
            const dialog = new CustomerDialog(this.customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
        }
    }

    protected async viewInvoice(invoice: Transaction) {
        const dialog = new InvoiceTransactionSummaryDialog(invoice);
        await dialog.show(DialogAnimation.SLIDE_UP);
    }

    protected async viewTransaction(transaction: Transaction) {
        const dialog = new TransactionDialog(transaction);
        await dialog.show(DialogAnimation.SLIDE_UP);
    }

    private async autoCheckPaymentStatus() {
        if (this.model.transaction.status === 'pending') await PaymentsApi.checkAndUpdatePaymentStatus(this.model.transaction);
    }

    public dispose() {
        this._dataChangedEvent.dispose();
        super.dispose();
    }

    public getTransTypeDescription(): string | undefined {
        return (
            (this.model.transaction.provider
                ? this.model.transaction.provider.charAt(0).toUpperCase() + this.model.transaction.provider.slice(1) + ' '
                : '') + ApplicationState.localise(TransactionService.getTransTypeDescription(this.model.transaction))
        );
    }

    public deleteTransaction = async () => {
        const deleteOnCancel = this.model.transaction.transactionType === TransactionType.AutomaticPayment ? 'always' : 'onSuccess';
        await TransactionService.cancelTransaction(this.model.transaction, false, undefined, deleteOnCancel);
        this.cancel();
    };

    public voidTransaction = async () => {
        const isGc = this.model.transaction.transactionSubType === 'auto.go-cardless';
        const isStripe =
            this.model.transaction.transactionSubType === 'auto.stripe' || this.model.transaction.transactionSubType === 'payment.stripe';
        const prompt = new Prompt(
            'prompts.confirm-title',
            isGc
                ? 'prompts.payment-gocardless-cancel-confirm'
                : isStripe
                ? 'prompts.payment-stripe-cancel-confirm'
                : 'prompts.payment-cancel-confirm',
            { okLabel: 'general.confirm' }
        );
        await prompt.show();
        if (!prompt.cancelled) {
            const currency = (this.model.transaction.paymentDetails && this.model.transaction.paymentDetails.currency) || '';
            try {
                await TransactionService.cancelTransaction(this.model.transaction);

                await new Prompt('prompts.success-title', 'prompts.payment-for-amount-cancelled', {
                    allowCancel: false,
                    localisationParams: { amount: (this.model.transaction.amount * -1).toFixed(2), currency },
                    cancelLabel: '',
                }).show();

                this.cancel();
            } catch (error) {
                Logger.error(error);
                await new Prompt('prompts.error-title', 'prompts.payment-for-amount-cancelled-error', {
                    allowCancel: false,
                    localisationParams: { amount: (this.model.transaction.amount * -1).toFixed(2), currency, error },
                    cancelLabel: '',
                }).show();
            }
        }
    };

    public retryPayment = async () => {
        const prompt = new Prompt('prompts.confirm-title', 'Are you sure you wish to retry this payment?' as TranslationKey, {
            okLabel: 'Retry' as TranslationKey,
        });
        await prompt.show();

        if (!prompt.cancelled) {
            try {
                await TransactionService.retryAutomaticPayment(this.model.transaction);

                await new Prompt('prompts.success-title', 'Payment retry initiated, please check back shortly.' as TranslationKey).show();

                this.model.transaction.status = 'pending';
                Data.put(this.model.transaction);
                this.cancel();
            } catch (error) {
                Logger.error(error);
                await new Prompt(
                    'prompts.error-title',
                    (typeof error === 'string'
                        ? error
                        : 'This payment cannot be retried, please check the GoCardless dashboard for more information.') as TranslationKey
                ).show();
            }
        }
    };

    protected contextMenuActions: Array<IMenuBarAction>;

    protected get hasProcessed() {
        return (
            this.model.transaction.status === undefined ||
            this.model.transaction.status === 'pending' ||
            this.model.transaction.status === 'complete'
        );
    }

    private updateFab() {
        this.contextMenuActions = [];

        if (
            this.model.transaction.transactionType === TransactionType.AutomaticPayment &&
            this.model.transaction.transactionSubType === 'auto.go-cardless' &&
            this.model.transaction.status === 'failed'
        ) {
            this.contextMenuActions.push({
                tooltip: 'actions.retry-transaction',
                actionType: 'action-payment-refresh',
                handler: this.retryPayment,
                roles: ['Owner', 'Admin'],
            });
        }

        if (
            this.model.transaction.transactionType === TransactionType.Payment ||
            (this.model.transaction.transactionType === TransactionType.AutomaticPayment &&
                !this.model.transaction.paymentDetails?.refundId &&
                !this.model.transaction.voidedId &&
                !this.model.transaction.transactionSubType?.startsWith('payment.tip') &&
                this.model.transaction.amount < 0)
        ) {
            if (!this.model.transaction.paymentDetails?.invoiceIds?.length) {
                this.contextMenuActions.push({
                    tooltip: 'payments.allocate-to-invoices',
                    actionType: 'action-edit',
                    handler: this.allocatePaymentToInvoices,
                    roles: ['Owner', 'Admin'],
                });
            } else {
                this.contextMenuActions.push({
                    tooltip: 'payments.unallocate-from-invoices',
                    actionType: 'action-edit',
                    handler: this.unallocatePaymentToInvoices,
                    roles: ['Owner', 'Admin'],
                });
            }
        }

        this.contextMenuActions.push({
            tooltip: 'payments.select-payment-account',
            actionType: 'action-edit',
            handler: this.selectAccount,
            roles: ['Owner', 'Admin'],
        });

        if (this.canCancel) {
            this.contextMenuActions.push({
                tooltip: 'actions.void-transaction',
                actionType: 'action-not-done',
                handler: this.voidTransaction,
                roles: ['Owner', 'Admin'],
            });
        }

        if (ApplicationState.stateFlags.devMode) {
            if (this.model.transaction.externalIds?.xero) {
                this.contextMenuActions.push({
                    tooltip: 'Update in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.updateConnectedEntity('xero', this.model.transaction._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
                this.contextMenuActions.push({
                    tooltip: 'Delete in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.deleteConnectedEntity('xero', this.model.transaction._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
            } else {
                this.contextMenuActions.push({
                    tooltip: 'Create in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.createConnectedEntity('xero', 'invoices', this.model.transaction._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
            }
        }

        if (this.model.transaction.transactionType === TransactionType.Transfer) {
            this.contextMenuActions.push({
                tooltip: 'Delete Transaction' as TranslationKey,
                actionType: 'action-delete',
                handler: this.deleteTransaction,
                roles: ['Owner', 'Admin'],
            });
        } else if (this.model.transaction.transactionSubType && this.model.transaction.transactionSubType.startsWith('payment.tip.')) {
            this.contextMenuActions.push({
                tooltip: 'Delete Transaction' as TranslationKey,
                actionType: 'action-delete',
                handler: this.deleteTip,
                roles: ['Owner', 'Admin'],
            });
        } else if (ApplicationState.stateFlags.devMode) {
            this.contextMenuActions.push({
                tooltip: 'Delete Transaction' as TranslationKey,
                actionType: 'action-delete',
                handler: this.deleteTransaction,
                roles: ['Owner', 'Admin'],
            });
        }
    }

    private deleteTip = async () => {
        const prompt = new Prompt('prompts.confirm-title', 'Are you sure you wish to delete this tip?' as TranslationKey, {
            okLabel: 'general.delete',
        });
        await prompt.show();
        if (prompt.cancelled) return;

        const toDelete = [this.model.transaction];
        const balance = Data.get<Transaction>(this.model.transaction._id + '_balance');
        if (balance) toDelete.push(balance);
        Data.delete(toDelete);
        this.cancel();
    };

    protected async checkPaymentStatus() {
        try {
            await PaymentsApi.checkAndUpdatePaymentStatus(this.model.transaction);
            new NotifyUserMessage('payments.recheck-status-complete');
        } catch (error) {
            Logger.error('Unable to recheck payment status', error);
            new NotifyUserMessage('payments.recheck-status-failed');
        }
    }

    protected get showCancelAutomaticPaymentButton() {
        return this.model.transaction.amount !== 0 &&
            this.model.transaction.paymentDetails &&
            this.model.transaction.paymentDetails &&
            this.model.transaction.paymentDetails.paymentProvider === 'gocardless' &&
            this.model.transaction.status === 'pending'
            ? true
            : false;
    }

    @computedFrom('model.transaction.paymentDetails.paymentHistory.length')
    protected get paymentHistory() {
        return this.model.transaction && this.model.transaction.paymentDetails && this.model.transaction.paymentDetails.paymentHistory;
    }

    @computedFrom('model.transaction.paymentDetails.invoiceIds.length')
    protected get allocatedInvoices() {
        const allocatedInvoices = [];
        if (this.model.transaction && this.model.transaction.paymentDetails && this.model.transaction.paymentDetails.invoiceIds) {
            for (const invoiceId of this.model.transaction.paymentDetails.invoiceIds) {
                const invoice = Data.get<Transaction>(invoiceId);
                if (invoice) allocatedInvoices.push(invoice);
            }
        }
        return allocatedInvoices;
    }

    protected get hasActions() {
        return this.canCheckStatus || this.canCancel || this.canRefund;
    }
}
