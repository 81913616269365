import type { ThemeOptions } from './ThemeOptions';

export const theStandardTheme: ThemeOptions = {
    '--themed-main-lighter': '#122e88',
    '--themed-main': '#041344',
    '--themed-main-dark': '#04154b',
    '--themed-main-darker': '#03103b',
    '--themed-primary': '#03103b',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#7e7e7e',
    '--themed-background-darker': '#585858',
    '--themed-background-white': '#ffffff',
    '--themed-light-gray': '#dddddd',
    '--themed-dark-gray': '#7e7e7e',
    '--themed-background-active': '#dddddd',
};

export const theRedTheme: ThemeOptions = {
    '--themed-main-lighter': '#ff1616',
    '--themed-main': '#e60000',
    '--themed-main-dark': '#d40000',
    '--themed-main-darker': '#b40000',
    '--themed-primary': '#b40000',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#8b8484',
    '--themed-background-darker': '#696363',
    '--themed-background-white': '#ffffff',
    '--themed-light-gray': '#dddddd',
    '--themed-dark-gray': '#7e7e7e',
    '--themed-background-active': '#d7cdcc',
};

export const theGreenTheme: ThemeOptions = {
    '--themed-main-lighter': '#0ea700',
    '--themed-main': '#0c8b00',
    '--themed-main-dark': '#096d00',
    '--themed-main-darker': '#074e00',
    '--themed-primary': '#074e00',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#6f736b',
    '--themed-background-darker': '#5f605b',
    '--themed-background-white': '#ffffff',
    '--themed-light-gray': '#dddddd',
    '--themed-dark-gray': '#7e7e7e',
    '--themed-background-active': '#d6dad1',
};

export const thePurpleTheme: ThemeOptions = {
    '--themed-main-lighter': '#9200d6',
    '--themed-main': '#8402c0',
    '--themed-main-dark': '#5f008b',
    '--themed-main-darker': '#4e0072',
    '--themed-primary': '#4e0072',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#7e7e7e',
    '--themed-background-darker': '#585858',
    '--themed-background-white': '#ffffff',
    '--themed-light-gray': '#dddddd',
    '--themed-dark-gray': '#7e7e7e',
    '--themed-background-active': '#dddddd',
};

export const thePinkTheme: ThemeOptions = {
    '--themed-main-lighter': '#ff1d87',
    '--themed-main': '#e7016d',
    '--themed-main-dark': '#ca005e',
    '--themed-main-darker': '#b80056',
    '--themed-primary': '#b80056',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#7e7e7e',
    '--themed-background-darker': '#585858',
    '--themed-background-white': '#ffffff',
    '--themed-light-gray': '#dddddd',
    '--themed-dark-gray': '#7e7e7e',
    '--themed-background-active': '#dddddd',
};

export const theBlueTheme: ThemeOptions = {
    '--themed-main-lighter': '#0066ff',
    '--themed-main': '#005ae0',
    '--themed-main-dark': '#014ab8',
    '--themed-main-darker': '#0043a8',
    '--themed-primary': '#0043a8',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#7e7e7e',
    '--themed-background-darker': '#585858',
    '--themed-background-white': '#ffffff',
    '--themed-light-gray': '#dddddd',
    '--themed-dark-gray': '#7e7e7e',
    '--themed-background-active': '#dddddd',
};

export const theNightTheme: ThemeOptions = {
    '--themed-main-lighter': '#182942',
    '--themed-main': '#00173a',
    '--themed-main-dark': '#001029',
    '--themed-main-darker': '#000713',
    '--themed-primary': '#001636',
    '--themed-background-light': '#f2f2f2',
    '--themed-background-dark': '#474747',
    '--themed-background-darker': '#303030',
    '--themed-background-white': '#c0c0c0',
    '--themed-light-gray': '#9c9c9c',
    '--themed-dark-gray': '#4e4e4e',
    '--themed-background-active': '#979797',
};
