import type { JobOccurrence, CustomerRating as SqueegeeCustomerRating, TranslationKey } from '@nexdynamic/squeegee-common';
import { portalUniqueUrlRegex } from '@nexdynamic/squeegee-common';
import type { CustomerRating, IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { SelectAttachmentsDialog } from '../../Attachments/Dialogs/SelectAttachmentsDialog';
import { Data } from '../../Data/Data';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { SelectMultiple } from '../../Dialogs/SelectMultiple';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { Utilities } from '../../Utilities';

export class DirectoryPortalSettingsCustomElement {
    protected originalDirectoryEntry?: IDirectoryEntryDetails;
    protected directoryEntry?: IDirectoryEntryDetails;
    protected isLoaded = false;
    protected failedLoading = false;
    protected customDomainHelper = ApplicationState.localise('directory.custom-domains-helper', {
        ip: '178.128.170.232',
        domain: 'app.squeeg.ee',
    });
    protected applicationState = ApplicationState;

    public async attached() {
        try {
            const response = await Api.get<IDirectoryEntryDetails>(Api.apiEndpoint, `/api/directory/secure/${ApplicationState.dataEmail}`);
            if (response && response.data) {
                this.directoryEntry = response.data;
                this.originalDirectoryEntry = JSON.parse(JSON.stringify(this.directoryEntry));
            }
            this.isLoaded = true;
        } catch (error) {
            this.failedLoading = true;
        }
    }

    @computedFrom('applicationState.stateFlags.devMode')
    get isDevMode() {
        return ApplicationState.stateFlags.devMode;
    }

    get hasUltimateOrAbove() {
        return ApplicationState.hasUltimateOrAbove;
    }

    get hasAdvancedOrAbove() {
        return ApplicationState.hasAdvancedOrAbove;
    }
    protected get uniqueUrlIsValid() {
        return this.isUniqueUrlIsValid(this.directoryEntry?.uniqueUrl);
    }
    protected isUniqueUrlIsValid(url?: string) {
        return (url && portalUniqueUrlRegex.test(url) && url.indexOf('--') === -1) || false;
    }
    protected validate() {
        const errors: Array<TranslationKey> = [];

        if (!this.directoryEntry) return false;
        if (!this.directoryEntry.businessName) errors.push('directory.business-name-required');
        if (!this.uniqueUrlIsValid) errors.push('directory.unique-url-required-long');

        if (errors.length) new NotifyUserMessage(<TranslationKey>Utilities.localiseList(errors));
        return errors.length === 0;
    }
    protected async save() {
        if (!this.directoryEntry || !this.validate()) return;

        new LoaderEvent(true);
        try {
            const result = await Api.put<{ success: boolean; errors: Array<string> }>(
                Api.apiEndpoint,
                `/api/directory/secure/${ApplicationState.dataEmail}`,
                this.directoryEntry
            );
            const regenerateSuccess = await this.regenerateDirectoryEntry();
            if (!result || !result.data || !result.data.success || !regenerateSuccess) {
                new NotifyUserMessage('fail.saving-directory-portal-settings');
            } else {
                this.originalDirectoryEntry = JSON.parse(JSON.stringify(this.directoryEntry));
                new NotifyUserMessage(this.directoryEntry.isPublic ? 'directory.published' : 'directory.saved');
            }
        } catch (error) {
            new NotifyUserMessage('fail.saving-directory-portal-settings');
        }
        new LoaderEvent(false);
    }

    protected async regenerateDirectoryEntry() {
        const response = await Api.put<{ success: boolean; errors: Array<string> }>(
            Api.apiEndpoint,
            `/api/directory/secure/${ApplicationState.dataEmail}/regenerate`,
            {}
        );
        return response?.data?.success || false;
    }

    protected openPortalDialog() {
        ApplicationState.navigateTo('portal-directory');
    }

    protected portalEnabledChanged(newValue?: boolean) {
        if (newValue === undefined || !this.directoryEntry) return;
        const value = newValue ? 'y' : 'n';
        this.directoryEntry.isPublic = value;
        this.directoryEntry.portalEnabled = value;
        this.save();
    }
    protected async selectBusinessLogo() {
        if (!this.directoryEntry) return;
        const dialog = new SelectAttachmentsDialog([], { selectOne: true });
        const resp = await dialog.show();
        const attachment = resp[0];
        if (!resp || !attachment) return;
        attachment.isPublic = true;
        await Data.put(attachment);
        this.directoryEntry.logo = `${Api.apiEndpoint}/v/${attachment._id}`;
        this.save();
    }

    protected async selectPublicRatings() {
        if (!this.directoryEntry) return;
        const allRatings = Data.all<JobOccurrence>('joboccurrences', item => item.customerRating !== undefined).map((item, indx) => ({
            ...item.customerRating,
            selectMultipleId: indx,
        })) as (SqueegeeCustomerRating & { selectMultipleId: number })[];
        const dialog = new SelectMultiple<SqueegeeCustomerRating & { selectMultipleId: number }>(
            'general.select',
            allRatings.slice(),
            'feedback',
            'selectMultipleId',
            [],
            {
                okLabel: '',
                cancelLabel: '',
                cssClass: 'select-dialog filter-select-dialog',
                allowClickOff: true,
            },
            option => {
                return `<${option.customerName ? option.customerName + ': ' : ''}${option.rating} / ${option.outOf}> ${option.feedback}`;
            }
        );

        const results = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            this.directoryEntry.ratings = results.map<CustomerRating>(e => ({
                date: e.date,
                outOf: e.outOf,
                rating: e.rating,
                customerLocation: e.customerLocation,
                customerName: e.customerName,
                feedback: e.feedback,
            }));
            this.save();
        }
    }
}
