import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { NumpadDialog } from '../Dialogs/Numpad/NumpadDialog';
import { TextDialog } from '../Dialogs/TextDialog';
import { GlobalFlags } from '../GlobalFlags';

export const getNumber = async ({
    title,
    value,
    integer,
    prefix,
    suffix,
}: {
    title: TranslationKey;
    value?: number;
    integer?: boolean;
    prefix?: string;
    suffix?: string;
}) => {
    if (GlobalFlags.isMobile) {
        const dialog = new NumpadDialog(value, prefix, suffix, integer);
        value = await dialog.show();
        if (dialog.cancelled) return;
        return value;
    }

    const validator = (v: string) => ((integer ? /^\d*$/.test(v) : /^\d*\.?\d*$/.test(v)) ? true : 'validation.integer-required');
    const valueDialog = new TextDialog(
        title,
        '',
        value?.toString() || '0',
        '',
        validator,
        undefined,
        integer ? 'number' : 'number-2dp',
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        prefix,
        suffix
    );
    const valueText = await valueDialog.show();

    if (valueDialog.cancelled) return;

    return Number(valueText);
};
