import type { SkipCharge } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { t } from "../t";


export const getDefaultSkipChargeText = () => {
    const skipCharge = ApplicationState.getSetting<SkipCharge | undefined>('global.skip-charge-default', undefined);
    if (!skipCharge)
        return t('settings.default-skip-charge-text');

    const amount = skipCharge.type === 'fixed'
        ? `${ApplicationState.currencySymbol()}${skipCharge.amount.toFixed(2)}`
        : `${skipCharge.amount.toFixed(0)}%`;

    return t('settings.default-skip-charge-text-amount', { amount });
};
