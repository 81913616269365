import { ApplicationState } from '../ApplicationState';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { UserService } from '../Users/UserService';
import { enterSkillText } from './enterSkillText';


export const updateSkills = async () => {
    for (; ;) {
        const options: Array<{ text: string; value: string; }> = [{ text: ApplicationState.localise('settings.new-skill'), value: '' }];
        const skills = ApplicationState.skills;
        for (const skill of skills) {
            options.push(({ value: skill, text: skill }));
        }

        const select = new Select('settings.edit-skills-dialog-title', options, 'text', 'value');
        const selectedSkill = await select.show();

        if (select.cancelled)
            return;

        let skill = skills.find(x => x === selectedSkill.value);

        const skillToUpdate = await enterSkillText(skill);
        if (skillToUpdate.action === 'cancel') continue;

        if (skillToUpdate.action === 'delete') {
            // check if any users have this skill and warn if so
            const users = UserService.getWorkers();

            const userSkills = users.map(x => x.skills).flat();

            if (userSkills.includes(skill)) {
                await new Prompt('general.warning', 'settings.skill-delete-assigned-warning', { cancelLabel: '' }).show();
                continue;
            }

            const deleteName = skill;
            if (!deleteName) continue;
            skills.splice(skills.findIndex(x => x === deleteName), 1);
            ApplicationState.setSetting('global.skills', skills);
            continue;
        }

        if (skill) {
            skills.splice(skills.findIndex(x => x === skill), 1, skillToUpdate.text);
        }
        else {
            if (skills.find(x => x.toLowerCase().trim() === skillToUpdate.text.toLowerCase().trim())) {
                await new Prompt('general.warning', 'settings.skill-duplicate-warning', { cancelLabel: '' }).show();
                continue;
            }
            skill = skillToUpdate.text;
            skills.push(skill);
        }

        await ApplicationState.setSetting('global.skills', skills);
    }
};
