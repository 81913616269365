import type { Transaction } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';

export class TransactionAvatarCustomElement {
    @bindable() transaction?: Transaction;

    @computedFrom('transaction')
    get avatar(): { colour: string; text: string } | void {
        if (!this.transaction) return;
        return TransactionUtils.getAvatarForTransaction(this.transaction);
    }
}
