import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { SelectUserOrTeamDialog } from '../../Users/SelectUserOrTeamDialog';
import { animate } from '../../Utilities';
import { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import type { IScheduleItemAction } from './IScheduleItemAction';

export class Assign implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) { }
    public actionType = <ItemActionType>'action-assign';
    public async handler() {
        await animate();
        const dialog = new SelectUserOrTeamDialog(undefined, undefined, undefined, this.scheduleItem.date);
        const defaultAssignee = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            await ScheduleService.bulkAssign([this.scheduleItem], defaultAssignee);
            ScheduleItem.refresh(this.scheduleItem);
            new LoaderEvent(false);
            return Promise.resolve(this.scheduleItem);
        } else {
            return Promise.reject(this.scheduleItem);
        }
    }
    public tooltip: TranslationKey = 'actions.assign';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Planner'];
}
