import type { AccountUser, StoredEventType, TimeEntry, TranslationKey } from '@nexdynamic/squeegee-common';
import {
    JobOccurrence,
    JobOccurrenceStatus,
    StoredEvent,
    formatTimestampDurationAsTime,
    getSummedTimestampOfEntryDurations,
    getTimerRunningForEntries,
    groupStoredEventsIntoTimeEntries,
    isTimerRunningForEntries,
} from '@nexdynamic/squeegee-common';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';

import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { AureliaReactComponentDialog } from '../Dialogs/AureliaReactComponentDialog';
import { Prompt } from '../Dialogs/Prompt';
import { DurationDialog } from '../Dialogs/TimeOrDuration/DurationDialog';
import { TimeDialog } from '../Dialogs/TimeOrDuration/TimeDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { UserService } from '../Users/UserService';
import { Utilities } from '../Utilities';
import ManageStoredEventsDialogComponent from './Dialogs/ManageStoredEventsDialogComponent';
import { StoredEventService } from './StoredEventService';
import type { WorkerTimer } from './WorkerTimer';

export class TimerService {
    static async getTimeFromUser(eventTimestamp: number, title: TranslationKey) {
        const timeDialog = new TimeDialog(moment(eventTimestamp).format('HH:mm'), false, undefined, title, false);
        const time = await timeDialog.show();
        if (!time) return;
        if (timeDialog.cancelled) return;
        return time;
    }

    static async showScheduleItemForActiveTimer(storedEvent: StoredEvent) {
        const id = storedEvent.occurrenceId;
        if (!id) return;
        const occurrence = Data.get<JobOccurrence>(id);
        if (!occurrence) return;

        const assignee = Data.get<AccountUser>(storedEvent.allocateTimeTo);
        if (!assignee) return;

        const { show } = AureliaReactComponentDialog.show({
            component: ManageStoredEventsDialogComponent,
            componentProps: {
                occurrence,
            },
            dialogTitle: 'manage.timed-events',
            isSecondaryView: true,
        });

        return show;
    }

    public static getTimerRunningForCurrentUser() {
        const currentUser = UserService.getUser();
        if (!currentUser) return;
        return TimerService.getTimerRunningForWorker(currentUser._id);
    }

    public static newTimerEventForJobOccurrence(params: {
        occurrence: JobOccurrence;
        assignee: AccountUser;
        type: StoredEventType;
        reason?: string;
        timeStamp?: number;
    }) {
        if (params.assignee.resourceType !== 'accountuser')
            throw 'Cannot create timer event for non-user assignee (resourceType: ' + params.assignee.resourceType + ')';
        if (params.type !== 'start' && params.type !== 'stop') throw new Error('Invalid timer event type: ' + params.type);
        const { occurrence, assignee, type, reason, timeStamp } = params;
        const eventDescription = `${reason ? ` (${reason})` : ''}`;
        const event = new StoredEvent(
            timeStamp || new Date().valueOf(),
            occurrence._id,
            occurrence.jobId,
            occurrence.customerId || 'unknown',
            assignee._id,
            type,
            eventDescription,
            ApplicationState.position?.coords && [ApplicationState.position.coords.longitude, ApplicationState.position.coords.latitude]
        );
        return event;
    }

    public static async stopTimer(occurrence: JobOccurrence, assignee: AccountUser, stopReason?: string) {
        if (!stopReason) {
            const { reason, cancelled } = await JobOccurrenceService.getTimerPauseReasonIfRequired();
            if (cancelled) return;
            stopReason = reason;
        }

        const timerEvent = TimerService.newTimerEventForJobOccurrence({ occurrence, assignee, type: 'stop', reason: stopReason });
        await Data.put([timerEvent]);
    }

    public static async toggleTimerForCurrentUser() {
        const worker = await UserService.getUser();
        if (!worker) return new NotifyUserMessage('notification.failed-to-toggle-timer');
        return TimerService.toggleTimerForWorker(worker);
    }

    public static async toggleTimerForWorker(worker: AccountUser) {
        const timerEntries = StoredEventService.getTimerEntriesForWorker(worker._id);

        const timerRunning = isTimerRunningForEntries(timerEntries);

        let reasonDesc;
        if (timerRunning) {
            const { reason, cancelled } = await JobOccurrenceService.getTimerPauseReasonIfRequired();
            if (cancelled) return;
            if (reason) reasonDesc = reason;
        }

        const timerEvent = TimerService.newWorkerTimerEvent({ worker, type: timerRunning ? 'stop' : 'start', reason: reasonDesc });

        await Data.put(timerEvent);
    }

    public static newWorkerTimerEvent(params: { worker: AccountUser; type: StoredEventType; reason?: string; timeStamp?: number }) {
        const { worker, type, reason, timeStamp } = params;

        const eventDescription = `${worker.name} Timer ${type} on ${reason ? ` (${reason})` : ''}`;
        const timerEvent = new StoredEvent(
            timeStamp || new Date().valueOf(),
            'none',
            undefined,
            undefined,
            worker._id,
            type,
            eventDescription
        );
        return timerEvent;
    }

    public static async pickDateAndTimeForEvent(eventTimestamp: number) {
        const dateTime = moment(eventTimestamp);
        const datePicker = new DateTimePicker(false, dateTime.format('YYYY-MM-DD'));
        datePicker.init();
        await datePicker.open();

        if (datePicker.canceled) return;
        const dateCompleted = datePicker.selectedDate.substring(0, 10);

        const timeCompleted = await TimerService.getTimeFromUser(eventTimestamp, 'enter.completed-time-title');
        if (!timeCompleted) return;

        const momentCompleted = moment(dateCompleted).startOf('day').add(timeCompleted).startOf('minute');
        return momentCompleted.valueOf();
    }

    public static async createManualEntryForJobOccurrence(
        occurrence: JobOccurrence,
        assignee: AccountUser,
        duration: string,
        dateTimeCompletedTS: number
    ): Promise<TimeEntry | false> {
        const newStopEvent = TimerService.newTimerEventForJobOccurrence({
            occurrence,
            assignee: assignee,
            type: 'stop',
            reason: 'Manual Entry',
        });

        const startedTS = moment(dateTimeCompletedTS).subtract(duration).valueOf();

        const newStartEvent = TimerService.newTimerEventForJobOccurrence({
            occurrence,
            assignee: assignee,
            type: 'start',
            reason: 'Manual Entry',
        });

        const valid = await this.validateCreateNewEntryForWorker(startedTS, dateTimeCompletedTS, assignee._id);
        if (!valid) return false;

        newStartEvent.eventTimestamp = startedTS;
        newStopEvent.eventTimestamp = dateTimeCompletedTS;

        await Data.put([occurrence, newStartEvent, newStopEvent]);

        return {
            assigneeId: assignee._id,
            start: newStartEvent,
            stop: newStopEvent,
            type: 'recorded',
            occurrenceId: occurrence._id,
        };
    }

    public static async validateCreateNewEntryForWorker(startTs: number, stopTs: number, workerId: string) {
        // Stop event must be after start event
        if (stopTs <= startTs) return false;

        // Stop and start event must be in the past
        if (startTs > new Date().valueOf()) {
            const msg = new Prompt('time-tracker.start-in-future-title', 'time-tracker.start-in-future-description', {
                cancelLabel: '',
            });
            await msg.show();
            return false;
        }

        if (stopTs > new Date().valueOf()) {
            const msg = new Prompt('time-entries.stop-in-future-title', 'time-entries.stop-in-future-description', {
                cancelLabel: '',
            });
            await msg.show();
            return false;
        }

        // Are there any conflicting events in the requested time period?
        const conflicts = await StoredEventService.getEventsInPeriodForWorker(workerId, startTs, stopTs);
        if (conflicts.length > 0) {
            const msg = new Prompt('time-tracker.conflict-title', 'time-tracker.conflict-description', {
                cancelLabel: '',
                localisationParams: {
                    conflicts: Utilities.localiseList(
                        conflicts.map(x => `${x.type}: ${moment(x.eventTimestamp).format('DD/MM/YYYY HH:mm:ss')}` as TranslationKey)
                    ),
                },
            });
            await msg.show();
            return false;
        }

        return true;
    }

    public static async validateStartEvent(eventTimestamp: number, workerId: string, editingId?: string) {
        if (eventTimestamp > new Date().valueOf()) {
            const msg = new Prompt('time-tracker.start-in-future-title', 'time-tracker.start-in-future-description', {
                cancelLabel: '',
            });
            await msg.show();
            return false;
        }

        const lastEvent = StoredEventService.getLastEventForWorker(workerId, eventTimestamp);
        if (lastEvent && lastEvent.type === 'start' && lastEvent._id !== editingId) {
            const msg = new Prompt('time-tracker.start-conflict-title', 'time-tracker.start-conflict-description', {
                cancelLabel: '',
            });
            await msg.show();
            return false;
        }
        return true;
    }

    public static async validateStopEvent(eventTimestamp: number, workerId: string, editingId?: string) {
        if (eventTimestamp > new Date().valueOf()) {
            const msg = new Prompt('time-entries.stop-in-future-title', 'time-entries.stop-in-future-description', {
                cancelLabel: '',
            });
            await msg.show();
            return false;
        }

        const lastEvent = StoredEventService.getLastEventForWorker(workerId, eventTimestamp);
        if (lastEvent && lastEvent.type === 'stop' && lastEvent._id !== editingId) {
            const msg = new Prompt('time-entries.stop-conflict-title', 'time-entries.stop-conflict-description', {
                cancelLabel: '',
            });
            await msg.show();
            return false;
        }
        return true;
    }

    public static async editEntry(storedEvent: StoredEvent) {
        const newTs = await this.pickDateAndTimeForEvent(storedEvent.eventTimestamp);
        if (!newTs) return false;

        if (storedEvent.type === 'start') {
            const valid = await this.validateStartEvent(newTs, storedEvent.allocateTimeTo, storedEvent._id);
            if (!valid) return false;
        } else if (storedEvent.type === 'stop') {
            const valid = await this.validateStopEvent(newTs, storedEvent.allocateTimeTo, storedEvent._id);
            if (!valid) return false;
        }

        storedEvent.eventTimestamp = newTs;

        await Data.put(storedEvent);
    }

    public static async updateTimeEntry(
        timeEntry: TimeEntry,
        newStart: number,
        newStop: number,
        jobOccurrence: JobOccurrence
    ): Promise<TimeEntry | false> {
        const { start, stop } = timeEntry;

        if (!start || !stop) return false;
        const startValid = await this.validateStartEvent(newStart, start.allocateTimeTo, start._id);
        if (!startValid) return false;

        const stopValid = await this.validateStopEvent(newStop, stop.allocateTimeTo, stop._id);
        if (!stopValid) return false;

        start.eventTimestamp = newStart;
        stop.eventTimestamp = newStop;
        if (jobOccurrence) {
            start.occurrenceId = jobOccurrence._id;
            stop.occurrenceId = jobOccurrence._id;
        }
        await Data.put([jobOccurrence, start, stop]);
        console.log('Updated time entry. New start/stop: ', start, stop);

        return { ...timeEntry, start, stop };
    }

    public static async deleteEntry(storedEvent: StoredEvent) {
        await Data.delete(storedEvent);
    }

    public static getWorkerTimers(occurrence: JobOccurrence) {
        const timers = [];

        const wTimers = TimerService.getWorkerTimersForJobOccurrence(occurrence);
        const assignees = AssignmentService.getAssignees(occurrence, true) as AccountUser[];
        const untimedWorkers = assignees
            .filter(w => !wTimers.some(t => t.assignee?._id === w._id))
            .map(x => ({ assignee: x, duration: 0, start: undefined, stop: undefined, running: false, durationText: '0:00', occurrence }));
        timers.push(...wTimers);
        timers.push(...untimedWorkers);
        return timers as Array<WorkerTimer>;
    }

    public static async enterTimeForJobOccurrence(occurrence: JobOccurrence) {
        new LoaderEvent(false);
        const { show } = AureliaReactComponentDialog.show({
            component: ManageStoredEventsDialogComponent,
            componentProps: {
                occurrence,
            },
            dialogTitle: 'manage.timed-events',
            isSecondaryView: true,
        });

        await show;
        return true;
    }

    public static async enterTimeForAllTimersOnJobOccurrence(occurrence: JobOccurrence) {
        const timers = await TimerService.getWorkerTimers(occurrence);

        new LoaderEvent(false);

        const dateTimeCompletedTS = await TimerService.pickDateAndTimeForEvent(new Date(JobOccurrence.getDate(occurrence)).valueOf());
        if (!dateTimeCompletedTS) return false;
        const durationDialog = new DurationDialog(occurrence.duration || '00:30', {
            seconds: false,
        });
        const result = await durationDialog.show();
        if (durationDialog.cancelled) return false;
        if (!result) return false;

        for (const timer of timers) {
            if (!timer.assignee) continue;
            await TimerService.createManualEntryForJobOccurrence(occurrence, timer.assignee, result, dateTimeCompletedTS);
        }
        return;
    }

    public static async enterTimeForTimerOnJobOccurrence(occurrence: JobOccurrence, assignee: AccountUser) {
        new LoaderEvent(false);

        const dateTimeCompletedTS = await TimerService.pickDateAndTimeForEvent(new Date(JobOccurrence.getDate(occurrence)).valueOf());
        if (!dateTimeCompletedTS) return false;
        const durationDialog = new DurationDialog(occurrence.duration || '00:30', {
            seconds: false,
        });
        const result = await durationDialog.show();
        if (durationDialog.cancelled) return false;
        if (!result) return false;

        await TimerService.createManualEntryForJobOccurrence(occurrence, assignee, result, dateTimeCompletedTS);

        return;
    }

    public static async stopTimerOnCompletion(occurrence: JobOccurrence): Promise<boolean> {
        const timeEntries = await TimerService.getTimeEntriesForJobOccurrence(occurrence);

        if (!timeEntries.length) {
            return await TimerService.enterTimeForJobOccurrence(occurrence);
        }

        const timers = await TimerService.getWorkerTimers(occurrence);

        for (const timer of timers) {
            if (timer.running) {
                if (!timer.assignee) continue;
                TimerService.stopTimer(occurrence, timer.assignee, 'Job Completed');
            }
        }

        return true;
    }

    public static async canStartTimer(occurrence: JobOccurrence, assignee: AccountUser) {
        if (occurrence.status === JobOccurrenceStatus.Done || occurrence.status === JobOccurrenceStatus.Skipped) {
            const dialog = new Prompt('general.status', 'timer.no-start-job-already-done-or-skipper', { cancelLabel: '' });
            await dialog.show();
            return false;
        }

        if (
            (occurrence.isoCompletedDate ? occurrence.isoPlannedDate || occurrence.isoDueDate : JobOccurrence.getDate(occurrence)) !==
            moment().format('YYYY-MM-DD')
        ) {
            const dialog = new Prompt('general.confirm', 'timer.no-start-only-jobs-planned-today', { cancelLabel: '' });
            await dialog.show();
            return false;
        }

        const timerRunningForWorker = TimerService.getTimerRunningForWorker(assignee._id);
        if (timerRunningForWorker) {
            const dialog = new Prompt('general.confirm', 'timer.no-start-already-active-timer', {
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
            });
            const resp = await dialog.show();
            if (resp) {
                await this.showScheduleItemForActiveTimer(timerRunningForWorker);
            }
            return false;
        }
        return true;
    }

    public static async startTimer(occurrence: JobOccurrence, assignee: AccountUser) {
        const timerEvent = TimerService.newTimerEventForJobOccurrence({ occurrence, assignee, type: 'start' });
        occurrence.started = occurrence.started || moment().format();
        const valid = await this.validateStartEvent(timerEvent.eventTimestamp, timerEvent.allocateTimeTo);
        if (!valid) return false;
        await Data.put([timerEvent, occurrence]);
    }

    static getTimeEntriesForJobOccurrence(jobOccurrence: JobOccurrence) {
        const timeEntries = StoredEventService.getTimerEntriesForJobOccurrence(jobOccurrence._id);
        return groupStoredEventsIntoTimeEntries(timeEntries);
    }

    static getTimeEventsForJobOccurrenceGroupedByAssignee(jobOccurrence: JobOccurrence) {
        const storedEvents = StoredEventService.getTimerEntriesForJobOccurrence(jobOccurrence._id);

        return TimerService.getTimeEventsGroupedByWorker(storedEvents);
    }

    static getTimeEventsGroupedByWorker(storedEvents: Array<StoredEvent>) {
        const groupEntriesByAssignee: Record<string, Array<StoredEvent>> = {};

        for (const entry of storedEvents) {
            // Legacy crap, geeze I have to loop through an anonymous array of ids to get the "possible" related job occurrence id, when time can only be allocated against one. Why? I don't know.
            // if (entry.metaData?.relatedIds?.storedObjectIds) {
            //     for (const id of entry.metaData.relatedIds.storedObjectIds) {
            //         const jo = Data.get<JobOccurrence>(id);
            //         if (jo?.resourceType !== 'joboccurrences') continue;
            //         const assignees = AssignmentService.getAssignees(jo);
            //         if (!assignees.length) continue;
            //         for (const assignee of assignees) {
            //             if (!groupEntriesByAssignee[assignee._id]) groupEntriesByAssignee[assignee._id] = [];
            //             groupEntriesByAssignee[assignee._id].push(entry);
            //         }

            //     }
            //     continue;
            // }

            // New version.  an actual property on an object... who would of thought of that?
            if (!groupEntriesByAssignee[entry.allocateTimeTo || 'unknown']) groupEntriesByAssignee[entry.allocateTimeTo || 'unknown'] = [];
            groupEntriesByAssignee[entry.allocateTimeTo || 'unknown'].push(entry);
        }
        return groupEntriesByAssignee;
    }

    static getWorkerTimersForStoredEvents(storedEvents: Array<StoredEvent>) {
        const timeEventsByAssignee = TimerService.getTimeEventsGroupedByWorker(storedEvents);
        const timers: Array<WorkerTimer> = [];
        for (const assigneeId in timeEventsByAssignee) {
            const timeEvents = timeEventsByAssignee[assigneeId];
            const duration = getSummedTimestampOfEntryDurations(timeEvents) || 0;
            const assignee = assigneeId ? Data.get<AccountUser>(assigneeId) : undefined;
            const running = isTimerRunningForEntries(timeEvents);
            const timeData = groupStoredEventsIntoTimeEntries(timeEvents);

            // const occurrence = lastTimeEntry.start?.occurrenceId ? Data.get<JobOccurrence>(lastTimeEntry.start.occurrenceId) : undefined;

            //Legacy occurrence

            const { stop, start, difference } = TimerService.getStartAndLastEndOfStoredEvents(timeEvents);

            const unrecordedTime = difference ? difference - duration : 0;

            const timer: WorkerTimer = {
                assignee,
                firstStartedTime: start?.eventTimestamp ? new Date(start?.eventTimestamp).toLocaleTimeString() : 'None',
                lastStoppedTime: stop?.eventTimestamp ? new Date(stop?.eventTimestamp).toLocaleTimeString() : 'None',
                running: running,
                duration: duration ? formatTimestampDurationAsTime(duration) : '00:00:00',
                unrecordedTime: unrecordedTime ? formatTimestampDurationAsTime(unrecordedTime) : 'None',
                totalTime: difference ? formatTimestampDurationAsTime(difference) : 'None',
                timeEntries: timeData,
            };

            timers.push(timer);
        }
        return timers;
    }

    static getStartAndLastEndOfStoredEvents(storedEvents: Array<StoredEvent>) {
        let stop;
        let start;
        for (const entry of storedEvents) {
            if (entry.type === 'stop' && (!stop || entry.eventTimestamp > stop.eventTimestamp)) stop = entry;
            if (entry.type === 'start' && (!start || entry.eventTimestamp < start.eventTimestamp)) start = entry;
        }
        return { start, stop, difference: stop && start && stop?.eventTimestamp - start?.eventTimestamp };
    }

    static getWorkerTimersForJobOccurrence(jobOccurrence: JobOccurrence) {
        const storedEvents = StoredEventService.getTimerEntriesForJobOccurrence(jobOccurrence._id);
        return TimerService.getWorkerTimersForStoredEvents(storedEvents);
    }

    static getTimerRunningForWorker(assigneeId: string) {
        const timeEntries = StoredEventService.getTimerEntriesForWorker(assigneeId);
        return getTimerRunningForEntries(timeEntries);
    }

    static isTimerRunning(jobOccurrence: JobOccurrence, assignee?: string) {
        const timeEntries = StoredEventService.getTimerEntriesForJobOccurrence(jobOccurrence._id, assignee);
        if (!assignee) return isTimerRunningForEntries(timeEntries);
        const entries = timeEntries.filter(entry => entry.allocateTimeTo === assignee);
        return isTimerRunningForEntries(entries);
    }
}
