export class StandardMessageModel {
    'customerName' = '';
    'firstname' = '';
    'lastname' = '';
    'customerAddress' = '';
    'customerRef' = '';
    'email' = '';
    'nextDate' = '';
    'nextTime' = '';
    'title' = '';
    'businessName' = '';
    'balance' = '';
    'unpaidInvoicesView' = '';
    'unpaidInvoicesDownload' = '';
    'paidInvoicesView' = '';
    'paidInvoicesDownload' = '';
    'allInvoicesView' = '';
    'allInvoicesDownload' = '';
    'isHtml' = '';
    'signature' = '';
    'logo' = '';
    'logoUrl' = '';
    'inviteLink' = '';
    'portalInviteLink' = '';
    'prepayLink=?' = '';
    'payBalance' = '';
    'payDueInvoices' = '';
}

export class StandardMessageModelTokens {
    'customerName' = '';
    'firstname' = '';
    'lastname' = '';
    'customerAddress' = '';
    'customerRef' = '';
    'email' = '';
    'nextDate' = '';
    'nextTime' = '';
    'title' = '';
    'businessName' = '';
    'balance' = '';
    'unpaidInvoicesView' = '';
    'unpaidInvoicesDownload' = '';
    'paidInvoicesView' = '';
    'paidInvoicesDownload' = '';
    'allInvoicesView' = '';
    'allInvoicesDownload' = '';
    'signature' = '';
    'logo' = '';
    'logoUrl' = '';
    'inviteLink' = '';
    'portalInviteLink' = '';
    'prepayLink=?'? = '';
    'payBalance'? = '';
}
