import type { ScheduleItem } from '../Components/ScheduleItem';
import { AllJobsView } from './AllJobsView';
export class AllJobsViewDialog extends AllJobsView {
    // WTF: This class is nasty and really finicky to get the search working, don't mess without testing search still works.
    public viewName = 'allJobsDialog_';

    protected selectedItemsData: { selectedItems?: Array<ScheduleItem> };

    async activate(data: { date?: string; scheduleItemId?: string; selectedItems?: Array<ScheduleItem> }, _route: any) {
        this.selectedItemsData = data;
    }
}
