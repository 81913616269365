import type {
    AuthorisedUser,
    IProductPlanSubscription,
    IStoredObjectSyncResponse,
    Pong,
    StandardApiResponse,
    StoredObject,
    WeatherDetailsDictionary,
} from '@nexdynamic/squeegee-common';
import { CommonApplicationState, SyncMode, wait } from '@nexdynamic/squeegee-common';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'axios';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { V2 } from '../Data/V2/V2';
import { Prompt } from '../Dialogs/Prompt';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { ActionableEvent } from '../Notifications/ActionableEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Stopwatch } from '../Stopwatch';
import { animate } from '../Utilities';
import type { IApplicationStateResponse } from './IApplicationStateResponse';
import type { IPingResponse } from './IPingResponse';
import { RethinkDbAuthClient } from './RethinkDbAuthClient';
import { SqueegeeClientSocketApi } from './SqueegeeClientSocketApi';
import { getHeaders } from './getHeaders';

Axios.defaults.timeout = 60000;

export const SYNC_INTERVAL = 2000;
export const SYNC_BATCH_SIZE = 200;

export class Api {
    static _cachedAuthClient: AxiosInstance;
    static _cachedNoAuthClient: AxiosInstance;
    private static async getHttpClient(apiRequest: boolean, ensureAuthentication: boolean): Promise<AxiosInstance | undefined> {
        if (!ensureAuthentication) {
            const headers: { [key: string]: string } = !apiRequest
                ? {}
                : {
                      'Authorization': RethinkDbAuthClient.session
                          ? `Bearer ${RethinkDbAuthClient.session.key}:${RethinkDbAuthClient.session.value}`
                          : '',
                      'data-email': ApplicationState.dataEmail,
                      'client-version': ApplicationState.version,
                      'client-id':
                          (SqueegeeClientSocketApi.instance &&
                              SqueegeeClientSocketApi.instance.server &&
                              SqueegeeClientSocketApi.instance.server.clientId) ||
                          '',
                      'device-id': ApplicationState.deviceId,
                  };

            const client = Axios.create({ withCredentials: true, headers });

            client.defaults.withCredentials = true;
            return client;
        }

        await Api.initialised;
        if (!ApplicationState.clientVersionStatus || ApplicationState.clientVersionStatus === 'failed') return;

        if (ApplicationState.clientVersionStatus === 'unsupported') {
            const clientVersionMessage = 'notifications.version-unsupported';
            if (!GlobalFlags.isHttp && (GlobalFlags.isAppleMobileDevice || GlobalFlags.isAndroidMobileDevice)) {
                new ActionableEvent(
                    ApplicationState.localise(clientVersionMessage),
                    ApplicationState.localise('notifications.view-update'),
                    GlobalFlags.isAppleMobileDevice
                        ? 'https://itunes.apple.com/app/squeegee/id1223407652?ls=1&mt=8'
                        : 'https://play.google.com/store/apps/details?id=com.squeegee&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
                    300000
                );
            } else {
                new NotifyUserMessage(ApplicationState.localise(clientVersionMessage), undefined, 300000);
            }
            return;
        } else if (ensureAuthentication && !(await ApplicationState.reauthenticate())) {
            Logger.info(
                'Unable to validate session at present, this client may be offline or have an invalid session.',
                ApplicationState.lastAuthenticationError
            );
            return;
        }

        if (!RethinkDbAuthClient.session) return;

        Api._cachedAuthClient = Api._cachedAuthClient || Axios.create({ withCredentials: true });
        Api._cachedAuthClient.defaults.withCredentials = true;
        Api._cachedAuthClient.defaults.headers = getHeaders(RethinkDbAuthClient.session);

        return Api._cachedAuthClient;
    }

    static async registerSupportAuthCode() {
        const result = await Api.post<StandardApiResponse & { code?: string }>(
            null,
            `/support/register-support-auth-code`,
            undefined,
            undefined,
            true
        );
        return (result?.data.success && result.data.code) || undefined;
    }
    public static async setCustomerCardOnFile(squeegeeCustomerId: string, stripeCustomerId: string) {
        await Api.post(null, `/api/set-default-card`, { squeegeeCustomerId, stripeCustomerId });
    }

    public static isConnected: boolean;
    public static connectionLatency: number;
    private static _refreshingConnectionMetadata = false;
    private static _connectionRefresh: any;
    public static async refreshConnectionMetadata(wentOffline = false) {
        if (wentOffline) {
            Logger.info(`Refreshing connection metadata because we went offline`);
            this.isConnected = false;
        }

        let timeout = 360000;

        try {
            if (Api._refreshingConnectionMetadata) return;
            await Api.processSyncQueue();
            clearTimeout(Api._connectionRefresh);
            delete Api._connectionRefresh;

            this._refreshingConnectionMetadata = true;

            let ping = await Api.ping(5000, Api.apiEndpoint).catch(() => undefined);
            if (!ping) ping = await Api.ping(15000, Api.apiEndpoint);
            if (ping?.versionInfo.pong) {
                Logger.info('Using ' + Api.apiEndpoint);

                await ApplicationState.validateClientVersion(ping);

                Api.isConnected = ApplicationState.clientVersionStatus === 'current' || ApplicationState.clientVersionStatus === 'outdated';
                const servedBy = Api.currentHostAndScheme;
                Api.currentHostAndScheme = ping.versionInfo.servedBy;

                Api.connectionLatency = ping.latency;
                if (!SqueegeeClientSocketApi.instance || servedBy !== Api.currentHostAndScheme) SqueegeeClientSocketApi.init();
                return;
            } else {
                Logger.info('Using ' + Api.apiEndpoint);
                Api.isConnected = false;

                if (ping) {
                    await ApplicationState.validateClientVersion(ping);
                    Api.currentHostAndScheme = ping.versionInfo.servedBy;
                    Api.connectionLatency = ping.latency;
                }

                Logger.info('Unable to connect to the Squeegee services at present.');
                Api.currentHostAndScheme = Api.apiEndpoint;
                timeout = 5000;
            }
        } finally {
            Api.updateConnectionData();
            Api._refreshingConnectionMetadata = false;
            Api._connectionRefresh = setTimeout(() => this.refreshConnectionMetadata(), timeout);
            Api.V2 = V2();
        }
    }

    public static async getSubscription() {
        try {
            const subscriptionRequest = await Api.get<IProductPlanSubscription>(null, '/api/customer/subscription');
            return subscriptionRequest && subscriptionRequest.data;
        } catch (error) {
            Logger.info('Failed to lookup the subscription', error);
        }
    }

    public static async requestSignoutOtherDevices() {
        const areYouSurePrompt = new Prompt('prompts.confirm-title', 'prompts.sign-out-others', { okLabel: 'general.yes' });
        if (await areYouSurePrompt.show()) await Api.signoutOtherDevices();
    }
    public static async signoutOtherDevices() {
        await Api.post(null, '/api/authentication/signout-other-devices');
    }

    private static updateConnectionData() {
        try {
            if (!Api.isConnected) {
                Api.connectionData = 'Offline';
                return;
            }

            const latency = !Api.connectionLatency
                ? ''
                : Api.connectionLatency < 500
                ? `${Api.connectionLatency}ms`
                : `${(Api.connectionLatency / 1000).toFixed(1)}s`;
            const performance = !Api.connectionLatency
                ? ''
                : Api.connectionLatency < 100
                ? ' (crazy awesome)'
                : Api.connectionLatency < 250
                ? ' (awesome)'
                : Api.connectionLatency < 500
                ? ' (fast)'
                : Api.connectionLatency < 1500
                ? ' (ok)'
                : Api.connectionLatency < 3000
                ? ' (slight delay)'
                : Api.connectionLatency < 5000
                ? ' (slow)'
                : Api.connectionLatency < 7500
                ? ' (very slow)'
                : ' (terrible, please check network)';

            Api.connectionData = `Connected to ${Api.currentHost} with ${latency} latency ${performance}`;
        } catch (error) {
            Logger.error('Unable to set the connection data', error);
        }
    }
    public static connectionData = '';

    public static async setInvoiceNumber(invoiceNumber: number) {
        const updateResult = await Api.put<{ success: boolean }>(null, '/api/payments/invoice-number', { invoiceNumber });

        if (!updateResult || !updateResult.data || !updateResult.data.success)
            return new Prompt('general.warning', 'problem.updating-invoice-number-contact-support', {
                okLabel: 'general.ok',
                cancelLabel: '',
            }).show();
    }

    public static getQueueItem(storedObjectId: string): StoredObject | undefined {
        return Api._syncObjects[storedObjectId];
    }

    public static async writeLogEntry(logEntry: string) {
        try {
            const client = await Api.getHttpClient(true, false);
            if (client) await client.post(`${Api.apiEndpoint}/api/log`, { error: logEntry });
        } catch (error) {
            /*  */ console.error('Failed to send client log entry', error); /*  */
        }
    }

    private static _syncObjects: { [id: string]: StoredObject } = {};

    public static canBeStringified(entity: any) {
        try {
            JSON.stringify(entity);
            return true;
        } catch (ex) {
            return false;
        }
    }

    public static async queueSyncObjects(storedObjects: { [id: string]: StoredObject | null }) {
        for (const key in storedObjects) {
            const o = storedObjects[key];
            if (o) {
                if (Api.canBeStringified(o)) {
                    Api._syncObjects[key] = o;
                } else Logger.error('Unable to serialise object with id ' + key);
            } else delete Api._syncObjects[key];
        }

        Object.assign(Api._syncObjects, storedObjects);
    }

    public static stopProcessingSyncQueue() {
        clearTimeout(Api._syncTimeout);
        delete Api._syncTimeout;
    }

    private static _syncTimeout?: any;
    public static currentSync: Promise<any>;
    private static _runningSync = false;
    public static async processSyncQueue() {
        try {
            clearTimeout(Api._syncTimeout);
            if (!Api.isConnected) return void (Api._runningSync = false);

            if (Api._runningSync) return;
            Api._runningSync = true;

            const lastSync = Api.currentSync;

            const ids = Object.keys(Api._syncObjects);
            //if (ids.length) {
            // eslint-disable-next-line no-async-promise-executor
            Api.currentSync = new Promise<void>(async resolve => {
                try {
                    lastSync && (await lastSync);

                    let syncBatchIds: Array<string> = [];
                    while (ids.length) {
                        syncBatchIds = ids.splice(0, SYNC_BATCH_SIZE);
                        const syncBatch = syncBatchIds
                            .map(id => Api._syncObjects[id])
                            .filter(storedObject => !!storedObject)
                            .reduce<{ [id: string]: StoredObject }>((syncDictionary, nextStoredObject) => {
                                syncDictionary[nextStoredObject._id] = nextStoredObject;
                                return syncDictionary;
                            }, {});

                        for (const id of syncBatchIds) delete Api._syncObjects[id];

                        try {
                            const synchronised = await Api.putStoredObjects(syncBatch);
                            if (!synchronised) throw new Error('put failed');

                            Logger.info(Object.keys(Api._syncObjects).length + ' items left to sync');
                        } catch (error) {
                            Logger.info('Sync failed for the following objects: ', { syncBatch, error });
                            // Put the objects back into the queue so they can get retried on next interval.
                            for (const id of syncBatchIds) {
                                if (Api._syncObjects[id] === undefined) {
                                    Api._syncObjects[id] = syncBatch[id];
                                }
                            }
                            await wait(5000);
                        } finally {
                            Api.updateItemsAwaitingSync();
                        }
                    }
                } catch (error) {
                    Logger.info('Sync failed for the following objects: ', { ids, error });
                } finally {
                    Api.updateItemsAwaitingSync();
                }

                Api._runningSync = false;
                return resolve();
            });
            //}
        } finally {
            Api._syncTimeout = setTimeout(async () => await Api.processSyncQueue(), SYNC_INTERVAL);
        }
    }

    private static _syncFailures = 0;

    public static async putStoredObjects(storedObjects: { [id: string]: StoredObject }) {
        let synchronised = false;
        try {
            const response = await Api.put<{ deniedResources: Array<StoredObject> }>(
                Api.apiEndpoint,
                `/api/sync`,
                storedObjects,
                false,
                false
            );
            if (!response) throw 'Unable to sync items to the server, check connection.';
            synchronised = true;
            try {
                if (response && response.data && response.data.deniedResources && response.data.deniedResources.length) {
                    Data.put(response.data.deniedResources, false);
                    const resources: { [resourceType: string]: true } = {};
                    for (const storedObject of response.data.deniedResources) {
                        resources[storedObject.resourceType] = true;
                    }
                }
            } catch (error) {
                Logger.info('Error storing the data locally in putStoredObjects() on Api', error);
                new NotifyUserMessage('notifications.failed-to-store-locally');
            }
            this._syncFailures = 0;
        } catch (error) {
            this._syncFailures++;
            if (this._syncFailures > 15) {
                Logger.info('Error in putStoredObjects() on Api after 15 attempts, please investigate.', error);
                if (Api.isConnected) new NotifyUserMessage('notifications.failed-to-sync-remotely');
                this._syncFailures = 0;
            } else {
                Logger.info("Can't putStoredObjects() on Api at present, session may be uncheckable.", error);
            }
        }

        return synchronised;
    }

    public static itemsAwaitingSync = Object.keys(Api._syncObjects).length;
    public static updateItemsAwaitingSync() {
        const count = Object.keys(Api._syncObjects).length;
        if (count !== Api.itemsAwaitingSync) Api.itemsAwaitingSync = count;
    }

    public static async getApplicationState(timeout?: number): Promise<IApplicationStateResponse> {
        if (!timeout) timeout = 45000;
        const localApplicationState = Data.get<CommonApplicationState>(CommonApplicationState.APPLICATION_STATE_ID);
        const timestamp = (localApplicationState && localApplicationState.timestamp) || 0;
        let remoteApplicationState: CommonApplicationState | undefined | false;

        let count = 3;
        const errors: Array<any> = [];
        while (count--) {
            try {
                const remoteApplicationStateRequest = await Api.get<CommonApplicationState | false | undefined>(
                    Api.apiEndpoint,
                    `/api/applicationstate?timestamp=${timestamp}`,
                    false,
                    timeout,
                    false
                );
                remoteApplicationState = remoteApplicationStateRequest && remoteApplicationStateRequest.data;
                if (remoteApplicationStateRequest) break;
            } catch (error) {
                errors.push(error);
                await wait(1000);
                continue;
            }
        }

        const failed = count < 0;
        return {
            failed,
            remoteApplicationState: {
                applicationState: remoteApplicationState || undefined,
                status: failed
                    ? undefined
                    : remoteApplicationState
                    ? 'remote-newer'
                    : remoteApplicationState === false
                    ? 'local-newer'
                    : 'same',
            },
        };
    }

    public static async putApplicationState(applicationState: CommonApplicationState): Promise<CommonApplicationState | undefined> {
        try {
            const clientId =
                (SqueegeeClientSocketApi.instance &&
                    SqueegeeClientSocketApi.instance.server &&
                    SqueegeeClientSocketApi.instance.server.clientId) ||
                '';
            const remoteApplicationStateResponse = await Api.put<CommonApplicationState>(
                Api.apiEndpoint,
                `/api/applicationstate?clientId=${clientId}`,
                applicationState
            );

            return remoteApplicationStateResponse && remoteApplicationStateResponse.data;
        } catch (error) {
            Logger.info('error in putApplicationState', error);
        }
    }

    private static _syncStreamProcessRateLimiter: any;
    public static async getAllSyncStream(
        delta: {
            [id: string]: number;
        },
        mode: SyncMode,
        processChanges: (storedObjects: Array<StoredObject>) => Promise<void>
    ): Promise<IStoredObjectSyncResponse | undefined> {
        try {
            const clearCache = mode > SyncMode.Full;
            const deleteCacheFiles = mode === SyncMode.FullClearCacheAndDisk;
            const lastSuccessfullSyncTimeStamp = mode > SyncMode.Partial ? 0 : Data.lastSuccessfullSyncTimeStamp;

            if (RethinkDbAuthClient.session) {
                await BGLoaderEvent.emit(true, 'Syncing data with the Squeegee™ Cloud.');
                await animate(25); // Show the message for sure.
                const abortController = new AbortController();
                const signal = abortController.signal;
                let recachingRequired = false;
                let recachingError: any = undefined;
                const currentUser = RethinkDbAuthClient.session.email; //prevent rogue syncs but need original user for to abort sync if changed
                if (!currentUser) return;

                const response = await fetch(
                    `${
                        Api.apiEndpoint
                    }/api/sync?last=${lastSuccessfullSyncTimeStamp}&clearcache=${clearCache.toString()}&deletecache=${deleteCacheFiles.toString()}`,
                    {
                        signal,
                        headers: {
                            'Authorization': `Bearer ${RethinkDbAuthClient.session.key}:${RethinkDbAuthClient.session.value}`,
                            'data-email': ApplicationState.dataEmail || '',
                            'client-version': ApplicationState.version,
                            'client-id':
                                (SqueegeeClientSocketApi.instance &&
                                    SqueegeeClientSocketApi.instance.server &&
                                    SqueegeeClientSocketApi.instance.server.clientId) ||
                                '',
                            'device-id': ApplicationState.deviceId,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify(delta),
                    }
                );

                if (!response.ok || !response.body) return;

                if (response.body) {
                    const reader = response.body.getReader();

                    const textDecoder = new TextDecoder();

                    let text = '';
                    await BGLoaderEvent.emit(true, 'Receiving account data from the Squeegee™ Cloud');
                    let fetchFailedTimer = setTimeout(() => abortController.abort(), 60000);
                    const batch = [] as Array<StoredObject>;
                    let itemsJson = '[';
                    let count = 0;
                    for (;;) {
                        let value: Uint8Array | undefined;
                        let done = false;
                        try {
                            const next = await reader.read();

                            value = next.value;
                            done = next.done;
                        } catch (error) {
                            Logger.info('Network error performing sync.', error);
                            return;
                        }

                        if (
                            !RethinkDbAuthClient.session ||
                            !RethinkDbAuthClient.session.email ||
                            currentUser !== RethinkDbAuthClient.session.email
                        ) {
                            abortController.abort();
                            Logger.info('Aborting data sync because session has been lost or user signed out mid sync');
                            break;
                        }
                        clearTimeout(fetchFailedTimer);
                        fetchFailedTimer = setTimeout(() => {
                            abortController.abort();
                            Logger.info('No data received during sync for 60 seconds, aborted.');
                        }, 60000);
                        if (value && value.length) {
                            const textParts = textDecoder.decode(value).split('\n');
                            for (let i = 0; i < textParts.length; i++) {
                                text += textParts[i];
                                if (i < textParts.length - 1) {
                                    if (text.startsWith('P')) {
                                        const p = text.slice(1).split(':');
                                        const total = Number(p[0]);
                                        const progress = Number(p[1]);
                                        if (!isNaN(total) && !isNaN(progress)) {
                                            const progressPercent = Number(((100 / total) * progress).toFixed(0));

                                            BGLoaderEvent.emit(
                                                true,
                                                `Comparing ${progress} of ${total} items with server`,
                                                progressPercent
                                            );
                                        }
                                    } else {
                                        count++;
                                        itemsJson += (itemsJson === '[' ? '' : ',') + text;
                                    }
                                    text = '';
                                }
                            }
                        }
                        if (count >= 500) {
                            count = 0;
                            if (itemsJson.length) {
                                try {
                                    itemsJson += ']';
                                    const storedObjects = JSON.parse(itemsJson) as Array<StoredObject>;
                                    batch.push(...storedObjects);
                                } catch (error) {
                                    // Invalid JSON object, report it to the server for re-caching.
                                    recachingRequired = true;
                                    recachingError = { itemsJson, error };
                                }
                            }
                            itemsJson = '[';
                        }
                        if (batch.length >= 500) {
                            if (processChanges) {
                                await processChanges(batch.splice(0, batch.length));
                            }
                        }
                        if (done) break;
                    }
                    if (itemsJson.length > 1) {
                        try {
                            itemsJson += ']';
                            const storedObjects = JSON.parse(itemsJson) as Array<StoredObject>;
                            batch.push(...storedObjects);
                        } catch (error) {
                            // Invalid JSON object, report it to the server for re-caching.
                            recachingRequired = true;
                            recachingError = { itemsJson, error };
                        }
                    }
                    if (processChanges) {
                        batch.length && (await processChanges(batch));
                    }

                    clearTimeout(fetchFailedTimer);
                    let complete: IStoredObjectSyncResponse | undefined;
                    if (!text?.trim().length) {
                        // WTF: Broken cache so the summary object was not sent.
                        recachingRequired = true;
                    } else {
                        complete = JSON.parse(text) as IStoredObjectSyncResponse;
                        Logger.info('Sync stream completed report.', complete);
                        if (complete.deleted && complete.deleted.length) {
                            await BGLoaderEvent.emit(true, `Cleaning up ${complete.deleted.length} items.`);
                            const deleted = complete.deleted.filter(d => d !== CommonApplicationState.APPLICATION_STATE_ID).filter(Boolean);

                            await Data.cleanUpConfirmedDeletesBackground(deleted);
                        }
                    }
                    await BGLoaderEvent.emit(true, 'All account data received from the Squeegee™ Cloud');
                    if (recachingRequired) {
                        Api.clearCorruptCache();
                        Logger.info('Sync stream completed with errors, requesting a clear cache.', recachingError);
                    } else {
                        Logger.info('Sync stream completed successfully.');
                    }
                    return complete;
                }
            }
        } finally {
            clearInterval(Api._syncStreamProcessRateLimiter);
        }
    }
    public static clearCorruptCache() {
        Api.delete(null, '/api/sync/cache');
    }

    public static async get<TResponseType>(
        apiUrl: string | null,
        url: string,
        isAbsolute = false,
        timeout?: number,
        ignoreError = true,
        ensureAuthentication = true,
        config: AxiosRequestConfig = {}
    ) {
        try {
            const client = await Api.getHttpClient(true, ensureAuthentication);
            if (!client || !url) return void Logger.info('Unable to perform http get, client unavailable offline.');
            const response = await client.get<TResponseType>(`${isAbsolute ? '' : apiUrl || Api.apiEndpoint}${url}`, {
                timeout,
                ...config,
            });
            return response;
        } catch (error) {
            return Api.handleHttpError<TResponseType>(error, 'get', url, apiUrl, ignoreError);
        }
    }

    public static async post<TResponseType>(
        apiUrl: string | null,
        url: string,
        data?: any,
        isAbsolute = false,
        ignoreError = true,
        options?: AxiosRequestConfig,
        ensureAuthentication = true
    ) {
        try {
            const client = await Api.getHttpClient(true, ensureAuthentication);
            if (!client || !url) return void Logger.info('Unable to perform http post, client unavailable offline.');
            return await client.post<TResponseType>(`${isAbsolute ? '' : apiUrl || Api.currentHostAndScheme}${url}`, data, options);
        } catch (error) {
            return Api.handleHttpError<TResponseType>(error, 'post', url, apiUrl, ignoreError);
        }
    }

    private static handleHttpError<TOriginalReturnType>(
        error: any,
        method: 'get' | 'put' | 'post' | 'delete',
        url: string,
        apiUrl: string | null,
        ignoreError = true
    ) {
        if (error && !error.response && ignoreError) return undefined;
        if (error && !error.response && !ignoreError) throw 'An unknown error occurred.';
        if (error && error.response && error.response.status >= 400 && error.response.status < 500) {
            if (error.response.status === 429) {
                //swallow rate limit errors
            } else if (typeof error?.response?.data?.error === 'string' && error.response.data.error.trim()) {
                new NotifyUserMessage(error.response.data.error);
            }
            return undefined;
        }
        if (error && error.code && error.code === 'ECONNABORTED') return undefined;

        Logger.info(`Error during ${method} to ${url} from ${apiUrl || 'current endpoint'}`, Api.getRequestError(error));

        if (!ignoreError) throw error;

        return (!!error && (error.response as AxiosResponse<TOriginalReturnType>)) || undefined;
    }

    public static async postFile<TFields, TReturnType>(
        apiUrl: string | null,
        url: string,
        fileOrFiles: File | Array<File>,
        formFields?: TFields & { [key: string]: any },
        isAbsolute = false,
        ignoreError = true,
        progressEvent?: (event: ProgressEvent) => void,
        canceler?: { cancel: (msg: string) => void },
        ensureAuthentication = true
    ) {
        try {
            const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];

            const client = await Api.getHttpClient(true, ensureAuthentication);
            if (!client || !url) {
                const err = 'Unable to perform http post, client unavailable offline.';
                Logger.info(err);
                if (ignoreError) {
                    return;
                } else {
                    throw new Error(err);
                }
            }

            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                cancelToken: canceler
                    ? new Axios.CancelToken(function executor(e) {
                          canceler.cancel = e;
                      })
                    : undefined,
                onUploadProgress: progressEvent ? progressEvent : undefined,
                timeout: 600000,
            };

            const formData = new FormData();
            if (formFields) {
                const fields = Object.keys(formFields);
                for (const field of fields) {
                    if (field && formFields[field]) formData.append(field, formFields[field]);
                }
            }
            // Must be appended after or request body will be empty
            for (const file of files) {
                const name = files.indexOf(file) === 0 ? 'file' : `file${files.indexOf(file)}`;
                formData.append(name, file);
            }
            return await client.post<TReturnType>(`${isAbsolute ? '' : apiUrl || Api.currentHostAndScheme}${url}`, formData, options);
        } catch (error) {
            // Throw errors futher up to be handled by ui
            Logger.info(`Error posting file to ${url} from ${apiUrl || 'current endpoint'}`, Api.getRequestError(error));
            throw error;
        }
    }

    public static async put<TResponseType>(apiUrl: string | null, url: string, data: any, isAbsolute = false, ignoreError = true) {
        try {
            const client = await Api.getHttpClient(true, true);
            if (!client || !url) return void Logger.info('Unable to perform http put, client unavailable offline.');
            return await client.put<TResponseType>(`${isAbsolute ? '' : apiUrl || Api.currentHostAndScheme}${url}`, data);
        } catch (error) {
            return Api.handleHttpError<TResponseType>(error, 'put', url, apiUrl, ignoreError);
        }
    }
    static getRequestError(error: any): any {
        const errorDetails: any = {};
        if (typeof error === 'string') {
            errorDetails.error = error;
        }
        if (!error || !Object.keys(error).length) {
            errorDetails.info = 'Unknown error, no details provided at all!';
        } else {
            const response = error && error.response;
            if (response) {
                errorDetails.status = response.status;
                errorDetails.statusText = response.statusText;
                errorDetails.description = response.data;
            } else {
                const request = error && error.request;
                if (request) {
                    delete error.request;
                    errorDetails.request = request;
                }
            }
        }

        //errorDetails.error = error || { error: 'Request failed but no additional error details were returned.', originalRequest };

        return errorDetails;
    }

    public static async delete<TResponseType>(apiUrl: string | null, url: string, isAbsolute = false, ignoreError = false) {
        try {
            const client = await Api.getHttpClient(true, true);
            if (!client || !url) return void Logger.info('Unable to perform http delete, client unavailable offline.');
            return <AxiosResponse<TResponseType>>await client.delete(`${isAbsolute ? '' : apiUrl || Api.apiEndpoint}${url}`);
        } catch (error) {
            Api.handleHttpError<TResponseType>(error, 'delete', url, apiUrl, ignoreError);
        }
    }

    public static async getForecast(lng: number, lat: number): Promise<WeatherDetailsDictionary | undefined> {
        const response = await Api.get<WeatherDetailsDictionary>(Api.apiEndpoint, `/api/weather/${lat}/${lng}`);
        if (response) return response.data;
    }

    public static get cachedAccounts(): Array<AuthorisedUser> | undefined {
        try {
            const key = `${RethinkDbAuthClient.session?.email}-accessible-accounts`;
            const accountsJson = SqueegeeLocalStorage.getItem(key);
            if (!accountsJson) return;
            return JSON.parse(accountsJson);
        } catch (error) {
            Logger.error(`Error getting cached accounts`, error);
        }
    }
    public static async getAccounts(): Promise<Array<AuthorisedUser> | undefined> {
        const response = await Api.get<Array<AuthorisedUser>>(Api.apiEndpoint, '/api/accounts');
        const accounts = response && response.data;
        const key = `${RethinkDbAuthClient.session?.email}-accessible-accounts`;
        if (accounts) SqueegeeLocalStorage.setItem(key, JSON.stringify(accounts));
        else SqueegeeLocalStorage.removeItem(key);
        return accounts;
    }

    public static async getSqueegeeCredits() {
        const response = await Api.get<{ squeegeeCredits: number; nextRefresh: string }>(Api.apiEndpoint, '/api/customer/squeegee-credits');
        const { squeegeeCredits, nextRefresh } = response?.data || { squeegeeCredits: 0, nextRefresh: '' };
        const roundedCredits = Math.round(squeegeeCredits * 100) / 100;
        return { squeegeeCredits: roundedCredits, nextRefresh };
    }

    private static _initialised: Promise<void> | undefined;
    public static get initialised() {
        return Api._initialised;
    }

    public static async init() {
        try {
            await Api.refreshConnectionMetadata();

            window.removeEventListener('online', Api.refreshConnectionMetadataHandler);
            window.addEventListener('online', Api.refreshConnectionMetadataHandler);

            window.removeEventListener('offline', Api.goingOfflineHandler);
            window.addEventListener('offline', Api.goingOfflineHandler);

            Logger.info('Using Api endpoint ' + Api.apiEndpoint);
        } catch (error) {
            Logger.error('Unable to initialise API', error);
            Api._initialised = undefined;
        }
    }
    private static goingOfflineHandler = () => {
        Api.isConnected = false;
        if (GlobalFlags.isDevServer) SqueegeeClientSocketApi.close();
    };

    private static refreshConnectionMetadataHandler = () => Api.refreshConnectionMetadata();

    public static V2: ReturnType<typeof V2>;

    public static get apiEndpoint(): string {
        try {
            const override = ApplicationState.apiEndpointOverride;
            if (override) return override;

            if (Api.isLocal) {
                return `http://localhost:${window?.location?.port || 3000}`;
            }

            const useLiveBaseEndpoint =
                GlobalFlags.isMobileApp || // Mobile should use sqgee.com by default.
                (GlobalFlags.isHttp && (!window.location || !window.location.href)) || // Unknown http should use sqg.ee by default.
                !!window?.location?.href.startsWith('https://sqgee.com') || // Unknown http should use sqg.ee by default.
                !!window?.location?.href.startsWith('https://app.squeeg.ee') ||
                !!window?.location?.href.startsWith('https://api.squeeg.ee');
            if (useLiveBaseEndpoint) return 'https://sqgee.com';

            const isUsingDev = !!window.location && !!window.location.href && window.location.href.startsWith('https://dev.');
            if (isUsingDev) return 'https://dev.squeeg.ee';

            const isUsingStaging = !!window.location && !!window.location.href && window.location.href.startsWith('https://staging.');
            if (isUsingStaging) return 'https://staging.squeeg.ee';

            const apiServerEndpoint = /https:\/\/api(\d\d)\.sqgee\.com/i.exec(window.location?.href || '');
            if (apiServerEndpoint) return `https://api${apiServerEndpoint[1]}.sqgee.com`;

            // All else fails use whatever the user is using.
            const scheme = window.document.location.protocol ? `${window.document.location.protocol}//` : '';
            const host = window.document.location.hostname;
            const port = window.document.location.port ? `:${window.document.location.port}` : '';

            return `${scheme}${host}${port}`;
        } catch {
            return 'https://sqgee.com';
        }
    }

    private static get isLocal() {
        if (GlobalFlags.isMobileApp) return false;

        const hostname = document.location.hostname;
        if (!hostname) return false;

        return hostname.startsWith('localhost');
    }

    public static async overrideApiEndpoint(override?: string) {
        ApplicationState.apiEndpointOverride = override;
        if (override) Api.currentHostAndScheme = override;
        Api.refreshConnectionMetadata();
    }

    public static currentHost = '';
    private static _currentHostAndScheme = '';
    public static get currentHostAndScheme() {
        return Api._currentHostAndScheme;
    }
    public static set currentHostAndScheme(servedBy: string) {
        if (!servedBy) return;
        Api.currentHost = servedBy.replace('http://', '').replace('https://', '');
        Api._currentHostAndScheme = servedBy;
    }

    private static _isProduction?: boolean;
    public static get isProduction() {
        return Api._isProduction;
    }
    public static async ping(timeout = 15000, endpoint?: string): Promise<IPingResponse | undefined> {
        endpoint = endpoint || Api.apiEndpoint;

        try {
            const stopWatch = new Stopwatch('Ping');
            const client = await Api.getHttpClient(true, false);
            if (!client) return;

            const response = await client.get<Pong>(`${endpoint}/api/ping`, { timeout: timeout, validateStatus: () => true });
            stopWatch.stop();

            if (response.status === 401 || response.status === 403) throw ApplicationState.signOut(false);

            const latency = Number(stopWatch.duration()) || 0;
            const pong = response.data ? { latency, versionInfo: response.data } : undefined;

            this._isProduction = response.data && !response.data.isDevServer;

            if (pong) Logger.info('ping complete', pong);

            if (pong?.versionInfo.reviewed) Api.appleReviewed = true;

            return pong;
        } catch (error) {
            Logger.info(`ping failed to ${endpoint}`, error);
        }
    }

    public static appleReviewed = false;
}
