import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { IViewOptions } from '../../IViewOptions';

export class ViewOptionsDialog extends CustomDialog<IViewOptions> {
    constructor(protected viewOptions: IViewOptions, protected isDayView: boolean) {
        super('viewOptionsDialog', '../Schedule/Views/ViewOptionsDialog.html', 'view-options.title', {
            cssClass: 'view-options-dialog',
        });
    }

    protected selectViewOptions() {
        this.ok(this.viewOptions);
    }

    public async getResult() {
        return this.viewOptions;
    }
}
