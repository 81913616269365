import { Box, Button, FormControl, InputLabel, ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DialogPaper, GradientButton } from '@nexdynamic/nex-ui-react';
import type { Team } from '@nexdynamic/squeegee-common';
import { type AccountUser, type JobOccurrence, type TimeEntry } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { UserService } from '../../Users/UserService';
import { TimerService } from '../TimerService';
import type { TimeEntriesPerWorker } from './ManageStoredEventsDialogComponent';

type Props = {
    occurrence: JobOccurrence;
    setCreatingTimeEntry: (value: boolean) => void;
    setEditingTimeEntry?: (value: TimeEntry | undefined) => void;
    timeEntryToEdit?: TimeEntry | undefined;
    setTimeEntries: Dispatch<SetStateAction<TimeEntriesPerWorker>>;
    workersAndTeams: Array<AccountUser | Team>;
};

type NewTimeEntry = {
    startTime: number;
    endTime: number;
    userId?: string;
    userType?: 'accountuser' | 'team';
};

const CreateTimeEntry = ({
    occurrence,
    workersAndTeams,
    setCreatingTimeEntry,
    setEditingTimeEntry,
    timeEntryToEdit,
    setTimeEntries,
}: Props) => {
    const [tempTimeEntry, setTempTimeEntry] = useState<NewTimeEntry>({
        startTime: moment().valueOf(),
        endTime: moment().valueOf(),
        userId: '',
        userType: 'accountuser',
    });

    useEffect(() => {
        if (!timeEntryToEdit) return;

        const assignee = UserService.getUserOrTeam(timeEntryToEdit.assigneeId);
        if (!assignee) throw new Error('Could not find assignee for time entry');

        const userType = assignee.resourceType === 'accountuser' ? 'accountuser' : 'team';

        const newTempTimeEntry: NewTimeEntry = {
            startTime: timeEntryToEdit.start?.eventTimestamp || 0,
            endTime: timeEntryToEdit.stop?.eventTimestamp || 0,
            userId: timeEntryToEdit.assigneeId,
            userType,
        };

        setTempTimeEntry(newTempTimeEntry);
    }, [timeEntryToEdit]);

    const [errors, setErrors] = useState<string[]>([]);

    const handleSaveTimeEntry = async () => {
        const { startTime, endTime, userId, userType } = tempTimeEntry;

        if (startTime && endTime && userId && userType) {
            try {
                if (userType === 'team') {
                    const team = workersAndTeams.find(worker => worker._id === userId) as Team;
                    if (!team) return;
                    const usersInTeam = workersAndTeams.filter(worker => team.members.includes(worker._id));
                    for (const user of usersInTeam) {
                        const duration = moment.duration(moment(endTime).diff(moment(startTime))).toISOString();
                        let timeEntry: TimeEntry | false;
                        if (timeEntryToEdit) {
                            timeEntry = await TimerService.updateTimeEntry(timeEntryToEdit, startTime, endTime, occurrence);
                            if (timeEntry === false) throw new Error('Failed to update time entry');
                        } else {
                            timeEntry = await TimerService.createManualEntryForJobOccurrence(
                                occurrence,
                                user as AccountUser,
                                duration,
                                endTime
                            );
                        }
                        if (timeEntry === false) throw new Error('Failed to create time entry');
                        const newTimeEntry = timeEntry;
                        setTimeEntries(state => {
                            const newState = { ...state };
                            if (!newState[user._id]) newState[user._id] = [];
                            newState[user._id].push(newTimeEntry);
                            return newState;
                        });
                    }
                    return;
                }

                const user = UserService.getUserById(userId);
                if (!user) return;

                const duration = moment.duration(moment(endTime).diff(moment(startTime))).toISOString();

                let timeEntry: TimeEntry | false;
                if (timeEntryToEdit) {
                    timeEntry = await TimerService.updateTimeEntry(timeEntryToEdit, startTime, endTime, occurrence);
                } else {
                    timeEntry = await TimerService.createManualEntryForJobOccurrence(occurrence, user, duration, endTime);
                }

                if (timeEntry === false) throw new Error('Failed to create time entry');
                const newTimeEntry = timeEntry;
                setTimeEntries(state => {
                    const newState = { ...state };
                    if (!newState[userId]) newState[userId] = [];
                    newState[userId].push(newTimeEntry);
                    return newState;
                });

                setCreatingTimeEntry(false);
                setEditingTimeEntry?.(undefined);
            } catch (error) {
                setErrors([error.message]);
            }
        } else {
            const newErrors = [];
            if (!startTime) newErrors.push('Start time is required');
            if (!endTime) newErrors.push('End time is required');
            if (!userId) newErrors.push('User or team is required');
            setErrors(newErrors);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Typography variant="h6">{timeEntryToEdit ? 'Edit Time Entry' : 'Create Time Entry'}</Typography>
                <Button
                    variant="text"
                    onClick={() => {
                        setEditingTimeEntry?.(undefined);
                        setCreatingTimeEntry(false);
                    }}
                >
                    Cancel
                </Button>
            </Box>
            <DialogPaper sx={{ p: 2, gap: 1 }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <DateTimePicker
                        label="Start Date and Time"
                        onChange={(value: number) => {
                            setTempTimeEntry({ ...tempTimeEntry, startTime: moment(value, 'HH:mm').valueOf() });
                        }}
                        value={moment(tempTimeEntry.startTime) as any}
                        disableFuture
                        sx={{ width: '100%' }}
                    />
                    <DateTimePicker
                        label="End Date and Time"
                        onChange={(value: number) => {
                            setTempTimeEntry({ ...tempTimeEntry, endTime: moment(value, 'HH:mm').valueOf() });
                        }}
                        value={moment(tempTimeEntry.endTime) as any}
                        disableFuture
                        sx={{ width: '100%' }}
                    />
                </Box>
                <FormControl fullWidth>
                    <InputLabel id="user-select-label">User or Team</InputLabel>
                    <Select
                        labelId="user-select-label"
                        id="user-select"
                        label="User or Team"
                        onChange={(event: any) => {
                            setTempTimeEntry({
                                ...tempTimeEntry,
                                userId: event.target.value.split('.')[1],
                                userType: event.target.value.split('.')[0],
                            });
                        }}
                        value={tempTimeEntry.userId ? `${tempTimeEntry.userType}.${tempTimeEntry.userId}` : ''}
                    >
                        <ListSubheader>Users</ListSubheader>
                        {workersAndTeams
                            .filter(worker => worker.resourceType === 'accountuser')
                            .map((worker: AccountUser) => (
                                <MenuItem key={worker._id} value={`accountuser.${worker._id}`}>
                                    {worker.name}
                                </MenuItem>
                            ))}
                        <ListSubheader>Teams</ListSubheader>
                        {workersAndTeams
                            .filter(worker => worker.resourceType === 'team')
                            .map((worker: Team) => (
                                <MenuItem key={worker._id} value={`team.${worker._id}`}>
                                    {worker.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                {errors.length ? (
                    <Typography variant="body2" color="error">
                        {errors.map(error => (
                            <span key={error}>{error}</span>
                        ))}
                    </Typography>
                ) : null}
                <GradientButton variant="contained" color="primary" onClick={handleSaveTimeEntry} disableElevation fullWidth>
                    {!timeEntryToEdit ? `Add Time Entry` : `Save Time Entry`}
                </GradientButton>
            </DialogPaper>
        </LocalizationProvider>
    );
};

export default CreateTimeEntry;
