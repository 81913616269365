import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Select } from '../Dialogs/Select';


export const getChargeType = async () => {
    const chargeOptions: Array<{ text: TranslationKey; value: 'none' | 'fixed' | 'percentage'; }> = [
        { text: ApplicationState.localise('settings.skip-reason-no-charge'), value: 'none' },
        { text: ApplicationState.localise('settings.skip-reason-fixed-charge'), value: 'fixed' },
        { text: ApplicationState.localise('settings.skip-reason-percentage-charge'), value: 'percentage' },
    ];
    const chargeSelect = new Select('settings.skip-reason-charge-type', chargeOptions, 'text', 'value');
    const chargeType = await chargeSelect.show();
    if (chargeSelect.cancelled)
        return;

    return chargeType.value;
};
