import type { SkipCharge, SkipReason } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { t } from "../t";

export const getReasonText = (reason: SkipReason) => {
    if (!ApplicationState.hasUltimateOrAbove) return reason.name;

    if (reason?.skipCharge) {
        const chargeText = !reason.skipCharge
            ? t('settings.skip-reason-charge-none-amount-info')
            : reason.skipCharge.type === 'fixed'
                ? t('settings.skip-reason-charge-fixed-amount-info', { amount: `${ApplicationState.currencySymbol()}${reason.skipCharge.amount.toFixed(2)}` })
                : t('settings.skip-reason-charge-percentage-amount-info', { amount: `${reason.skipCharge.amount.toFixed(0)}%` });

        return `${reason.name} (${chargeText})`;
    }

    const charge = ApplicationState.getSetting<SkipCharge>('global.skip-charge-default', undefined);
    const chargeText = !charge
        ? t('settings.skip-reason-charge-none-amount-info')
        : charge.type === 'fixed'
            ? t('settings.skip-reason-charge-fixed-amount-info-default', { amount: `${ApplicationState.currencySymbol()}${charge.amount.toFixed(2)}` })
            : t('settings.skip-reason-charge-percentage-amount-info-default', { amount: `${charge.amount.toFixed(0)}%` });

    return `${reason.name} (${chargeText})`;
};
