import { Utilities } from '../Utilities';

export class Words {
    public static streetTypes = ['Close', 'Cresent', 'Drive', 'Lane', 'Point', 'Square', 'Street', 'Way'];

    public static towns = [
        'Barking',
        'Bexley',
        'Blackburn',
        'Blackpool',
        'Bolton',
        'Bournemouth',
        'Brighton',
        'Cambridge',
        'Camden',
        'Chesterfield',
        'Colchester',
        'Crawley',
        'Dudley',
        'Eastbourne',
        'Exeter',
        'Gloucester',
        'Greenwich',
        'Hackney',
        'Hammersmith',
        'Haringey',
        'Harrow',
        'Havering',
        'Hounslow',
        'Huddersfield',
        'Ipswich',
        'Islington',
        'Kensington',
        'Kingston',
        'Luton',
        'Merton',
        'Middlesbrough',
        'Newcastle',
        'Northampton',
        'Norwich',
        'Oldbury',
        'Oldham',
        'Oxford',
        'Peterborough',
        'Poole',
        'Portsmouth',
        'Preston',
        'Richmond',
        'Rotherham',
        'Slough',
        'Southend',
        'St',
        'Stockport',
        'Sunderland',
        'Sutton',
        'Sutton',
        'Swindon',
        'Tower',
        'Walsall',
        'Waltham',
        'Watford',
        'West',
        'Westminster',
        'Woking',
        'York',
    ];

    public static streetNames = [
        'Arrow',
        'Back',
        'Bail',
        'Bald',
        'Bank',
        'Bare',
        'Battle',
        'Blade',
        'Block',
        'Blood',
        'Bloom',
        'Board',
        'Bolt',
        'Bow',
        'Box',
        'Bread',
        'Break',
        'Brush',
        'Cake',
        'Camp',
        'Castle',
        'Chant',
        'Charge',
        'Chip',
        'Cloud',
        'Coach',
        'Cook',
        'Cover',
        'Crate',
        'Dam',
        'Dive',
        'Dock',
        'Dream',
        'Dress',
        'Duck',
        'Echo',
        'Fire',
        'Flash',
        'Flood',
        'Flower',
        'Foot',
        'Garden',
        'Guard',
        'Hammer',
        'Hope',
        'Iron',
        'Jail',
        'Judge',
        'Light',
        'Link',
        'Loan',
        'Lock',
        'Love',
        'Pack',
        'Paddle',
        'Park',
        'Pine',
        'Pump',
        'Rain',
        'Rock',
        'Sand',
        'Smoke',
        'Star',
        'Storm',
        'Tack',
        'Train',
        'Water',
        'Whip',
        'Whistle',
        'Wood',
        'Work',
    ];

    public static surnames = [
        'Andrews',
        'Appleyard',
        'Barrett',
        'Bewley',
        'Billington',
        'Bishop',
        'Bloor',
        'Brankin',
        'Bruce',
        'Bueller',
        'Cathcart',
        'Croft',
        'Cross',
        'Davies',
        'Drummond',
        'Dutton',
        'Feeney',
        'Fox',
        'Gittings',
        'Greenhouse',
        'Harding',
        'Harris',
        'Heron',
        'Hicks',
        'Hodgekinson',
        'Holmer',
        'Hoochen',
        'Jackson',
        'Jennings',
        'Johnson',
        'Jones',
        'Keily',
        'King',
        'Laithwaite',
        'Lewis',
        'Morris',
        'Murphy',
        'Patterson',
        'Peters',
        'Peterson',
        'Poiner',
        'Price',
        'Prior',
        'Pritchard',
        'Richards',
        'Roberts',
        'Sherratt',
        'Singleton',
        'Slater',
        'Smith',
        'Speers',
        'Stevens',
        'Walker',
        'Wilkins',
        'Wilkinson',
        'Woodrow',
        'Wright',
    ];

    public static getWeightedRandomForename() {
        const max = Words.forenameMap[Words.forenameMap.length - 1].weightMap;
        const mapIndex = Utilities.randomInteger(0, max);
        const selection = Words.forenameMap.find(x => x.weightMap >= mapIndex) || this.forenameMap[0];
        return selection.name;
    }

    public static forenameMap = [
        { name: 'Olivia', weightMap: 444 },
        { name: 'Emily', weightMap: 867 },
        { name: 'Jack', weightMap: 1289 },
        { name: 'Isla', weightMap: 1672 },
        { name: 'Sophie', weightMap: 2003 },
        { name: 'Oliver', weightMap: 2333 },
        { name: 'James', weightMap: 2656 },
        { name: 'Amelia', weightMap: 2968 },
        { name: 'Logan', weightMap: 3275 },
        { name: 'Leo', weightMap: 3575 },
        { name: 'Lewis', weightMap: 3873 },
        { name: 'Ella', weightMap: 4168 },
        { name: 'Alexander', weightMap: 4462 },
        { name: 'Harris', weightMap: 4756 },
        { name: 'Ava', weightMap: 5048 },
        { name: 'Grace', weightMap: 5334 },
        { name: 'Noah', weightMap: 5618 },
        { name: 'Rory', weightMap: 5898 },
        { name: 'Aria', weightMap: 6170 },
        { name: 'Charlie', weightMap: 6432 },
        { name: 'Harry', weightMap: 6694 },
        { name: 'Jessica', weightMap: 6956 },
        { name: 'Lucas', weightMap: 7217 },
        { name: 'Jacob', weightMap: 7468 },
        { name: 'Thomas', weightMap: 7719 },
        { name: 'Alfie', weightMap: 7968 },
        { name: 'Charlotte', weightMap: 8214 },
        { name: 'Archie', weightMap: 8457 },
        { name: 'Sophia', weightMap: 8684 },
        { name: 'Freya', weightMap: 8910 },
        { name: 'Lucy', weightMap: 9131 },
        { name: 'Max', weightMap: 9350 },
        { name: 'Lily', weightMap: 9569 },
        { name: 'Finlay', weightMap: 9787 },
        { name: 'Evie', weightMap: 9999 },
        { name: 'Finn', weightMap: 10207 },
        { name: 'Adam', weightMap: 10408 },
        { name: 'Daniel', weightMap: 10609 },
        { name: 'Ethan', weightMap: 10805 },
        { name: 'Mason', weightMap: 10998 },
        { name: 'Brodie', weightMap: 11190 },
        { name: 'Harper', weightMap: 11381 },
        { name: 'Ellie', weightMap: 11570 },
        { name: 'Joshua', weightMap: 11753 },
        { name: 'Eilidh', weightMap: 11932 },
        { name: 'Theo', weightMap: 12109 },
        { name: 'Cameron', weightMap: 12284 },
        { name: 'Oscar', weightMap: 12458 },
        { name: 'Mia', weightMap: 12632 },
        { name: 'Millie', weightMap: 12796 },
        { name: 'Hunter', weightMap: 12957 },
        { name: 'Aaron', weightMap: 13114 },
        { name: 'Callum', weightMap: 13270 },
        { name: 'Anna', weightMap: 13426 },
        { name: 'William', weightMap: 13579 },
        { name: 'Emma', weightMap: 13731 },
        { name: 'Ruby', weightMap: 13883 },
        { name: 'Liam', weightMap: 14034 },
        { name: 'Matthew', weightMap: 14185 },
        { name: 'Maisie', weightMap: 14336 },
        { name: 'Jaxon', weightMap: 14486 },
        { name: 'Rosie', weightMap: 14636 },
        { name: 'Georgia', weightMap: 14784 },
        { name: 'Harrison', weightMap: 14930 },
        { name: 'Freddie', weightMap: 15075 },
        { name: 'Ollie', weightMap: 15220 },
        { name: 'Eva', weightMap: 15365 },
        { name: 'Jamie', weightMap: 15509 },
        { name: 'Chloe', weightMap: 15652 },
        { name: 'Caleb', weightMap: 15790 },
        { name: 'Poppy', weightMap: 15928 },
        { name: 'Orla', weightMap: 16064 },
        { name: 'Nathan', weightMap: 16199 },
        { name: 'Luke', weightMap: 16333 },
        { name: 'Muhammad', weightMap: 16466 },
        { name: 'Holly', weightMap: 16593 },
        { name: 'Jude', weightMap: 16718 },
        { name: 'Hannah', weightMap: 16843 },
        { name: 'Erin', weightMap: 16967 },
        { name: 'Willow', weightMap: 17091 },
        { name: 'Arthur', weightMap: 17214 },
        { name: 'Ivy', weightMap: 17335 },
        { name: 'Mila', weightMap: 17454 },
        { name: 'Arran', weightMap: 17572 },
        { name: 'Esme', weightMap: 17690 },
        { name: 'Layla', weightMap: 17808 },
        { name: 'Luca', weightMap: 17925 },
        { name: 'Sofia', weightMap: 18042 },
        { name: 'Angus', weightMap: 18158 },
        { name: 'Isabella', weightMap: 18272 },
        { name: 'Carter', weightMap: 18385 },
        { name: 'Connor', weightMap: 18498 },
        { name: 'Dylan', weightMap: 18611 },
        { name: 'George', weightMap: 18721 },
        { name: 'Blake', weightMap: 18830 },
        { name: 'Robert', weightMap: 18939 },
        { name: 'Robyn', weightMap: 19048 },
        { name: 'Sienna', weightMap: 19157 },
        { name: 'Skye', weightMap: 19265 },
        { name: 'Zara', weightMap: 19373 },
        { name: 'Arlo', weightMap: 19480 },
        { name: 'Samuel', weightMap: 19587 },
        { name: 'Hamish', weightMap: 19693 },
        { name: 'Alice', weightMap: 19799 },
        { name: 'Jackson', weightMap: 19904 },
        { name: 'Cole', weightMap: 20008 },
        { name: 'Ryan', weightMap: 20112 },
        { name: 'Maya', weightMap: 20216 },
        { name: 'Andrew', weightMap: 20319 },
        { name: 'David', weightMap: 20421 },
        { name: 'Emilia', weightMap: 20523 },
        { name: 'Daisy', weightMap: 20624 },
        { name: 'Fraser', weightMap: 20724 },
        { name: 'Joseph', weightMap: 20824 },
        { name: 'Kai', weightMap: 20924 },
        { name: 'Isaac', weightMap: 21023 },
        { name: 'Michael', weightMap: 21120 },
        { name: 'Reuben', weightMap: 21216 },
        { name: 'Leon', weightMap: 21311 },
        { name: 'Katie', weightMap: 21406 },
        { name: 'Aidan', weightMap: 21500 },
        { name: 'Benjamin', weightMap: 21594 },
        { name: 'Niamh', weightMap: 21688 },
        { name: 'Blair', weightMap: 21781 },
        { name: 'Amber', weightMap: 21873 },
        { name: 'Riley', weightMap: 21964 },
        { name: 'Rose', weightMap: 22053 },
        { name: 'Ben', weightMap: 22141 },
        { name: 'Molly', weightMap: 22227 },
        { name: 'John', weightMap: 22312 },
        { name: 'Zoe', weightMap: 22396 },
        { name: 'Abigail', weightMap: 22479 },
        { name: 'Iona', weightMap: 22562 },
        { name: 'Josh', weightMap: 22644 },
        { name: 'Murray', weightMap: 22726 },
        { name: 'Sam', weightMap: 22806 },
        { name: 'Leah', weightMap: 22884 },
        { name: 'Hallie', weightMap: 22961 },
        { name: 'Thea', weightMap: 23038 },
        { name: 'Grayson', weightMap: 23113 },
        { name: 'Cooper', weightMap: 23187 },
        { name: 'Gracie', weightMap: 23261 },
        { name: 'Lilly', weightMap: 23335 },
        { name: 'Callan', weightMap: 23407 },
        { name: 'Elijah', weightMap: 23479 },
        { name: 'Jake', weightMap: 23551 },
        { name: 'Hollie', weightMap: 23623 },
        { name: 'Imogen', weightMap: 23694 },
        { name: 'Alex', weightMap: 23764 },
        { name: 'Innes', weightMap: 23834 },
        { name: 'Louis', weightMap: 23904 },
        { name: 'Ruaridh', weightMap: 23974 },
        { name: 'Cara', weightMap: 24043 },
        { name: 'Owen', weightMap: 24111 },
        { name: 'Tommy', weightMap: 24179 },
        { name: 'Tyler', weightMap: 24247 },
        { name: 'Ayla', weightMap: 24315 },
        { name: 'Henry', weightMap: 24382 },
        { name: 'Cora', weightMap: 24449 },
        { name: 'Elliot', weightMap: 24514 },
        { name: 'Lyle', weightMap: 24579 },
        { name: 'Jayden', weightMap: 24643 },
        { name: 'Kayden', weightMap: 24707 },
        { name: 'Elsie', weightMap: 24770 },
        { name: 'Aiden', weightMap: 24832 },
        { name: 'Frankie', weightMap: 24894 },
        { name: 'Gabriel', weightMap: 24956 },
        { name: 'Bella', weightMap: 25018 },
        { name: 'Phoebe', weightMap: 25080 },
        { name: 'Jax', weightMap: 25141 },
        { name: 'Carson', weightMap: 25201 },
        { name: 'Robbie', weightMap: 25261 },
        { name: 'Summer', weightMap: 25321 },
        { name: 'Sebastian', weightMap: 25380 },
        { name: 'Ada', weightMap: 25439 },
        { name: 'Bonnie', weightMap: 25498 },
        { name: 'Julia', weightMap: 25557 },
        { name: 'Euan', weightMap: 25615 },
        { name: 'Sonny', weightMap: 25673 },
        { name: 'Amelie', weightMap: 25731 },
        { name: 'Brooke', weightMap: 25789 },
        { name: 'Calvin', weightMap: 25846 },
        { name: 'Ross', weightMap: 25903 },
        { name: 'Zac', weightMap: 25960 },
        { name: 'Matilda', weightMap: 26017 },
        { name: 'Austin', weightMap: 26073 },
        { name: 'Kyle', weightMap: 26129 },
        { name: 'Louie', weightMap: 26185 },
        { name: 'Callie', weightMap: 26241 },
        { name: 'Maria', weightMap: 26297 },
        { name: 'Piper', weightMap: 26353 },
        { name: 'Cody', weightMap: 26408 },
        { name: 'Darcy', weightMap: 26463 },
        { name: 'Brody', weightMap: 26517 },
        { name: 'Ellis', weightMap: 26571 },
        { name: 'Struan', weightMap: 26625 },
        { name: 'Luna', weightMap: 26679 },
        { name: 'Calum', weightMap: 26732 },
        { name: 'Lola', weightMap: 26785 },
        { name: 'Teddy', weightMap: 26837 },
        { name: 'Aila', weightMap: 26889 },
        { name: 'Lexi', weightMap: 26941 },
        { name: 'Lochlan', weightMap: 26992 },
        { name: 'Rebecca', weightMap: 27043 },
        { name: 'Conor', weightMap: 27093 },
        { name: 'Parker', weightMap: 27143 },
        { name: 'Hope', weightMap: 27193 },
        { name: 'Penelope', weightMap: 27243 },
        { name: 'Lachlan', weightMap: 27292 },
        { name: 'Mohammed', weightMap: 27341 },
        { name: 'Patrick', weightMap: 27390 },
        { name: 'Rhys', weightMap: 27439 },
        { name: 'Ruairidh', weightMap: 27488 },
        { name: 'Kayla', weightMap: 27537 },
        { name: 'Rowan', weightMap: 27586 },
        { name: 'Evan', weightMap: 27634 },
        { name: 'Kian', weightMap: 27682 },
        { name: 'Magnus', weightMap: 27730 },
        { name: 'Roman', weightMap: 27778 },
        { name: 'Clara', weightMap: 27826 },
        { name: 'Georgie', weightMap: 27874 },
        { name: 'Lena', weightMap: 27922 },
        { name: 'Sadie', weightMap: 27970 },
        { name: 'Hugo', weightMap: 28017 },
        { name: 'Scott', weightMap: 28064 },
        { name: 'Theodore', weightMap: 28111 },
        { name: 'Zachary', weightMap: 28158 },
        { name: 'Aurora', weightMap: 28205 },
        { name: 'Eden', weightMap: 28252 },
        { name: 'Florence', weightMap: 28299 },
        { name: 'Nina', weightMap: 28346 },
        { name: 'Quinn', weightMap: 28393 },
        { name: 'Violet', weightMap: 28440 },
        { name: 'Edward', weightMap: 28486 },
        { name: 'Marcus', weightMap: 28532 },
        { name: 'Myles', weightMap: 28578 },
        { name: 'Amy', weightMap: 28624 },
        { name: 'Eve', weightMap: 28670 },
        { name: 'Scarlett', weightMap: 28716 },
        { name: 'Harvey', weightMap: 28761 },
        { name: 'Joey', weightMap: 28806 },
        { name: 'Alba', weightMap: 28851 },
        { name: 'Ariana', weightMap: 28896 },
        { name: 'Elizabeth', weightMap: 28941 },
        { name: 'Mya', weightMap: 28986 },
        { name: 'Myla', weightMap: 29031 },
        { name: 'Charles', weightMap: 29075 },
        { name: 'Corey', weightMap: 29119 },
        { name: 'Zach', weightMap: 29163 },
        { name: 'Evelyn', weightMap: 29207 },
        { name: 'Harley', weightMap: 29251 },
        { name: 'Eli', weightMap: 29294 },
        { name: 'Zack', weightMap: 29337 },
        { name: 'Penny', weightMap: 29380 },
        { name: 'Sarah', weightMap: 29423 },
        { name: 'Anthony', weightMap: 29465 },
        { name: 'Jaxson', weightMap: 29507 },
        { name: 'Darcie', weightMap: 29549 },
        { name: 'Heidi', weightMap: 29591 },
        { name: 'Nova', weightMap: 29633 },
        { name: 'Fergus', weightMap: 29674 },
        { name: 'Kieran', weightMap: 29715 },
        { name: 'Abbie', weightMap: 29756 },
        { name: 'Annie', weightMap: 29797 },
        { name: 'Hanna', weightMap: 29837 },
        { name: 'Madison', weightMap: 29877 },
        { name: 'Cillian', weightMap: 29916 },
        { name: 'Zak', weightMap: 29955 },
        { name: 'Flora', weightMap: 29994 },
        { name: 'Lyla', weightMap: 30033 },
        { name: 'Mirren', weightMap: 30072 },
        { name: 'Paige', weightMap: 30111 },
        { name: 'Christopher', weightMap: 30149 },
        { name: 'Levi', weightMap: 30187 },
        { name: 'Shay', weightMap: 30225 },
        { name: 'Halle', weightMap: 30263 },
        { name: 'Megan', weightMap: 30301 },
        { name: 'Ailsa', weightMap: 30338 },
        { name: 'Annabelle', weightMap: 30375 },
        { name: 'Aoife', weightMap: 30412 },
        { name: 'Arya', weightMap: 30449 },
        { name: 'Elena', weightMap: 30486 },
        { name: 'Iris', weightMap: 30523 },
        { name: 'Keira', weightMap: 30560 },
        { name: 'Lottie', weightMap: 30597 },
        { name: 'Miller', weightMap: 30633 },
        { name: 'Peter', weightMap: 30669 },
        { name: 'Toby', weightMap: 30705 },
        { name: 'Faith', weightMap: 30741 },
        { name: 'Harriet', weightMap: 30777 },
        { name: 'Lara', weightMap: 30813 },
        { name: 'Rachel', weightMap: 30849 },
        { name: 'Rosa', weightMap: 30885 },
        { name: 'Dominic', weightMap: 30920 },
        { name: 'Rowan', weightMap: 30955 },
        { name: 'Sean', weightMap: 30990 },
        { name: 'Lucia', weightMap: 31025 },
        { name: 'Finley', weightMap: 31059 },
        { name: 'Gregor', weightMap: 31093 },
        { name: 'Harley', weightMap: 31127 },
        { name: 'Beth', weightMap: 31161 },
        { name: 'Gabriella', weightMap: 31195 },
        { name: 'Lacey', weightMap: 31229 },
        { name: 'Alan', weightMap: 31262 },
        { name: 'Bradley', weightMap: 31295 },
        { name: 'Cian', weightMap: 31328 },
        { name: 'Ezra', weightMap: 31361 },
        { name: 'Flynn', weightMap: 31394 },
        { name: 'Ruben', weightMap: 31427 },
        { name: 'Alexandra', weightMap: 31460 },
        { name: 'Alyssa', weightMap: 31493 },
        { name: 'April', weightMap: 31526 },
        { name: 'Arianna', weightMap: 31559 },
        { name: 'Eleanor', weightMap: 31592 },
        { name: 'Laura', weightMap: 31625 },
        { name: 'Riley', weightMap: 31658 },
        { name: 'Savannah', weightMap: 31691 },
        { name: 'Felix', weightMap: 31723 },
        { name: 'Jay', weightMap: 31755 },
        { name: 'Kaiden', weightMap: 31787 },
        { name: 'Reggie', weightMap: 31819 },
        { name: 'Elise', weightMap: 31851 },
        { name: 'Louisa', weightMap: 31883 },
        { name: 'Mollie', weightMap: 31915 },
        { name: 'Victoria', weightMap: 31947 },
        { name: 'Ciaran', weightMap: 31978 },
        { name: 'Quinn', weightMap: 32009 },
        { name: 'Zander', weightMap: 32040 },
        { name: 'Eliza', weightMap: 32071 },
        { name: 'Belle', weightMap: 32101 },
        { name: 'Indie', weightMap: 32131 },
        { name: 'Maddison', weightMap: 32161 },
        { name: 'Sara', weightMap: 32191 },
        { name: 'Alasdair', weightMap: 32220 },
        { name: 'Antoni', weightMap: 32249 },
        { name: 'Hayden', weightMap: 32278 },
        { name: 'Hudson', weightMap: 32307 },
        { name: 'Robin', weightMap: 32336 },
        { name: 'Liliana', weightMap: 32365 },
        { name: 'Zofia', weightMap: 32394 },
        { name: 'Albie', weightMap: 32422 },
        { name: 'Jan', weightMap: 32450 },
        { name: 'Mohammad', weightMap: 32478 },
        { name: 'Olly', weightMap: 32506 },
        { name: 'Ronan', weightMap: 32534 },
        { name: 'Autumn', weightMap: 32562 },
        { name: 'Ayda', weightMap: 32590 },
        { name: 'Darcey', weightMap: 32618 },
        { name: 'Jasmine', weightMap: 32646 },
        { name: 'Lucie', weightMap: 32674 },
        { name: 'Elliott', weightMap: 32701 },
        { name: 'Oskar', weightMap: 32728 },
        { name: 'Harlow', weightMap: 32755 },
        { name: 'Lillie', weightMap: 32782 },
        { name: 'Natalia', weightMap: 32809 },
        { name: 'Neve', weightMap: 32836 },
        { name: 'Remi', weightMap: 32863 },
        { name: 'Filip', weightMap: 32889 },
        { name: 'Jakub', weightMap: 32915 },
        { name: 'Jordan', weightMap: 32941 },
        { name: 'Mark', weightMap: 32967 },
        { name: 'Nicholas', weightMap: 32993 },
        { name: 'Paul', weightMap: 33019 },
        { name: 'Alexis', weightMap: 33045 },
        { name: 'Blake', weightMap: 33071 },
        { name: 'Lois', weightMap: 33097 },
        { name: 'Martha', weightMap: 33123 },
        { name: 'Norah', weightMap: 33149 },
        { name: 'Tilly', weightMap: 33175 },
        { name: 'Jason', weightMap: 33200 },
        { name: 'Oran', weightMap: 33225 },
        { name: 'Seth', weightMap: 33250 },
        { name: 'Elodie', weightMap: 33275 },
        { name: 'Marnie', weightMap: 33300 },
        { name: 'Naomi', weightMap: 33325 },
        { name: 'Saoirse', weightMap: 33350 },
        { name: 'Cohen', weightMap: 33374 },
        { name: 'Ibrahim', weightMap: 33398 },
        { name: 'Jasper', weightMap: 33422 },
        { name: 'Jenson', weightMap: 33446 },
        { name: 'Joel', weightMap: 33470 },
        { name: 'Junior', weightMap: 33494 },
        { name: 'Alex', weightMap: 33518 },
        { name: 'Caitlin', weightMap: 33542 },
        { name: 'Cali', weightMap: 33566 },
        { name: 'Charley', weightMap: 33590 },
        { name: 'Eloise', weightMap: 33614 },
        { name: 'Emilie', weightMap: 33638 },
        { name: 'Frankie', weightMap: 33662 },
        { name: 'Isobel', weightMap: 33686 },
        { name: 'Maggie', weightMap: 33710 },
        { name: 'Maja', weightMap: 33734 },
        { name: 'Zoey', weightMap: 33758 },
        { name: 'Bailey', weightMap: 33781 },
        { name: 'Craig', weightMap: 33804 },
        { name: 'Duncan', weightMap: 33827 },
        { name: 'Lennon', weightMap: 33850 },
        { name: 'Nathaniel', weightMap: 33873 },
        { name: 'Rocco', weightMap: 33896 },
        { name: 'Aaliyah', weightMap: 33919 },
        { name: 'Arabella', weightMap: 33942 },
        { name: 'Ariella', weightMap: 33965 },
        { name: 'Bethany', weightMap: 33988 },
        { name: 'Catherine', weightMap: 34011 },
        { name: 'Edith', weightMap: 34034 },
        { name: 'Lauren', weightMap: 34057 },
        { name: 'Archer', weightMap: 34079 },
        { name: 'Callen', weightMap: 34101 },
        { name: 'Colton', weightMap: 34123 },
        { name: 'Declan', weightMap: 34145 },
        { name: 'Findlay', weightMap: 34167 },
        { name: 'Frederick', weightMap: 34189 },
        { name: 'Kacper', weightMap: 34211 },
        { name: 'Kaleb', weightMap: 34233 },
        { name: 'Steven', weightMap: 34255 },
        { name: 'Francesca', weightMap: 34277 },
        { name: 'Jessie', weightMap: 34299 },
        { name: 'Jorgie', weightMap: 34321 },
        { name: 'Lexie', weightMap: 34343 },
        { name: 'Orlagh', weightMap: 34365 },
        { name: 'Peyton', weightMap: 34387 },
        { name: 'Pippa', weightMap: 34409 },
        { name: 'Zuzanna', weightMap: 34431 },
        { name: 'Ashton', weightMap: 34452 },
        { name: 'Kevin', weightMap: 34473 },
        { name: 'Nico', weightMap: 34494 },
        { name: 'Reece', weightMap: 34515 },
        { name: 'Stephen', weightMap: 34536 },
        { name: 'Tom', weightMap: 34557 },
        { name: 'Travis', weightMap: 34578 },
        { name: 'Vincent', weightMap: 34599 },
        { name: 'Carly', weightMap: 34620 },
        { name: 'Maci', weightMap: 34641 },
        { name: 'Macie', weightMap: 34662 },
        { name: 'Reeva', weightMap: 34683 },
        { name: 'Rhea', weightMap: 34704 },
        { name: 'Bobby', weightMap: 34724 },
        { name: 'Caelan', weightMap: 34744 },
        { name: 'Joe', weightMap: 34764 },
        { name: 'Lennox', weightMap: 34784 },
        { name: 'Niall', weightMap: 34804 },
        { name: 'Rio', weightMap: 34824 },
        { name: 'Zayn', weightMap: 34844 },
        { name: 'Anya', weightMap: 34864 },
        { name: 'Caoimhe', weightMap: 34884 },
        { name: 'Connie', weightMap: 34904 },
        { name: 'Fern', weightMap: 34924 },
        { name: 'Macy', weightMap: 34944 },
        { name: 'Maia', weightMap: 34964 },
        { name: 'Skylar', weightMap: 34984 },
        { name: 'Danny', weightMap: 35003 },
        { name: 'Dean', weightMap: 35022 },
        { name: 'Francis', weightMap: 35041 },
        { name: 'Jonah', weightMap: 35060 },
        { name: 'Keir', weightMap: 35079 },
        { name: 'Milo', weightMap: 35098 },
        { name: 'Muhammed', weightMap: 35117 },
        { name: 'Odin', weightMap: 35136 },
        { name: 'Billie', weightMap: 35155 },
        { name: 'Ellis', weightMap: 35174 },
        { name: 'Fearne', weightMap: 35193 },
        { name: 'Libby', weightMap: 35212 },
        { name: 'Orlaith', weightMap: 35231 },
        { name: 'Caiden', weightMap: 35249 },
        { name: 'Campbell', weightMap: 35267 },
        { name: 'Eric', weightMap: 35285 },
        { name: 'Leighton', weightMap: 35303 },
        { name: 'River', weightMap: 35321 },
        { name: 'Ruairi', weightMap: 35339 },
        { name: 'Spencer', weightMap: 35357 },
        { name: 'Addison', weightMap: 35375 },
        { name: 'Anastasia', weightMap: 35393 },
        { name: 'Charlie', weightMap: 35411 },
        { name: 'Ella-Rose', weightMap: 35429 },
        { name: 'Elle', weightMap: 35447 },
        { name: 'Hazel', weightMap: 35465 },
        { name: 'Nora', weightMap: 35483 },
        { name: 'Stella', weightMap: 35501 },
        { name: 'Taylor', weightMap: 35519 },
        { name: 'Alistair', weightMap: 35536 },
        { name: 'Jensen', weightMap: 35553 },
        { name: 'Layton', weightMap: 35570 },
        { name: 'Maxwell', weightMap: 35587 },
        { name: 'Xander', weightMap: 35604 },
        { name: 'Yusuf', weightMap: 35621 },
        { name: 'Amaya', weightMap: 35638 },
        { name: 'Ciara', weightMap: 35655 },
        { name: 'Dakota', weightMap: 35672 },
        { name: 'Darci', weightMap: 35689 },
        { name: 'Fatima', weightMap: 35706 },
        { name: 'Kara', weightMap: 35723 },
        { name: 'Nadia', weightMap: 35740 },
        { name: 'Nieve', weightMap: 35757 },
        { name: 'Noor', weightMap: 35774 },
        { name: 'Nylah', weightMap: 35791 },
        { name: 'Albert', weightMap: 35807 },
        { name: 'Archibald', weightMap: 35823 },
        { name: 'Billy', weightMap: 35839 },
        { name: 'Colby', weightMap: 35855 },
        { name: 'Cruz', weightMap: 35871 },
        { name: 'Franciszek', weightMap: 35887 },
        { name: 'Haris', weightMap: 35903 },
        { name: 'Lincoln', weightMap: 35919 },
        { name: 'Odhran', weightMap: 35935 },
        { name: 'Ralph', weightMap: 35951 },
        { name: 'Taylor', weightMap: 35967 },
        { name: 'Astrid', weightMap: 35983 },
        { name: 'Caitlyn', weightMap: 35999 },
        { name: 'Erica', weightMap: 36015 },
        { name: 'Hayley', weightMap: 36031 },
        { name: 'Kacey', weightMap: 36047 },
        { name: 'Khloe', weightMap: 36063 },
        { name: 'Lyra', weightMap: 36079 },
        { name: 'Maisy', weightMap: 36095 },
        { name: 'Mara', weightMap: 36111 },
        { name: 'Oliwia', weightMap: 36127 },
        { name: 'Zahra', weightMap: 36143 },
        { name: 'Ali', weightMap: 36158 },
        { name: 'Conall', weightMap: 36173 },
        { name: 'Ewan', weightMap: 36188 },
        { name: 'Fletcher', weightMap: 36203 },
        { name: 'Hector', weightMap: 36218 },
        { name: 'Ian', weightMap: 36233 },
        { name: 'Killian', weightMap: 36248 },
        { name: 'Lauchlan', weightMap: 36263 },
        { name: 'Mack', weightMap: 36278 },
        { name: 'Maksymilian', weightMap: 36293 },
        { name: 'Malcolm', weightMap: 36308 },
        { name: 'Aimee', weightMap: 36323 },
        { name: 'Ana', weightMap: 36338 },
        { name: 'Annabel', weightMap: 36353 },
        { name: 'Ariah', weightMap: 36368 },
        { name: 'Casey', weightMap: 36383 },
        { name: 'Elspeth', weightMap: 36398 },
        { name: 'Faye', weightMap: 36413 },
        { name: 'Freyja', weightMap: 36428 },
        { name: 'India', weightMap: 36443 },
        { name: 'Isabelle', weightMap: 36458 },
        { name: 'Kali', weightMap: 36473 },
        { name: 'Kate', weightMap: 36488 },
        { name: 'Lana', weightMap: 36503 },
        { name: 'Lydia', weightMap: 36518 },
        { name: 'Macey', weightMap: 36533 },
        { name: 'Maeve', weightMap: 36548 },
        { name: 'Melissa', weightMap: 36563 },
        { name: 'Payton', weightMap: 36578 },
        { name: 'Skyla', weightMap: 36593 },
        { name: 'Valentina', weightMap: 36608 },
        { name: 'Abdullah', weightMap: 36622 },
        { name: 'Bodhi', weightMap: 36636 },
        { name: 'Casey', weightMap: 36650 },
        { name: 'Cayden', weightMap: 36664 },
        { name: 'Drew', weightMap: 36678 },
        { name: 'Lukas', weightMap: 36692 },
        { name: 'Lyall', weightMap: 36706 },
        { name: 'Murdo', weightMap: 36720 },
        { name: 'Noel', weightMap: 36734 },
        { name: 'Ronnie', weightMap: 36748 },
        { name: 'Rudi', weightMap: 36762 },
        { name: 'Stanley', weightMap: 36776 },
        { name: 'Tristan', weightMap: 36790 },
        { name: 'Alicja', weightMap: 36804 },
        { name: 'Alisha', weightMap: 36818 },
        { name: 'Ayesha', weightMap: 36832 },
        { name: 'Brooklyn', weightMap: 36846 },
        { name: 'Carmen', weightMap: 36860 },
        { name: 'Laila', weightMap: 36874 },
        { name: 'Mabel', weightMap: 36888 },
        { name: 'Mary', weightMap: 36902 },
        { name: 'Morven', weightMap: 36916 },
        { name: 'Nicole', weightMap: 36930 },
        { name: 'Talia', weightMap: 36944 },
        { name: 'Abel', weightMap: 36957 },
        { name: 'Ayaan', weightMap: 36970 },
        { name: 'Brandon', weightMap: 36983 },
        { name: 'Darragh', weightMap: 36996 },
        { name: 'Dexter', weightMap: 37009 },
        { name: 'Dominik', weightMap: 37022 },
        { name: 'Eden', weightMap: 37035 },
        { name: 'Elias', weightMap: 37048 },
        { name: 'Fionn', weightMap: 37061 },
        { name: 'Hugh', weightMap: 37074 },
        { name: 'Jace', weightMap: 37087 },
        { name: 'Julian', weightMap: 37100 },
        { name: 'Keegan', weightMap: 37113 },
        { name: 'Kerr', weightMap: 37126 },
        { name: 'Kit', weightMap: 37139 },
        { name: 'Marcel', weightMap: 37152 },
        { name: 'Miles', weightMap: 37165 },
        { name: 'Nolan', weightMap: 37178 },
        { name: 'Richard', weightMap: 37191 },
        { name: 'Rohan', weightMap: 37204 },
        { name: 'Rudy', weightMap: 37217 },
        { name: 'Wyatt', weightMap: 37230 },
        { name: 'Alicia', weightMap: 37243 },
        { name: 'Allie', weightMap: 37256 },
        { name: 'Breagha', weightMap: 37269 },
        { name: 'Edie', weightMap: 37282 },
        { name: 'Ellen', weightMap: 37295 },
        { name: 'Kendall', weightMap: 37308 },
        { name: 'Mairi', weightMap: 37321 },
        { name: 'Margot', weightMap: 37334 },
        { name: 'Marley', weightMap: 37347 },
        { name: 'Mylah', weightMap: 37360 },
        { name: 'Paisley', weightMap: 37373 },
        { name: 'Perrie', weightMap: 37386 },
        { name: 'Rae', weightMap: 37399 },
        { name: 'River', weightMap: 37412 },
        { name: 'Vaila', weightMap: 37425 },
        { name: 'Asher', weightMap: 37437 },
        { name: 'Cailean', weightMap: 37449 },
        { name: 'Douglas', weightMap: 37461 },
        { name: 'Freddy', weightMap: 37473 },
        { name: 'Jaiden', weightMap: 37485 },
        { name: 'Leyton', weightMap: 37497 },
        { name: 'Martin', weightMap: 37509 },
        { name: 'Mateo', weightMap: 37521 },
        { name: 'Matteo', weightMap: 37533 },
        { name: 'Michal', weightMap: 37545 },
        { name: 'Mikolaj', weightMap: 37557 },
        { name: 'Nairn', weightMap: 37569 },
        { name: 'Omar', weightMap: 37581 },
        { name: 'Preston', weightMap: 37593 },
        { name: 'Ruari', weightMap: 37605 },
        { name: 'Alana', weightMap: 37617 },
        { name: 'Aliyah', weightMap: 37629 },
        { name: 'Amara', weightMap: 37641 },
        { name: 'Ariya', weightMap: 37653 },
        { name: 'Avery', weightMap: 37665 },
        { name: 'Blair', weightMap: 37677 },
        { name: 'Blaire', weightMap: 37689 },
        { name: 'Cassie', weightMap: 37701 },
        { name: 'Cleo', weightMap: 37713 },
        { name: 'Heather', weightMap: 37725 },
        { name: 'Helena', weightMap: 37737 },
        { name: 'Jennifer', weightMap: 37749 },
        { name: 'Kayleigh', weightMap: 37761 },
        { name: 'Kiara', weightMap: 37773 },
        { name: 'Leia', weightMap: 37785 },
        { name: 'Leila', weightMap: 37797 },
        { name: 'Lila', weightMap: 37809 },
        { name: 'Michelle', weightMap: 37821 },
        { name: 'Morgan', weightMap: 37833 },
        { name: 'Nevaeh', weightMap: 37845 },
        { name: 'Pola', weightMap: 37857 },
        { name: 'Vanessa', weightMap: 37869 },
        { name: 'Winter', weightMap: 37881 },
        { name: 'Abdul', weightMap: 37892 },
        { name: 'Aron', weightMap: 37903 },
        { name: 'Artur', weightMap: 37914 },
        { name: 'Brayden', weightMap: 37925 },
        { name: 'Chester', weightMap: 37936 },
        { name: 'Clayton', weightMap: 37947 },
        { name: 'Frank', weightMap: 37958 },
        { name: 'Hamza', weightMap: 37969 },
        { name: 'Igor', weightMap: 37980 },
        { name: 'Jonathan', weightMap: 37991 },
        { name: 'Kylo', weightMap: 38002 },
        { name: 'Lawson', weightMap: 38013 },
        { name: 'Leonardo', weightMap: 38024 },
        { name: 'Mikey', weightMap: 38035 },
        { name: 'Mitchell', weightMap: 38046 },
        { name: 'Rex', weightMap: 38057 },
        { name: 'Russell', weightMap: 38068 },
        { name: 'Tobias', weightMap: 38079 },
        { name: 'Vinnie', weightMap: 38090 },
        { name: 'Abby', weightMap: 38101 },
        { name: 'Aisha', weightMap: 38112 },
        { name: 'Alivia', weightMap: 38123 },
        { name: 'Amelia-Rose', weightMap: 38134 },
        { name: 'Arla', weightMap: 38145 },
        { name: 'Arwen', weightMap: 38156 },
        { name: 'Aubrey', weightMap: 38167 },
        { name: 'Aurelia', weightMap: 38178 },
        { name: 'Aurla', weightMap: 38189 },
        { name: 'Christina', weightMap: 38200 },
        { name: 'Effie', weightMap: 38211 },
        { name: 'Esther', weightMap: 38222 },
        { name: 'Felicity', weightMap: 38233 },
        { name: 'Georgina', weightMap: 38244 },
        { name: 'Islay', weightMap: 38255 },
        { name: 'Kady', weightMap: 38266 },
        { name: 'Kiera', weightMap: 38277 },
        { name: 'Klara', weightMap: 38288 },
        { name: 'Kornelia', weightMap: 38299 },
        { name: 'Lilah', weightMap: 38310 },
        { name: 'Lilia', weightMap: 38321 },
        { name: 'Milana', weightMap: 38332 },
        { name: 'Miley', weightMap: 38343 },
        { name: 'Zainab', weightMap: 38354 },
        { name: 'Brendan', weightMap: 38364 },
        { name: 'Coby', weightMap: 38374 },
        { name: 'Curtis', weightMap: 38384 },
        { name: 'Damian', weightMap: 38394 },
        { name: 'Darren', weightMap: 38404 },
        { name: 'Ivor', weightMap: 38414 },
        { name: 'Jayce', weightMap: 38424 },
        { name: 'Kayson', weightMap: 38434 },
        { name: 'Lloyd', weightMap: 38444 },
        { name: 'Mac', weightMap: 38454 },
        { name: 'Marco', weightMap: 38464 },
        { name: 'Musa', weightMap: 38474 },
        { name: 'Reagan', weightMap: 38484 },
        { name: 'Torin', weightMap: 38494 },
        { name: 'Tymon', weightMap: 38504 },
        { name: 'Wiktor', weightMap: 38514 },
        { name: 'Zain', weightMap: 38524 },
        { name: 'Ailie', weightMap: 38534 },
        { name: 'Aleksandra', weightMap: 38544 },
        { name: 'Dani', weightMap: 38554 },
        { name: 'Delilah', weightMap: 38564 },
        { name: 'Ebony', weightMap: 38574 },
        { name: 'Emmie', weightMap: 38584 },
        { name: 'Everly', weightMap: 38594 },
        { name: 'Fiadh', weightMap: 38604 },
        { name: 'Fiona', weightMap: 38614 },
        { name: 'Gabrielle', weightMap: 38624 },
        { name: 'Hailey', weightMap: 38634 },
        { name: 'Inaaya', weightMap: 38644 },
        { name: 'Lillian', weightMap: 38654 },
        { name: 'Lilly-Mae', weightMap: 38664 },
        { name: 'Louise', weightMap: 38674 },
        { name: 'Maryam', weightMap: 38684 },
        { name: 'Meghan', weightMap: 38694 },
        { name: 'Myah', weightMap: 38704 },
        { name: 'Nancy', weightMap: 38714 },
        { name: 'Nela', weightMap: 38724 },
        { name: 'Nuala', weightMap: 38734 },
        { name: 'Olive', weightMap: 38744 },
        { name: 'Orlah', weightMap: 38754 },
        { name: 'Romy', weightMap: 38764 },
        { name: 'Seren', weightMap: 38774 },
        { name: 'Sloane', weightMap: 38784 },
        { name: 'Tiana', weightMap: 38794 },
        { name: 'Wynter', weightMap: 38804 },
        { name: 'Adrian', weightMap: 38813 },
        { name: 'Aleksander', weightMap: 38822 },
        { name: 'Amir', weightMap: 38831 },
        { name: 'Antonio', weightMap: 38840 },
        { name: 'Brooklyn', weightMap: 38849 },
        { name: 'Bryce', weightMap: 38858 },
        { name: 'Cairn', weightMap: 38867 },
        { name: 'Coen', weightMap: 38876 },
        { name: 'Conan', weightMap: 38885 },
        { name: 'Fabian', weightMap: 38894 },
        { name: 'Fynn', weightMap: 38903 },
        { name: 'Gary', weightMap: 38912 },
        { name: 'Jakob', weightMap: 38921 },
        { name: 'Jaxx', weightMap: 38930 },
        { name: 'Kade', weightMap: 38939 },
        { name: 'Karson', weightMap: 38948 },
        { name: 'Karter', weightMap: 38957 },
        { name: 'Kobi', weightMap: 38966 },
        { name: 'Lee', weightMap: 38975 },
        { name: 'Mackenzie', weightMap: 38984 },
        { name: 'Maximilian', weightMap: 38993 },
        { name: 'Mohamed', weightMap: 39002 },
        { name: 'Nicolas', weightMap: 39011 },
        { name: 'Oisin', weightMap: 39020 },
        { name: 'Olivier', weightMap: 39029 },
        { name: 'Phoenix', weightMap: 39038 },
        { name: 'Remy', weightMap: 39047 },
        { name: 'Sandy', weightMap: 39056 },
        { name: 'Sol', weightMap: 39065 },
        { name: 'Victor', weightMap: 39074 },
        { name: 'Wojciech', weightMap: 39083 },
        { name: 'Alexa', weightMap: 39092 },
        { name: 'Alia', weightMap: 39101 },
        { name: 'Amira', weightMap: 39110 },
        { name: 'Annalise', weightMap: 39119 },
        { name: 'Athena', weightMap: 39128 },
        { name: 'Beau', weightMap: 39137 },
        { name: 'Cerys', weightMap: 39146 },
        { name: 'Chelsea', weightMap: 39155 },
        { name: 'Claudia', weightMap: 39164 },
        { name: 'Constance', weightMap: 39173 },
        { name: 'Diana', weightMap: 39182 },
        { name: 'Emmy', weightMap: 39191 },
        { name: 'Etta', weightMap: 39200 },
        { name: 'Ferne', weightMap: 39209 },
        { name: 'Gabriela', weightMap: 39218 },
        { name: 'Hana', weightMap: 39227 },
        { name: 'Harleigh', weightMap: 39236 },
        { name: 'Jess', weightMap: 39245 },
        { name: 'Jorgia', weightMap: 39254 },
        { name: 'Josephine', weightMap: 39263 },
        { name: 'Josie', weightMap: 39272 },
        { name: 'Keeva', weightMap: 39281 },
        { name: 'Margaret', weightMap: 39290 },
        { name: 'Meadow', weightMap: 39299 },
        { name: 'Melody', weightMap: 39308 },
        { name: 'Meredith', weightMap: 39317 },
        { name: 'Mhairi', weightMap: 39326 },
        { name: 'Milena', weightMap: 39335 },
        { name: 'Milly', weightMap: 39344 },
        { name: 'Murron', weightMap: 39353 },
        { name: 'Natalie', weightMap: 39362 },
        { name: 'Neave', weightMap: 39371 },
        { name: 'Neive', weightMap: 39380 },
        { name: 'Polly', weightMap: 39389 },
        { name: 'Rebekah', weightMap: 39398 },
        { name: 'Shelby', weightMap: 39407 },
        { name: 'Tia', weightMap: 39416 },
        { name: 'Wiktoria', weightMap: 39425 },
        { name: 'Zoya', weightMap: 39434 },
        { name: 'Ahmad', weightMap: 39442 },
        { name: 'Ahmed', weightMap: 39450 },
        { name: 'Ajay', weightMap: 39458 },
        { name: 'Alfred', weightMap: 39466 },
        { name: 'Allan', weightMap: 39474 },
        { name: 'Anton', weightMap: 39482 },
        { name: 'Ari', weightMap: 39490 },
        { name: 'Ayden', weightMap: 39498 },
        { name: 'Bear', weightMap: 39506 },
        { name: 'Beau', weightMap: 39514 },
        { name: 'Brian', weightMap: 39522 },
        { name: 'Bruce', weightMap: 39530 },
        { name: 'Bruno', weightMap: 39538 },
        { name: 'Caden', weightMap: 39546 },
        { name: 'Cain', weightMap: 39554 },
        { name: 'Christian', weightMap: 39562 },
        { name: 'Clark', weightMap: 39570 },
        { name: 'Dawid', weightMap: 39578 },
        { name: 'Enzo', weightMap: 39586 },
        { name: 'Eoin', weightMap: 39594 },
        { name: 'Johnny', weightMap: 39602 },
        { name: 'Kenzie', weightMap: 39610 },
        { name: 'Korey', weightMap: 39618 },
        { name: 'Lochlann', weightMap: 39626 },
        { name: 'Luka', weightMap: 39634 },
        { name: 'Malachy', weightMap: 39642 },
        { name: 'Maximus', weightMap: 39650 },
        { name: 'Milan', weightMap: 39658 },
        { name: 'Millar', weightMap: 39666 },
        { name: 'Nikodem', weightMap: 39674 },
        { name: 'Otis', weightMap: 39682 },
        { name: 'Otto', weightMap: 39690 },
        { name: 'Philip', weightMap: 39698 },
        { name: 'Roddy', weightMap: 39706 },
        { name: 'Rogan', weightMap: 39714 },
        { name: 'Saul', weightMap: 39722 },
        { name: 'Simon', weightMap: 39730 },
        { name: 'Stuart', weightMap: 39738 },
        { name: 'Szymon', weightMap: 39746 },
        { name: 'Yahya', weightMap: 39754 },
        { name: 'Zakariya', weightMap: 39762 },
        { name: 'Ailish', weightMap: 39770 },
        { name: 'Alix', weightMap: 39778 },
        { name: 'Aliza', weightMap: 39786 },
        { name: 'Anabia', weightMap: 39794 },
        { name: 'Andi', weightMap: 39802 },
        { name: 'Aya', weightMap: 39810 },
        { name: 'Calla', weightMap: 39818 },
        { name: 'Camilla', weightMap: 39826 },
        { name: 'Carys', weightMap: 39834 },
        { name: 'Catriona', weightMap: 39842 },
        { name: 'Charli', weightMap: 39850 },
        { name: 'Demi', weightMap: 39858 },
        { name: 'Elyse', weightMap: 39866 },
        { name: 'Emelia', weightMap: 39874 },
        { name: 'Esmee', weightMap: 39882 },
        { name: 'Fallon', weightMap: 39890 },
        { name: 'Farah', weightMap: 39898 },
        { name: 'Honor', weightMap: 39906 },
        { name: 'Isabel', weightMap: 39914 },
        { name: 'Izzy', weightMap: 39922 },
        { name: 'Juno', weightMap: 39930 },
        { name: 'Katy', weightMap: 39938 },
        { name: 'Kenzie', weightMap: 39946 },
        { name: 'Kianna', weightMap: 39954 },
        { name: 'Kyla', weightMap: 39962 },
        { name: 'Lacie', weightMap: 39970 },
        { name: 'Leona', weightMap: 39978 },
        { name: 'Lia', weightMap: 39986 },
        { name: 'Malia', weightMap: 39994 },
        { name: 'Miah', weightMap: 40002 },
        { name: 'Nellie', weightMap: 40010 },
        { name: 'Robin', weightMap: 40018 },
        { name: 'Rona', weightMap: 40026 },
        { name: 'Wren', weightMap: 40034 },
        { name: 'Aston', weightMap: 40041 },
        { name: 'Axel', weightMap: 40048 },
        { name: 'Ayan', weightMap: 40055 },
        { name: 'Brady', weightMap: 40062 },
        { name: 'Conlan', weightMap: 40069 },
        { name: 'Conner', weightMap: 40076 },
        { name: 'Dawson', weightMap: 40083 },
        { name: 'Glen', weightMap: 40090 },
        { name: 'Gordon', weightMap: 40097 },
        { name: 'Greyson', weightMap: 40104 },
        { name: 'Ismail', weightMap: 40111 },
        { name: 'Ivan', weightMap: 40118 },
        { name: 'Jesse', weightMap: 40125 },
        { name: 'Kajus', weightMap: 40132 },
        { name: 'Kingsley', weightMap: 40139 },
        { name: 'Kody', weightMap: 40146 },
        { name: 'Kole', weightMap: 40153 },
        { name: 'Leonard', weightMap: 40160 },
        { name: 'Marley', weightMap: 40167 },
        { name: 'Matei', weightMap: 40174 },
        { name: 'Mateusz', weightMap: 40181 },
        { name: 'Micah', weightMap: 40188 },
        { name: 'Munro', weightMap: 40195 },
        { name: 'Natan', weightMap: 40202 },
        { name: 'Niven', weightMap: 40209 },
        { name: 'Oliwier', weightMap: 40216 },
        { name: 'Ralphie', weightMap: 40223 },
        { name: 'Reegan', weightMap: 40230 },
        { name: 'Reilly', weightMap: 40237 },
        { name: 'Seamus', weightMap: 40244 },
        { name: 'Shaun', weightMap: 40251 },
        { name: 'Sorley', weightMap: 40258 },
        { name: 'Stanislaw', weightMap: 40265 },
        { name: 'Stewart', weightMap: 40272 },
        { name: 'Syed', weightMap: 40279 },
        { name: 'Warren', weightMap: 40286 },
        { name: 'Woody', weightMap: 40293 },
        { name: 'Yousef', weightMap: 40300 },
        { name: 'Zayne', weightMap: 40307 },
        { name: 'Abbey', weightMap: 40314 },
        { name: 'Aida', weightMap: 40321 },
        { name: 'Aiyla', weightMap: 40328 },
        { name: 'Alaina', weightMap: 40335 },
        { name: 'Alannah', weightMap: 40342 },
        { name: 'Antonina', weightMap: 40349 },
        { name: 'Ariyah', weightMap: 40356 },
        { name: 'Ava-Rose', weightMap: 40363 },
        { name: 'Ayat', weightMap: 40370 },
        { name: 'Bailey', weightMap: 40377 },
        { name: 'Bobbi', weightMap: 40384 },
        { name: 'Brodie', weightMap: 40391 },
        { name: 'Carla', weightMap: 40398 },
        { name: 'Cassidy', weightMap: 40405 },
        { name: 'Claire', weightMap: 40412 },
        { name: 'Devon', weightMap: 40419 },
        { name: 'Eira', weightMap: 40426 },
        { name: 'Eliana', weightMap: 40433 },
        { name: 'Erika', weightMap: 40440 },
        { name: 'Esmae', weightMap: 40447 },
        { name: 'Floraidh', weightMap: 40454 },
        { name: 'Genevieve', weightMap: 40461 },
        { name: 'Hailie', weightMap: 40468 },
        { name: 'Inaya', weightMap: 40475 },
        { name: 'Joni', weightMap: 40482 },
        { name: 'Joy', weightMap: 40489 },
        { name: 'Kacie', weightMap: 40496 },
        { name: 'Kaitlyn', weightMap: 40503 },
        { name: 'Karlie', weightMap: 40510 },
        { name: 'Kennedy', weightMap: 40517 },
        { name: 'Kirsty', weightMap: 40524 },
        { name: 'Leyla', weightMap: 40531 },
        { name: 'Lily-Rose', weightMap: 40538 },
        { name: 'Lina', weightMap: 40545 },
        { name: 'Livia', weightMap: 40552 },
        { name: 'Lori', weightMap: 40559 },
        { name: 'Luci', weightMap: 40566 },
        { name: 'Madeleine', weightMap: 40573 },
        { name: 'Mairead', weightMap: 40580 },
        { name: 'Marlie', weightMap: 40587 },
        { name: 'Melanie', weightMap: 40594 },
        { name: 'Minnie', weightMap: 40601 },
        { name: 'Natasha', weightMap: 40608 },
        { name: 'Nola', weightMap: 40615 },
        { name: 'Olivia-Rose', weightMap: 40622 },
        { name: 'Philippa', weightMap: 40629 },
        { name: 'Pixie', weightMap: 40636 },
        { name: 'Rhona', weightMap: 40643 },
        { name: 'Ruth', weightMap: 40650 },
        { name: 'Safa', weightMap: 40657 },
        { name: 'Sorcha', weightMap: 40664 },
        { name: 'Vienna', weightMap: 40671 },
        { name: 'Zaynab', weightMap: 40678 },
        { name: 'Aaran', weightMap: 40684 },
        { name: 'Ally', weightMap: 40690 },
        { name: 'Andrei', weightMap: 40696 },
        { name: 'Aryan', weightMap: 40702 },
        { name: 'Avery', weightMap: 40708 },
        { name: 'Broden', weightMap: 40714 },
        { name: 'Carlo', weightMap: 40720 },
        { name: 'Colin', weightMap: 40726 },
        { name: 'Conrad', weightMap: 40732 },
        { name: 'Corran', weightMap: 40738 },
        { name: 'Damien', weightMap: 40744 },
        { name: 'Dara', weightMap: 40750 },
        { name: 'Dillon', weightMap: 40756 },
        { name: 'Donald', weightMap: 40762 },
        { name: 'Eddie', weightMap: 40768 },
        { name: 'Emmanuel', weightMap: 40774 },
        { name: 'Erik', weightMap: 40780 },
        { name: 'Greig', weightMap: 40786 },
        { name: 'Ivar', weightMap: 40792 },
        { name: 'Jai', weightMap: 40798 },
        { name: 'Jonas', weightMap: 40804 },
        { name: 'Justin', weightMap: 40810 },
        { name: 'Kairo', weightMap: 40816 },
        { name: 'Kalvin', weightMap: 40822 },
        { name: 'Klay', weightMap: 40828 },
        { name: 'Kobe', weightMap: 40834 },
        { name: 'Kyren', weightMap: 40840 },
        { name: 'Lawrence', weightMap: 40846 },
        { name: 'Marshall', weightMap: 40852 },
        { name: 'Morgan', weightMap: 40858 },
        { name: 'Mylo', weightMap: 40864 },
        { name: 'Oban', weightMap: 40870 },
        { name: 'Olaf', weightMap: 40876 },
        { name: 'Orrin', weightMap: 40882 },
        { name: 'Ramsay', weightMap: 40888 },
        { name: 'Ricco', weightMap: 40894 },
        { name: 'Roan', weightMap: 40900 },
        { name: 'Rupert', weightMap: 40906 },
        { name: 'Solomon', weightMap: 40912 },
        { name: 'Teodor', weightMap: 40918 },
        { name: 'Wilfred', weightMap: 40924 },
        { name: 'Will', weightMap: 40930 },
        { name: 'Xavier', weightMap: 40936 },
        { name: 'Yousaf', weightMap: 40942 },
        { name: 'Abi', weightMap: 40948 },
        { name: 'Adaline', weightMap: 40954 },
        { name: 'Agnes', weightMap: 40960 },
        { name: 'Ailidh', weightMap: 40966 },
        { name: 'Aiza', weightMap: 40972 },
        { name: 'Aizah', weightMap: 40978 },
        { name: 'Alanna', weightMap: 40984 },
        { name: 'Alexandria', weightMap: 40990 },
        { name: 'Amal', weightMap: 40996 },
        { name: 'Annabella', weightMap: 41002 },
        { name: 'Audrey', weightMap: 41008 },
        { name: 'Ava-Grace', weightMap: 41014 },
        { name: 'Barbara', weightMap: 41020 },
        { name: 'Beatrice', weightMap: 41026 },
        { name: 'Chiara', weightMap: 41032 },
        { name: 'Danielle', weightMap: 41038 },
        { name: 'Elara', weightMap: 41044 },
        { name: 'Emeli', weightMap: 41050 },
        { name: 'Emelie', weightMap: 41056 },
        { name: 'Emmeline', weightMap: 41062 },
        { name: 'Eshaal', weightMap: 41068 },
        { name: 'Eva-Rose', weightMap: 41074 },
        { name: 'Farrah', weightMap: 41080 },
        { name: 'Gemma', weightMap: 41086 },
        { name: 'Gianna', weightMap: 41092 },
        { name: 'Gracie-Mae', weightMap: 41098 },
        { name: 'Hafsa', weightMap: 41104 },
        { name: 'Hania', weightMap: 41110 },
        { name: 'Haniya', weightMap: 41116 },
        { name: 'Harmony', weightMap: 41122 },
        { name: 'Hiba', weightMap: 41128 },
        { name: 'Hoorain', weightMap: 41134 },
        { name: 'Ida', weightMap: 41140 },
        { name: 'Iga', weightMap: 41146 },
        { name: 'Imaan', weightMap: 41152 },
        { name: 'Ivy-Rose', weightMap: 41158 },
        { name: 'Jagoda', weightMap: 41164 },
        { name: 'Jenna', weightMap: 41170 },
        { name: 'Joanna', weightMap: 41176 },
        { name: 'Juliet', weightMap: 41182 },
        { name: 'Juliette', weightMap: 41188 },
        { name: 'Kaia', weightMap: 41194 },
        { name: 'Karis', weightMap: 41200 },
        { name: 'Karolina', weightMap: 41206 },
        { name: 'Katherine', weightMap: 41212 },
        { name: 'Kayley', weightMap: 41218 },
        { name: 'Khadijah', weightMap: 41224 },
        { name: 'Kiana', weightMap: 41230 },
        { name: 'Laurie', weightMap: 41236 },
        { name: 'Leela', weightMap: 41242 },
        { name: 'Leen', weightMap: 41248 },
        { name: 'Lennox', weightMap: 41254 },
        { name: 'Lilianna', weightMap: 41260 },
        { name: 'Luisa', weightMap: 41266 },
        { name: 'Mae', weightMap: 41272 },
        { name: 'Michalina', weightMap: 41278 },
        { name: 'Miriam', weightMap: 41284 },
        { name: 'Murrin', weightMap: 41290 },
        { name: 'Myra', weightMap: 41296 },
        { name: 'Nell', weightMap: 41302 },
        { name: 'Nia', weightMap: 41308 },
        { name: 'Noa', weightMap: 41314 },
        { name: 'Nyla', weightMap: 41320 },
        { name: 'Ophelia', weightMap: 41326 },
        { name: 'Phoenix', weightMap: 41332 },
        { name: 'Pyper', weightMap: 41338 },
        { name: 'Raven', weightMap: 41344 },
        { name: 'Rhia', weightMap: 41350 },
        { name: 'Roma', weightMap: 41356 },
        { name: 'Rosalie', weightMap: 41362 },
        { name: 'Rosemary', weightMap: 41368 },
        { name: 'Roxy', weightMap: 41374 },
        { name: 'Samantha', weightMap: 41380 },
        { name: 'Serena', weightMap: 41386 },
        { name: 'Syeda', weightMap: 41392 },
        { name: 'Sylvie', weightMap: 41398 },
        { name: 'Tabitha', weightMap: 41404 },
        { name: 'Tillie', weightMap: 41410 },
        { name: 'Verity', weightMap: 41416 },
        { name: 'Viola', weightMap: 41422 },
        { name: 'Vivienne', weightMap: 41428 },
        { name: 'Ace', weightMap: 41433 },
        { name: 'Al', weightMap: 41438 },
        { name: 'Anders', weightMap: 41443 },
        { name: 'Atticus', weightMap: 41448 },
        { name: 'Bentley', weightMap: 41453 },
        { name: 'Blaine', weightMap: 41458 },
        { name: 'Borys', weightMap: 41463 },
        { name: 'Bryson', weightMap: 41468 },
        { name: 'Caelen', weightMap: 41473 },
        { name: 'Carrick', weightMap: 41478 },
        { name: 'Casper', weightMap: 41483 },
        { name: 'Cassius', weightMap: 41488 },
        { name: 'Charley', weightMap: 41493 },
        { name: 'Corrie', weightMap: 41498 },
        { name: 'Cullen', weightMap: 41503 },
        { name: 'Deacon', weightMap: 41508 },
        { name: 'Denis', weightMap: 41513 },
        { name: 'Devin', weightMap: 41518 },
        { name: 'Emanuel', weightMap: 41523 },
        { name: 'Eryk', weightMap: 41528 },
        { name: 'Ezekiel', weightMap: 41533 },
        { name: 'Fox', weightMap: 41538 },
        { name: 'Franky', weightMap: 41543 },
        { name: 'Gerard', weightMap: 41548 },
        { name: 'Hadi', weightMap: 41553 },
        { name: 'Hassan', weightMap: 41558 },
        { name: 'Haydn', weightMap: 41563 },
        { name: 'Howie', weightMap: 41568 },
        { name: 'Iain', weightMap: 41573 },
        { name: 'Isaiah', weightMap: 41578 },
        { name: 'Jase', weightMap: 41583 },
        { name: 'Jeremy', weightMap: 41588 },
        { name: 'Jokubas', weightMap: 41593 },
        { name: 'Josiah', weightMap: 41598 },
        { name: 'Kal', weightMap: 41603 },
        { name: 'Kamil', weightMap: 41608 },
        { name: 'Kane', weightMap: 41613 },
        { name: 'Kasper', weightMap: 41618 },
        { name: 'Kendrick', weightMap: 41623 },
        { name: 'Kenneth', weightMap: 41628 },
        { name: 'Koen', weightMap: 41633 },
        { name: 'Konrad', weightMap: 41638 },
        { name: 'Kristian', weightMap: 41643 },
        { name: 'Kylan', weightMap: 41648 },
        { name: 'Kyran', weightMap: 41653 },
        { name: 'Lenny', weightMap: 41658 },
        { name: 'Lochlyn', weightMap: 41663 },
        { name: 'Lockie', weightMap: 41668 },
        { name: 'Lomond', weightMap: 41673 },
        { name: 'Lorenzo', weightMap: 41678 },
        { name: 'Lucca', weightMap: 41683 },
        { name: 'Luis', weightMap: 41688 },
        { name: 'Malachi', weightMap: 41693 },
        { name: 'Marc', weightMap: 41698 },
        { name: 'Mathew', weightMap: 41703 },
        { name: 'Mayson', weightMap: 41708 },
        { name: 'Mckenzie', weightMap: 41713 },
        { name: 'Nataniel', weightMap: 41718 },
        { name: 'Oakley', weightMap: 41723 },
        { name: 'Orin', weightMap: 41728 },
        { name: 'Orran', weightMap: 41733 },
        { name: 'Oryn', weightMap: 41738 },
        { name: 'Ossian', weightMap: 41743 },
        { name: 'Ozzy', weightMap: 41748 },
        { name: 'Raymond', weightMap: 41753 },
        { name: 'Regan', weightMap: 41758 },
        { name: 'Romeo', weightMap: 41763 },
        { name: 'Ronin', weightMap: 41768 },
        { name: 'Rueben', weightMap: 41773 },
        { name: 'Seb', weightMap: 41778 },
        { name: 'Seumas', weightMap: 41783 },
        { name: 'Shae', weightMap: 41788 },
        { name: 'Stefan', weightMap: 41793 },
        { name: 'Teddie', weightMap: 41798 },
        { name: 'Torran', weightMap: 41803 },
        { name: 'Troy', weightMap: 41808 },
        { name: 'Tymoteusz', weightMap: 41813 },
        { name: 'Walker', weightMap: 41818 },
        { name: 'Weston', weightMap: 41823 },
        { name: 'Aaira', weightMap: 41828 },
        { name: 'Adriana', weightMap: 41833 },
        { name: 'Ailey', weightMap: 41838 },
        { name: 'Aivah', weightMap: 41843 },
        { name: 'Alessia', weightMap: 41848 },
        { name: 'Alexia', weightMap: 41853 },
        { name: 'Alina', weightMap: 41858 },
        { name: 'Aminah', weightMap: 41863 },
        { name: 'Amna', weightMap: 41868 },
        { name: 'Anastazja', weightMap: 41873 },
        { name: 'Anaya', weightMap: 41878 },
        { name: 'Andie', weightMap: 41883 },
        { name: 'Angel', weightMap: 41888 },
        { name: 'Angela', weightMap: 41893 },
        { name: 'Annika', weightMap: 41898 },
        { name: 'Antonia', weightMap: 41903 },
        { name: 'Aria-Rose', weightMap: 41908 },
        { name: 'Ashley', weightMap: 41913 },
        { name: 'Aubree', weightMap: 41918 },
        { name: 'Avah', weightMap: 41923 },
        { name: 'Beatrix', weightMap: 41928 },
        { name: 'Caris', weightMap: 41933 },
        { name: 'Cayla', weightMap: 41938 },
        { name: 'Cecilia', weightMap: 41943 },
        { name: 'Celia', weightMap: 41948 },
        { name: 'Courtney', weightMap: 41953 },
        { name: 'Daria', weightMap: 41958 },
        { name: 'Darla', weightMap: 41963 },
        { name: 'Elaina', weightMap: 41968 },
        { name: 'Elisa', weightMap: 41973 },
        { name: 'Ella-May', weightMap: 41978 },
        { name: 'Ellie-Mae', weightMap: 41983 },
        { name: 'Elsa', weightMap: 41988 },
        { name: 'Emmi', weightMap: 41993 },
        { name: 'Evangeline', weightMap: 41998 },
        { name: 'Fatimah', weightMap: 42003 },
        { name: 'Frances', weightMap: 42008 },
        { name: 'Harper-Rose', weightMap: 42013 },
        { name: 'Harriett', weightMap: 42018 },
        { name: 'Iqra', weightMap: 42023 },
        { name: 'Isha', weightMap: 42028 },
        { name: 'Isla-Rose', weightMap: 42033 },
        { name: 'Isra', weightMap: 42038 },
        { name: 'Ivanna', weightMap: 42043 },
        { name: 'Jana', weightMap: 42048 },
        { name: 'Jemima', weightMap: 42053 },
        { name: 'Jenny', weightMap: 42058 },
        { name: 'Jorja', weightMap: 42063 },
        { name: 'Kaci', weightMap: 42068 },
        { name: 'Kaja', weightMap: 42073 },
        { name: 'Kallie', weightMap: 42078 },
        { name: 'Karley', weightMap: 42083 },
        { name: 'Keevah', weightMap: 42088 },
        { name: 'Kelsey', weightMap: 42093 },
        { name: 'Kendal', weightMap: 42098 },
        { name: 'Khadija', weightMap: 42103 },
        { name: 'Kira', weightMap: 42108 },
        { name: 'Leigha', weightMap: 42113 },
        { name: 'Lilian', weightMap: 42118 },
        { name: 'Lilly-Rose', weightMap: 42123 },
        { name: 'Lily-May', weightMap: 42128 },
        { name: 'Mackenzie', weightMap: 42133 },
        { name: 'Madeline', weightMap: 42138 },
        { name: 'Maiya', weightMap: 42143 },
        { name: 'Manha', weightMap: 42148 },
        { name: 'Mariam', weightMap: 42153 },
        { name: 'Marika', weightMap: 42158 },
        { name: 'Martyna', weightMap: 42163 },
        { name: 'Mckenzie', weightMap: 42168 },
        { name: 'Merida', weightMap: 42173 },
        { name: 'Mikayla', weightMap: 42178 },
        { name: 'Millie-Rose', weightMap: 42183 },
        { name: 'Murren', weightMap: 42188 },
        { name: 'Mylie', weightMap: 42193 },
        { name: 'Noelle', weightMap: 42198 },
        { name: 'Novah', weightMap: 42203 },
        { name: 'Ottilie', weightMap: 42208 },
        { name: 'Petra', weightMap: 42213 },
        { name: 'Primrose', weightMap: 42218 },
        { name: 'Reagan', weightMap: 42223 },
        { name: 'Remy', weightMap: 42228 },
        { name: 'Renee', weightMap: 42233 },
        { name: 'Ria', weightMap: 42238 },
        { name: 'Scarlet', weightMap: 42243 },
        { name: 'Selena', weightMap: 42248 },
        { name: 'Sierra', weightMap: 42253 },
        { name: 'Sophia-Grace', weightMap: 42258 },
        { name: 'Starr', weightMap: 42263 },
        { name: 'Stevie', weightMap: 42268 },
        { name: 'Sylvia', weightMap: 42273 },
        { name: 'Tala', weightMap: 42278 },
        { name: 'Tallulah', weightMap: 42283 },
        { name: 'Tara', weightMap: 42288 },
        { name: 'Teagan', weightMap: 42293 },
        { name: 'Thalia', weightMap: 42298 },
        { name: 'Theia', weightMap: 42303 },
        { name: 'Veronica', weightMap: 42308 },
        { name: 'Abdulrahman', weightMap: 42312 },
        { name: 'Aj', weightMap: 42316 },
        { name: 'Albi', weightMap: 42320 },
        { name: 'Alessandro', weightMap: 42324 },
        { name: 'Alexandros', weightMap: 42328 },
        { name: 'Anas', weightMap: 42332 },
        { name: 'Andre', weightMap: 42336 },
        { name: 'Arjun', weightMap: 42340 },
        { name: 'Arron', weightMap: 42344 },
        { name: 'Arryn', weightMap: 42348 },
        { name: 'August', weightMap: 42352 },
        { name: 'Axl', weightMap: 42356 },
        { name: 'Aydan', weightMap: 42360 },
        { name: 'Aydin', weightMap: 42364 },
        { name: 'Azaan', weightMap: 42368 },
        { name: 'Bartosz', weightMap: 42372 },
        { name: 'Baxter', weightMap: 42376 },
        { name: 'Bowie', weightMap: 42380 },
        { name: 'Brogan', weightMap: 42384 },
        { name: 'Bryan', weightMap: 42388 },
        { name: 'Calan', weightMap: 42392 },
        { name: 'Caspian', weightMap: 42396 },
        { name: 'Cayde', weightMap: 42400 },
        { name: 'Clay', weightMap: 42404 },
        { name: 'Codi', weightMap: 42408 },
        { name: 'Colt', weightMap: 42412 },
        { name: 'Cormac', weightMap: 42416 },
        { name: 'Cory', weightMap: 42420 },
        { name: 'Dax', weightMap: 42424 },
        { name: 'Derek', weightMap: 42428 },
        { name: 'Devlin', weightMap: 42432 },
        { name: 'Devon', weightMap: 42436 },
        { name: 'Drake', weightMap: 42440 },
        { name: 'Eesa', weightMap: 42444 },
        { name: 'Elis', weightMap: 42448 },
        { name: 'Emil', weightMap: 42452 },
        { name: 'Ernest', weightMap: 42456 },
        { name: 'Ernie', weightMap: 42460 },
        { name: 'Finnlay', weightMap: 42464 },
        { name: 'Forrest', weightMap: 42468 },
        { name: 'Frazer', weightMap: 42472 },
        { name: 'Gus', weightMap: 42476 },
        { name: 'Guy', weightMap: 42480 },
        { name: 'Harper', weightMap: 42484 },
        { name: 'Holden', weightMap: 42488 },
        { name: 'Hubert', weightMap: 42492 },
        { name: 'Ivo', weightMap: 42496 },
        { name: 'Jad', weightMap: 42500 },
        { name: 'Jawad', weightMap: 42504 },
        { name: 'Jeffrey', weightMap: 42508 },
        { name: 'John-Paul', weightMap: 42512 },
        { name: 'Jonny', weightMap: 42516 },
        { name: 'Judah', weightMap: 42520 },
        { name: 'Kallan', weightMap: 42524 },
        { name: 'Karam', weightMap: 42528 },
        { name: 'Keenan', weightMap: 42532 },
        { name: 'Kellan', weightMap: 42536 },
        { name: 'Kingston', weightMap: 42540 },
        { name: 'Koa', weightMap: 42544 },
        { name: 'Koby', weightMap: 42548 },
        { name: 'Koda', weightMap: 42552 },
        { name: 'Kolby', weightMap: 42556 },
        { name: 'Kolton', weightMap: 42560 },
        { name: 'Kornel', weightMap: 42564 },
        { name: 'Kruz', weightMap: 42568 },
        { name: 'Kye', weightMap: 42572 },
        { name: 'Lachlann', weightMap: 42576 },
        { name: 'Laurie', weightMap: 42580 },
        { name: 'Loui', weightMap: 42584 },
        { name: 'Lucian', weightMap: 42588 },
        { name: 'Maison', weightMap: 42592 },
        { name: 'Maxim', weightMap: 42596 },
        { name: 'Mikael', weightMap: 42600 },
        { name: 'Monroe', weightMap: 42604 },
        { name: 'Montgomery', weightMap: 42608 },
        { name: 'Mustafa', weightMap: 42612 },
        { name: 'Nikolai', weightMap: 42616 },
        { name: 'Nixon', weightMap: 42620 },
        { name: 'Omer', weightMap: 42624 },
        { name: 'Rafael', weightMap: 42628 },
        { name: 'Raiden', weightMap: 42632 },
        { name: 'Raphael', weightMap: 42636 },
        { name: 'Ray', weightMap: 42640 },
        { name: 'Rayaan', weightMap: 42644 },
        { name: 'Rayyan', weightMap: 42648 },
        { name: 'Reid', weightMap: 42652 },
        { name: 'Rhuaridh', weightMap: 42656 },
        { name: 'Rian', weightMap: 42660 },
        { name: 'Rico', weightMap: 42664 },
        { name: 'Roderick', weightMap: 42668 },
        { name: 'Ruan', weightMap: 42672 },
        { name: 'Ruaraidh', weightMap: 42676 },
        { name: 'Rufus', weightMap: 42680 },
        { name: 'Ryker', weightMap: 42684 },
        { name: 'Rylee', weightMap: 42688 },
        { name: 'Ryszard', weightMap: 42692 },
        { name: 'Saad', weightMap: 42696 },
        { name: 'Sami', weightMap: 42700 },
        { name: 'Shaye', weightMap: 42704 },
        { name: 'Silas', weightMap: 42708 },
        { name: 'Sulaiman', weightMap: 42712 },
        { name: 'Sunny', weightMap: 42716 },
        { name: 'Ted', weightMap: 42720 },
        { name: 'Thor', weightMap: 42724 },
        { name: 'Wallace', weightMap: 42728 },
        { name: 'Yousif', weightMap: 42732 },
        { name: 'Youssef', weightMap: 42736 },
        { name: 'Zayaan', weightMap: 42740 },
        { name: 'Zayan', weightMap: 42744 },
        { name: 'Aaria', weightMap: 42748 },
        { name: 'Aayat', weightMap: 42752 },
        { name: 'Adele', weightMap: 42756 },
        { name: 'Adeline', weightMap: 42760 },
        { name: 'Aela', weightMap: 42764 },
        { name: 'Ailith', weightMap: 42768 },
        { name: 'Aiman', weightMap: 42772 },
        { name: 'Aine', weightMap: 42776 },
        { name: 'Alayna', weightMap: 42780 },
        { name: 'Aleah', weightMap: 42784 },
        { name: 'Aleeza', weightMap: 42788 },
        { name: 'Alesha', weightMap: 42792 },
        { name: 'Alisa', weightMap: 42796 },
        { name: 'Aliya', weightMap: 42800 },
        { name: 'Allegra', weightMap: 42804 },
        { name: 'Alma', weightMap: 42808 },
        { name: 'Alya', weightMap: 42812 },
        { name: 'Amalia', weightMap: 42816 },
        { name: 'Amari', weightMap: 42820 },
        { name: 'Amelia-Grace', weightMap: 42824 },
        { name: 'Amie', weightMap: 42828 },
        { name: 'Amiyah', weightMap: 42832 },
        { name: 'Anayah', weightMap: 42836 },
        { name: 'Anja', weightMap: 42840 },
        { name: 'Arina', weightMap: 42844 },
        { name: 'Aura', weightMap: 42848 },
        { name: 'Averie', weightMap: 42852 },
        { name: 'Ayana', weightMap: 42856 },
        { name: 'Aylah', weightMap: 42860 },
        { name: 'Ayleigh', weightMap: 42864 },
        { name: 'Aysha', weightMap: 42868 },
        { name: 'Bianca', weightMap: 42872 },
        { name: 'Bianka', weightMap: 42876 },
        { name: 'Bonni', weightMap: 42880 },
        { name: 'Bria', weightMap: 42884 },
        { name: 'Brianna', weightMap: 42888 },
        { name: 'Bronwyn', weightMap: 42892 },
        { name: 'Cameron', weightMap: 42896 },
        { name: 'Camille', weightMap: 42900 },
        { name: 'Carina', weightMap: 42904 },
        { name: 'Carrie', weightMap: 42908 },
        { name: 'Cassandra', weightMap: 42912 },
        { name: 'Ceitidh', weightMap: 42916 },
        { name: 'Clarke', weightMap: 42920 },
        { name: 'Dahlia', weightMap: 42924 },
        { name: 'Daisey', weightMap: 42928 },
        { name: 'Daniela', weightMap: 42932 },
        { name: 'Daphne', weightMap: 42936 },
        { name: 'Daya', weightMap: 42940 },
        { name: 'Demi-Leigh', weightMap: 42944 },
        { name: 'Destiny', weightMap: 42948 },
        { name: 'Dorothy', weightMap: 42952 },
        { name: 'Ella-Grace', weightMap: 42956 },
        { name: 'Emily-Rose', weightMap: 42960 },
        { name: 'Emme', weightMap: 42964 },
        { name: 'Enya', weightMap: 42968 },
        { name: 'Ffion', weightMap: 42972 },
        { name: 'Freja', weightMap: 42976 },
        { name: 'Giovanna', weightMap: 42980 },
        { name: 'Haleema', weightMap: 42984 },
        { name: 'Haneen', weightMap: 42988 },
        { name: 'Harlie', weightMap: 42992 },
        { name: 'Inayah', weightMap: 42996 },
        { name: 'Ingrid', weightMap: 43000 },
        { name: 'Jaime', weightMap: 43004 },
        { name: 'Jamie', weightMap: 43008 },
        { name: 'Kaiya', weightMap: 43012 },
        { name: 'Karina', weightMap: 43016 },
        { name: 'Kasey', weightMap: 43020 },
        { name: 'Katelyn', weightMap: 43024 },
        { name: 'Kaya', weightMap: 43028 },
        { name: 'Kaycie', weightMap: 43032 },
        { name: 'Kyra', weightMap: 43036 },
        { name: 'Laya', weightMap: 43040 },
        { name: 'Layla-Rose', weightMap: 43044 },
        { name: 'Lea', weightMap: 43048 },
        { name: 'Leanna', weightMap: 43052 },
        { name: 'Leja', weightMap: 43056 },
        { name: 'Lexii', weightMap: 43060 },
        { name: 'Liana', weightMap: 43064 },
        { name: 'Lilli', weightMap: 43068 },
        { name: 'Lisa', weightMap: 43072 },
        { name: 'Marcie', weightMap: 43076 },
        { name: 'Marie', weightMap: 43080 },
        { name: 'Marina', weightMap: 43084 },
        { name: 'Marla', weightMap: 43088 },
        { name: 'Michaela', weightMap: 43092 },
        { name: 'Mirin', weightMap: 43096 },
        { name: 'Mirryn', weightMap: 43100 },
        { name: 'Monroe', weightMap: 43104 },
        { name: 'Nelly', weightMap: 43108 },
        { name: 'Nicola', weightMap: 43112 },
        { name: 'Pamela', weightMap: 43116 },
        { name: 'Parker', weightMap: 43120 },
        { name: 'Paula', weightMap: 43124 },
        { name: 'Poppie', weightMap: 43128 },
        { name: 'Princess', weightMap: 43132 },
        { name: 'Rachael', weightMap: 43136 },
        { name: 'Rania', weightMap: 43140 },
        { name: 'Raya', weightMap: 43144 },
        { name: 'Rayna', weightMap: 43148 },
        { name: 'Rayne', weightMap: 43152 },
        { name: 'Reese', weightMap: 43156 },
        { name: 'Rhiannon', weightMap: 43160 },
        { name: 'Rio', weightMap: 43164 },
        { name: 'Rita', weightMap: 43168 },
        { name: 'Rosanna', weightMap: 43172 },
        { name: 'Roxanne', weightMap: 43176 },
        { name: 'Ruby-Rose', weightMap: 43180 },
        { name: 'Sana', weightMap: 43184 },
        { name: 'Sasha', weightMap: 43188 },
        { name: 'Saskia', weightMap: 43192 },
        { name: 'Scout', weightMap: 43196 },
        { name: 'Shay', weightMap: 43200 },
        { name: 'Sia', weightMap: 43204 },
        { name: 'Skyler', weightMap: 43208 },
        { name: 'Stefania', weightMap: 43212 },
        { name: 'Stephanie', weightMap: 43216 },
        { name: 'Stevi', weightMap: 43220 },
        { name: 'Sunny', weightMap: 43224 },
        { name: 'Susie', weightMap: 43228 },
        { name: 'Tegan', weightMap: 43232 },
        { name: 'Tessa', weightMap: 43236 },
        { name: 'Thora', weightMap: 43240 },
        { name: 'Umaiza', weightMap: 43244 },
        { name: 'Una', weightMap: 43248 },
        { name: 'Yana', weightMap: 43252 },
        { name: 'Zarah', weightMap: 43256 },
        { name: 'Zelda', weightMap: 43260 },
        { name: 'Aadam', weightMap: 43263 },
        { name: 'Aaryn', weightMap: 43266 },
        { name: 'Aayan', weightMap: 43269 },
        { name: 'Abdallah', weightMap: 43272 },
        { name: 'Adem', weightMap: 43275 },
        { name: 'Adhvik', weightMap: 43278 },
        { name: 'Adnan', weightMap: 43281 },
        { name: 'Aedan', weightMap: 43284 },
        { name: 'Ahaan', weightMap: 43287 },
        { name: 'Aksel', weightMap: 43290 },
        { name: 'Alastair', weightMap: 43293 },
        { name: 'Alec', weightMap: 43296 },
        { name: 'Alexandru', weightMap: 43299 },
        { name: 'Ander', weightMap: 43302 },
        { name: 'Andy', weightMap: 43305 },
        { name: 'Aodhan', weightMap: 43308 },
        { name: 'Argyll', weightMap: 43311 },
        { name: 'Arjan', weightMap: 43314 },
        { name: 'Ash', weightMap: 43317 },
        { name: 'Atlas', weightMap: 43320 },
        { name: 'Austen', weightMap: 43323 },
        { name: 'Barclay', weightMap: 43326 },
        { name: 'Barnaby', weightMap: 43329 },
        { name: 'Barry', weightMap: 43332 },
        { name: 'Beauden', weightMap: 43335 },
        { name: 'Blane', weightMap: 43338 },
        { name: 'Bleu', weightMap: 43341 },
        { name: 'Boaz', weightMap: 43344 },
        { name: 'Bodie', weightMap: 43347 },
        { name: 'Bogdan', weightMap: 43350 },
        { name: 'Braiden', weightMap: 43353 },
        { name: 'Buddy', weightMap: 43356 },
        { name: 'Buster', weightMap: 43359 },
        { name: 'Caben', weightMap: 43362 },
        { name: 'Cai', weightMap: 43365 },
        { name: 'Cairo', weightMap: 43368 },
        { name: 'Cal', weightMap: 43371 },
        { name: 'Cammy', weightMap: 43374 },
        { name: 'Cassian', weightMap: 43377 },
        { name: 'Ceejay', weightMap: 43380 },
        { name: 'Chase', weightMap: 43383 },
        { name: 'Che', weightMap: 43386 },
        { name: 'Chris', weightMap: 43389 },
        { name: 'Clyde', weightMap: 43392 },
        { name: 'Cobie', weightMap: 43395 },
        { name: 'Coll', weightMap: 43398 },
        { name: 'Colson', weightMap: 43401 },
        { name: 'Connal', weightMap: 43404 },
        { name: 'Connell', weightMap: 43407 },
        { name: 'Crawford', weightMap: 43410 },
        { name: 'Cristian', weightMap: 43413 },
        { name: 'Cyrus', weightMap: 43416 },
        { name: 'Dale', weightMap: 43419 },
        { name: 'Damon', weightMap: 43422 },
        { name: 'Dante', weightMap: 43425 },
        { name: 'Darach', weightMap: 43428 },
        { name: 'Darius', weightMap: 43431 },
        { name: 'Darryl', weightMap: 43434 },
        { name: 'Daryl', weightMap: 43437 },
        { name: 'Davis', weightMap: 43440 },
        { name: 'Dennis', weightMap: 43443 },
        { name: 'Desmond', weightMap: 43446 },
        { name: 'Devyn', weightMap: 43449 },
        { name: 'Diego', weightMap: 43452 },
        { name: 'Donnie', weightMap: 43455 },
        { name: 'Donovan', weightMap: 43458 },
        { name: 'Easton', weightMap: 43461 },
        { name: 'Eddy', weightMap: 43464 },
        { name: 'Edgar', weightMap: 43467 },
        { name: 'Elvin', weightMap: 43470 },
        { name: 'Elyas', weightMap: 43473 },
        { name: 'Enoch', weightMap: 43476 },
        { name: 'Eoghan', weightMap: 43479 },
        { name: 'Everett', weightMap: 43482 },
        { name: 'Faisal', weightMap: 43485 },
        { name: 'Fallon', weightMap: 43488 },
        { name: 'Fionnlagh', weightMap: 43491 },
        { name: 'Forbes', weightMap: 43494 },
        { name: 'Franco', weightMap: 43497 },
        { name: 'Fredrick', weightMap: 43500 },
        { name: 'Gael', weightMap: 43503 },
        { name: 'Garry', weightMap: 43506 },
        { name: 'Gene', weightMap: 43509 },
        { name: 'Giovanni', weightMap: 43512 },
        { name: 'Graham', weightMap: 43515 },
        { name: 'Grant', weightMap: 43518 },
        { name: 'Gray', weightMap: 43521 },
        { name: 'Hari', weightMap: 43524 },
        { name: 'Harlo', weightMap: 43527 },
        { name: 'Hendrix', weightMap: 43530 },
        { name: 'Hussain', weightMap: 43533 },
        { name: 'Idris', weightMap: 43536 },
        { name: 'Iker', weightMap: 43539 },
        { name: 'Indiana', weightMap: 43542 },
        { name: 'Innis', weightMap: 43545 },
        { name: 'Irvine', weightMap: 43548 },
        { name: 'Ismaeel', weightMap: 43551 },
        { name: 'Issac', weightMap: 43554 },
        { name: 'Jaden', weightMap: 43557 },
        { name: 'Jaidyn', weightMap: 43560 },
        { name: 'Jaxen', weightMap: 43563 },
        { name: 'Jaxon-James', weightMap: 43566 },
        { name: 'Jaxyn', weightMap: 43569 },
        { name: 'Jayson', weightMap: 43572 },
        { name: 'Jeremiah', weightMap: 43575 },
        { name: 'Jett', weightMap: 43578 },
        { name: 'Jock', weightMap: 43581 },
        { name: 'Johnathan', weightMap: 43584 },
        { name: 'Jon', weightMap: 43587 },
        { name: 'Jules', weightMap: 43590 },
        { name: 'Julius', weightMap: 43593 },
        { name: 'Kaelan', weightMap: 43596 },
        { name: 'Kaidan', weightMap: 43599 },
        { name: 'Kaison', weightMap: 43602 },
        { name: 'Kelvin', weightMap: 43605 },
        { name: 'Kenny', weightMap: 43608 },
        { name: 'Kenzi', weightMap: 43611 },
        { name: 'Khalid', weightMap: 43614 },
        { name: 'Kiyan', weightMap: 43617 },
        { name: 'Kobie', weightMap: 43620 },
        { name: 'Kodi', weightMap: 43623 },
        { name: 'Kohen', weightMap: 43626 },
        { name: 'Kylian', weightMap: 43629 },
        { name: 'Kyro', weightMap: 43632 },
        { name: 'Kyson', weightMap: 43635 },
        { name: 'Leonidas', weightMap: 43638 },
        { name: 'Leroy', weightMap: 43641 },
        { name: 'Levison', weightMap: 43644 },
        { name: 'Lex', weightMap: 43647 },
        { name: 'Lukasz', weightMap: 43650 },
        { name: 'Matas', weightMap: 43653 },
        { name: 'Matias', weightMap: 43656 },
        { name: 'Mattia', weightMap: 43659 },
        { name: 'Maverick', weightMap: 43662 },
        { name: 'Mckinley', weightMap: 43665 },
        { name: 'Mirza', weightMap: 43668 },
        { name: 'Nate', weightMap: 43671 },
        { name: 'Neil', weightMap: 43674 },
        { name: 'Nelson', weightMap: 43677 },
        { name: 'Neo', weightMap: 43680 },
        { name: 'Nikita', weightMap: 43683 },
        { name: 'Nikolas', weightMap: 43686 },
        { name: 'Nyle', weightMap: 43689 },
        { name: 'Orion', weightMap: 43692 },
        { name: 'Pierce', weightMap: 43695 },
        { name: 'Raife', weightMap: 43698 },
        { name: 'Ralphy', weightMap: 43701 },
        { name: 'Rayan', weightMap: 43704 },
        { name: 'Remi', weightMap: 43707 },
        { name: 'Reni', weightMap: 43710 },
        { name: 'Rhuairidh', weightMap: 43713 },
        { name: 'Rhudi', weightMap: 43716 },
        { name: 'Ricksen', weightMap: 43719 },
        { name: 'Riyansh', weightMap: 43722 },
        { name: 'Rodrigo', weightMap: 43725 },
        { name: 'Ronald', weightMap: 43728 },
        { name: 'Roy', weightMap: 43731 },
        { name: 'Ruadhan', weightMap: 43734 },
        { name: 'Rui', weightMap: 43737 },
        { name: 'Salah', weightMap: 43740 },
        { name: 'Sebastien', weightMap: 43743 },
        { name: 'Shane', weightMap: 43746 },
        { name: 'Soren', weightMap: 43749 },
        { name: 'Sorren', weightMap: 43752 },
        { name: 'Sullivan', weightMap: 43755 },
        { name: 'Tadhg', weightMap: 43758 },
        { name: 'Tait', weightMap: 43761 },
        { name: 'Talha', weightMap: 43764 },
        { name: 'Thom', weightMap: 43767 },
        { name: 'Thorin', weightMap: 43770 },
        { name: 'Tiago', weightMap: 43773 },
        { name: 'Timothy', weightMap: 43776 },
        { name: 'Titus', weightMap: 43779 },
        { name: 'Tobiasz', weightMap: 43782 },
        { name: 'Tony', weightMap: 43785 },
        { name: 'Trey', weightMap: 43788 },
        { name: 'Tucker', weightMap: 43791 },
        { name: 'Tyler-James', weightMap: 43794 },
        { name: 'Tyr', weightMap: 43797 },
        { name: 'Viktor', weightMap: 43800 },
        { name: 'Vito', weightMap: 43803 },
        { name: 'Vivaan', weightMap: 43806 },
        { name: 'Wade', weightMap: 43809 },
        { name: 'Walter', weightMap: 43812 },
        { name: 'Younes', weightMap: 43815 },
        { name: 'Yuvraj', weightMap: 43818 },
        { name: 'Zakaria', weightMap: 43821 },
        { name: 'Zakariyya', weightMap: 43824 },
        { name: 'Zayyan', weightMap: 43827 },
        { name: 'Zidan', weightMap: 43830 },
        { name: 'Ziggy', weightMap: 43833 },
        { name: 'Zion', weightMap: 43836 },
        { name: 'Zohaib', weightMap: 43839 },
        { name: 'Aaila', weightMap: 43842 },
        { name: 'Adalyn', weightMap: 43845 },
        { name: 'Adelina', weightMap: 43848 },
        { name: 'Adia', weightMap: 43851 },
        { name: 'Adley', weightMap: 43854 },
        { name: 'Adrianna', weightMap: 43857 },
        { name: 'Aeryn', weightMap: 43860 },
        { name: 'Afton', weightMap: 43863 },
        { name: 'Aisling', weightMap: 43866 },
        { name: 'Alaya', weightMap: 43869 },
        { name: 'Alise', weightMap: 43872 },
        { name: 'Alison', weightMap: 43875 },
        { name: 'Alissa', weightMap: 43878 },
        { name: 'Alissia', weightMap: 43881 },
        { name: 'Ameerah', weightMap: 43884 },
        { name: 'Amilia', weightMap: 43887 },
        { name: 'Andii', weightMap: 43890 },
        { name: 'Anita', weightMap: 43893 },
        { name: 'Anne', weightMap: 43896 },
        { name: 'Ariel', weightMap: 43899 },
        { name: 'Ariela', weightMap: 43902 },
        { name: 'Arizona', weightMap: 43905 },
        { name: 'Arwa', weightMap: 43908 },
        { name: 'Aryah', weightMap: 43911 },
        { name: 'Aryia', weightMap: 43914 },
        { name: 'Aspen', weightMap: 43917 },
        { name: 'Ava-May', weightMap: 43920 },
        { name: 'Avanna', weightMap: 43923 },
        { name: 'Ayah', weightMap: 43926 },
        { name: 'Ayra', weightMap: 43929 },
        { name: 'Beatriz', weightMap: 43932 },
        { name: 'Betsy', weightMap: 43935 },
        { name: 'Bibiana', weightMap: 43938 },
        { name: 'Blanka', weightMap: 43941 },
        { name: 'Blythe', weightMap: 43944 },
        { name: 'Brea', weightMap: 43947 },
        { name: 'Breanna', weightMap: 43950 },
        { name: 'Bronagh', weightMap: 43953 },
        { name: 'Bryony', weightMap: 43956 },
        { name: 'Cadence', weightMap: 43959 },
        { name: 'Camryn', weightMap: 43962 },
        { name: 'Cassia', weightMap: 43965 },
        { name: 'Catalina', weightMap: 43968 },
        { name: 'Cece', weightMap: 43971 },
        { name: 'Celine', weightMap: 43974 },
        { name: 'Clio', weightMap: 43977 },
        { name: 'Clodagh', weightMap: 43980 },
        { name: 'Coral', weightMap: 43983 },
        { name: "D'arcy", weightMap: 43986 },
        { name: 'Danni', weightMap: 43989 },
        { name: 'Dara', weightMap: 43992 },
        { name: 'Deborah', weightMap: 43995 },
        { name: 'Dina', weightMap: 43998 },
        { name: 'Ebony-Rose', weightMap: 44001 },
        { name: 'Eevie', weightMap: 44004 },
        { name: 'Effy', weightMap: 44007 },
        { name: 'Elia', weightMap: 44010 },
        { name: 'Elif', weightMap: 44013 },
        { name: 'Ella-Mae', weightMap: 44016 },
        { name: 'Elouise', weightMap: 44019 },
        { name: 'Elsie-Mae', weightMap: 44022 },
        { name: 'Emaan', weightMap: 44025 },
        { name: 'Eman', weightMap: 44028 },
        { name: 'Emery', weightMap: 44031 },
        { name: 'Emilia-Rose', weightMap: 44034 },
        { name: 'Emilie-Rose', weightMap: 44037 },
        { name: 'Emma-Rose', weightMap: 44040 },
        { name: 'Eryn', weightMap: 44043 },
        { name: 'Erynn', weightMap: 44046 },
        { name: 'Everleigh', weightMap: 44049 },
        { name: 'Evie-Rose', weightMap: 44052 },
        { name: 'Fia', weightMap: 44055 },
        { name: 'Franki', weightMap: 44058 },
        { name: 'Freyah', weightMap: 44061 },
        { name: 'Frida', weightMap: 44064 },
        { name: 'Gaia', weightMap: 44067 },
        { name: 'Giorgia', weightMap: 44070 },
        { name: 'Hadley', weightMap: 44073 },
        { name: 'Hafsah', weightMap: 44076 },
        { name: 'Harli', weightMap: 44079 },
        { name: 'Harlo', weightMap: 44082 },
        { name: 'Hawa', weightMap: 44085 },
        { name: 'Hayleigh', weightMap: 44088 },
        { name: 'Helen', weightMap: 44091 },
        { name: 'Honey', weightMap: 44094 },
        { name: 'Huda', weightMap: 44097 },
        { name: 'Iman', weightMap: 44100 },
        { name: 'Inara', weightMap: 44103 },
        { name: 'Indi', weightMap: 44106 },
        { name: 'Isobelle', weightMap: 44109 },
        { name: 'Izabela', weightMap: 44112 },
        { name: 'Izabella', weightMap: 44115 },
        { name: 'Jade', weightMap: 44118 },
        { name: 'Jamie-Leigh', weightMap: 44121 },
        { name: 'Jannat', weightMap: 44124 },
        { name: 'Jessika', weightMap: 44127 },
        { name: 'Jocelyn', weightMap: 44130 },
        { name: 'Jodi', weightMap: 44133 },
        { name: 'Jodie', weightMap: 44136 },
        { name: 'Joelle', weightMap: 44139 },
        { name: 'Jorji', weightMap: 44142 },
        { name: 'Juliana', weightMap: 44145 },
        { name: 'Julie', weightMap: 44148 },
        { name: 'Juniper', weightMap: 44151 },
        { name: 'Jura', weightMap: 44154 },
        { name: 'Kadie', weightMap: 44157 },
        { name: 'Kaila', weightMap: 44160 },
        { name: 'Kalia', weightMap: 44163 },
        { name: 'Karli', weightMap: 44166 },
        { name: 'Karly', weightMap: 44169 },
        { name: 'Kassidy', weightMap: 44172 },
        { name: 'Kathryn', weightMap: 44175 },
        { name: 'Khushi', weightMap: 44178 },
        { name: 'Kirstin', weightMap: 44181 },
        { name: 'Kourtney', weightMap: 44184 },
        { name: 'Lake', weightMap: 44187 },
        { name: 'Leah-Rose', weightMap: 44190 },
        { name: 'Leena', weightMap: 44193 },
        { name: 'Leigh', weightMap: 44196 },
        { name: 'Leonie', weightMap: 44199 },
        { name: 'Leora', weightMap: 44202 },
        { name: 'Lianna', weightMap: 44205 },
        { name: 'Lillianna', weightMap: 44208 },
        { name: 'Lilly-May', weightMap: 44211 },
        { name: 'Liusaidh', weightMap: 44214 },
        { name: 'Liv', weightMap: 44217 },
        { name: 'Logan', weightMap: 44220 },
        { name: 'Lorelai', weightMap: 44223 },
        { name: 'Lorena', weightMap: 44226 },
        { name: 'Luciana', weightMap: 44229 },
        { name: 'Lucja', weightMap: 44232 },
        { name: 'Lula', weightMap: 44235 },
        { name: 'Luna-May', weightMap: 44238 },
        { name: 'Lylah', weightMap: 44241 },
        { name: 'Maddie', weightMap: 44244 },
        { name: 'Magdalena', weightMap: 44247 },
        { name: 'Makayla', weightMap: 44250 },
        { name: 'Maliyah', weightMap: 44253 },
        { name: 'Marcella', weightMap: 44256 },
        { name: 'Marcy', weightMap: 44259 },
        { name: 'Margo', weightMap: 44262 },
        { name: 'Marlowe', weightMap: 44265 },
        { name: 'Marni', weightMap: 44268 },
        { name: 'Marta', weightMap: 44271 },
        { name: 'Martina', weightMap: 44274 },
        { name: 'Matylda', weightMap: 44277 },
        { name: 'Mavis', weightMap: 44280 },
        { name: 'Maxine', weightMap: 44283 },
        { name: 'May', weightMap: 44286 },
        { name: 'Mayah', weightMap: 44289 },
        { name: 'Meabh', weightMap: 44292 },
        { name: 'Meerab', weightMap: 44295 },
        { name: 'Milah', weightMap: 44298 },
        { name: 'Mina', weightMap: 44301 },
        { name: 'Mira', weightMap: 44304 },
        { name: 'Mirrin', weightMap: 44307 },
        { name: 'Miya', weightMap: 44310 },
        { name: 'Monika', weightMap: 44313 },
        { name: 'Morag', weightMap: 44316 },
        { name: 'Muireann', weightMap: 44319 },
        { name: 'Nara', weightMap: 44322 },
        { name: 'Natalya', weightMap: 44325 },
        { name: 'Nel', weightMap: 44328 },
        { name: 'Nikola', weightMap: 44331 },
        { name: 'Niomi', weightMap: 44334 },
        { name: 'Octavia', weightMap: 44337 },
        { name: 'Olivia-Grace', weightMap: 44340 },
        { name: 'Olivia-May', weightMap: 44343 },
        { name: 'Oonagh', weightMap: 44346 },
        { name: 'Orlaigh', weightMap: 44349 },
        { name: 'Paris', weightMap: 44352 },
        { name: 'Paulina', weightMap: 44355 },
        { name: 'Pearl', weightMap: 44358 },
        { name: 'Peighton', weightMap: 44361 },
        { name: 'Pennie', weightMap: 44364 },
        { name: 'Perri', weightMap: 44367 },
        { name: 'Poppi', weightMap: 44370 },
        { name: 'Presley', weightMap: 44373 },
        { name: 'Ramona', weightMap: 44376 },
        { name: 'Rana', weightMap: 44379 },
        { name: 'Reem', weightMap: 44382 },
        { name: 'Reva', weightMap: 44385 },
        { name: 'Rey', weightMap: 44388 },
        { name: 'Rhyleigh', weightMap: 44391 },
        { name: 'Rital', weightMap: 44394 },
        { name: 'Roisin', weightMap: 44397 },
        { name: 'Romi', weightMap: 44400 },
        { name: 'Rosalyn', weightMap: 44403 },
        { name: 'Roza', weightMap: 44406 },
        { name: 'Runa', weightMap: 44409 },
        { name: 'Rylie', weightMap: 44412 },
        { name: 'Saanvi', weightMap: 44415 },
        { name: 'Sabrina', weightMap: 44418 },
        { name: 'Safaa', weightMap: 44421 },
        { name: 'Salma', weightMap: 44424 },
        { name: 'Sapphire', weightMap: 44427 },
        { name: 'Saylor', weightMap: 44430 },
        { name: 'Sham', weightMap: 44433 },
        { name: 'Shannon', weightMap: 44436 },
        { name: 'Shea', weightMap: 44439 },
        { name: 'Shiloh', weightMap: 44442 },
        { name: 'Sienna-Rose', weightMap: 44445 },
        { name: 'Sila', weightMap: 44448 },
        { name: 'Simona', weightMap: 44451 },
        { name: 'Simrah', weightMap: 44454 },
        { name: 'Siobhan', weightMap: 44457 },
        { name: 'Sky', weightMap: 44460 },
        { name: 'Sofie', weightMap: 44463 },
        { name: 'Soraya', weightMap: 44466 },
        { name: 'Susanna', weightMap: 44469 },
        { name: 'Tahlia', weightMap: 44472 },
        { name: 'Teigan', weightMap: 44475 },
        { name: 'Tess', weightMap: 44478 },
        { name: 'Theodora', weightMap: 44481 },
        { name: 'Tierney', weightMap: 44484 },
        { name: 'Toni', weightMap: 44487 },
        { name: 'Vera', weightMap: 44490 },
        { name: 'Vivien', weightMap: 44493 },
        { name: 'Winnie', weightMap: 44496 },
        { name: 'Yasmin', weightMap: 44499 },
        { name: 'Yusra', weightMap: 44502 },
        { name: 'Zoha', weightMap: 44505 },
        { name: 'Zola', weightMap: 44508 },
        { name: 'Zuri', weightMap: 44511 },
        { name: 'Aahil', weightMap: 44513 },
        { name: 'Aarav', weightMap: 44515 },
        { name: 'Aayden', weightMap: 44517 },
        { name: 'Abu-Bakr', weightMap: 44519 },
        { name: 'Adair', weightMap: 44521 },
        { name: 'Adamas', weightMap: 44523 },
        { name: 'Aderemi', weightMap: 44525 },
        { name: 'Adi', weightMap: 44527 },
        { name: 'Adie', weightMap: 44529 },
        { name: 'Adomas', weightMap: 44531 },
        { name: 'Afan', weightMap: 44533 },
        { name: 'Affan', weightMap: 44535 },
        { name: 'Agampreet', weightMap: 44537 },
        { name: 'Ailbe', weightMap: 44539 },
        { name: 'Akram', weightMap: 44541 },
        { name: 'Albaraa', weightMap: 44543 },
        { name: 'Alberto', weightMap: 44545 },
        { name: 'Alby', weightMap: 44547 },
        { name: 'Alejandro', weightMap: 44549 },
        { name: 'Aleksandr', weightMap: 44551 },
        { name: 'Alessio', weightMap: 44553 },
        { name: 'Alexander-James', weightMap: 44555 },
        { name: 'Alexei', weightMap: 44557 },
        { name: 'Alexis', weightMap: 44559 },
        { name: 'Amaar', weightMap: 44561 },
        { name: 'Amin', weightMap: 44563 },
        { name: 'Anakin', weightMap: 44565 },
        { name: 'Andres', weightMap: 44567 },
        { name: 'Aoun', weightMap: 44569 },
        { name: 'Aram', weightMap: 44571 },
        { name: 'Aras', weightMap: 44573 },
        { name: 'Arham', weightMap: 44575 },
        { name: 'Arian', weightMap: 44577 },
        { name: 'Ariel', weightMap: 44579 },
        { name: 'Aris', weightMap: 44581 },
        { name: 'Arlen', weightMap: 44583 },
        { name: 'Arnas', weightMap: 44585 },
        { name: 'Arrin', weightMap: 44587 },
        { name: 'Arun', weightMap: 44589 },
        { name: 'Arvin', weightMap: 44591 },
        { name: 'Arya', weightMap: 44593 },
        { name: 'Ashton-James', weightMap: 44595 },
        { name: 'Ashwin', weightMap: 44597 },
        { name: 'Atholl', weightMap: 44599 },
        { name: 'Auley', weightMap: 44601 },
        { name: 'Austyn', weightMap: 44603 },
        { name: 'Avyaan', weightMap: 44605 },
        { name: 'Ayaz', weightMap: 44607 },
        { name: 'Ayrton', weightMap: 44609 },
        { name: 'Basil', weightMap: 44611 },
        { name: 'Beinn', weightMap: 44613 },
        { name: 'Beniamin', weightMap: 44615 },
        { name: 'Benny', weightMap: 44617 },
        { name: 'Bjorn', weightMap: 44619 },
        { name: 'Blue', weightMap: 44621 },
        { name: 'Bo', weightMap: 44623 },
        { name: 'Boyd', weightMap: 44625 },
        { name: 'Brae', weightMap: 44627 },
        { name: 'Bram', weightMap: 44629 },
        { name: 'Brax', weightMap: 44631 },
        { name: 'Braxton', weightMap: 44633 },
        { name: 'Braydan', weightMap: 44635 },
        { name: 'Brett', weightMap: 44637 },
        { name: 'Brodi', weightMap: 44639 },
        { name: 'Brook', weightMap: 44641 },
        { name: 'Brydon', weightMap: 44643 },
        { name: 'Cade', weightMap: 44645 },
        { name: 'Caiben', weightMap: 44647 },
        { name: 'Cailan', weightMap: 44649 },
        { name: 'Cailen', weightMap: 44651 },
        { name: 'Calder', weightMap: 44653 },
        { name: 'Calen', weightMap: 44655 },
    ];
}
