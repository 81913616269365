import type { PriceModifier, PriceModifierType, Service, TranslationKey } from '@nexdynamic/squeegee-common';
import { TagType, copyObject, to2dp, uuid } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { FileUploadDialog } from '../Dialogs/FileUploadDialog';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';

export class AdvancedServicesService {
    static async publishServiceChanges() {
        const url = `/api/directory/secure/${ApplicationState.dataEmail}/regenerate`;
        const response = await Api.put<{ success: boolean; errors: Array<string> }>(null, url, {});
        new NotifyUserMessage(
            (response?.data?.success
                ? 'Updated service details published to portal.'
                : 'failed to publish updated service details.') as TranslationKey
        );
    }

    static async getPriceModifierValues(existing?: PriceModifier, id = uuid(), forceType?: PriceModifierType) {
        // effect type
        let modifierType = forceType;
        if (!modifierType) {
            const options: Array<{
                text: string;
                name: PriceModifierType;
            }> = [
                { text: 'portal.modifier-percentage-increase', name: 'percentage' },
                { text: 'portal.modifier-fixed-increase', name: 'fixed' },
            ];
            const modifierTypePicker = new Select('dialogs.available-frequency-effect-type-select', options, 'text', 'name');
            modifierType = (await modifierTypePicker.show()).name;
        }
        if (!modifierType) return;

        const fixed = modifierType === 'fixed';
        const priceModifier: PriceModifier = {
            id: id + '-effect',
            type: modifierType,
            value: existing?.value || (fixed ? 0 : 100),
        };

        // effect amount
        const modifierValueDialogTitle: TranslationKey = fixed
            ? 'dialogs.available-frequency-effect-fixed'
            : 'dialogs.available-frequency-effect-percentage';
        const modifierValueDialogInputText: TranslationKey = fixed
            ? 'dialogs.available-frequency-effect-fixed-input'
            : 'dialogs.available-frequency-effect-percentage-input';
        const modifierValueDialog = new TextDialog(
            modifierValueDialogTitle,
            modifierValueDialogInputText,
            priceModifier.value.toString(),
            modifierValueDialogInputText,
            value => {
                const parsedValue = parseFloat(value);
                if (isNaN(parsedValue) || to2dp(parsedValue) !== parsedValue) return 'validation.number-required-max-two-decimal-places';

                if (fixed || parsedValue >= 0) return true;

                return 'validation.percentage-invalid';
            },
            false,
            'number-2dp'
        );
        const modifierValueDialogResult = await modifierValueDialog.show();

        if (modifierValueDialog.cancelled) return;
        else priceModifier.value = parseFloat(modifierValueDialogResult);

        return priceModifier;
    }

    static exportAdvancedServices = (includeImages: boolean) => {
        new LoaderEvent(true, true);

        const advancedServices = Data.all<Service>('tags', s => s.showOnDirectory || !!s.parentId, { type: TagType.SERVICE }).slice();

        const exporter = RethinkDbAuthClient.session?.email || ApplicationState.dataEmail;

        const copiedAdvancedServices = AdvancedServicesService.cloneAndAnonymiseAdvancedServices(advancedServices, exporter, includeImages);

        const advancedServicesJson = JSON.stringify(copiedAdvancedServices, null, 2);

        const downloadUrl = `data:application/json;charset=utf-8,${encodeURIComponent(advancedServicesJson)}`;

        const link = document.createElement('a');
        link.download = `advanced_services_export-${moment().format('YYYY-MM-DD_HH-mm-ss')}.json`;

        link.href = downloadUrl;

        link.click();

        new LoaderEvent(false);
    };

    static importAdvancedServices = async () => {
        const dialog = new FileUploadDialog(['json'], 'upload.upload-your-downloaded-services', 1048576, false);

        const files = await dialog.show();
        if (!dialog.cancelled) {
            if (!files || !files.length) return;

            const existingData = Data.all<Service>('tags', s => s.showOnDirectory || (!!s.parentId && !s._deleted), {
                type: TagType.SERVICE,
            }).slice();
            console.log(existingData);
            if (existingData.length > 0) {
                const clearExistingData = await new Prompt('dialogs.clear-existing-data', 'dialogs.clear-existing-data-description', {
                    okLabel: 'dialogs.clear-existing-data-ok',
                    cancelLabel: 'dialogs.clear-existing-data-cancel',
                }).show();

                if (clearExistingData) {
                    await Data.delete(existingData);
                }
            }
            new LoaderEvent(true, true);

            const file = files[0];

            const advancedServicesJson = await file.text();

            const advancedServices = JSON.parse(advancedServicesJson);

            const clonedServices = AdvancedServicesService.cloneAndAnonymiseAdvancedServices(
                advancedServices,
                ApplicationState.dataEmail,
                true
            );

            await Data.put(clonedServices);

            new LoaderEvent(false);
        }
    };

    private static cloneAndAnonymiseAdvancedServices(advancedServices: Array<Service>, exporter: string, includeImages: boolean) {
        const idDictionary: Record<string, string> = {};
        const getNewId = (oldId: string) => idDictionary[oldId] || (idDictionary[oldId] = uuid());

        const copiedAdvancedServices = copyObject(advancedServices);
        for (const advancedService of copiedAdvancedServices) {
            advancedService.createdBy = exporter;
            advancedService.createdDate = '';
            advancedService.createdOnDevice = '';
            advancedService.ownerEmail = '';
            if (advancedService.parentId) advancedService.parentId = getNewId(advancedService.parentId);
            advancedService.updatedByUser = '';
            advancedService.updatedDate = '';
            advancedService.updatedOnDevice = '';
            advancedService._externalId = '';
            advancedService.externalIds = {
                sourceAccount: ApplicationState.dataEmail,
            };
            if (!includeImages) advancedService.imageInfo = undefined;

            advancedService._id = getNewId(advancedService._id);
        }
        return copiedAdvancedServices;
    }
}
