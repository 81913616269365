import type {
    AutomaticPaymentTransactionSubType,
    Customer,
    IPaymentProviderMetadata,
    Job,
    JobGroup,
    JobOrderData,
    PaymentTransactionSubType,
    StoredEvent,
    StoredObject,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    FrequencyBuilder,
    FrequencyType,
    JobOccurrence,
    JobOccurrenceStatus,
    TransactionType,
    copyObject,
    getPropertyFunctionParams,
    sortByCreatedDateDesc,
    sortByDateDesc,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { DateTimePicker } from './Components/DateTimePicker/DateTimePicker';
import { Data } from './Data/Data';
import { Prompt } from './Dialogs/Prompt';
import { Select } from './Dialogs/Select';
import { TextDialog } from './Dialogs/TextDialog';
import { LoaderEvent } from './Events/LoaderEvent';
import { JobService } from './Jobs/JobService';
import { Logger } from './Logger';
import { ScheduleService } from './Schedule/ScheduleService';
import { Api } from './Server/Api';
import { AssignmentService } from './Users/Assignees/AssignmentService';
import { Utilities } from './Utilities';

type OldStoredEvent = StoredEvent & { metaData: { relatedIds: { customerId: string; storedObjectIds: string[] } } };

const toolDictionary = {
    'Clear customer to number Clicksend cache': async () => {
        const customers = Data.all<Customer>('customers');
        const numbers = customers.map(c => c.telephoneNumber).filter(n => n);
        const otherNumbers = customers.map(c => c.telephoneNumberOther).filter(n => n);
        const tos = numbers.concat(otherNumbers);
        const result = await Api.post<{ ok: boolean; error: string }>(null, '/api/clicksend/clearcache', {
            tos,
        });
        if (result?.data?.ok) {
            return Prompt.info('general.status-completed');
        } else {
            return Prompt.info((result?.data?.error || 'No response') as TranslationKey);
        }
    },

    'Set customer automatic payment methods': async () => {
        const includeGoCardless = await new Prompt('include.gocardless', 'include.gocardless-in-automatic-payment-methods', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        }).show();

        const includeStripe = await new Prompt('include.stripe', 'include.stripe-in-automatic-payment-methods', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        }).show();

        if (!includeGoCardless && !includeStripe) return;

        let preferred: 'Stripe' | 'GoCardless';
        if (includeGoCardless && !includeStripe) {
            preferred = 'GoCardless';
        } else if (!includeGoCardless && includeStripe) {
            preferred = 'Stripe';
        } else {
            const preferStripe = await new Prompt('payment.method-preference', 'payment.method-preference-customer-both', {
                okLabel: 'Stripe',
                cancelLabel: 'GoCardless',
            }).show();

            preferred = preferStripe ? 'Stripe' : 'GoCardless';
        }

        const setDefaultForCustomersWhoAreNotSetupYet = await new Prompt(
            'update.customer-default-payment-type',
            ('Would you also like set ' +
                preferred +
                ' as the default for customers who have not yet registered on ' +
                preferred +
                '?') as TranslationKey,
            { okLabel: 'general.yes', cancelLabel: 'general.no' }
        ).show();

        for (const customer of Data.all<Customer>('customers')) {
            const hasGoCardless =
                customer.paymentProviderMetaData?.gocardless?.sourceOrMandateId &&
                (customer.paymentProviderMetaData?.gocardless?.status === 'active' ||
                    customer.paymentProviderMetaData?.gocardless?.status === 'pending');

            const hasStripe =
                customer.paymentProviderMetaData?.stripe?.sourceOrMandateId &&
                customer.paymentProviderMetaData?.stripe?.status === 'active';

            if (hasGoCardless && (preferred === 'GoCardless' || (!hasStripe && includeGoCardless))) {
                if (customer.automaticPaymentMethod === 'gocardless' && customer.takePaymentOnInvoiced) continue;
                customer.automaticPaymentMethod = 'gocardless';
                customer.takePaymentOnInvoiced = true;
                Data.put(customer);
                continue;
            }

            if (hasStripe && (preferred === 'Stripe' || (!hasGoCardless && includeStripe))) {
                if (customer.automaticPaymentMethod === 'stripe' && customer.takePaymentOnInvoiced) continue;
                customer.automaticPaymentMethod = 'stripe';
                customer.takePaymentOnInvoiced = true;
                Data.put(customer);
                continue;
            }

            if (!hasStripe && !hasGoCardless && setDefaultForCustomersWhoAreNotSetupYet) {
                const method = preferred === 'GoCardless' ? 'gocardless' : 'stripe';
                if (customer.automaticPaymentMethod === method && customer.takePaymentOnInvoiced) continue;
                customer.automaticPaymentMethod = method;
                customer.takePaymentOnInvoiced = true;
                Data.put(customer);
                continue;
            }
        }
    },
    'Set customer last payment methods': () => {
        for (const customer of Data.all<Customer>('customers')) {
            const payments = Data.all<Transaction>(
                'transactions',
                t =>
                    (t.transactionType === TransactionType.Payment || t.transactionType === TransactionType.AutomaticPayment) &&
                    !!t.transactionSubType &&
                    t.transactionSubType !== 'payment.refund' &&
                    t.transactionSubType !== 'payment.imported' &&
                    t.transactionSubType !== 'auto.other' &&
                    t.transactionSubType !== 'invoice.credit-note' &&
                    t.transactionSubType !== 'invoice.write-off',
                {
                    customerId: customer._id,
                }
            )
                .slice()
                .sort(sortByCreatedDateDesc);
            if (payments.length) {
                customer.lastPaymentMethod = payments[0].transactionSubType as
                    | AutomaticPaymentTransactionSubType
                    | PaymentTransactionSubType;
                Data.put(customer);
            }
        }
        return Prompt.info('update.customer-last-payment-methods-for-all-customers-complete');
    },
    'Fix all job schedules': async () => {
        const dryRun = await SqueegeeTools.dryRun();
        let totalUpdated = 0;
        for (const customer of Data.all<Customer>('customers')) {
            let updated = false;
            for (const jobId in customer.jobs || {}) {
                if (Utilities.fixJobSchedule(customer.jobs[jobId], dryRun)) updated = true;
            }
            if (updated) {
                Data.put(customer);
                totalUpdated++;
            }
        }
        return Prompt.info(`Fix job schedules complete, updated ${totalUpdated} jobs updated.` as TranslationKey);
    },
    'Mark done uninvoiced jobs as unbillable before date': async () => {
        const datePickerDialog = new DateTimePicker(false, '2021-01-01');
        datePickerDialog.init();
        datePickerDialog.title = 'select.cut-off-date';
        await datePickerDialog.open();
        if (datePickerDialog.canceled) return;

        const before = datePickerDialog.selectedDate;
        if (!before) return;
        const toProcess = [];
        for (const o of JSON.parse(JSON.stringify(Data.all('joboccurrences')))) {
            if (o.status !== 1 || o.invoiceTransactionId || o.paymentStatus === 'unbillable') continue;
            if (!o.isoDueDate || o.isoDueDate >= before) continue;
            toProcess.push(o);
        }

        if (!toProcess.length)
            return new Prompt('general.status-completed', ('No uninvoiced done jobs found before ' + before) as TranslationKey, {
                cancelLabel: '',
            }).show();

        const goAheadDialog = new Prompt(
            `${toProcess.length} Unbillable Jobs Found` as TranslationKey,
            `${toProcess.length} unbillable jobs were found with due dates before ${before}, continue and mark them all as unbillable?` as TranslationKey,
            { okLabel: 'general.continue', cancelLabel: 'general.cancel' }
        );

        const goAhead = await goAheadDialog.show();

        if (goAhead) {
            for (const o of toProcess) o.paymentStatus = 'unbillable';
            Data.put(toProcess);
            return new Prompt(
                'general.status-completed',
                (toProcess.length + ' uninvoiced done jobs found before ' + before + ' and marked as unbillable') as TranslationKey,
                { cancelLabel: '' }
            ).show();
        }
    },
    'Upgrade gocardless mandate data to new model': async () => {
        const customersWithMandateId = Data.all<Customer & { mandateId?: string; automaticPaymentStatus: string }>(
            'customers',
            (c: Customer & { mandateId?: string; automaticPaymentStatus: string }) =>
                (c.automaticPaymentMethod === 'gocardless' && !!c.mandateId && !c.paymentProviderMetaData?.gocardless?.sourceOrMandateId) ||
                (c.automaticPaymentMethod === 'gocardless' &&
                    !!c.automaticPaymentStatus &&
                    c.automaticPaymentStatus !== 'invited' &&
                    !c.paymentProviderMetaData?.gocardless?.status)
        );
        if (customersWithMandateId.length) {
            for (const customer of customersWithMandateId) {
                if (!customer.mandateId) continue;
                if (!customer.paymentProviderMetaData) customer.paymentProviderMetaData = {};
                if (!customer.paymentProviderMetaData.gocardless)
                    customer.paymentProviderMetaData.gocardless = {
                        provider: 'gocardless',
                        customerId: customer.mandateId,
                        sourceOrMandateId: customer.mandateId,
                        status: customer.automaticPaymentStatus === 'invited' ? undefined : customer.automaticPaymentStatus,
                        inviteNotificationId: customer.automaticPaymentStatus === 'invited' ? 'invited' : undefined,
                    } as IPaymentProviderMetadata;
                else {
                    customer.paymentProviderMetaData.gocardless.sourceOrMandateId = customer.mandateId;
                }
            }

            await Data.put(customersWithMandateId as any as Array<StoredObject>);
        }
    },
    'Fix incorrectly voided and duplicate void gocardless payments': async () => {
        let fixedIncorrectlyVoided = 0;
        const automaticPayments = Data.all<Transaction>('transactions', { transactionType: TransactionType.AutomaticPayment });
        for (const payment of automaticPayments) {
            // Ignore missing customer ID.
            if (!payment.customerId) continue;

            // Ignore if not failed.
            if (payment.status !== 'failed') continue;

            // Ignore if not succeeded.
            if (!payment.paymentDetails?.paymentHistory?.some(t => t.action === 'confirmed' || t.action === 'paid_out')) continue;

            if (payment.voidedId) {
                const voided = Data.get(payment.voidedId);
                if (voided) Data.delete(voided);
                payment.voidedId = undefined;
            }

            payment.status = 'complete';

            Data.put(payment);

            fixedIncorrectlyVoided++;
        }

        let fixedDuplicateVoids = 0;
        let fixedBrokenIds = 0;
        let failedPaymentsWithNoVoid = 0;
        for (const payment of automaticPayments) {
            // Ignore if not failed.
            if (payment.status !== 'failed') continue;

            const possibleVoids = Data.all<Transaction>('transactions', { voidedId: payment._id });

            if (possibleVoids.length === 0) {
                console.log(`No voids match on ID for payment "${payment._id}" customer "${payment.customerId}"`);
                failedPaymentsWithNoVoid++;
                // Possibly fix this here? Need to create a void...
            } else if (possibleVoids.length === 1) {
                if (possibleVoids[0]._id === payment.voidedId) {
                    // OK
                    // console.log('1 void correctly matched on ID');
                } else {
                    console.log(`1 void, match on ID is incorrect "${payment.voidedId || 'missing'}" vs "${possibleVoids[0]._id}"`);
                    payment.voidedId = possibleVoids[0]._id;
                    Data.put(payment);
                    fixedBrokenIds++;
                }
            } else if (possibleVoids.length > 1) {
                const trueVoid = possibleVoids.find(v => v._id === payment.voidedId);

                // Make sure the void ID is correct then fix by removing other voids.
                if (trueVoid) {
                    console.log(`Multiple voids found for "${payment._id}" and ""${trueVoid._id}" has a matching ID`);
                } else {
                    console.log(`Multiple voids found for "${payment._id}" and none of them has a matching ID`);
                    // Fix by updating the voidedId to match the first one then delete the others.
                    payment.voidedId = possibleVoids[0]._id;
                    Data.put(payment);
                }
                const others = possibleVoids.filter(v => v._id !== payment.voidedId);
                Data.delete(others);

                fixedDuplicateVoids += others.length;
            }
        }

        new Prompt(
            'general.complete',
            (`Fixed ${fixedIncorrectlyVoided} incorrectly voided.<br>` +
                `Fixed ${fixedBrokenIds} broken void IDs.<br>` +
                `Fixed ${fixedDuplicateVoids} duplicate voided payments.<br>` +
                `Found ${failedPaymentsWithNoVoid} failed payments with no void.`) as TranslationKey
        ).show();
    },
    'Fix lat lng inversions on occurrences': async () => {
        for (const o of Data.all<JobOccurrence>('joboccurrences')) {
            if (o.location && o.location.lngLat && o.customerId) {
                const customer = Data.get<Customer>(o.customerId);
                if (!customer) continue;

                const job = customer.jobs[o.jobId];
                if (!job?.location?.lngLat) continue;

                if (job.location.lngLat[0] === o.location.lngLat[1] && job.location.lngLat[1] === o.location.lngLat[0]) {
                    const temp = o.location.lngLat[0];
                    o.location.lngLat[0] = o.location.lngLat[1];
                    o.location.lngLat[1] = temp;
                    await Data.put<JobOccurrence>(o);
                }
            }
        }
    },
    'Fix reverted address validations': async () => {
        const dryRun = await SqueegeeTools.dryRun();
        const updatedCustomers: Array<Customer> = [];
        const updatedJobOccurrences: Array<JobOccurrence> = [];
        for (const customer of Data.all<Customer>('customers')) {
            let updatedCustomer = false;
            for (const jobId in customer.jobs || {}) {
                const job = customer.jobs[jobId];
                if (job.location) {
                    if (!job.location.isVerified) {
                        const verifiedOccurrence = Data.firstOrDefault<JobOccurrence>(
                            'joboccurrences',

                            o => (o.location && o.location.isVerified) || false,

                            { jobId }
                        );
                        if (verifiedOccurrence && verifiedOccurrence.location) {
                            if (!dryRun) job.location = Utilities.copyObject(verifiedOccurrence.location);
                            updatedCustomer = true;
                        }
                    }

                    if (job.location && job.location.isVerified) {
                        for (const o of Data.all<JobOccurrence>(
                            'joboccurrences',
                            o => (o.status === JobOccurrenceStatus.NotDone && o.location && !o.location.isVerified) || false,

                            { jobId }
                        )) {
                            if (!dryRun) o.location = Utilities.copyObject(job.location);
                            updatedJobOccurrences.push(o);
                        }
                    }
                }
            }
            if (updatedCustomer) updatedCustomers.push(customer);
        }

        if (updatedCustomers.length || updatedJobOccurrences.length) {
            if (!dryRun) Data.put((updatedCustomers as Array<StoredObject>).concat(updatedJobOccurrences));
        }

        Logger.info(
            `${dryRun ? 'Dry run complete, would have updated' : 'Updated'} ${updatedCustomers.length} customer job addresses and ${
                updatedJobOccurrences.length
            } job appointments`
        );
    },
    'Set default card for Stripe customers without a default': async () => {
        try {
            const customers = Data.all<Customer>(
                'customers',
                c => !!c.externalIds?.stripe && !c.paymentProviderMetaData?.stripe?.sourceOrMandateId
            );
            new LoaderEvent(true, true, ('Checking for cards on ' + customers.length + ' customers') as TranslationKey);
            let count = 0;
            for (const customer of customers) {
                await SqueegeeTools.setDefaultCard(customer);
                count++;
                new LoaderEvent(
                    true,
                    true,
                    ('Checked card details for ' + count + ' of ' + customers.length + ' customers') as TranslationKey
                );
            }
        } finally {
            new LoaderEvent(false);
        }
    },
    'Clean and reduce size of job order data': async () => {
        return SqueegeeTools.cleanUpJobOrderData();
    },
    'Missing historic time data, upgrade it to V2': async () => {
        function upgradeStoredEventsToV2() {
            const oldStoredEvents = Data.all<OldStoredEvent>('storedevents', x => !x.allocateTimeTo && !x.occurrenceId);
            const warnings: Array<unknown> = [];
            const storedObjectsToUpdate: Array<StoredEvent> = [];

            oldStoredEvents.forEach(oldStoredEvent => {
                const tryAndConvert = upgradeOldStoredEventToNewStoredEvent(oldStoredEvent);
                if (tryAndConvert.errors.length > 0) {
                    warnings.push(...tryAndConvert.errors);
                }
                storedObjectsToUpdate.push(...tryAndConvert.storedObjectsToUpdate);
            });

            return { errors: warnings, storedObjectsToUpdate };
        }

        function upgradeOldStoredEventToNewStoredEvent(oldStoredEvent: OldStoredEvent) {
            // Iterate through the metadata.relatedIds.storedObjectIds and find which on is a JobOccurrence

            const warnings = [];
            const storedObjectsToUpdate = [];

            for (const storedObjectId of oldStoredEvent.metaData.relatedIds.storedObjectIds) {
                const storedObject = Data.get<JobOccurrence>(storedObjectId);
                if (!storedObject) {
                    warnings.push({ error: 'Stored object not found', storedObjectId, oldStoredEvent });
                    continue;
                }
                if (storedObject?.resourceType === 'joboccurrences') {
                    // get the assignees of this occurrence and allocate th e time to them
                    const assignees = AssignmentService.getAssignees(storedObject);
                    if (assignees.length === 0) {
                        warnings.push({
                            error: 'This occurrence has no assignee so time will be recording against just job',
                            storedObject,
                            assignees,
                            oldStoredEvent,
                        });
                    } else if (assignees.length > 1) {
                        warnings.push({
                            error: 'This occurrence has more than one assignee so time will be recording against just the first one',
                            storedObject,
                            assignees,
                            oldStoredEvent,
                        });
                    }
                    storedObjectsToUpdate.push({
                        ...oldStoredEvent,
                        occurrenceId: storedObjectId,
                        jobId: storedObject.jobId,
                        customerId: storedObject.customerId,
                        allocateTimeTo: assignees[0]?._id,
                    });
                }
            }

            return { errors: warnings, storedObjectsToUpdate };
        }

        const dryRun = await SqueegeeTools.dryRun();
        const resp = upgradeStoredEventsToV2();

        if (dryRun) {
            Logger.info('Dry run complete, no changes made', resp);
        } else {
            Logger.info('Changes made', resp);
            Data.put(resp.storedObjectsToUpdate);
        }
    },
    'Fix multiple assignees on jobs and occurrences': async () => {
        const dryRun = await SqueegeeTools.dryRun();

        const jobsWithAssignees = JobService.getJobs()
            .map(x => ({
                job: x,
                assignees: AssignmentService.getAssignees(x),
            }))
            .filter(x => x.assignees.length > 1);

        const josWithAssignees = Data.all<JobOccurrence>('joboccurrences')
            .map(x => ({
                jo: x,
                assignees: AssignmentService.getAssignees(x),
            }))
            .filter(x => x.assignees.length > 1);

        Logger.info('Jobs with multiple assignees', { jobsWithAssignees, josWithAssignees });

        if (!dryRun) {
            for (const x of jobsWithAssignees) {
                const assigneeIdToAssignTo = x.assignees[x.assignees.length - 1]._id;
                await AssignmentService.assign(x.job, assigneeIdToAssignTo, true, true);
            }

            for (const x of josWithAssignees) {
                const assigneeIdToAssignTo = x.assignees[x.assignees.length - 1]._id;
                await AssignmentService.assign(x.jo, assigneeIdToAssignTo, true, true);
            }
            Logger.info('Run complete, jobs and job occurrecnes updated');
        } else {
            Logger.info('Dry run complete, no changes made');
        }
    },
    'Clean external calendar scheduled jobs': async () => {
        JobService.cleanJobExternalCalendarSettings();
    },
    'Fix job dates that have been set to the wrong day due to the monsters': async () => {
        const jobs = JobService.getJobs();
        const today = moment().format('YYYY-MM-DD');

        for (const job of jobs) {
            if (job.frequencyType === FrequencyType.NoneRecurring) continue;
            if (job.frequencyType === FrequencyType.ExternalCalendar) continue;

            let round: JobGroup | undefined = job?.rounds[0];
            if (!round) continue;

            round = Data.get<JobGroup>(round._id);
            if (!round) continue;

            if (!job.date) continue;

            const frequencyData = round.frequencyData || round.schedules?.[0];
            let date = frequencyData?.firstScheduledDate;
            if (frequencyData && date) {
                const frequency = FrequencyBuilder.getFrequencyFromDateAndFrequencyData(frequencyData);
                if (!frequency) continue;

                let count = 100;
                while (count--) {
                    const newDate: string | undefined = frequency.getNextDate(moment(date).add(1, 'day').format('YYYY-MM-DD'));
                    if (!newDate || newDate > today) break;
                    date = newDate;
                }

                if (job.date === date) continue;

                Logger.info(`Fixing next due for round scheduled job ${job._id} from ${job.date} to ${date}`);
                const customer = Data.get<Customer>(job.customerId);
                if (!customer) continue;

                const latestJob = customer.jobs[job._id];
                if (!latestJob) continue;

                latestJob.date = date;
                Data.put(customer);
            } else {
                const mostRecentOccurrence = Data.all<JobOccurrence>('joboccurrences', { jobId: job._id })
                    .map(x => {
                        return {
                            date: x.isoDueDate,
                            _id: x._id,
                        };
                    })
                    .filter(o => o.date <= today)
                    .sort(sortByDateDesc)
                    .map(o => Data.get<JobOccurrence>(o._id))[0];

                if (!mostRecentOccurrence) continue;

                if (job.date >= mostRecentOccurrence.isoDueDate) continue;

                const customer = Data.get<Customer>(job.customerId);
                if (!customer || (customer.state || 'active') !== 'active') continue;

                const latestJob = customer.jobs[job._id];
                if (!latestJob) continue;

                Logger.info(`Fixing next due for directly scheduled job ${job._id} from ${job.date} to ${date}`);

                job.date = mostRecentOccurrence.isoDueDate;
                Data.put(customer);
            }
        }
    },
};
export class SqueegeeTools {
    [name: string]: (...args: Array<any>) => any;
    public static async runTool() {
        const options = [] as Array<{ [name: string]: string }>;

        for (const name in toolDictionary) {
            options.push({ name, text: name });
        }

        const toolPicker = new Select('select.tool-squeegee-tools', options, 'text', 'name', undefined, { coverViewport: true });

        const result = await toolPicker.show();
        const func = (toolDictionary as any)[result.name];
        if (func) {
            const params = getPropertyFunctionParams(func);
            const paramValues = [] as Array<string | boolean>;
            let dryRun = false;
            if (params && params.length) {
                for (const param of params) {
                    if (param.toLowerCase() === 'dryrun') {
                        dryRun = await new Prompt('general.dry-run', 'general.perform-dry-run', {
                            okLabel: 'general.dry-run',
                            cancelLabel: 'general.run-for-real',
                        }).show();
                        paramValues.push(dryRun);
                    } else {
                        const paramDialog = new TextDialog(
                            param as TranslationKey,
                            'enter.value-for-param' as TranslationKey,
                            '',
                            '',
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            { param }
                        );
                        paramValues.push(await paramDialog.show());
                        if (paramDialog.cancelled) return;
                    }
                }
            }
            if (
                dryRun ||
                (await new Prompt(`${func.name}` as TranslationKey, `Go ahead and ${func.name}?` as TranslationKey, {
                    okLabel: 'global.run',
                    cancelLabel: 'general.cancel',
                }).show())
            ) {
                try {
                    await func(...paramValues);
                } catch (error) {
                    Logger.error('Dev tool failed to run', error);
                    new Prompt(
                        'prompts.error-title',
                        'The tool failed to run, check the console for the error produced.' as TranslationKey,
                        { cancelLabel: '' }
                    ).show();
                } finally {
                    new LoaderEvent(false);
                }
            }
        }
    }

    public static async dryRun() {
        return await new Prompt('Dry Run' as TranslationKey, 'Perform a dry run first?' as TranslationKey, {
            okLabel: 'Dry Run' as TranslationKey,
            cancelLabel: 'Run for Real' as TranslationKey,
        }).show();
    }

    static async cleanUpJobOrderData(jobOrderData?: JobOrderData) {
        if (!jobOrderData) jobOrderData = ScheduleService.getJobOrderData();

        if (!jobOrderData) return;

        const jobs = JobService.getJobs();

        if (!jobs.length) return;

        const jobD = jobs.reduce<Record<string, Job>>((x, y) => {
            x[y._id] = y;
            return x;
        }, {});

        const aWeekAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');

        const newOrder = jobOrderData.data.filter(x => {
            if (jobD[x]) return true;
            if (x.length <= 36) return false;
            const o = Data.get<JobOccurrence>(x);
            if (!o) return false;
            if (JobOccurrence.getDate(o) < aWeekAgo) return false;
            return true;
        });

        jobOrderData.data = newOrder;

        await Data.put(jobOrderData);
    }

    static async setDefaultCard(customer: Customer) {
        try {
            if (customer.paymentProviderMetaData?.stripe?.sourceOrMandateId) return;

            const stripeCustomerId = customer.externalIds?.stripe;
            if (!stripeCustomerId) return;

            const paymentMethodResult = await Api.get<Array<stripe.paymentMethod.PaymentMethod>>(
                null,
                `/api/get-cards-on-file?customerId=${customer.externalIds?.stripe}`,
                undefined,
                undefined,
                false
            );

            const sourceOrMandateId = paymentMethodResult?.data[0].id;
            if (!sourceOrMandateId) return;

            const card = paymentMethodResult?.data?.[0]?.card;
            if (!card) return;

            const refindCustomer = Data.get<Customer>(customer._id);
            if (!refindCustomer) return;

            customer = copyObject<Customer>(refindCustomer);
            if (!customer) return;

            if (!customer.paymentProviderMetaData) customer.paymentProviderMetaData = {};

            customer.paymentProviderMetaData.stripe = {
                sourceOrMandateId,
                status: 'active',
            };

            customer.paymentProviderMetaData.stripe.cardLast4 = card.last4 || '';
            customer.paymentProviderMetaData.stripe.cardExpMonth = card.exp_month.toString() || '';
            customer.paymentProviderMetaData.stripe.cardExpYear = card.exp_year.toString() || '';
            customer.paymentProviderMetaData.stripe.cardFunding = card.funding || '';
            customer.paymentProviderMetaData.stripe.cardBrand = card.brand || '';
            Data.put(customer);
        } catch (error) {
            console.log('Failed to update card for ' + customer.name, error);
        }
    }
}

(window as any).SqueegeeTools = SqueegeeTools;
