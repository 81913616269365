import type { StandardApiResponse, TranslationKey } from '@nexdynamic/squeegee-common';
import type { AxiosResponse } from 'axios';
import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import './SupportDialog.scss';
export class SupportDialog extends CustomDialog<void> {
    protected files?: File[];
    protected name = ApplicationState.account?.name || ApplicationState.account?.businessName || 'Name not set';
    protected userEmail = RethinkDbAuthClient.session?.email || '';
    constructor(
        protected readonly isSubjectEditable: boolean,
        protected subject?: string,
        protected message?: string,
        protected customHeading?: TranslationKey) {
        super('SupportDialog', '../Support/SupportDialog.html', customHeading || 'support.squeegee-team', {
            cssClass: 'support-dialog',
            okLabel: 'general.send',
            coverViewport: GlobalFlags.isMobile,
        });
    }

    public async ok(): Promise<void> {
        if (!navigator.onLine) return void new NotifyUserMessage('feature.unavailable-while-you-are-offline');

        if (!this.name) return void new NotifyUserMessage('support.name-required');

        if (!this.userEmail) return void new NotifyUserMessage('support.email-required');

        if (!this.subject) return void new NotifyUserMessage('support.subject-required');

        if (!this.message) return void new NotifyUserMessage('support.message-required');

        new LoaderEvent(true, true);

        try {
            const queries = new Array<string>();
            queries.push(`name=${this.name}`);

            if (!RethinkDbAuthClient.session?.email) queries.push(`userEmail=${this.userEmail}`);

            queries.push(`subject=${encodeURIComponent(this.subject || '')}`);
            queries.push(`message=${encodeURIComponent(this.message?.replace(/\r?\n/g, '<br />') || '')}`);

            const files = this.files;
            const url = `/api/support/ticket?${queries.join('&')}`;
            let result: AxiosResponse<StandardApiResponse> | undefined;
            if (files?.length) {
                result = await Api.postFile<undefined, StandardApiResponse>(
                    Api.apiEndpoint,
                    url,
                    files,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    false
                );
            } else {
                result = await Api.post<StandardApiResponse>(Api.apiEndpoint, url, this.files, undefined, undefined, undefined, false);
            }
            if (!result?.data) throw new Error('Unknown error submitting support ticket, no response returned.');

            if (result.data?.success === false) throw new Error(result?.data?.error || 'Unknown error submitting support ticket.');

            super.ok();
        } catch (error) {
            Logger.error('Failed to submit support ticket', { error });
            new NotifyUserMessage('support.failed-to-contact-support');
        } finally {
            new LoaderEvent(false);
        }
    }

    public cancel(): Promise<boolean> {
        return super.cancel();
    }
}
