import { ApplicationState } from '../ApplicationState';
import { getReasonText } from './getSkipReasonText';


export const generateSkipReasonsAndChargesText = () => {
    const skipReasons = ApplicationState.skipReasons;
    return skipReasons.length
        ? skipReasons.map(x => getReasonText(x))
        : [ApplicationState.localise('settings.skip-reasons-and-charges-text')];
};
