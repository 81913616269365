import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import type { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import type { IScheduleItemAction } from './IScheduleItemAction';
export class PrintJobNotification implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {
        if (scheduleItem.occurrence.paymentStatus === 'paid') {
            this.tooltip = 'print.job-receipt';
            this.actionType = <ItemActionType>'action-print';
            this._notificationType = 'receipt';
        } else {
            this.tooltip = 'print.job-complete-notice';
            this.actionType = <ItemActionType>'action-print';
            this._notificationType = 'complete';
        }
    }

    private _notificationType: 'receipt' | 'complete';

    public actionType: ItemActionType;

    public async handler() {
        await ScheduleService.viewJobNotification(this._notificationType, this.scheduleItem);
    }
    public tooltip: TranslationKey;
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker', 'Creator', 'Planner'];
}
