import type { LngLat, StoredObject, TrackingEvent } from '@nexdynamic/squeegee-common';
import { Tracker } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { GlobalFlags } from '../GlobalFlags';
export class TrackingService {
    public static getTracker(trackedItem: StoredObject) {
        return Data.get<Tracker>(Tracker.getTrackingNumber(trackedItem));
    }

    public static getLatestTracking(trackedItem: StoredObject): TrackingEvent | null {
        const tracker = TrackingService.getTracker(trackedItem);

        return tracker ? Tracker.latest(tracker) : null;
    }

    public static async update(trackedItem: StoredObject, lngLat: LngLat) {
        let tracker = TrackingService.getTracker(trackedItem);
        if (!tracker) tracker = new Tracker(trackedItem);

        Tracker.update({ tracker, lngLat, deviceId: ApplicationState.deviceId, isMobile: GlobalFlags.isMobile });

        await Data.put(tracker);
    }
}
