import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { CustomerCreditDialog } from '../../Customers/Components/CustomerCreditDialog';
import { Data } from '../../Data/Data';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import type { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import type { IScheduleItemAction } from './IScheduleItemAction';
export class DoneAndPaid implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) { }
    public actionType = <ItemActionType>'action-done-paid';
    public async handler() {
        // Get setting for what to ask for in the payment dialog
        if (this.scheduleItem.occurrence.invoiceTransactionId) return;
        const confirmedDoneDate = await ScheduleService.getConfirmedDoneDate([this.scheduleItem]);
        if (!confirmedDoneDate) return;

        if (!Data.get(this.scheduleItem.occurrence._id)) Data.put(this.scheduleItem.occurrence);
        const dialog = CustomerCreditDialog.createForScheduleItem(this.scheduleItem);
        const payment = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            const completed = await ScheduleService.completeScheduleItems([this.scheduleItem], payment, confirmedDoneDate);
            if (!completed) {
                await new Prompt('general.warning', 'unable.mark-job-as-done').show();
            }
        }

        return Promise.resolve(this.scheduleItem);
    }
    public tooltip: TranslationKey = 'actions.done-and-paid';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker'];
}
