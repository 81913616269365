import type { JobOrderData, Service, TranslationKey } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { TextDialog } from '../Dialogs/TextDialog';
import { MenuBarActionsEvent } from '../Events/MenuBarActionsEvent';
import { FabWithActions } from '../FabWithActions';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { TagService } from '../Tags/TagService';
import { AdvancedServicesService } from './AdvancedServicesService';

@inject(Router)
export class Services {
    protected services: Array<Service> = [];

    constructor(protected router: Router) { }
    protected selectItem = async (item: Service) => {
        try {
            this.router.navigate(`/services-advanced/${item._id}/`);
        } catch (error) {
            Logger.error('Error in viewService() on Services', { item, error });
            new NotifyUserMessage('services.error-adding-service');
        }
    };

    protected async addService() {
        const dialog = new TextDialog(
            'dialogs.services-add-title',
            'dialogs.add-service-title',
            '',
            'dialogs.add-service-placeholder',
            this.serviceNameValid,
            false
        );
        const name = await dialog.show(DialogAnimation.SLIDE_UP);
        if (name) {
            try {
                await TagService.addTag(name.trim(), TagType.SERVICE);
            } catch (error) {
                Logger.error('Error in addService() on Services', { name, error });
                new NotifyUserMessage('services.error-adding-service');
            }
        }
    }

    private serviceNameValid: (serviceName: string) => true | TranslationKey = (serviceName: string) => {
        if (!serviceName || !serviceName.trim()) return 'services.enter-service';

        // const value = serviceName.trim();

        // const duplicate = this.list.some(tag => tag.description.toLowerCase() === value.toLowerCase());
        // if (duplicate) {
        //     return 'services.service-already-exists';
        // }
        return true;
    };

    protected viewOnlyPortalServices = SqueegeeLocalStorage.getItem('viewOnlyPortalServices') === 'true';
    private toggleViewOnlyPortalServices() {
        this.viewOnlyPortalServices = !this.viewOnlyPortalServices;
        SqueegeeLocalStorage.setItem('viewOnlyPortalServices', this.viewOnlyPortalServices.toString());
        this.refreshServices();
    }

    private refreshServices() {
        const orderData = Data.all<JobOrderData>('joborderdata', x => x.type === 'services')[0]?.data;
        const results = Data.all<Service>(
            'tags',
            tag => tag.type === TagType.SERVICE && tag.parentId === undefined && (!this.viewOnlyPortalServices || tag.showOnDirectory === true)
        ).slice();
        if (orderData)
            results.sort((a: Service, b: Service) => {
                return orderData.indexOf(a._id) - orderData.indexOf(b._id);
            });

        this.services = results;
    }

    async attached() {
        this.refreshServices();

        new MenuBarActionsEvent([
            {
                tooltip: 'actions.publish-changes',
                actionType: 'action-replan',
                handler: () => AdvancedServicesService.publishServiceChanges(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'actions.toggle-view-portal-services-only',
                actionType: 'action-manage-time-entries',
                handler: async () => await this.toggleViewOnlyPortalServices(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'actions.export-advanced-services-with-images',
                actionType: 'action-copy-advanced-services',
                handler: async () => await AdvancedServicesService.exportAdvancedServices(true),
                roles: ['Owner', 'Admin']
            },
            {
                tooltip: 'actions.export-advanced-services-without-images',
                actionType: 'action-copy-advanced-services',
                handler: async () => await AdvancedServicesService.exportAdvancedServices(false),
                roles: ['Owner', 'Admin']
            },
            {
                tooltip: 'actions.import-advanced-services',
                actionType: 'action-paste-advanced-services',
                handler: async () => await AdvancedServicesService.importAdvancedServices().then(() => this.refreshServices()),
                roles: ['Owner', 'Admin']
            }
        ]);

        FabWithActions.register([
            {
                tooltip: 'actions.create-service',
                actionType: 'action-new-service',
                handler: () => {
                    this.addService();
                },
                roles: ['Owner', 'Admin'],
            },
        ]);
    }

    protected _dragDropped = (oldIndex: number | Array<number>, newIndex: number | Array<number>) => {
        oldIndex = Array.isArray(oldIndex) ? oldIndex[0] : oldIndex;
        newIndex = Array.isArray(newIndex) ? newIndex[0] : newIndex;

        const newArray = this.services.slice();

        newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);

        this.services = newArray;
        this._updateChildrenOrders();
        this.save();
    };

    private _updateChildrenOrders() {
        for (const child of this.services) {
            child.order = this.services.indexOf(child);
        }
    }

    protected save = () => {
        Data.put(this.services);
    };

    protected _dragStarted = (_event: any) => {
        // this._dragInProgress = true;
        //const selectedItems = this.selectedIndexes.map(i => this.listFiltered[i].original);
        // const itemInDrag = this.children[event.oldIndex];
        // if (!itemInDrag || selectedItems.length) return;
    };

    async detatched() {
        new MenuBarActionsEvent();
        FabWithActions.unregister();
    }
}
