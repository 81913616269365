// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.startup {
  background-color: var(--themed-main);
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  position: relative;
}
.startup__logo {
  width: 520px;
  margin: auto;
  top: calc(var(--notch-inset-top) * -1);
  position: relative;
}
.startup__loader {
  padding: 1rem;
  top: calc(var(--notch-inset-top) * -1);
  position: relative;
}
.startup__loader__msg {
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.startup__info {
  color: #7c99cc;
  font-weight: 500;
  margin: auto;
  top: calc(var(--notch-inset-top) * -1);
  position: relative;
}
.startup__sign-out {
  position: absolute;
  bottom: calc(var(--notch-inset-top) + 16px);
  left: 16px;
}

@media only screen and (max-width: 600px) {
  .startup__logo {
    width: 300px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Startup/Startup.scss"],"names":[],"mappings":"AAEA;EACI,oCAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;EACA,kBAAA;AADJ;AAEI;EACI,YAAA;EACA,YAAA;EACA,sCAAA;EACA,kBAAA;AAAR;AAEI;EACI,aAAA;EAOA,sCAAA;EACA,kBAAA;AANR;AADQ;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAGZ;AAEI;EACI,cAAA;EACA,gBAAA;EACA,YAAA;EACA,sCAAA;EACA,kBAAA;AAAR;AAEI;EACI,kBAAA;EACA,2CAAA;EACA,UAAA;AAAR;;AAIA;EAEQ;IACI,YAAA;EAFV;AACF","sourcesContent":["@import '../Theme.scss';\n\n.startup {\n    background-color: $main;\n    width: 100%;\n    height: 100%;\n    display: grid;\n    justify-content: center;\n    align-content: center;\n    position: relative;\n    &__logo {\n        width: 520px;\n        margin: auto;\n        top: calc(var(--notch-inset-top) * -1);\n        position: relative;\n    }\n    &__loader {\n        padding: 1rem;\n        &__msg {\n            text-align: center;\n            color: #fff;\n            font-size: 16px;\n            font-weight: 500;\n        }\n        top: calc(var(--notch-inset-top) * -1);\n        position: relative;\n    }\n    &__info {\n        color: #7c99cc;\n        font-weight: 500;\n        margin: auto;\n        top: calc(var(--notch-inset-top) * -1);\n        position: relative;\n    }\n    &__sign-out {\n        position: absolute;\n        bottom: calc(var(--notch-inset-top) + 16px);\n        left: 16px;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .startup {\n        &__logo {\n            width: 300px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
