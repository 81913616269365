import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { FileUploadDialog } from '../Dialogs/FileUploadDialog';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';

export class SupportFormCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) protected hideHeading: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) protected isSubjectEditable: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected name: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected userEmail: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected subject: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected message: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected files: File[];

    protected signedIn = !!RethinkDbAuthClient.session;
    protected async attachFiles() {
        const fileUploadDialog = new FileUploadDialog([], 'general.attachments', 52428800, true);
        fileUploadDialog._settings.zIndex = 9;
        const files = await fileUploadDialog.show();
        if (fileUploadDialog.cancelled) return;

        this.files = files;
    }

    protected nameLabel = ApplicationState.localise('general.name');
    protected emailLabel = ApplicationState.localise('general.email');
    protected subjectLabel = ApplicationState.localise('general.subject');
}
