import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { TextAreaDialog } from '../Dialogs/TextAreaDialog';
import { TextDialog } from '../Dialogs/TextDialog';
import { Utilities } from '../Utilities';
import { MessageTemplates } from './MessageTemplates';

export class SettingsUtils {
    static async setTextField(heading: TranslationKey, currentValue = ''): Promise<string | undefined> {
        const dialog = new TextDialog(heading, heading, currentValue, heading);
        await dialog.show();

        if (!dialog.cancelled) return dialog.value;
    }

    static async setIntegerNumberField(heading: TranslationKey, currentValue?: number): Promise<number | undefined> {
        const dialog = new TextDialog(heading, heading, currentValue?.toFixed(0) || '0', heading, v =>
            /^[1-9][0-9]*?$/.test(v) ? true : ApplicationState.localise('validation.integer-required')
        );
        await dialog.show();

        if (!dialog.cancelled) return Number(dialog.value) || 0;
    }

    static async setTextareaField(heading: TranslationKey, currentValue = ''): Promise<string | undefined> {
        const dialog = new TextAreaDialog(heading, currentValue, ApplicationState.localise(heading), ApplicationState.localise(heading));
        const text = await dialog.show();

        if (!dialog.cancelled) return text;
    }

    static async setSmsTemplate(title: TranslationKey, currentTemplate?: string): Promise<string | undefined> {
        const charLength = (value: string) => Utilities.getSmsLengthText(value);

        const dialog = new TextDialog(
            title,
            `${MessageTemplates.instance.smsMessage.instructions} [link]` as TranslationKey,
            currentTemplate || ApplicationState.localise('quote.editable-sms-template'),
            'message-templates.enter-a-default',
            value => {
                if (!value.includes('[link]')) return ApplicationState.localise('validation.token-required', { token: '[link]' });
                return true;
            },
            false,
            'textarea',
            undefined,
            undefined,
            charLength,
            1224
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            //Currently tinymce just gives us a document even if no content was entered
            const content = dialog.hasContent();
            return content ? result : '';
        }
    }

    static async setEmailTemplate(title: TranslationKey, currentTemplate = ''): Promise<string | undefined> {
        const charLength = (value: string) => Utilities.getEmailLengthText(value);

        const dialog = new TextDialog(
            title,
            title,
            currentTemplate,
            'message-templates.enter-a-default',
            undefined,
            false,
            'tinymce',
            undefined,
            undefined,
            charLength,
            undefined
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            //Currently tinymce just gives us a document even if no content was entered
            const content = dialog.hasContent();
            return content ? result : '';
        }
    }
}
