// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supplier-select div > mdc-list-item {
  padding-left: 0;
  padding-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Suppliers/supplier-select.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,gBAAA;AAAR","sourcesContent":[".supplier-select {\n    div > mdc-list-item {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
