import type { Transaction } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { InvoiceTransactionSummaryDialog } from '../../Invoices/InvoiceTransactionSummaryDialog';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';
import { TransactionDialog } from './TransactionDialog';

export class TransactionCustomElement {
    @bindable() transaction: Transaction;
    @bindable() balance?: number;
    @bindable() includeCustomerData = false;
    view() {
        switch (this.transaction.transactionType) {
            case TransactionType.Invoice:
                new InvoiceTransactionSummaryDialog(this.transaction).show();
                break;
            default:
                new TransactionDialog(this.transaction).show();
                break;
        }
    }

    @computedFrom('transaction.transactionType')
    get title() {
        if (!this.transaction) return;
        return TransactionUtils.getSubTypeTitle(this.transaction.transactionType, this.transaction.transactionSubType);
    }

    @computedFrom('transaction.description')
    get description() {
        if (!this.transaction) return;
        if (this.transaction.transactionType === TransactionType.Invoice) {
            return this.transaction.invoice?.billTo;
        }
        return this.transaction.description;
    }
}
