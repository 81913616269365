import type {
    Job,
    JobOccurrence,
    JobOccurrenceTranslateableKeys,
    StoredEvent,
    Team,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Alert,
    FrequencyType,
    JobOccurrenceStatus,
    TagType,
    generateJobOccurrenceId,
    getUkPostcode,
    notNullUndefinedEmptyOrZero,
} from '@nexdynamic/squeegee-common';
import type { PortalCustomerAppointmentRating } from '@nexdynamic/squeegee-portal-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import type { MapCustomElement } from '../../Components/Map';
import { CustomerDialog } from '../../Customers/Components/CustomerDialog';
import { CustomerDeletedMessage } from '../../Customers/CustomerMessage';
import { Data } from '../../Data/Data';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import { SelectMultiple } from '../../Dialogs/SelectMultiple';
import { TextAreaDialog } from '../../Dialogs/TextAreaDialog';
import { DurationDialog } from '../../Dialogs/TimeOrDuration/DurationDialog';
import { TimeDialog } from '../../Dialogs/TimeOrDuration/TimeDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { InvalidateMapSizesEvent } from '../../Events/InvalidateMapSizesEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { InvoiceService } from '../../Invoices/InvoiceService';
import { InvoiceTransactionSummaryDialog } from '../../Invoices/InvoiceTransactionSummaryDialog';
import { JobOccurrenceTags } from '../../Jobs/Components/JobOccurrenceTags';
import { JobPricingDialog } from '../../Jobs/Components/JobPricing';
import { JobDeletedMessage } from '../../Jobs/JobMessage';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { JobService } from '../../Jobs/JobService';
import { JobActions } from '../../Jobs/actions/JobActions';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { ScheduleDetailsRatingsDialogProps } from '../../ReactUI/dialogs/ScheduleDetails/ScheduleDetailsRatingsDialogComponent';
import ScheduleDetailsRatingsDialogComponent from '../../ReactUI/dialogs/ScheduleDetails/ScheduleDetailsRatingsDialogComponent';
import { SelectTagsDialog } from '../../Tags/Components/SelectTagsDialog';
import ManageStoredEventsDialogComponent from '../../Tracking/Dialogs/ManageStoredEventsDialogComponent';
import { TimerService } from '../../Tracking/TimerService';
import type { WorkerTimer } from '../../Tracking/WorkerTimer';
import { TransactionService } from '../../Transactions/TransactionService';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { UserService } from '../../Users/UserService';
import { Utilities } from '../../Utilities';
import { Replan } from '../Actions/Replan';
import { ScheduleActionEvent } from '../Actions/ScheduleActionEvent';
import { ScheduleService } from '../ScheduleService';
import { ScheduleItem } from './ScheduleItem';

export class ScheduleDetailsDialog extends CustomDialog<ScheduleItem> {
    private _jobDeletedEventSub: Subscription;
    private _customerRemovedSub: Subscription;
    private _dataRefreshedEvent: Subscription;

    protected locationVerified = false;
    protected customerBalance: number;
    public initialJobFrequencyInterval: number;
    public initialJobFrequencyType: number;
    protected contextMenuActions: Array<IMenuBarAction>;
    protected multiUserEnabled = ApplicationState.multiUserEnabled;

    protected currencySymbol = ApplicationState.currencySymbol();
    protected replanAction: Replan;
    public viewOptions = ApplicationState.viewOptions;
    protected nextDueScheduleItem?: ScheduleItem;
    protected allowedToViewJobAttachments = ApplicationState.allowedRolesToViewJobAttachments;

    private protectArchived() {
        if (!this.scheduleItem.occurrence._archived) return;
        prompt('general.error', 'appointments.appointment-archived-editing-disabled', { cancelLabel: '' }).show();
        throw new Error('This occurrence is archived and cannot be edited.');
    }

    protected balanceLabel: string;
    private _showContact =
        ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']) ||
        !ApplicationState.getSetting('global.worker-planner-hide-contact-details', false);

    private _updateTimerUIEverySecond: NodeJS.Timeout;
    @computedFrom('_showContact')
    protected get showContact() {
        return this._showContact;
    }

    protected showTimer = false;

    @computedFrom('scheduleItem.occurrence.status')
    protected get showTimerAction() {
        return this.scheduleItem.occurrence.status === JobOccurrenceStatus.NotDone;
    }

    protected timedDuration: string | undefined;

    constructor(public scheduleItem: ScheduleItem) {
        super(generateJobOccurrenceId(scheduleItem.job, scheduleItem.date), '../Schedule/Components/ScheduleDetailsDialog.html', '', {
            cssClass: 'color-schedule  schedule-details-dialog details-dialog no-nav-shadow',
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            destructive: true,
        });
        this.initialJobFrequencyInterval = scheduleItem.job.frequencyInterval;
        this.initialJobFrequencyType = scheduleItem.job.frequencyType;

        this._dataRefreshedEvent = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            this.refresh();

            if (
                event.hasAnyType('storedevents') &&
                event
                    .filterByType<StoredEvent>('storedevents')
                    .some(se => se.occurrenceId && se.occurrenceId === this.scheduleItem.occurrence._id)
            ) {
                this.updateTimerState();
            }
        });

        this._jobDeletedEventSub = JobDeletedMessage.subscribe((message: JobDeletedMessage) => {
            if (message.jobId === scheduleItem.job._id) return this.cancel(true);
        });

        this._customerRemovedSub = CustomerDeletedMessage.subscribe((message: CustomerDeletedMessage) => {
            if (message.customerId === this.scheduleItem.customer._id) {
                return this.cancel(true);
            }
        });

        this.replanAction = new Replan(this.scheduleItem);

        this.showTimer = scheduleItem.isTimeTracked();

        this.saved = !!Data.get(scheduleItem.occurrence._id);
    }

    protected saved = false;

    protected timers: Array<WorkerTimer> = [];

    public updateTimerState() {
        this.timers = TimerService.getWorkerTimers(this.scheduleItem.occurrence);
    }

    protected viewPreviousDate() {
        if (this.scheduleItem.previousScheduleItem)
            new ScheduleDetailsDialog(this.scheduleItem.previousScheduleItem).show(DialogAnimation.SLIDE);
    }

    protected viewNextDate() {
        if (this.scheduleItem.nextScheduleItem) new ScheduleDetailsDialog(this.scheduleItem.nextScheduleItem).show(DialogAnimation.SLIDE);
    }

    protected async toggleTimers() {
        this.protectArchived();

        if (!this.scheduleItem.assignees?.length) {
            const currentUser = UserService.getUser();
            if (!currentUser) return new NotifyUserMessage('general.oopsError');

            if (this.scheduleItem.timerRunning) return await TimerService.stopTimer(this.scheduleItem.occurrence, currentUser);

            return await TimerService.startTimer(this.scheduleItem.occurrence, currentUser);
        }
        if (this.scheduleItem.timerRunning) {
            const { reason, cancelled } = await JobOccurrenceService.getTimerPauseReasonIfRequired();
            if (cancelled) return;
            for (const timer of this.timers) {
                if (!timer.assignee) continue;
                await TimerService.stopTimer(this.scheduleItem.occurrence, timer.assignee, reason);
            }
        } else {
            for (const timer of this.timers) {
                if (!timer.assignee) continue;
                const canStart = await TimerService.canStartTimer(this.scheduleItem.occurrence, timer.assignee);
                if (!canStart) return;
                await TimerService.startTimer(this.scheduleItem.occurrence, timer.assignee);
            }
        }
    }

    protected skillsEnabled = ApplicationState.multiUserEnabled;

    protected async addSkills(event: Event) {
        this.protectArchived();
        if (!this.isEditor) return;

        const availableSkills = ApplicationState.skills.map(skill => ({ text: skill, value: skill }));

        const selectedSkills =
            this.scheduleItem.occurrence.skills?.map(
                skill => availableSkills.find(s => s.value === skill) || { value: skill, text: skill }
            ) || [];
        const dialog = new SelectMultiple('general.select-skills', availableSkills, 'text', 'value', selectedSkills);
        const skills = await dialog.show(DialogAnimation.SLIDE_UP);
        if (dialog.cancelled) return;
        await this.confirmUpdateScheduleOrAppointment(
            ['skills'],
            async () => (this.scheduleItem.occurrence.skills = skills.map(s => s.value)),
            async (job: Job) => {
                this.scheduleItem.occurrence.skills = skills.map(s => s.value);
                job.skills = skills.map(s => s.value);
            }
        );
        if (event) event.stopPropagation();
    }

    protected viewRating() {
        const isPublished = ApplicationState.getSetting(
            'global.public-customer-appointment-ratings',
            new Array<PortalCustomerAppointmentRating>()
        ).some(r => r.appointmentId === this.scheduleItem.occurrence._id);

        if (!this.scheduleItem.occurrence.customerRating) return;
        const dialog = new AureliaReactComponentDialog<undefined, ScheduleDetailsRatingsDialogProps>({
            dialogTitle: 'directory.customer-rating-view-title',
            component: ScheduleDetailsRatingsDialogComponent,
            isSecondaryView: true,
            componentProps: {
                occurrenceId: this.scheduleItem.occurrence._id,
                rating: this.scheduleItem.occurrence.customerRating,
                published: isPublished,
            },
        });
        dialog.show();
    }

    protected async viewJobHistory() {
        JobActions.viewAppointments(this.scheduleItem.customer._id, this.scheduleItem.job._id);
    }

    @computedFrom('scheduleItem.price')
    protected get canViewBalance() {
        return this.scheduleItem.price !== undefined;
    }

    public async init() {
        this.updateTimerState();

        this._updateTimerUIEverySecond = setInterval(() => this.updateTimerState(), 1000);

        await this.refresh(false);
        new InvalidateMapSizesEvent(); // To make map resize if we are in desktop mode from overview
    }
    private refreshTimer?: any;

    private async refresh(updateScheduleItem = true) {
        clearInterval(this.refreshTimer);
        delete this.refreshTimer;
        this.refreshTimer = setTimeout(async () => {
            try {
                updateScheduleItem && ScheduleItem.refresh(this.scheduleItem);
                const balance = TransactionService.getCustomerBalance(this.scheduleItem.customer._id, moment().format('YYYY-MM-DD'));
                this.customerBalance = balance.amount;

                this.fabActions = this.scheduleItem.scheduleItemActions.actions;
                this.nextDueScheduleItem = await ScheduleService.getNextDueScheduleItemForJob(
                    this.scheduleItem.job,
                    moment(this.scheduleItem.date).add(1, 'day').format('YYYY-MM-DD')
                );

                delete this.refreshTimer;
                this.setContextMenuBarActions();

                this.balanceLabel = !this.customerBalance
                    ? 'financials.balance-label'
                    : this.customerBalance < 0
                    ? 'financials.credit-label'
                    : 'financials.owed-label';

                this.updateTimerState();
            } catch (error) {
                Logger.info('Refresh failed.', error);
            }
        }, 100);
    }

    private setContextMenuBarActions() {
        const item = this.scheduleItem;
        this.contextMenuActions = [
            {
                tooltip: 'menubar.view-job-history',
                actionType: 'action-view-job-history',
                handler: () => this.viewJobHistory(),
                roles: ['Owner', 'Admin', 'Creator', 'Planner'],
            },
        ];

        if (this.hasAdvancedOrAbove)
            this.contextMenuActions.push({
                tooltip: 'actions.manage-time-entries',
                actionType: 'action-manage-time-entries',
                handler: async () => {
                    this.protectArchived();
                    AureliaReactComponentDialog.show({
                        component: ManageStoredEventsDialogComponent,
                        componentProps: {
                            occurrence: item.occurrence,
                        },
                        dialogTitle: 'manage.timed-events',
                        isSecondaryView: true,
                    });
                },
                roles: ['Owner', 'Admin'],
            });

        if (item.status === JobOccurrenceStatus.Done && !item.occurrence.paymentStatus) {
            this.contextMenuActions.push({
                tooltip: 'actions.mark-unbillable',
                actionType: 'action-credit',
                handler: async () => {
                    this.scheduleItem.occurrence.paymentStatus = 'unbillable';
                    Data.put(this.scheduleItem.occurrence, true, 'lazy');
                    this.refresh(true);
                    const event = {} as any;
                    event[this.scheduleItem.customer._id] = this.scheduleItem.customer;
                    DataRefreshedEvent.emit(event, false);
                },
                roles: ['Owner', 'Admin', 'Creator', 'Planner'],
            });
        } else if (item.status === JobOccurrenceStatus.Done && item.occurrence.paymentStatus === 'unbillable') {
            this.contextMenuActions.push({
                tooltip: 'actions.mark-billable',
                actionType: 'action-credit',
                handler: async () => {
                    this.scheduleItem.occurrence.paymentStatus = undefined;
                    Data.put(this.scheduleItem.occurrence, true, 'lazy');
                    this.refresh(true);
                    const event = {} as any;
                    event[this.scheduleItem.customer._id] = this.scheduleItem.customer;
                    DataRefreshedEvent.emit(event, false);
                },
                roles: ['Owner', 'Admin', 'Creator', 'Planner'],
            });
        }

        if (item.status === JobOccurrenceStatus.NotDone) {
            if (this.scheduleItem.job.frequencyType !== FrequencyType.NoneRecurring) {
                this.contextMenuActions.push({
                    tooltip: 'menubar.reset-to-job-defaults',
                    actionType: 'action-reset-to-job-defaults',
                    handler: async () => {
                        if (
                            await new Prompt('dialogs.reset-to-job-defaults-title', 'dialogs.reset-to-job-defaults-text', {
                                okLabel: 'general.yes',
                                cancelLabel: 'general.no',
                            }).show()
                        ) {
                            if (await JobOccurrenceService.resetOccurrenceToJobDefaults(item.occurrence, item.job)) {
                                ScheduleItem.refresh(item);
                            }
                        }
                    },
                    roles: ['Owner', 'Admin', 'Creator'],
                });
                this.contextMenuActions.push({
                    tooltip: 'reset-job-schedule-one.title',
                    actionType: 'action-reset-to-job-defaults',
                    handler: async () => {
                        const prompt = new Prompt('prompts.confirm-title', 'prompts.reset-job-confirm-text', {
                            okLabel: 'general.yes',
                            cancelLabel: 'general.no',
                        });
                        const result = await prompt.show();
                        if (result) {
                            try {
                                await JobService.hardResetSchedule(
                                    this.scheduleItem.job,
                                    moment().add(1, 'day').format().substring(0, 10),
                                    true
                                );
                                const job = JobService.getJob(this.scheduleItem.job.customerId, this.scheduleItem.job._id) as Job;
                                this.scheduleItem = (await ScheduleService.getLastScheduleItemForJob(
                                    job,
                                    moment().format('YYYY-MM-DD')
                                )) as ScheduleItem;
                                this.refresh();
                                new ScheduleActionEvent('action-replan');
                            } catch (error) {
                                new NotifyUserMessage('problem.resetting-schedule');
                                Logger.error('Error in setContextMenuBarActions() on ScheduleDetailsDialog', { item, error });
                            }
                        }
                    },
                    roles: ['Owner', 'Admin', 'Creator'],
                });
            }
        }
    }

    public dispose() {
        this._jobDeletedEventSub && this._jobDeletedEventSub.dispose();
        this._customerRemovedSub && this._customerRemovedSub.dispose();
        this._dataRefreshedEvent && this._dataRefreshedEvent.dispose();

        clearInterval(this._updateTimerUIEverySecond);

        super.dispose();
    }

    protected frequencyText = ApplicationState.localise('schedule-details.frequency-text', {
        frequencyText: this.scheduleItem.frequencyText,
    });

    public async viewCustomer() {
        if (!ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator'])) return false;

        const dialog = new CustomerDialog(this.scheduleItem.customer);
        await dialog.show(DialogAnimation.SLIDE_IN);
    }

    public async viewInvoice() {
        if (!this.isAdmin) return false;
        if (!this.scheduleItem.occurrence.invoiceTransactionId) return;
        const invoice = TransactionService.getTransaction(this.scheduleItem.occurrence.invoiceTransactionId);
        if (invoice) {
            new InvoiceTransactionSummaryDialog(invoice).show(DialogAnimation.SLIDE_UP);
        }
    }

    public async verifyAddress() {
        this.protectArchived();
        if (this.scheduleItem && this.scheduleItem.job && this.scheduleItem.occurrence.location) {
            const oldLocation = Utilities.copyObject(this.scheduleItem.occurrence.location);
            const newLocation = Utilities.copyObject(this.scheduleItem.occurrence.location);

            const locationUpdated = await LocationUtilities.updateLocationIfUnverified(newLocation);

            if (locationUpdated) {
                this.scheduleItem.occurrence.location = newLocation;
                await JobOccurrenceService.addOrUpdateJobOccurrence(this.scheduleItem.occurrence);

                await LocationUtilities.updateCustomerAndJobLocationsOnVerification(
                    this.scheduleItem.customer,
                    this.scheduleItem.job._id,
                    oldLocation,
                    newLocation
                );
            }
        }
    }

    protected isAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected isEditor = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'JobEditor']);
    protected isPlanner = ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'JobEditor', 'Planner']);

    @computedFrom('scheduleItem.occurrence.isoCompletedDate')
    protected get completedDateFormatted() {
        if (this.scheduleItem.occurrence.isoCompletedDate) {
            return moment(this.scheduleItem.occurrence.isoCompletedDate).format('ddd, MMM Do');
        }
    }
    @computedFrom('scheduleItem.occurrence.paidDate')
    protected get paidDateFormatted() {
        if (this.scheduleItem.occurrence.paidDate) {
            return moment(this.scheduleItem.occurrence.paidDate).format('ddd, MMM Do');
        }
    }
    @computedFrom('scheduleItem.occurrence.isoPlannedDate')
    protected get plannedDateFormatted() {
        if (this.scheduleItem.occurrence.isoPlannedDate) {
            return moment(this.scheduleItem.occurrence.isoPlannedDate).format('ddd, MMM Do');
        }
    }
    @computedFrom('scheduleItem.occurrence.dueDateFormatted')
    protected get dueDateFormatted() {
        if (this.scheduleItem.occurrence.isoDueDate) {
            return moment(this.scheduleItem.occurrence.isoDueDate).format('ddd, MMM Do');
        }
    }

    editTimer = (timer: WorkerTimer) => {
        this.protectArchived();
        const assignedTeams = AssignmentService.getAssignees(this.scheduleItem.occurrence).map(a => {
            if (a.resourceType === 'team') return a as Team;
        });
        const isAssigneeMemberOfTeam = assignedTeams?.some(t => t?.members?.some(m => m === timer.assignee?._id));

        AureliaReactComponentDialog.show({
            component: ManageStoredEventsDialogComponent,
            componentProps: {
                occurrence: this.scheduleItem.occurrence,
                initialAssignee: !isAssigneeMemberOfTeam ? timer.assignee : undefined,
            },
            dialogTitle: 'manage.timed-events',
            isSecondaryView: true,
        });
    };

    protected async editTime(event: Event) {
        this.protectArchived();
        if (!this.isPlanner) return;

        const timeDialog = new TimeDialog(this.scheduleItem.occurrence.time, !this.scheduleItem.occurrence.time);
        const time = await timeDialog.show();
        if (timeDialog.cancelled) return;

        await this.confirmUpdateScheduleOrAppointment(
            ['time'],
            async () => (this.scheduleItem.occurrence.time = time),
            async (job: Job) => {
                this.scheduleItem.occurrence.time = time;
                job.time = time;
            }
        );
        if (event) event.stopPropagation();
    }

    protected async editDuration(event: Event) {
        this.protectArchived();
        if (!this.isPlanner) return;

        const durationDialog = new DurationDialog(
            this.scheduleItem.occurrence.duration || ApplicationState.account.defaultJobDuration || '00:30'
        );
        const duration = await durationDialog.show();
        if (durationDialog.cancelled || !duration) return;

        await this.confirmUpdateScheduleOrAppointment(
            ['duration'],
            async () => (this.scheduleItem.occurrence.duration = duration),
            async (job: Job) => {
                this.scheduleItem.occurrence.duration = duration;
                job.duration = duration;
            }
        );
        if (event) event.stopPropagation();
    }

    protected async selectRound(event: Event) {
        this.protectArchived();
        if (!this.isEditor) return;
        if (event) event.stopPropagation();
        ScheduleService.bulkMoveRoundScheduleItems([this.scheduleItem]);
    }

    protected async selectServices(event: Event) {
        this.protectArchived();
        if (this.scheduleItem.price === undefined) return;

        const dialog = new SelectTagsDialog(this.scheduleItem.occurrence.services.slice(), TagType.SERVICE, 0);
        const services = await dialog.show(DialogAnimation.SLIDE_UP);
        if (dialog.cancelled) return;
        await this.confirmUpdateScheduleOrAppointment(
            ['services'],
            async () => {
                this.scheduleItem.occurrence.services = services.slice();
            },
            async (job: Job) => {
                this.scheduleItem.occurrence.services = services.slice();
                job.services = services.slice();
            }
        );
        if (event) event.stopPropagation();
    }

    protected hasTags = ApplicationState.features.ServiceTagging && this.scheduleItem.occurrence.services.some(s => s.requiresTagging);
    protected setTags = async () => {
        new JobOccurrenceTags(this.scheduleItem.occurrence).show(DialogAnimation.SLIDE_UP);
    };

    protected async editNotes(event: Event) {
        this.protectArchived();
        const dialog = new TextAreaDialog(
            'customers.notes-label',
            this.scheduleItem.occurrence.description ? this.scheduleItem.occurrence.description : '',
            undefined,
            undefined
        );
        const notes = await dialog.show();
        if (dialog.cancelled) return;
        await this.confirmUpdateScheduleOrAppointment(
            ['description'],
            async () => {
                this.scheduleItem.occurrence.description = notes;
            },
            async (job: Job) => {
                this.scheduleItem.occurrence.description = notes;
                job.description = notes;
            }
        );

        if (event) event.stopPropagation();
    }

    private async alertAdminOfChange(fields: Array<keyof JobOccurrence>) {
        const accountUser = UserService.getUser();
        const user = accountUser ? accountUser.name : 'A user';
        await Data.put(Alert.createWorkerModifiedAlert(this.scheduleItem.customer, this.scheduleItem.occurrence, user, fields));
    }

    private async updateJustThis(updateOccurrenceMethod: () => void, fields: Array<keyof JobOccurrence>) {
        this.protectArchived();
        if (!this.isAdmin) this.alertAdminOfChange(fields);

        await updateOccurrenceMethod();
        await JobOccurrenceService.addOrUpdateJobOccurrence(this.scheduleItem.occurrence);
    }

    private async updateSchedule(updateScheduleMethod: (job: Job) => void, fields: Array<keyof JobOccurrence>) {
        this.protectArchived();
        if (!this.isAdmin) this.alertAdminOfChange(fields);

        const job = Utilities.copyObject(this.scheduleItem.job);
        await updateScheduleMethod(job);
        await JobOccurrenceService.addOrUpdateJobOccurrence(this.scheduleItem.occurrence);
        await JobService.addOrUpdateJob(job.customerId, job, undefined, undefined, undefined, true, fields);
    }

    private async confirmUpdateScheduleOrAppointment(
        fields: Array<keyof JobOccurrenceTranslateableKeys>,
        updateOccurrenceMethod: () => void,
        updateScheduleMethod: (job: Job) => void
    ) {
        this.protectArchived();
        if (!this.scheduleItem.job.date) return this.updateSchedule(updateScheduleMethod, fields);
        if (!this.isEditor) {
            this.updateJustThis(updateOccurrenceMethod, fields);
            return;
        }

        const job = Utilities.copyObject(this.scheduleItem.job);
        const isAdHoc = job.frequencyType === FrequencyType.NoneRecurring;
        const title = isAdHoc ? 'schedule-appointment.ad-hoc-job' : 'schedule-appointment.repeating-job';
        const messageKey = isAdHoc ? 'workload.update-all-or-one-adhoc' : 'workload.update-all-or-one-repeating';
        const fieldKeys: Array<TranslationKey> = [];
        for (const field of fields) {
            fieldKeys.push(`field-name.${field}`);
        }
        const localisationParams = { fields: Utilities.localiseList(fieldKeys) };

        const prompt = new Prompt(title, messageKey, {
            okLabel: 'general.ok-label-just-this',
            cancelLabel: 'general.cancel',
            altLabel: 'general.all-title',
            localisationParams,
        });

        const updateJustThisOne = await prompt.show();
        if (prompt.cancelled) return;

        if (updateJustThisOne) this.updateJustThis(updateOccurrenceMethod, fields);
        else this.updateSchedule(updateScheduleMethod, fields);
    }

    protected async replan(event: Event) {
        this.protectArchived();
        if (this.scheduleItem.occurrence.status !== JobOccurrenceStatus.NotDone) return;
        if (!this.isPlanner && !this.isEditor) return;
        await this.replanAction.handler();

        if (event) event.stopPropagation();
    }

    protected async editPrice(event: Event) {
        this.protectArchived();
        if (this.scheduleItem.price === undefined) return;

        const jobOccurrence = Utilities.copyObject(this.scheduleItem.occurrence);

        let alsoUpdateInvoice = false;
        if (jobOccurrence.invoiceTransactionId) {
            const invoice = Data.get<Transaction>(jobOccurrence.invoiceTransactionId);
            if (invoice && invoice.invoice && invoice.invoice.paid) {
                new Prompt('action-invoice-paid', 'fail.to-update-price-invoice-paid', {
                    cancelLabel: '',
                }).show();
                return;
            }
            const dialog = new Prompt('general.confirm', 'warning.also-update-associated-invoice');
            await dialog.show();
            if (dialog.cancelled) return;
            alsoUpdateInvoice = true;
        }

        const dialog = new JobPricingDialog(jobOccurrence);
        await dialog.show();
        if (dialog.cancelled) return;
        await this.confirmUpdateScheduleOrAppointment(
            ['price', 'services'],
            async () => {
                this.scheduleItem.occurrence.price = jobOccurrence.price;
                this.scheduleItem.occurrence.services = jobOccurrence.services.slice();
            },
            async (job: Job) => {
                this.scheduleItem.occurrence.price = jobOccurrence.price;
                this.scheduleItem.occurrence.services = jobOccurrence.services.slice();
                job.price = jobOccurrence.price;
                job.services = jobOccurrence.services.slice();
            }
        );

        if (jobOccurrence.invoiceTransactionId && alsoUpdateInvoice) {
            const invoice = Data.get<Transaction>(jobOccurrence.invoiceTransactionId);

            if (invoice && invoice.invoice) {
                for (const item of invoice.invoice.items?.filter(x => x.refID === jobOccurrence._id) || []) {
                    invoice.invoice.items?.splice(invoice.invoice.items.indexOf(item, 1));
                }

                const showNotes = !invoice.invoice.items
                    ?.map(i => Data.get<JobOccurrence>(i.refID))
                    .filter(o => !!o && o.resourceType === 'joboccurrences')
                    .every(o => (<JobOccurrence>o).jobId === jobOccurrence.jobId);
                const newInvoiceItems = InvoiceService.occurrenceToInvoiceItems(
                    jobOccurrence,
                    jobOccurrence.location?.addressDescription !== this.scheduleItem.customer.address.addressDescription,
                    showNotes
                );

                invoice.invoice.items = invoice.invoice.items?.concat(newInvoiceItems);
                InvoiceService.updateTotals(invoice.invoice);
                invoice.amount = invoice.invoice.total;

                await Data.put(invoice);
            }
        }

        if (event) event.stopPropagation();
    }

    protected async toggleTimer(timer: WorkerTimer) {
        this.protectArchived();
        if (!timer.assignee) return;
        const stoppingTimer = timer.running;

        if (stoppingTimer) {
            await TimerService.stopTimer(this.scheduleItem.occurrence, timer.assignee);
        } else {
            const canStart = await TimerService.canStartTimer(this.scheduleItem.occurrence, timer.assignee);
            if (!canStart) return;
            await TimerService.startTimer(this.scheduleItem.occurrence, timer.assignee);
        }
        this.updateTimerState();
    }

    public hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;

    protected async editSchedule(event: Event) {
        this.protectArchived();
        if (!this.isEditor && !this.isPlanner) return;
        const mode: 'one-off' | 'frequency' = this.scheduleItem.job.frequencyType === FrequencyType.NoneRecurring ? 'one-off' : 'frequency';
        let updated = false;
        const job = Utilities.copyObject(this.scheduleItem.job);

        const matchingTexts = [
            getUkPostcode(job.location?.addressDescription),
            getUkPostcode(job.location?.addressDescription, false),
        ].filter(notNullUndefinedEmptyOrZero);

        if (this.scheduleItem.job.isQuote) {
            updated = await JobService.pickFrequency(job, false, true, false, mode, undefined, undefined, matchingTexts);
        } else {
            updated = await JobService.pickFrequency(
                job,
                true,
                true,
                true,
                mode,
                job.location && job.location.lngLat,
                job.rounds && job.rounds[0],
                matchingTexts
            );
        }
        if (updated) {
            if (!job.date) job.date = moment().format('YYYY-MM-DD');

            if (this.scheduleItem.occurrence.status === JobOccurrenceStatus.NotDone) {
                // Replan this occurrence too, as its not done.
                await JobOccurrenceService.replanJobSchedule(this.scheduleItem.occurrence, job, job.date, this.scheduleItem.date, false);
            } else {
                await JobService.updateJobSchedule(job, job.date, this.scheduleItem.date, true);
            }

            ScheduleItem.refresh(this.scheduleItem);
        }
        if (event) event.stopPropagation();
    }

    protected showPinLocationButton = !!ApplicationState.position;

    public showPinAddressAtMyLocationDialog() {
        this.protectArchived();
        LocationUtilities.pinJobAtMyLocation(this.scheduleItem);
    }

    protected fitMapToBounds = (map: MapCustomElement) => map.fitMapToLocationsBounds();
}
