// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-item-list__distance {
  position: absolute;
  left: 8px;
  bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Schedule/Components/ScheduleItemList.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,SAAA;EACA,YAAA;AAAR","sourcesContent":[".schedule-item-list {\n    &__distance {\n        position: absolute;\n        left: 8px;\n        bottom: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
