import type { QuickScheduleOptions } from '../ReactUI/scheduleMessages/QuickScheduleOptions';

export const defaultQuickScheduleOptions: QuickScheduleOptions = {
    availableDayOptions: [
        { value: -5, },
        { value: -4, },
        { value: -3, },
        { value: -2, },
        { value: -1, },
    ]
};
