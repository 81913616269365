import type {
    AreaPriceModifier,
    Attachment,
    AvailableFrequency,
    JobOrderData,
    Service,
    StoredObjectResourceTypes,
    Tag,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { TagType, uuid } from '@nexdynamic/squeegee-common';
import { computedFrom, inject } from 'aurelia-framework';
import { Router, activationStrategy } from 'aurelia-router';
import { ApplicationState } from '../ApplicationState';
import { AttachmentsDialog } from '../Attachments/Dialogs/AttachmentsDialog';
import { SelectAttachmentsDialog } from '../Attachments/Dialogs/SelectAttachmentsDialog';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { Data } from '../Data/Data';
import { AureliaReactComponentDialog } from '../Dialogs/AureliaReactComponentDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { AvailableFrequenciesPickerDialog } from '../Dialogs/FrequencyPicker/AdvancedFrequenciesPickerDialog';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';
import { MenuBarActionsEvent } from '../Events/MenuBarActionsEvent';
import { FabWithActions } from '../FabWithActions';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { getNumber } from '../Settings/getNumber';
import { SelectTagsDialog } from '../Tags/Components/SelectTagsDialog';
import { TagService } from '../Tags/TagService';
import { Utilities } from '../Utilities';
import { AdvancedServicesService } from './AdvancedServicesService';
import type { AreaPriceModifiersDialogOptions } from './react/AreaPriceModifiersDialog';
import AreaPriceModifiersDialog from './react/AreaPriceModifiersDialog';

export type ServiceType = 'select-all' | 'select-one' | 'required-select-one' | 'select-multiple' | 'select-none' | 'custom' | '';

@inject(Router)
export class ServiceItem {
    private setImageThumbnail() {
        if (this.parent?.imageInfo?.id) {
            this.thumbnail = `${Api.currentHostAndScheme}/v/${this.parent.imageInfo.id}`;
        } else {
            this.thumbnail = undefined;
        }
    }

    protected listTitle: TranslationKey = 'Service Options' as TranslationKey;

    @computedFrom('parent.isStructureOnlyService')
    protected get structureOnly() {
        return this.parent?.isStructureOnlyService as boolean;
    }
    private set structureOnly(value: boolean) {
        if (!this.parent) return;
        this.parent.isStructureOnlyService = value;
        this.save();
    }

    @computedFrom('parent.showOnDirectory')
    protected get showOnDirectory() {
        return this.parent?.showOnDirectory as boolean;
    }
    private set showOnDirectory(value: boolean) {
        if (!this.parent) return;
        this.parent.showOnDirectory = value;
        this.save();
    }

    @computedFrom('parent.hidePriceOnDirectory')
    protected get hidePriceOnDirectory() {
        return this.parent?.hidePriceOnDirectory as boolean;
    }
    private set hidePriceOnDirectory(value: boolean) {
        if (!this.parent) return;
        this.parent.hidePriceOnDirectory = value;
        this.save();
    }

    constructor(protected router: Router) {}

    private getServiceTypeFromCardinality(): ServiceType {
        if (!this.parent) return '';
        if (!this.children || !this.children.length) return '';
        if (this.parent.minChildren === 0 && this.parent.maxChildren === 0) return 'select-none';
        if (this.parent.minChildren === 0 && this.parent.maxChildren === 1) return 'select-one';
        if (this.parent.minChildren === 1 && this.parent.maxChildren === 1) return 'required-select-one';
        if (this.children.every(x => x.min)) return 'select-all';
        if (this.serviceType === 'custom' || (this.parent.minChildren || 0) > 1) return 'custom';
        if (this.parent.maxChildren === undefined || this.parent.maxChildren > 1) return 'select-multiple';
        return '';
    }

    protected serviceType: ServiceType;

    protected serviceTypeChanged(newServiceType: ServiceType) {
        if (!this.parent) return;
        this.serviceType = newServiceType;
        switch (this.serviceType) {
            case 'select-none':
                this.parent.maxChildren = 0;
                this.parent.minChildren = 0;
                this.children.forEach(x => {
                    x.min = 0;
                    x.max = 0;
                });
                break;
            case 'select-one':
                this.parent.maxChildren = 1;
                this.parent.minChildren = 0;
                this.children.forEach(x => {
                    x.min = 0;
                    x.max = 1;
                });
                break;
            case 'required-select-one':
                this.parent.maxChildren = 1;
                this.parent.minChildren = 1;
                this.children.forEach(x => {
                    x.min = 0;
                    x.max = 1;
                });

                break;
            case 'select-multiple':
                this.parent.maxChildren = undefined;
                this.parent.minChildren = 0;
                this.children.forEach(x => {
                    x.min = 0;
                    x.max = 1;
                });
                break;
            case 'select-all':
                this.children.forEach(x => {
                    x.min = 1;
                    x.max = 1;
                });
                break;
            case 'custom':
                break;
            default:
                this.parent.minChildren = undefined;
                this.parent.maxChildren = undefined;
                this.children.forEach(x => {
                    x.min = undefined;
                    x.max = undefined;
                });
        }
        this.save();
    }

    protected children: Array<Service> = [];

    protected selectedParentId?: string;
    public parent?: Service;
    protected ancestors?: Array<Service>;
    determineActivationStrategy() {
        return activationStrategy.replace;
    }
    protected allowDragAndDrop = true;

    protected listenToResourceTypes = ['tags' as StoredObjectResourceTypes];
    private getAncestors() {
        let parent: Service | undefined = this.getParent();
        if (!parent) return [];
        const ancestors: Array<Service> = [];
        while (parent && parent.parentId) {
            parent = Data.get(parent.parentId);
            if (parent) ancestors.push(parent);
        }
        return ancestors.reverse();
    }

    private getParent() {
        if (!this.selectedParentId) return;
        return Data.get<Service>(this.selectedParentId);
    }
    protected thumbnail?: string;

    attached() {
        this.loadItems();
        FabWithActions.register(this.fabActions);
        const actions: Array<IMenuBarAction> = [
            {
                tooltip: 'actions.publish-changes',
                actionType: 'action-replan',
                handler: () => AdvancedServicesService.publishServiceChanges(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'menubar.delete',
                actionType: 'action-delete',
                handler: () => this.deleteService(),
                roles: ['Owner', 'Admin'],
            },

            {
                tooltip: 'general.copy',
                actionType: 'action-edit',
                handler: () => this.copy(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'Set shared meta data key' as TranslationKey,
                actionType: 'action-edit',
                handler: () => this.setDefaultQuantityToMetadataKey(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'Move up a level' as TranslationKey,
                actionType: 'action-edit',
                handler: () => this.moveUpALevel(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'Make child' as TranslationKey,
                actionType: 'action-edit',
                handler: () => this.makeChild(),
                roles: ['Owner', 'Admin'],
            },
        ];

        new MenuBarActionsEvent(actions);

        this.refreshModelInstanceText();
    }
    async setDefaultQuantityToMetadataKey() {
        if (!this.parent) return;
        const td = new TextDialog(
            'Enter shared meta field name' as TranslationKey,
            '',
            this.parent.mapDefaultQuantityToMetadataKey || '',
            ''
        );
        const newValue = await td.show();
        if (td.cancelled) return;
        this.parent.mapDefaultQuantityToMetadataKey = newValue;
        this.save();
    }
    public detached() {
        FabWithActions.unregister();
        new MenuBarActionsEvent();
    }

    protected loadItems = async () => {
        this.selectedParentId = this.router.currentInstruction.params && this.router.currentInstruction.params.id;
        this.ancestors = this.getAncestors();
        this.parent = this.getParent();
        this.router.updateTitle();
        this.serviceType = this.getServiceTypeFromCardinality();
        if (!this.parent) return;
        this.setImageThumbnail();
        this.children = this.getChildren(this.parent._id);
        this._updateChildrenOrders();
    };

    private getChildren(parentId: string) {
        const orderData = Data.all<JobOrderData>('joborderdata', x => x.type === 'services')[0]?.data; // Legacy

        return Data.all<Service>('tags', tag => tag.type === TagType.SERVICE && tag.parentId === parentId)
            .slice()
            .sort((a, b) => {
                let orderA = a.order === undefined ? orderData?.indexOf(a._id) : a.order;
                let orderB = b.order === undefined ? orderData?.indexOf(b._id) : b.order;

                orderA = orderA > -1 ? orderA : 0;
                orderB = orderB > -1 ? orderB : 0;
                if (orderA < orderB) return -1;
                else if (orderA > orderB) return 1;
                else return 0;
            });
    }

    protected selectItem = async (item: Service) => {
        this.router.navigateToRoute('service-item', { id: item._id }, { replace: true, trigger: true });
    };

    protected configureModelInstanceService = async () => {
        if (!this.parent) return;

        const modelInstanceSelectDialog = new Prompt(
            'advanced-services.model-instance-title',
            'advanced-services.model-instance-description',
            {
                cancelLabel: 'advanced-services.model-instance-off',
                altLabel: 'advanced-services.model-instance-is-instance-button',
                okLabel: 'advanced-services.model-instance-is-model-button',
            }
        );

        const isModel = await modelInstanceSelectDialog.show();
        if (modelInstanceSelectDialog.cancelled) {
            this.parent.mapDefaultQuantityToMetadataKey = undefined;
            this.save();
            return this.refreshModelInstanceText();
        }

        if (isModel) {
            this.parent.mapDefaultQuantityToMetadataKey = this.parent._id;
            this.save();
            return this.refreshModelInstanceText();
        }

        const modelServices = Data.all<Service>('tags', tag => tag.mapDefaultQuantityToMetadataKey === tag._id, {
            type: TagType.SERVICE,
            showOnDirectory: true,
        });
        if (modelServices.length === 0) {
            new NotifyUserMessage('advanced-services.model-instance-no-models');
            return;
        }

        const modelServicePicker = new SelectTagsDialog([], TagType.SERVICE, 1, 1, undefined, undefined, true, modelServices.slice());
        const service = (await modelServicePicker.show())?.[0];

        if (modelServicePicker.cancelled || !service) return;

        this.parent.mapDefaultQuantityToMetadataKey = service._id;
        this.save();
        return this.refreshModelInstanceText();
    };

    protected modelInstanceServiceDescription: string;

    protected fabActions: Array<IFabAction> = [
        {
            tooltip: 'actions.create-service',
            actionType: 'action-new-service',
            handler: () => {
                this.addService();
            },
            roles: ['Owner', 'Admin'],
        },
    ];

    private _updateChildrenOrders() {
        for (const child of this.children) {
            child.order = this.children.indexOf(child);
        }
    }

    protected save = () => {
        if (!this.parent) return;
        Data.put(this.children.concat([this.parent]));
    };

    private refreshModelInstanceText() {
        if (!this.parent) return (this.modelInstanceServiceDescription = '');

        if (!this.parent.mapDefaultQuantityToMetadataKey)
            return (this.modelInstanceServiceDescription = ApplicationState.localise('advanced-services.model-instance-off-description'));

        if (this.parent.mapDefaultQuantityToMetadataKey === this.parent._id)
            return (this.modelInstanceServiceDescription = ApplicationState.localise(
                'advanced-services.model-instance-is-model-description'
            ));

        const modelService = Data.get<Service>(this.parent.mapDefaultQuantityToMetadataKey);
        if (!modelService)
            return (this.modelInstanceServiceDescription = ApplicationState.localise('advanced-services.model-instance-off-description'));

        this.modelInstanceServiceDescription = ApplicationState.localise('advanced-services.model-instance-is-instance-description', {
            description: modelService.description,
        });
    }

    private async updateImageAndThumbnail(serviceImage?: Attachment) {
        if (!this.parent) return;
        if (!serviceImage) {
            const dialog = new SelectAttachmentsDialog([], { selectOne: true, isPublic: true });
            const resp = await dialog.show();
            if (!resp) return;
            serviceImage = resp[0];
            if (!serviceImage) return;
        }
        this.parent.imageInfo = { id: serviceImage._id, dimensions: serviceImage.dimensions, name: serviceImage.name };
        await Data.put(this.parent);
    }

    protected async addService() {
        const dialog = new TextDialog(
            'dialogs.services-add-title',
            'dialogs.add-service-title',
            '',
            'dialogs.add-service-placeholder',
            this.serviceNameValid,
            false
        );
        const name = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!name) return;

        try {
            await TagService.addTag(name.trim(), TagType.SERVICE, true, this.parent && this.parent._id, true);
            this.serviceType = this.getServiceTypeFromCardinality();
            this.loadItems();
        } catch (error) {
            Logger.error('Error in addService() on Services', { name, error });
            new NotifyUserMessage('services.error-adding-service');
        }
    }

    private serviceNameValid: (serviceName: string) => true | TranslationKey = (serviceName: string) => {
        if (!serviceName || !serviceName.trim()) return 'services.enter-service';

        //const value = serviceName.trim();

        // const duplicate = this.list.some(tag => tag.description.toLowerCase() === value.toLowerCase());
        // if (duplicate) {
        //     return 'services.service-already-exists';
        // }
        return true;
    };

    protected copy = async () => {
        if (!this.parent) return;

        const toUpdate: Array<Tag> = [];

        const newService = Utilities.copyObject(this.parent);
        newService._id = uuid();
        newService.description = `Copy of ${newService.description}`;
        toUpdate.push(newService);
        await Data.put(newService);
        const toCopy = this.copyDecendants(this.parent._id, newService._id);

        Data.put([newService].concat(toCopy));
        this.save();
    };

    private copyDecendants(serviceId: string, newId?: string) {
        const decendants: Array<Service> = [];
        this._copyDecendants(decendants, serviceId, newId);
        return decendants;
    }

    private _copyDecendants(copiedDecendants: Array<Service>, serviceId: string, newId?: string) {
        const children = Data.all<Service>('tags', (t: Tag) => t.type === TagType.SERVICE && t.parentId === serviceId);
        for (const child of children) {
            const copy = Utilities.copyObject(child);
            copy._id = uuid();
            copy.parentId = newId;
            copiedDecendants.push(copy);
            this._copyDecendants(copiedDecendants, child._id, copy._id);
        }
    }

    protected async editName() {
        if (!this.parent) return;
        const resp = await TextDialog.show('services.enter-service', this.parent.description || '');
        if (resp === false) return;
        this.parent.description = resp;
        this.save();
    }

    protected async editDescription() {
        if (!this.parent) return;
        const resp = await TextDialog.show('general.description', this.parent.longDescription || '', undefined, undefined, true);
        if (resp === false) return;
        this.parent.longDescription = resp;
        this.save();
    }

    protected async editDefaultPrice() {
        if (!this.parent) return;

        const price = await getNumber({ title: 'general.price', value: this.parent.price, prefix: ApplicationState.currencySymbol() });
        if (price === undefined) return;

        this.parent.price = price;
        this.save();
    }

    protected async editDefaultQuantity() {
        if (!this.parent) return;

        const quantity = await getNumber({ title: 'general.quantity', value: this.parent.quantity });
        if (quantity === undefined) return;

        this.parent.quantity = quantity;
        this.save();
    }

    protected async editMinimumPrice() {
        if (!this.parent) return;

        const price = await getNumber({
            title: 'general.price',
            value: this.parent.minimumPrice,
            prefix: ApplicationState.currencySymbol(),
        });
        if (price === undefined) return;

        this.parent.minimumPrice = price;
        this.save();
    }

    protected editFrequency = async () => {
        if (!this.parent) return;
        const frequencyDialog = new AvailableFrequenciesPickerDialog(this.parent);
        const selectedFrequencies = await frequencyDialog.show();
        if (!selectedFrequencies) return;
        this.parent.availableFrequencies = selectedFrequencies;
        this.save();
    };

    protected setInitialVisitPriceModifier = async () => {
        if (!this.parent) return;

        const priceEffect = await AdvancedServicesService.getPriceModifierValues(this.parent.initialPriceEffect, undefined, 'percentage');
        if (!priceEffect) return;

        this.parent.initialPriceEffect = priceEffect;
        this.save();
    };

    protected setPriceModifier = async () => {
        if (!this.parent) return;

        const priceModifier = await AdvancedServicesService.getPriceModifierValues(this.parent.priceModifier, undefined, 'percentage');
        if (!priceModifier) return;

        this.parent.priceModifier = priceModifier;
        this.save();
    };

    protected setAreaPriceModifiers = async (areaPriceModifiers: Array<AreaPriceModifier>) => {
        if (!this.parent) return;

        this.parent.areaPriceModifiers = areaPriceModifiers;
        this.save();
    };

    protected editAreaPriceModifiers = async () => {
        if (!this.parent) return;

        const dialog = new AureliaReactComponentDialog<Array<AreaPriceModifier>, AreaPriceModifiersDialogOptions>({
            component: AreaPriceModifiersDialog,
            componentProps: {
                storedAreas: this.parent.areaPriceModifiers || [],
            },
            dialogTitle: 'advanced-services.area-price-modifiers-title',
            isSecondaryView: true,
        });

        const priceModifiers = await dialog.show();
        if (!dialog.cancelled) this.setAreaPriceModifiers(priceModifiers);
    };

    protected get parentFrequencyText() {
        if (!this.parent) return '';
        return this.getAvailableFrequenciesText(this.parent?.availableFrequencies) || this.getFrequencyText(this.parent?.frequencyType);
    }

    protected get parentFirstVisitPriceModifier() {
        if (!this.parent) return '';
        return this.parent.initialPriceEffect?.value === 100 || !this.parent.initialPriceEffect
            ? 'No effect'
            : this.parent.initialPriceEffect?.value + '%';
    }
    protected get parentRegularPriceModifier() {
        if (!this.parent) return '';
        return this.parent.priceModifier?.value === 100 || !this.parent.priceModifier
            ? 'No effect'
            : this.parent.priceModifier?.value + '%';
    }

    protected get parentAreaPriceModifiers() {
        if (!this.parent) return '';
        return this.parent.areaPriceModifiers?.length || 'None';
    }

    protected getFrequencyText(value?: number) {
        if (value === undefined) return 'None';
        if (value === 0) return 'Ad-hoc';
        return 'Repeating';
    }

    protected getAvailableFrequenciesText(value?: AvailableFrequency[]) {
        if (value === undefined) return 'None';
        return value.map(e => e.label || this.getFrequencyText(e.frequency.type)).join(', ');
    }

    protected incrementMin() {
        if (!this.parent) return;
        if (!this.parent.min) this.parent.min = 0;
        this.parent.min++;
        this.serviceType = this.getServiceTypeFromCardinality();
        this.save();
    }

    protected decrementMin() {
        if (!this.parent) return;
        if (!this.parent.min) this.parent.min = 0;
        if (this.parent.min === 0) return;
        this.parent.min--;
        this.serviceType = this.getServiceTypeFromCardinality();
        this.save();
    }

    protected incrementMax() {
        if (!this.parent) return;
        if (!this.parent.max) this.parent.max = 0;
        this.parent.max++;
        this.serviceType = this.getServiceTypeFromCardinality();
        this.save();
    }

    protected decrementMax() {
        if (!this.parent) return;
        if (!this.parent.max) this.parent.max = 0;
        if (this.parent.max === 0) return;
        this.parent.max--;
        this.serviceType = this.getServiceTypeFromCardinality();
        this.save();
    }

    protected incrementMinChildren() {
        if (!this.parent) return;
        if (!this.parent.minChildren) this.parent.minChildren = 0;
        this.serviceType = 'custom';
        this.parent.minChildren++;
        this.save();
    }

    protected decrementMinChildren() {
        if (!this.parent) return;
        if (!this.parent.minChildren) this.parent.minChildren = 0;
        if (this.parent.minChildren === 0) return;
        this.serviceType = 'custom';
        this.parent.minChildren--;
        this.save();
    }

    protected incrementMaxChildren() {
        if (!this.parent) return;
        if (!this.parent.maxChildren) this.parent.maxChildren = 0;
        this.serviceType = 'custom';
        this.parent.maxChildren++;
        this.save();
    }

    protected decrementMaxChildren() {
        if (!this.parent) return;
        if (!this.parent.maxChildren) this.parent.maxChildren = 0;
        if (this.parent.maxChildren === 0) return;
        this.serviceType = 'custom';
        this.parent.maxChildren--;
        this.save();
    }

    private deleteService = async () => {
        if (!this.parent) return;
        let message: TranslationKey;
        const decendants: Array<Service> = [];
        this._getDecendants(decendants, this.parent._id);
        const length = decendants.length + 1;
        if (length === 0) message = 'dialogs.services-delete-message-none';
        else if (length === 1) message = 'dialogs.services-delete-message-one';
        else message = 'dialogs.services-delete-message-many';

        const prompt = new Prompt('prompts.confirm-title', message, {
            okLabel: 'general.delete',
            localisationParams: length > 1 ? { count: decendants.length.toString() } : undefined,
        });
        await prompt.show();

        if (!prompt.cancelled) {
            await Data.delete([this.parent].concat(decendants));
            this.save();
            if (this.parent.parentId)
                this.router.navigateToRoute('service-item', { id: this.parent.parentId }, { replace: true, trigger: true });
            else this.router.navigateToRoute('services-advanced', { replace: true, trigger: true });
        }
    };

    private _getDecendants(decendants: Array<Service>, serviceId: string) {
        const children = Data.all<Service>('tags', (t: Tag) => t.type === TagType.SERVICE && t.parentId === serviceId);
        for (const child of children) {
            decendants.push(child);
            this._getDecendants(decendants, child._id);
        }
    }

    //private _dragInProgress: boolean;
    //private _itemsAreDirty: boolean;

    protected _dragDropped = (oldIndex: number | Array<number>, newIndex: number | Array<number>) => {
        oldIndex = Array.isArray(oldIndex) ? oldIndex[0] : oldIndex;
        newIndex = Array.isArray(newIndex) ? newIndex[0] : newIndex;

        const newArray = this.children.slice();

        newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);

        this.children = newArray;
        this._updateChildrenOrders();
        this.save();
    };

    protected _dragStarted = (_event: any) => {
        // this._dragInProgress = true;
        //const selectedItems = this.selectedIndexes.map(i => this.listFiltered[i].original);
        // const itemInDrag = this.children[event.oldIndex];
        // if (!itemInDrag || selectedItems.length) return;
    };

    protected viewAttachments = async () => {
        if (!this.parent) return;
        const dialog = new AttachmentsDialog(this.parent, 'Service: ' + this.parent.description, 'node_add', 'Service Attachments');
        await dialog.show();
        this.save();
    };

    protected async setImage() {
        if (this.parent?.imageInfo) {
            const replacePrompt = new Prompt(
                'Replace or Remove Service Image' as TranslationKey,
                'Do you want to replace or remove the current service image?' as TranslationKey,
                {
                    okLabel: 'attachments.replace',
                    altLabel: 'general.remove',
                    cancelLabel: 'general.cancel',
                }
            );
            const replace = await replacePrompt.show();
            if (replacePrompt.cancelled) return;

            if (!replace) {
                this.parent.imageInfo = undefined;
                await Data.put(this.parent);
                this.setImageThumbnail();
                return;
            }
        }

        await this.updateImageAndThumbnail();
        this.setImageThumbnail();
    }

    protected async moveUpALevel() {
        if (!this.parent) return;
        if (!this.parent.parentId) return;
        const parentParent = await Data.get<Service>(this.parent.parentId);
        if (!parentParent) return;
        this.parent.parentId = parentParent.parentId;
        await this.save();
        this.loadItems();
    }

    protected async makeChild() {
        if (!this.parent) return;
        const parentId = this.parent._id;
        const parentParentId = this.parent.parentId;
        if (!parentParentId) return;
        const siblings = this.getChildren(parentParentId);
        const indexOfThis = siblings.findIndex(x => x._id === parentId);
        if (indexOfThis < 1) return;
        const newParent = siblings[indexOfThis - 1];
        this.parent.parentId = newParent._id;
        await this.save();
        this.loadItems();
    }
}
