import type { SettingIds, TranslationKey } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { StateFlagsChangedEvent } from '../../Events/StateFlagsChangedEvent';

export class ToggleSettingCustomElement {
    @bindable settingId: SettingIds;
    @bindable released = true;
    @bindable tag?: 'new' | 'beta' | 'experiment';
    @bindable default = false;
    @bindable toggled?: (enabled: boolean) => void | boolean | Promise<void | boolean>;
    @bindable hide?: boolean;
    // TODO: Implement user scoped settings
    @bindable scope: 'global' | 'local' = 'global'; // account wide or per-user
    name: string;

    description: string;
    developer = ApplicationState.stateFlags.devMode;
    private _enabled: boolean;
    private _devModeSubscription: Subscription;
    attached() {
        this._enabled = ApplicationState.getSetting<boolean>(this.settingId, this.default);
        this.name = ApplicationState.localise(`settings.${this.settingId.replace(/\./g, '-')}` as TranslationKey);
        this.description = this._getDesc(this._enabled);
        this.hide = ApplicationState.stateFlags.devMode;

        this._devModeSubscription = StateFlagsChangedEvent.subscribe((e: StateFlagsChangedEvent) => (this.developer = e.devMode));
    }

    @computedFrom('_enabled')
    protected get toggle() {
        return this._enabled;
    }

    protected set toggle(enabled: boolean) {
        this._enabled = enabled;
        this.description = this._getDesc(enabled);
        ApplicationState.setSetting(this.settingId, enabled);
        const undoCheck = async () => {
            const toggled = await this.toggled?.(enabled);
            const undo = toggled === false;
            if (!undo) return;

            this._enabled = !enabled;
            this.description = this._getDesc(this._enabled);
            ApplicationState.setSetting(this.settingId, this._enabled);
        };
        undoCheck();
    }

    private _getDesc(checked: boolean) {
        return ApplicationState.localise(
            `settings.${this.settingId.replace(/\./g, '-')}-description-${checked ? 'on' : 'off'}` as TranslationKey
        );
    }

    detached() {
        this._devModeSubscription?.dispose();
    }
}
