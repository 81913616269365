import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { ScheduleItem } from '../Components/ScheduleItem';
import type { IScheduleItemAction } from './IScheduleItemAction';
export class Skip implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {}
    public actionType = <ItemActionType>'action-skip';
    public async handler() {
        await JobOccurrenceService.skipJobOccurrence(this.scheduleItem.occurrence);
        ScheduleItem.refresh(this.scheduleItem);
        return Promise.resolve(this.scheduleItem);
    }
    public tooltip: TranslationKey = 'actions.skip';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Planner', 'JobEditor', 'Worker', 'Creator'];
}
