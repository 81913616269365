import type { Supplier } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { Data } from '../Data/Data';
import './supplier-select.scss';

// TODO Copy and pasted from another select seems a bit overkill need to check docs on mui select

export class SupplierSelect {
    @bindable() value: undefined | string;

    protected allSuppliers: Readonly<Array<Supplier>>;
    protected loading = true;

    @bindable protected selected: Supplier | undefined;
    async init() {
        this.allSuppliers = Data.all<Supplier>(
            'supplier',
        );
        this.selected = this.allSuppliers.find(x => x._id === this.value);
    }

    valueChanged() {
        if (this.loading) return;
        this.selected = this.allSuppliers.find(x => x._id === this.value);
    }

    getSuppliers = async (searchText: string, currentValue: Supplier) => {
        const results = [];
        if (currentValue) {
            this.selected = currentValue;
            this.value = currentValue?._id;
            results.push(currentValue);
        }

        const sResults = Data.all<Supplier>(
            'supplier',
            x => x._id !== currentValue?._id &&
                (!searchText || x.name.toLowerCase().includes(searchText.toLowerCase()))
        );
        results.push(...sResults);
        return results;
    };

    async attached() {
        this.loading = true;
        this.init();
        this.loading = false;
    }
}
