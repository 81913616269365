import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { TextDialog } from '../Dialogs/TextDialog';
import { Logger } from '../Logger';
import type { ThemeOptions } from './ThemeOptions';
import { theBlueTheme, theGreenTheme, theNightTheme, thePinkTheme, thePurpleTheme, theRedTheme, theStandardTheme } from './Themes';

type themes = 'standard' | 'standard-dark' | 'red' | 'green' | 'purple' | 'pink' | 'blue' | 'night' | 'custom';

export class ThemeService {
    public static changeTheme(theme: ThemeOptions) {
        const r = document.querySelector(':root') as HTMLElement;
        if (!r?.style) return;

        for (const key in theme) {
            r.style.setProperty(key, theme[key as keyof ThemeOptions]);
        }

        try {
            localStorage.setItem('squeegee-theme', JSON.stringify(theme));
        } catch (error) {
            Logger.error('Failed to store theme in local storage', error);
        }
    }

    public static setStandardTheme() {
        this.changeTheme(theStandardTheme);
    }

    public static setTheRedTheme() {
        this.changeTheme(theRedTheme);
    }

    public static setTheGreenTheme() {
        this.changeTheme(theGreenTheme);
    }

    public static setThePurpleTheme() {
        this.changeTheme(thePurpleTheme);
    }

    public static setThePinkTheme() {
        this.changeTheme(thePinkTheme);
    }

    public static setTheBlueTheme() {
        this.changeTheme(theBlueTheme);
    }

    public static setTheNightTheme() {
        this.changeTheme(theNightTheme);
    }

    public static switchTheme(theme?: themes) {
        switch (theme) {
            case 'standard':
                ThemeService.setStandardTheme();
                break;
            case 'red':
                ThemeService.setTheRedTheme();
                break;
            case 'green':
                ThemeService.setTheGreenTheme();
                break;
            case 'blue':
                ThemeService.setTheBlueTheme();
                break;
            case 'purple':
                ThemeService.setThePurpleTheme();
                break;
            case 'pink':
                ThemeService.setThePinkTheme();
                break;
            case 'night':
                ThemeService.setTheNightTheme();
                break;
            case 'custom': {
                const customTheme = ApplicationState.getSetting<ThemeOptions>('global.theme-custom');
                if (customTheme) ThemeService.changeTheme(customTheme);
                break;
            }
            default: {
                ThemeService.setStandardTheme();
                return;
            }
        }

        ApplicationState.setSetting('global.theme', theme);
    }

    public static init() {
        try {
            const theme = ApplicationState.getSetting<themes>('global.theme');
            if (!theme) {
                localStorage.removeItem('squeegee-theme');
                return;
            }

            ThemeService.switchTheme(theme);
        } catch (error) {
            Logger.error('Failed to load theme', error);
        }
    }

    public static preInit() {
        try {
            ApplicationState.setZoom();
            const themeJson = localStorage.getItem('squeegee-theme');
            const theme = themeJson ? JSON.parse(themeJson) || theStandardTheme : theStandardTheme;
            ThemeService.changeTheme(theme);
        } catch {
            // swallow, don't care.
        }
    }
    public static async createCustomTheme() {
        const theme = ApplicationState.getSetting<themes>('global.theme', 'standard');

        const customTheme =
            ApplicationState.getSetting<ThemeOptions>('global.theme-custom') ||
            (theme === 'red' ? theRedTheme : theme === 'green' ? theGreenTheme : theStandardTheme);

        for (const key in customTheme) {
            const dialog = new TextDialog(
                key.replace('--themed-', '').replace(/-/g, ' ') as TranslationKey,
                'Enter a css colour value' as TranslationKey,
                customTheme[key as keyof ThemeOptions],
                '',
                undefined,
                undefined,
                'colour'
            );

            const showing = dialog.show(DialogAnimation.NONE);
            customTheme[key as keyof ThemeOptions] = await showing;
            if (dialog.cancelled) return;
        }

        await ApplicationState.setSetting('global.theme', 'custom');
        await ApplicationState.setSetting('global.theme-custom', customTheme);

        ThemeService.changeTheme(customTheme);
    }
}
