import type {
    AutomaticPaymentStatusRequired,
    Customer,
    Expense,
    PaymentAccount,
    Transaction,
    TransactionSubType,
    TransferTransaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { fetchTransactionNotificationStatus } from '../Notifications/functions/fetchTransactionNotificationStatus';
import { t } from '../t';

export class TransactionUtils {
    public static isOrphanedVoid(transaction: Transaction): boolean {
        // if we have a voidedId but the voided transaction is not in the data store, then this is an orphaned void
        if (transaction.voidedId && Data.get(transaction.voidedId) === undefined) return true;
        return false;
    }

    public static isVoidForAnotherTransaction(transaction: Transaction): boolean {
        if (ApplicationState.stateFlags.devMode || !transaction.voidedId || TransactionUtils.isOrphanedVoid(transaction) === true)
            return false;

        const isNegative = (value: number): boolean => {
            return (value || 0) < 0;
        };

        const isPositive = (value: number): boolean => {
            return (value || 0) >= 0;
        };

        switch (transaction.transactionSubType) {
            case 'invoice.standard':
            case 'invoice.imported':
            case 'invoice.other':
            case 'payment.refund':
                return isNegative(transaction.amount);
            case 'invoice.credit-note':
            case 'invoice.write-off':
            case 'payment.cash':
            case 'payment.cheque':
            case 'payment.bank-transfer':
            case 'payment.card':
            case 'payment.stripe':
            case 'payment.imported':
            case 'payment.other':
            case 'payment.tip.cash':
            case 'payment.tip.cheque':
            case 'payment.tip.bank-transfer':
            case 'payment.tip.card':
            case 'payment.tip.other':
            case 'adjustment.write-off-imported':
            case 'adjustment.write-off':
            case 'auto.go-cardless':
            case 'auto.other':
                return isPositive(transaction.amount);
            default:
                return false;
        }
    }

    public static getAvatarForTransaction(transaction: Transaction): { colour: string; text: string } {
        const avatar = { colour: '', text: '' };
        const { voidedId, amount, transactionType, invoice } = transaction;
        if (voidedId) {
            avatar.colour = '#ff4f4f';
            avatar.text = 'block';
            return avatar;
        }

        switch (transactionType) {
            case TransactionType.Invoice: {
                const isTip = transaction.transactionSubType === 'invoice.tip';
                avatar.text = isTip
                    ? 'savings'
                    : amount < 0
                    ? 'edit'
                    : amount === 0
                    ? 'check'
                    : invoice && invoice.paid
                    ? 'check'
                    : 'hourglass_empty';
                avatar.colour = isTip
                    ? '#66b988'
                    : transaction.amount <= 0
                    ? '#33577B'
                    : transaction.invoice && transaction.invoice.paid
                    ? '#ab47bc'
                    : '#e67635';

                break;
            }
            case TransactionType.Payment:
                avatar.text = transaction.transactionSubType?.includes('tip') ? 'savings' : 'credit_card';
                avatar.colour = transaction.transactionSubType?.includes('tip')
                    ? '#66b988'
                    : transaction.amount <= 0
                    ? '#33577B'
                    : '#F66578';
                break;
            case TransactionType.Adjustment:
                avatar.text = 'mode_edit';
                avatar.colour = '#AB47BC';
                break;
            case TransactionType.AutomaticPayment:
                avatar.text = 'autorenew';
                avatar.colour = '#33577B';
                break;
            case TransactionType.Expense:
                avatar.text = 'receipt';
                avatar.colour = '#33577B';
                break;
            case TransactionType.Transfer:
                avatar.text = transaction.transactionSubType === 'transfer.in' ? 'arrow_right' : 'arrow_left';
                avatar.colour = transaction.transactionSubType === 'transfer.in' ? '#66b988' : '#F66578';
        }

        return avatar;
    }
    public static getTagsForTransaction(transaction: Transaction, includeCustomerData = false): Array<{ text: string; status: string }> {
        if (transaction.transactionSubType === 'invoice.tip' || transaction.transactionSubType?.includes('tip')) return [];

        const { invoice, voidedId, transactionType, transactionSubType, status = '', customerId } = transaction;

        const tags: Array<{ text: string; status: string; description?: TranslationKey }> = [];

        if (includeCustomerData && customerId) {
            const customer = Data.get<Customer>(customerId);
            if (customer) {
                tags.push({ text: customer.name, status: 'bg-slate' });
                if (customer.address.addressDescription) tags.push({ text: customer.address.addressDescription, status: 'bg-slate' });
            }
        }

        if (invoice && invoice.invoiceNumber) {
            tags.push({
                text: `#${invoice.invoiceNumber.toString()}`,
                status: invoice.paid ? 'paid' : 'bg-orange',
            });
        }

        if (voidedId) {
            if (transaction.transactionType === TransactionType.Payment || transaction.transactionType === TransactionType.AutomaticPayment)
                if (transaction.amount > 0) {
                    tags.push({
                        text: `${ApplicationState.stateFlags.devMode ? 'DEV_MODE ENABLED ' : ''}${ApplicationState.localise(
                            'payments.reversal'
                        )}`,
                        status: 'bg-red',
                    });
                } else {
                    tags.push({ text: ApplicationState.localise('general.cancelled'), status: 'bg-red' });
                }
            else if (transaction.transactionType === TransactionType.Invoice) {
                if (transaction.amount < 0) {
                    tags.push({
                        text: `${ApplicationState.stateFlags.devMode ? 'DEV_MODE ENABLED ' : ''}${ApplicationState.localise(
                            'payments.invoice-cancellation'
                        )}`,
                        status: 'bg-red',
                    });
                } else {
                    tags.push({ text: ApplicationState.localise('general.cancelled'), status: 'bg-red' });
                }
            }
        }
        if (transactionType === TransactionType.AutomaticPayment || transactionType === TransactionType.Payment) {
            if (ApplicationState.hasAdvancedOrAbove) {
                const paymentAccountId = transaction.paymentDetails && transaction.paymentDetails.paymentAccountId;
                if (paymentAccountId) {
                    const account = Data.get<PaymentAccount>(paymentAccountId);
                    if (account) {
                        if (ApplicationState.hasAdvancedOrAbove) tags.push({ text: account.name, status: 'bg-slate' });
                    }
                } else {
                    if (ApplicationState.hasAdvancedOrAbove && transactionType !== TransactionType.AutomaticPayment) {
                        tags.push({ text: 'No payment account', status: 'bg-slate' });
                    }
                }
            }
        } else if (transactionType === TransactionType.Expense) {
            const expense = transaction as Expense;

            if (expense.paymentDate) {
                const paymentAccountId = expense.paymentAccountId;

                if (paymentAccountId) {
                    const account = Data.get<PaymentAccount>(paymentAccountId);
                    if (account) {
                        if (ApplicationState.hasAdvancedOrAbove) tags.push({ text: account.name, status: 'bg-slate' });
                    }
                } else {
                    if (ApplicationState.hasAdvancedOrAbove) tags.push({ text: 'No payment account', status: 'bg-slate' });
                }
            } else {
                if (ApplicationState.hasAdvancedOrAbove) tags.push({ text: 'Not paid', status: 'bg-warning' });
            }
        } else if (transactionType === TransactionType.Transfer) {
            const paymentAccountId = (transaction as TransferTransaction).paymentAccountId;
            if (paymentAccountId) {
                const account = Data.get<PaymentAccount>(paymentAccountId);
                if (account) {
                    tags.push({ text: account.name, status: 'bg-slate' });
                }
            } else {
                if (ApplicationState.hasAdvancedOrAbove) tags.push({ text: 'No account', status: 'bg-slate' });
            }
        }

        if (invoice) {
            if (!voidedId && invoice && !invoice.paid) {
                const days = moment(invoice.dueDate).diff(moment(), 'days');
                if (days === 0) tags.push({ text: 'due today', status: 'bg-orange' });
                else if (days === 1) tags.push({ text: 'due tomorrow', status: 'bg-blue' });
                else if (days === -1) tags.push({ text: 'due yesterday', status: 'bg-red' });
                else
                    tags.push({
                        text: `${days > 0 ? 'due in ' : 'due '}${Math.abs(days)} days ${days < 0 ? 'ago' : ''}`,
                        status: days > 0 ? 'bg-blue' : 'bg-red',
                    });
            } else if (!voidedId && invoice && invoice.paid) {
                tags.push({ text: 'Paid', status: 'paid' });
            }
        } else if (transactionType === TransactionType.AutomaticPayment) {
            tags.push({
                //Legacy payments don't have a provider set
                text:
                    transaction.paymentDetails?.paymentProvider === 'stripe' || transaction.paymentDetails?.paymentProvider === 'gocardless'
                        ? transaction.paymentDetails?.paymentProvider
                        : 'Custom Payment Method',
                status:
                    status === 'complete'
                        ? 'paid'
                        : ['failed', 'missing', 'unknown'].includes(status)
                        ? 'bg-red'
                        : status === 'pending'
                        ? 'bg-orange'
                        : 'bg-blue',
            });
        } else if (transactionType === TransactionType.Payment && transactionSubType) {
            const text = TransactionUtils.getSubTypeTitle(transactionType, transactionSubType);
            tags.push({ text, status: 'default' });
        } else if (transactionType === TransactionType.Invoice && transactionSubType) {
            tags.push({
                text: transactionSubType.replace('invoice.', '').replace(/\./g, ' ').replace(/\-/g, ' '),
                status: 'default',
            });
        }

        if (!voidedId && status && status !== 'apiHandlingComplete' && status !== 'complete') {
            tags.push({
                text: t(
                    ['complete', 'failed', 'missing', 'unknown'].includes(status)
                        ? `automatic-payment-status.${status as AutomaticPaymentStatusRequired}`
                        : 'automatic-payment-status.pending'
                ),
                status: ['complete', 'failed', 'missing', 'unknown', 'apiHandlingFailed'].includes(status) ? 'bg-red' : 'bg-blue',
                description: t(
                    ['complete', 'failed', 'missing', 'unknown'].includes(status)
                        ? `automatic-payment-status-description.${status as AutomaticPaymentStatusRequired}`
                        : 'automatic-payment-status-description.pending'
                ),
            });
        }

        return tags;
    }

    public static async getTransactionNotificationStatusTags(transaction: Transaction, forceStatusCheck = false) {
        const result = await fetchTransactionNotificationStatus(transaction._id, transaction.createdDate, forceStatusCheck);
        const tags = [];

        if (result.success) {
            if (result.status.sms) tags.push({ text: `sms ${result.status.sms}`, status: result.status.sms });
            if (result.status.email) tags.push({ text: `email ${result.status.email}`, status: result.status.email });

            if (tags.length === 0) {
                tags.push({ text: `not sent`, status: 'bg-orange' });
            }
        }

        return tags;
    }

    public static getSubTypeTitle(type: TransactionType, subType: TransactionSubType | undefined): string {
        if (!subType) return ApplicationState.localise(('transaction.type-' + type) as TranslationKey);

        if (subType.startsWith('payment.custom-')) {
            return TransactionUtils.getCachedCustomPaymentTypeName(subType);
        }

        return ApplicationState.localise(('transaction.sub-type-' + subType.replace(/\./g, '-')) as TranslationKey);
    }

    private static _cachedCustomPaymentTypes: Record<string, string>;
    public static getCachedCustomPaymentTypeName(subType: string): string {
        if (!TransactionUtils._cachedCustomPaymentTypes?.[subType]) {
            TransactionUtils._cachedCustomPaymentTypes = ApplicationState.getSetting(
                'global.custom-payment-methods',
                {} as Record<string, string>
            );
        }
        return TransactionUtils._cachedCustomPaymentTypes[subType];
    }
}
