import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';


export const enterSkillText = async (skill?: string): Promise<{ action: 'text', text: string; } | { action: 'cancel'; } | { action: 'delete'; }> => {
    if (skill) {
        const deleteReasonDialog = await new Prompt('general.warning', 'settings.skill-delete-check', { altLabel: 'general.delete', okLabel: 'general.edit' });
        const deleteReason = await deleteReasonDialog.show();
        if (deleteReasonDialog.cancelled) return { action: 'cancel' };

        if (!deleteReason) return { action: 'delete' };
    }

    const title: TranslationKey = skill ? 'settings.edit-skill-dialog-title' : 'settings.skill-text-title-new';
    const skillTextDialog = new TextDialog(title, '', skill || '', '');
    const skillText = await skillTextDialog.show();

    if (skillTextDialog.cancelled || !skillText) return { action: 'cancel' };

    return { action: 'text', text: skillText };
};
