import type { LedgerId } from '@nexdynamic/balance-core';
import type { Expense, StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import { ExpenseCategory, Tag, TagType, TransactionType } from '@nexdynamic/squeegee-common';
import { standardServices } from '@nexdynamic/squeegee-portal-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import { Utilities } from '../Utilities';

export class TagService {
    public static getTags() {
        return Data.all<Tag>('tags');
    }

    public static getTagsByType(type: TagType, onlySystemTags = false) {
        const result: Array<Tag> = [];

        if (onlySystemTags) {
            if (type === TagType.SERVICE) {
                for (const service of standardServices) {
                    const translationKey = service as TranslationKey;
                    const systemServiceTag = new Tag(ApplicationState.localise(translationKey), TagType.SERVICE);

                    //systemServiceTag.isSystemTag = true;
                    systemServiceTag._id = service;
                    result.push(systemServiceTag);
                }
            }
        } else {
            result.push(...Data.all('tags', (t: Tag) => t.type === type));
        }

        return result;
    }

    private static async validateTagAndNotifyUI(tag: Tag): Promise<Array<any>> {
        const errors = Utilities.validateAndNotifyUI(!!tag.description && !!tag.description.length, 'validation.description-required');
        if (errors.length > 0) return errors;

        const description = tag.description ? tag.description.toLowerCase() : '';
        const count = Data.count<Tag>(
            'tags',
            dataTag => tag.type === dataTag.type && !!dataTag.description && dataTag.description.toLowerCase() === description
        );
        return Utilities.validateAndNotifyUI(
            count === 0,
            tag.type === TagType.SERVICE ? 'services.service-already-exists' : 'rounds.round-already-exists'
        );
    }

    public static async updateTag(tag: Tag, skipValidate?: boolean) {
        if (!skipValidate) {
            const errors = await TagService.validateTagAndNotifyUI(tag);
            if (!errors || errors.length === 0) await Data.put(tag);
        } else {
            await Data.put(tag);
        }
        await TagService.updateTagToLinkedItems(tag);
    }

    public static async addExpenseCategory(description: string, balanceLedgerId?: LedgerId | null): Promise<ExpenseCategory | undefined> {
        let tag = new ExpenseCategory(description);
        tag.balanceLedgerId = balanceLedgerId;
        const duplicateTags = Data.all('tags', (t: Tag) => t.type === tag.type && t.description === tag.description);
        if (!duplicateTags.length) await Data.put(tag);
        else tag = duplicateTags[0];
        return tag;
    }

    public static async addTag(
        description: string,
        type: TagType,
        skipValidate?: boolean,
        parentId?: string,
        skipDupeCheck?: boolean,
        isForDirectory?: boolean
    ): Promise<Tag | undefined> {
        let tag = new Tag(description, type);
        tag.parentId = parentId;
        if (isForDirectory) (tag as any).showOnDirectory = true;
        if (!skipValidate) {
            const errors = await TagService.validateTagAndNotifyUI(tag);
            if (errors.length) return;
        }
        const duplicateTags = Data.all('tags', (t: Tag) => t.type === tag.type && t.description === tag.description);

        if (!duplicateTags.length || skipDupeCheck) await Data.put(tag);
        else tag = duplicateTags[0];
        return tag;
    }

    public static async removeTag(tag: Tag) {
        await Data.delete(tag);
        await TagService.removeTagFromLinkedItems(tag);
    }

    private static async removeTagFromLinkedItems(tag: Tag) {
        try {
            const toUpdate: Array<StoredObject> = [];
            if (tag.type === TagType.EXPENSE_CATEGORY) {
                const expensesThatNeedUpdating = Data.all<Expense>(
                    'transactions',
                    t => t.transactionType === TransactionType.Expense && t.category?._id === tag._id
                );
                for (const expense of expensesThatNeedUpdating) {
                    expense.category = undefined;
                    toUpdate.push(expense);
                }
            }

            if (toUpdate.length) await Data.put(toUpdate);
        } catch (error) {
            Logger.error('Unable to remove tag from linked item', error);
        }
    }

    private static async updateTagToLinkedItems(tag: Tag) {
        try {
            const toUpdate: Array<StoredObject> = [];
            if (tag.type === TagType.EXPENSE_CATEGORY) {
                const expensesThatNeedUpdating = Data.all<Expense>(
                    'transactions',
                    t => t.transactionType === TransactionType.Expense && t.category?._id === tag._id
                );
                for (const expense of expensesThatNeedUpdating) {
                    expense.category = tag;
                    toUpdate.push(expense);
                }
            }

            if (toUpdate.length) await Data.put(toUpdate);
        } catch (error) {
            Logger.error('Unable to update tag to linked item', error);
        }
    }
}
