import type { IProductPlanSubscription, SqueegeeCustomer, SqueegeePlan } from '@nexdynamic/squeegee-common';
import type { Stripe } from 'stripe';
import { Api } from './Api';

export class SubscriptionApi {
    public static async checkAndUpdateDefaultCard() {
        await Api.post<Stripe.Customer | undefined>(Api.apiEndpoint, '/api/customer/set-default-card');
    }
    public static async getCustomer(): Promise<undefined | SqueegeeCustomer> {
        const response = await Api.get<SqueegeeCustomer>(Api.apiEndpoint, '/api/customer-with-plan');
        const customer: SqueegeeCustomer | undefined = response && response.data;
        if (customer) return customer;
    }

    public static async getCustomerBalance(): Promise<{ balance: number; currencySymbol: string } | undefined> {
        const response = await Api.get<{ balance: number; currencySymbol: string }>(Api.apiEndpoint, '/api/customer-balance').catch(
            () => undefined
        );

        const data = response && response.data;
        if (data && data.balance !== undefined) return { balance: Number(data.balance) || 0, currencySymbol: data.currencySymbol || '' };
    }

    public static async getPlans() {
        const url = '/api/customer/plans';
        const response = await Api.get<{ data: Array<SqueegeePlan> }>(Api.apiEndpoint, url);
        if (response && response.data && response.data.data) return response.data.data;
    }

    public static async customerSubscribe(
        planId: string,
        userCount: number,
        billingDay?: 'start' | '15th' | 'end',
        verifyPayment = true,
        failOnExisting = false
    ) {
        const url = '/api/customer/subscribe';
        const data: any = { planId, userCount, billingDay, verifyPayment, failOnExisting };

        const response = await Api.post<IProductPlanSubscription>(Api.apiEndpoint, url, data, false, true);
        if (response) {
            if (response.status === 200) return response.data;
            if (response.status === 500) throw response.data;
        }
    }

    public static async cancelSubscription(subscriptionId: string): Promise<SqueegeePlan | undefined> {
        const url = `/api/customer/cancel?subscriptionId=${subscriptionId}`;
        const response = await Api.post<SqueegeePlan>(Api.apiEndpoint, url, {}, false, false);
        if (response) return response.data;
    }

    public static async deleteAccount(dataEmail: string): Promise<boolean | undefined> {
        const url = `/api/customer/delete-account-request?dataEmail=${encodeURIComponent(dataEmail)}`;
        const response = await Api.post<boolean>(Api.apiEndpoint, url, {}, false, false);
        if (response) return response.data;
    }

    public static async reactivateSubscription(subscriptionId: string) {
        const url = `/api/customer/reactivate?subscriptionId=${subscriptionId}`;
        const response = await Api.post<SqueegeePlan>(Api.apiEndpoint, url, {}, false, false);
        if (response) return response.data;
    }

    public static async payBalance() {
        const paid = await Api.post<{ success: boolean }>(Api.apiEndpoint, '/api/customer/pay-balance');
        return paid?.data?.success || false;
    }
}
