import type { Job, JobGroup, LngLat, Tag } from '@nexdynamic/squeegee-common';

export class TagData {
    public constructor(
        public tag: Tag,
        public jobs: Array<Job>,
        public activeJobs: number,
        public financialData: FinancialData = new FinancialData()
    ) {}
}

export class JobGroupData {
    public tag: JobGroup;
    public frequencyText?: string;
    public financialData: FinancialData = new FinancialData();
    public jobs: Array<Job> = [];
    public distance?: number;
    public lngLat?: LngLat;
    public matchingText?: string;
}

export class FinancialData {
    totalPerYear = 0;
    totalPerMonth = 0;
    avgPerJobPerYear = 0;
    avgPerJobPerMonth = 0;
    totalOneOff = 0;
}
