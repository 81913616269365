import DeleteIcon from '@mui/icons-material/Delete';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PeopleIcon from '@mui/icons-material/People';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import {
    Box,
    Button,
    ButtonBase,
    Chip,
    IconButton,
    lighten,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { AccountUser, Team, TimeEntry } from '@nexdynamic/squeegee-common';
import { type Customer, type JobOccurrence } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ExpandableFab from '../../Components/Fabs/React/ExpandableFab';
import { Data } from '../../Data/Data';
import type { AureliaReactComponentDialogComponent } from '../../Dialogs/AureliaReactComponentDialogComponent';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { UserService } from '../../Users/UserService';
import { TimerService } from '../TimerService';
import type { WorkerTimer } from '../WorkerTimer';
import CreateTimeEntry from './CreateTimeEntry';

export type MangedStoredEventsDialogComponentProps = {
    occurrence: JobOccurrence;
    initialAssignee?: AccountUser | Team;
};

export type TimeEntriesPerWorker = {
    [assigneeId: string]: Array<TimeEntry>;
};

const ManageStoredEventsDialogComponent: AureliaReactComponentDialogComponent<MangedStoredEventsDialogComponentProps> = ({
    occurrence,
    initialAssignee,
}) => {
    const theme = useTheme();
    const workers = UserService.getActiveUsers();
    const workersAndTeams: Array<AccountUser | Team> = [...workers, ...UserService.getTeams()];

    const customer = Data.get<Customer>(occurrence.customerId || '');

    const [assignees, setAssignees] = useState<Array<AccountUser | Team>>();
    const [timers, setTimers] = useState<Array<WorkerTimer>>();
    const [timeEntries, setTimeEntries] = useState<TimeEntriesPerWorker>({});
    const [selectedAssignee, setSelectedAssignee] = useState<AccountUser | Team | undefined>(initialAssignee);
    const [creatingTimeEntry, setCreatingTimeEntry] = useState(false);
    const [editingTimeEntry, setEditingTimeEntry] = useState<TimeEntry | undefined>();

    const updateUI = () => {
        setTimers(TimerService.getWorkerTimersForJobOccurrence(occurrence));
        setAssignees(AssignmentService.getAssignees(occurrence));
        setTimeEntries(() => {
            const assignees: Array<AccountUser | Team> = [...UserService.getActiveUsers(), ...UserService.getTeams()];
            return assignees.reduce((acc, assignee) => {
                if (assignee.resourceType === 'team') {
                    const teamMembers = (assignee as Team).members;
                    const teamTimeEntries = teamMembers.reduce((acc, memberId) => {
                        const memberTimeEntries = TimerService.getTimeEntriesForJobOccurrence(occurrence).filter(
                            timeEntry => timeEntry.assigneeId === memberId
                        );
                        return acc.concat(memberTimeEntries);
                    }, [] as TimeEntry[]);
                    acc[assignee._id] = teamTimeEntries;
                    return acc;
                } else {
                    acc[assignee._id] = TimerService.getTimeEntriesForJobOccurrence(occurrence).filter(
                        timeEntry => timeEntry.assigneeId === assignee._id
                    );
                }
                return acc;
            }, {} as TimeEntriesPerWorker);
        });

        if (TimerService.isTimerRunning(occurrence)) {
            const interval = setInterval(() => {
                setTimers(TimerService.getWorkerTimersForJobOccurrence(occurrence));
            }, 1000);
            return () => clearInterval(interval);
        }
    };

    useEffect(() => {
        updateUI();
    }, [occurrence, creatingTimeEntry, editingTimeEntry]);

    const [visibleTimeEntries, setVisibleTimeEntries] = useState<Array<TimeEntry>>([]);

    useEffect(() => {
        if (!selectedAssignee) {
            setVisibleTimeEntries(
                Object.values(timeEntries)
                    .reduce((acc, val) => {
                        return val.reduce((acc, timeEntry) => {
                            if (!acc.find(accTimeEntry => accTimeEntry.start?._id === timeEntry.start?._id)) {
                                acc.push(timeEntry);
                            }
                            return acc;
                        }, acc);
                    }, [])
                    .sort((a, b) => {
                        if (!a.start || !a.stop) return 1;
                        if (!b.start || !b.stop) return -1;
                        return a.start.eventTimestamp - b.start.eventTimestamp;
                    })
                    .reverse()
                    .slice(0, 10)
            );
        } else {
            if (!timeEntries[selectedAssignee._id]) return;
            setVisibleTimeEntries(
                timeEntries[selectedAssignee._id]
                    .sort((a, b) => {
                        if (!a.start || !a.stop) return 1;
                        if (!b.start || !b.stop) return -1;
                        return a.start.eventTimestamp - b.start.eventTimestamp;
                    })
                    .reverse()
                    .slice(0, 10)
            );
        }
    }, [selectedAssignee, timeEntries]);

    const handleDeleteTimeEntry = async (timeEntry: TimeEntry) => {
        try {
            const confirmationDialog = prompt('time-entries.delete-time-entry', 'time-entries.delete-time-entry-confirmation', {
                cancelLabel: 'general.cancel',
                okLabel: 'general.delete',
            });

            const confirmed = await confirmationDialog.show();
            if (!confirmed) return;

            let entries;

            const team = assignees?.find(
                (team: Team) => team.resourceType === 'team' && team.members?.find(member => member === timeEntry.assigneeId)
            );

            if (!team) {
                entries = timeEntries[timeEntry.assigneeId];
            } else {
                entries = timeEntries[team._id];
            }

            if (!entries) throw new Error('Could not find time entry for assignee, does it exist?');

            const startEvent = entries.find(entry => entry.start?._id === timeEntry.start?._id)?.start;
            const stopEvent = entries.find(entry => entry.stop?._id === timeEntry.stop?._id)?.stop;

            if (!startEvent || !stopEvent) throw new Error('Could not find start or stop event for time entry.');

            await TimerService.deleteEntry(startEvent);
            await TimerService.deleteEntry(stopEvent);

            const newEntries = TimerService.getTimeEntriesForJobOccurrence(occurrence);
            const newEntry = newEntries.find(entry => {
                return (
                    entry.assigneeId === timeEntry.assigneeId &&
                    entry.start?.eventTimestamp === timeEntry.start?.eventTimestamp &&
                    entry.stop?.eventTimestamp === timeEntry.stop?.eventTimestamp
                );
            });

            if (newEntry) throw new Error('Time entry appears to still exist, please try again.');

            updateUI();
        } catch (error) {
            console.error(error);
            new NotifyUserMessage('error.while-deleting-time-entry');
        }
    };

    const handleEditTimeEntry = async (timeEntry: TimeEntry) => {
        setEditingTimeEntry(timeEntry);
    };

    const handleStartTimer = async (assignee: AccountUser | Team) => {
        try {
            if (assignee.resourceType === 'team') {
                const teamMembers = (assignee as Team).members;
                const allAssignees = (workers as Array<AccountUser>).filter(worker => teamMembers?.find(member => member === worker._id));

                if (!allAssignees.length) throw new Error('No team members found for team, cannot start timer for team.');

                await Promise.all(allAssignees.map(async assignee => await TimerService.startTimer(occurrence, assignee)));
            } else {
                await TimerService.startTimer(occurrence, assignee as AccountUser);
            }
            updateUI();
        } catch (error) {
            console.error(error);
        }
    };

    const handleStopTimer = async (assignee: AccountUser | Team) => {
        try {
            if (assignee.resourceType === 'team') {
                const { reason, cancelled } = await JobOccurrenceService.getTimerPauseReasonIfRequired();
                if (cancelled) return;
                const stopReason = reason;

                const teamMembers = (assignee as Team).members;
                const allAssignees = (workers as Array<AccountUser>).filter(worker => teamMembers?.find(member => member === worker._id));

                if (!allAssignees.length) throw new Error('No team members found for team, cannot start timer for team.');

                await Promise.all(allAssignees.map(async assignee => await TimerService.stopTimer(occurrence, assignee, stopReason)));
            } else {
                await TimerService.stopTimer(occurrence, assignee as AccountUser);
            }
            updateUI();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box display="flex" flexDirection="column" height="100%" sx={{ overflowY: 'auto' }}>
                    <Box sx={{ background: 'var(--themed-main)', color: 'white', p: 2 }}>
                        <Typography>
                            {customer?.name}
                            <br />
                            {occurrence.location?.addressDescription}
                            <br />
                            {moment(occurrence.isoDueDate).format('MMMM Do YYYY')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 2, pb: 0 }}>
                        {!creatingTimeEntry && !editingTimeEntry && (
                            <>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    Assigned Users
                                </Typography>
                                <TableContainer component={Paper} elevation={1} sx={{ borderRadius: 1, overflowX: 'none' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Duration</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignees?.map(assignee => {
                                                let allAssignees = [assignee];
                                                if (assignee.resourceType === 'team') {
                                                    const teamMembers = (assignee as Team).members;
                                                    allAssignees = (
                                                        workers?.filter(
                                                            assignee => assignee.resourceType === 'accountuser'
                                                        ) as Array<AccountUser>
                                                    ).filter(assignee => teamMembers?.find(member => member === assignee._id));
                                                }
                                                const duration = moment
                                                    .utc(
                                                        timers
                                                            ?.filter(timer =>
                                                                allAssignees.find(assignee => timer.assignee?.name === assignee.name)
                                                            )
                                                            .reduce((acc, timer) => {
                                                                return Math.max(acc, moment.duration(timer.duration).asMilliseconds());
                                                            }, 0) || 0
                                                    )
                                                    .format('HH:mm:ss');
                                                return (
                                                    <TableRow
                                                        key={assignee.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {assignee.name}
                                                        </TableCell>
                                                        <TableCell align="right">{duration}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {!assignees?.length && (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>-</TableCell>
                                                    <TableCell align="right">-</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="h6" sx={{ my: 1 }}>
                                    Time Entries
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, pb: 1, overflowX: 'auto' }}>
                                    <Chip
                                        label="All"
                                        sx={{
                                            textTransform: 'unset',
                                            fontWeight: 400,
                                        }}
                                        component={Button}
                                        onClick={() => setSelectedAssignee(undefined)}
                                        icon={<PeopleIcon />}
                                    />
                                    {workersAndTeams
                                        .sort(a => (a.resourceType === 'team' ? -1 : 1))
                                        .map(worker => {
                                            if (!timeEntries[worker._id]?.length) return null;
                                            let allAssignees = [worker];
                                            if (worker.resourceType === 'team') {
                                                const teamMembers = (worker as unknown as Team).members;
                                                allAssignees = (
                                                    workers?.filter(
                                                        assignee => assignee.resourceType === 'accountuser'
                                                    ) as Array<AccountUser>
                                                ).filter(assignee => teamMembers?.find(member => member === assignee._id));
                                            }
                                            const duration = moment
                                                .utc(
                                                    timers
                                                        ?.filter(timer =>
                                                            allAssignees.find(assignee => timer.assignee?.name === assignee.name)
                                                        )
                                                        .reduce((acc, timer) => {
                                                            return Math.max(acc, moment.duration(timer.duration).asMilliseconds());
                                                        }, 0) || 0
                                                )
                                                .format('HH:mm:ss');

                                            const isTimerRunning = timers?.find(timer =>
                                                allAssignees.find(assignee => timer.assignee?.name === assignee.name)
                                            )?.running;

                                            const selected = selectedAssignee?._id === worker._id;
                                            return (
                                                <Chip
                                                    key={worker?.name}
                                                    label={`${worker?.name} - ${duration}`}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            backgroundColor: isTimerRunning ? lighten(theme.palette.success.dark, 0.1) : '',
                                                        },
                                                        'backgroundColor': isTimerRunning ? theme.palette.success.dark : '',
                                                        'boxShadow': selected
                                                            ? `inset 0px 0px 0px 1px ${
                                                                  !isTimerRunning ? theme.palette.divider : theme.palette.success.light
                                                              }`
                                                            : '',
                                                        'textTransform': 'unset',
                                                        'fontWeight': 400,
                                                    }}
                                                    component={ButtonBase}
                                                    onClick={() => setSelectedAssignee(worker)}
                                                    deleteIcon={!isTimerRunning ? <PlayArrowIcon /> : <StopIcon />}
                                                    onDelete={e => {
                                                        e.stopPropagation();
                                                        if (isTimerRunning) {
                                                            handleStopTimer(worker);
                                                        } else {
                                                            handleStartTimer(worker);
                                                        }
                                                        updateUI();
                                                    }}
                                                />
                                            );
                                        })}
                                </Box>
                                <TableContainer
                                    component={Paper}
                                    elevation={1}
                                    sx={{
                                        borderRadius: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Assignee</TableCell>
                                                <TableCell>Start Time</TableCell>
                                                <TableCell>End Time</TableCell>
                                                <TableCell align="right">Duration</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {visibleTimeEntries.map(timeEntry => {
                                                if (!timeEntry.start || !timeEntry.stop) return null;
                                                const duration = !timeEntry.start
                                                    ? 0
                                                    : !timeEntry.stop
                                                    ? moment().valueOf() - timeEntry.start.eventTimestamp
                                                    : timeEntry.stop.eventTimestamp - timeEntry.start.eventTimestamp;
                                                const humanizedDuration = moment.duration(duration).humanize();

                                                if (timeEntry.type === 'running') return null;
                                                const assignee = workers?.find(assignee => assignee._id === timeEntry.assigneeId);
                                                return (
                                                    <TableRow
                                                        key={timeEntry.start?._id || timeEntry.stop?._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {assignee?.name}
                                                        </TableCell>
                                                        <TableCell>{moment(timeEntry.start?.eventTimestamp).format('HH:mm')}</TableCell>
                                                        <TableCell>{moment(timeEntry.stop?.eventTimestamp).format('HH:mm')}</TableCell>
                                                        <TableCell align="right">{humanizedDuration}</TableCell>
                                                        <TableCell align="right">
                                                            <Box sx={{ display: 'flex', justifyContent: 'right', gap: 1 }}>
                                                                <GradientButton
                                                                    variant="text"
                                                                    size="small"
                                                                    onClick={() => handleEditTimeEntry(timeEntry)}
                                                                >
                                                                    Edit
                                                                </GradientButton>
                                                                <IconButton
                                                                    size="small"
                                                                    color="error"
                                                                    onClick={() => handleDeleteTimeEntry(timeEntry)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {!visibleTimeEntries.length && (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>-</TableCell>
                                                    <TableCell>-</TableCell>
                                                    <TableCell align="right">-</TableCell>
                                                    <TableCell align="right">-</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                        {(creatingTimeEntry || editingTimeEntry) && (
                            <CreateTimeEntry
                                occurrence={occurrence}
                                setCreatingTimeEntry={setCreatingTimeEntry}
                                setEditingTimeEntry={setEditingTimeEntry}
                                timeEntryToEdit={editingTimeEntry}
                                workersAndTeams={workersAndTeams}
                                setTimeEntries={setTimeEntries}
                            />
                        )}
                    </Box>
                </Box>
                <ExpandableFab
                    actions={[
                        {
                            icon: <MoreTimeIcon />,
                            name: 'Add Time Entry',
                            color: '#66B988',
                            onClick: () => {
                                setCreatingTimeEntry(true);
                            },
                        },
                    ]}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default ManageStoredEventsDialogComponent;
