import type { Customer, JobOccurrence, Location, Service, StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus, copyObject } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { CustomerDialog } from '../Customers/Components/CustomerDialog';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { JobDeletedMessage, JobSavedMessage } from '../Jobs/JobMessage';
import { JobService } from '../Jobs/JobService';
import type { JobSummary } from '../Jobs/JobSummary';
import { LocationUtilities } from '../Location/LocationUtilities';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { ScheduleService } from '../Schedule/ScheduleService';
import { getNumber } from '../Settings/getNumber';
import type { TagData } from '../Tags/TagData';
import { ServicesService } from './ServicesService';

export class ServiceDataDialog extends CustomDialog<boolean> {
    private static _cssClasses = 'service-data-dialog details-dialog no-nav-shadow';
    protected menuTitle = 'Service data';
    protected moreActions: Array<IMenuBarAction>;
    protected jobLocations: Array<Location> = [];
    protected jobSummaries: Array<JobSummary> = [];
    protected totalJobsDue = 0;
    protected totalJobsOverDue = 0;
    protected appointmentsWithOverDueJobs = 0;
    protected appointmentsWithDueJobs = 0;
    private _jobChangedEventSub: Subscription;
    private _jobDeletedEventSub: Subscription;
    private _serviceUpdated: Subscription;
    protected requiresTaggingStatus: TranslationKey;
    protected serviceData: TagData;

    public loading = true;


    constructor(private serviceId: string) {
        super(serviceId, '../Services/ServiceDataDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: ServiceDataDialog._cssClasses,
            isSecondaryView: true,
        });



        this._jobChangedEventSub = JobSavedMessage.subscribe(() => {
            return this.cancel(true);
        });
        this._jobDeletedEventSub = JobDeletedMessage.subscribe(() => {
            return this.cancel(true);
        });

        this._serviceUpdated = DataRefreshedEvent.subscribe((result: DataRefreshedEvent) => {
            if (result.updatedObjects[serviceId])
                this.init();
        })

    }

    public async init() {
        this.serviceData = ServicesService.getServiceData(this.serviceId) as TagData;

        this.jobLocations = [];

        if (!this.serviceData) this.cancel();

        for (let i = 0; i < this.serviceData.jobs.length; i++) {

            const job = this.serviceData.jobs[i];

            if (job.location && job.location.isVerified) {

                this.jobLocations.push(job.location);
            }

        }

        this.moreActions = this.getServiceDataMoreActions();

        if (this.serviceData.tag.description) {

            this.menuTitle = this.serviceData?.tag.description;
        }

        //TODO WHAT!!

        this.refreshTaggingStatus();
        this._extractJobs();

    }

    public dispose() {
        this._jobChangedEventSub?.dispose();
        this._jobDeletedEventSub?.dispose();
        this._serviceUpdated?.dispose();
        super.dispose();
    }

    private async _extractJobs() {
        this.jobSummaries = await JobService.getJobSummariesFromJobs(this.serviceData.jobs);
        this._createDateIndicatorsForJobSummaries();
        this._sortJobSummaries();
        this.loading = false;
    }

    protected async viewCustomer(jobSummary: JobSummary) {
        const dialog = new CustomerDialog(jobSummary.customer);
        await dialog.show(DialogAnimation.SLIDE);
    }

    private getServiceDataMoreActions(): Array<IMenuBarAction> {
        const menu: Array<IMenuBarAction> = [
            {
                tooltip: 'menubar.rename',
                actionType: 'action-edit',
                handler: this.renameService,
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'menubar.set-service-default-price',
                actionType: 'action-edit',
                handler: this.editDefaultPrice,
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'menubar.set-service-default-quantity',
                actionType: 'action-edit',
                handler: this.editDefaultQuantity,
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'menubar.set-service-description',
                actionType: 'action-edit',
                handler: this.editServiceLongDescriptionAndUpdateOccurrences,
                roles: ['Owner', 'Admin'],
            }];
        if (ApplicationState.features.ServiceTagging) {
            menu.push({
                tooltip: 'menubar.set-service-tagging',
                actionType: 'action-edit',
                handler: this.setTaggingRequirements,
                roles: ['Owner', 'Admin'],
            },
            );
        }

        menu.push({
            tooltip: 'menubar.delete',
            actionType: 'action-delete',
            handler: this.deleteService,
            roles: ['Owner', 'Admin'],
        });

        return menu;
    }

    private deleteService = async () => {
        let message: TranslationKey;
        const length = this.serviceData.jobs.length;
        if (length === 0) message = 'dialogs.services-delete-message-none';
        else if (length === 1) message = 'dialogs.services-delete-message-one';
        else message = 'dialogs.services-delete-message-many';

        const prompt = new Prompt('prompts.confirm-title', message, {
            okLabel: 'general.delete',
            localisationParams: length > 1 ? { count: this.serviceData.jobs.length.toString() } : undefined,
        });
        await prompt.show();

        if (!prompt.cancelled) {
            const failedToRemoveService = await ServicesService.deleteService(this.serviceData.tag._id);
            if (failedToRemoveService?.length)
                await Prompt.info(
                    ApplicationState.localise('services.failed-to-remove', { services: failedToRemoveService.join(' / ') }),
                    ApplicationState.localise('general.unable-to-delete')
                );
            else this.ok(true);
        }
    };

    private renameService = async () => {
        const dialog = new TextDialog(
            'dialogs.services-rename-title',
            'dialogs.services-rename-label',
            this.serviceData.tag.description,
            'dialogs.services-rename-label'
        );
        const newDescription = await dialog.show(DialogAnimation.SLIDE_UP);

        if (!dialog.cancelled) {
            const oldDescription = this.serviceData.tag.description;
            this.serviceData.tag.description = newDescription;
            await ServicesService.renameService(oldDescription, newDescription, this.serviceData.tag);
            this.ok(true);
        }
    };

    private editDefaultPrice = async () => {
        const currentPrice = (this.serviceData.tag as Service).price || 0;
        const price = await getNumber({ title: 'field-name.price', value: currentPrice, integer: false, prefix: ApplicationState.currencySymbol() });
        if (price === undefined) return;

        (this.serviceData.tag as Service).price = price;
        await Data.put(this.serviceData.tag);
    };

    private editDefaultQuantity = async () => {
        const currentQuantity = (this.serviceData.tag as Service).quantity || 0;
        const quantity = await getNumber({ title: 'general.quantity', value: currentQuantity, integer: true });

        if (quantity === undefined) return;

        (this.serviceData.tag as Service).quantity = quantity;
        await Data.put(this.serviceData.tag);
    };

    private editServiceLongDescriptionAndUpdateOccurrences = async () => {
        const tag = copyObject(this.serviceData.tag);

        const descriptionDialog = new TextDialog(
            'dialogs.services-description-title',
            tag.description as TranslationKey,
            tag.longDescription || "",
            "dialogs.services-description-label",
            undefined,
            false,
            'textarea'
        );

        const result = await descriptionDialog.show();
        if (descriptionDialog.cancelled) return;

        tag.longDescription = result;
        const updates = new Array<StoredObject>();
        updates.push(tag);


        for (const occurrence of Data.all<JobOccurrence>('joboccurrences', { status: JobOccurrenceStatus.NotDone })) {
            let updated = false;
            for (const service of occurrence.services || []) {
                if (service._id !== tag._id) continue;

                service.longDescription = descriptionDialog.value;
                updated = true;

            }
            if (updated) updates.push(occurrence);
        }

        for (const c of Data.all<Customer>('customers')) {
            let updated = false;
            for (const job of Object.values(c.jobs || {})) {
                for (const s of job.services) {
                    if (s._id !== tag._id) continue;

                    s.longDescription = descriptionDialog.value;
                    updated = true;
                }
            }
            if (updated) updates.push(c);
        }

        await Data.put(updates);
    }

    private tagOptions = [
        { value: undefined, text: ApplicationState.localise('services.tagging-requirements-none') },
        { value: 'service', text: ApplicationState.localise('services.tagging-requirements-service') },
        { value: 'instance', text: ApplicationState.localise('services.tagging-requirements-instance') },
    ]

    private setTaggingRequirements = async () => {

        const taggingSelect = new Select('services.tagging-requirements-title', this.tagOptions, 'text', 'value', (this.serviceData.tag as Service).requiresTagging);
        await taggingSelect.show();
        if (taggingSelect.cancelled) return;

        (this.serviceData.tag as Service).requiresTagging = taggingSelect.selectedValue;

        const updates = new Array<StoredObject>();
        updates.push(this.serviceData.tag);

        for (const o of Data.all<JobOccurrence>('joboccurrences', { status: JobOccurrenceStatus.NotDone })) {
            let updated = false;
            for (const s of o.services || []) {
                if (s._id !== this.serviceData.tag._id) continue;

                s.requiresTagging = taggingSelect.selectedValue;
                updated = true;
            }
            if (updated) updates.push(o);
        }

        for (const c of Data.all<Customer>('customers')) {
            let updated = false;
            for (const job of Object.values(c.jobs || {})) {
                for (const s of job.services) {
                    if (s._id !== this.serviceData.tag._id) continue;

                    s.requiresTagging = taggingSelect.selectedValue;
                    updated = true;
                }
            }
            if (updated) updates.push(c);
        }

        await Data.put(updates);
        this.refreshTaggingStatus();
    }

    private _sortJobSummaries() {
        const jobSummariesWithDistance = this.jobSummaries.filter(jobSummary => {
            return jobSummary.distance;
        });
        jobSummariesWithDistance.sort(ServiceDataDialog.sortJobSummariesOnDistance);
        const jobSummariesWithOutDistance = this.jobSummaries.filter(jobSummary => {
            return !jobSummary.distance;
        });
        jobSummariesWithOutDistance.sort(ServiceDataDialog._sortJobSummariesOnLocationDescription);

        const summaries: Array<JobSummary> = [];
        Array.prototype.push.apply(summaries, jobSummariesWithDistance);
        Array.prototype.push.apply(summaries, jobSummariesWithOutDistance);

        this.jobSummaries = summaries;
    }

    private static sortJobSummariesOnDistance(a: JobSummary, b: JobSummary) {
        if (a.distance && b.distance) {
            if (a.distance - b.distance === 0) {
                return ServiceDataDialog._sortJobSummariesOnLocationDescription(a, b);
            } else {
                return a.distance - b.distance;
            }
        } else if (a.distance) {
            return -1;
        } else if (b.distance) {
            return 1;
        } else {
            return 1;
        }
    }

    private static _sortJobSummariesOnLocationDescription(a: JobSummary, b: JobSummary): number {
        if (a.job.location && a.job.location.addressDescription && b.job.location && b.job.location.addressDescription) {
            const aDescription = a.job.location.addressDescription;
            const bDescription = b.job.location.addressDescription;
            return LocationUtilities.sortAddressDescription(aDescription, bDescription);
        } else if (a.job.location && a.job.location.addressDescription) {
            return -1;
        } else {
            return 1;
        }
    }

    private async _createDateIndicatorsForJobSummaries() {
        this.totalJobsDue = 0;
        this.totalJobsOverDue = 0;
        this.appointmentsWithDueJobs = 0;
        this.appointmentsWithOverDueJobs = 0;
        const scheduleItems = await ScheduleService.getScheduleForJobs(this.jobSummaries.map(x => x.job));

        for (let i = 0; i < this.jobSummaries.length; i++) {
            const jobSummary = this.jobSummaries[i];
            let jobsDue = 0;
            let jobsOverDue = 0;

            for (let i = 0; i < scheduleItems.length; i++) {
                const scheduleItem = scheduleItems[i];
                if (scheduleItem.job._id === jobSummary.job._id) {
                    switch (scheduleItem.scheduleStatus) {
                        case 'due':
                            jobsDue += 1;
                            break;
                        case 'overdue':
                            jobsOverDue += 1;
                            break;
                    }
                }
            }
            if (jobsDue) {
                jobSummary.indicators.push({ description: 'DUE TODAY' + (jobsDue > 1 ? '(' + jobsDue + ')' : ''), state: 'current' });
                this.appointmentsWithDueJobs += 1;
                this.totalJobsDue += jobsDue;
            }
            if (jobsOverDue) {
                jobSummary.indicators.push({ description: 'OVERDUE' + (jobsOverDue > 1 ? '(' + jobsOverDue + ')' : ''), state: 'overdue' });
                this.appointmentsWithOverDueJobs += 1;
                this.totalJobsOverDue += jobsOverDue;
            }
        }
    }

    private refreshTaggingStatus = () => {
        if (!ApplicationState.features.ServiceTagging) return;
        this.requiresTaggingStatus = this.tagOptions.find(x => x.value === (this.serviceData.tag as Service).requiresTagging)?.text || ApplicationState.localise('services.tagging-requirements-none');
    }

}
