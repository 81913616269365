import type { Service, Tag, TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, TagType } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { TextDialog } from '../Dialogs/TextDialog';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { FabWithActions } from '../FabWithActions';
import { JobDeletedMessage, JobSavedMessage } from '../Jobs/JobMessage';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { TagData } from '../Tags/TagData';
import { TagService } from '../Tags/TagService';
import { isDevMode } from '../isDevMode';
import { ServiceDataDialog } from './ServiceDataDialog';
import { ServicesService } from './ServicesService';

export class Services {
    protected servicesTagList: Array<Tag> = [];
    protected servicesData: Array<TagData> = [];
    protected servicesDataFiltered: Array<TagData> = [];
    private _jobChangedEventSub: Subscription;
    private _jobDeletedEventSub: Subscription;

    protected fabWithActions: FabWithActions;
    @bindable public searchText = '';
    public searchTextChanged(): void {
        this.filter();
    }

    private static _colours = new AlphanumericCharColourDictionary();

    public async loadServices() {
        Logger.info('Dev mode: ' + isDevMode());
        const services = isDevMode()
            ? Data.all<Service>('tags', { type: TagType.SERVICE })
            : Data.all<Service>('tags', service => service.showOnDirectory !== true, { type: TagType.SERVICE });
        this.servicesTagList = services.slice();
        this.servicesData = ServicesService.getServicesData(this.servicesTagList);
        this.filter();
    }

    protected filter() {
        this.servicesDataFiltered = this.servicesData
            .filter((serviceData: TagData) => {
                return (
                    this.searchText.length === 0 || serviceData.tag.description.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
                );
            })
            .sort((serviceDataA, serviceDataB) => {
                return serviceDataA.tag.description < serviceDataB.tag.description
                    ? -1
                    : serviceDataA.tag.description > serviceDataB.tag.description
                        ? 1
                        : 0;
            });
    }

    protected avatarText(serviceData: TagData) {
        if (serviceData && serviceData.tag && serviceData.tag.description && serviceData.tag.description.length)
            return serviceData.tag.description.substring(0, 2).toUpperCase();
        return '';
    }

    protected getAvatarColorForServiceData(serviceData: TagData) {
        if (serviceData && serviceData.tag && serviceData.tag.description && serviceData.tag.description.length)
            return Services._colours[serviceData.tag.description.substring(0, 1).toUpperCase()];
        return '';
    }
    public async attached() {
        new ViewResizeEvent();

        await this.loadServices();

        FabWithActions.register(this.getFabActions());

        this._jobChangedEventSub = JobSavedMessage.subscribe(() => {
            return this.loadServices();
        });
        this._jobDeletedEventSub = JobDeletedMessage.subscribe(() => {
            return this.loadServices();
        });
    }

    private _delegateAddService = () => this.addService();
    private getFabActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'actions.create-service',
                actionType: 'action-new-service',
                handler: this._delegateAddService,
                roles: ['Owner', 'Admin'],
            },
        ];
        return fabActions;
    }

    public detached() {
        this._jobChangedEventSub?.dispose();
        this._jobDeletedEventSub?.dispose();
        FabWithActions.unregister();
    }

    protected async addService() {
        const dialog = new TextDialog(
            'dialogs.services-add-title',
            'dialogs.add-service-title',
            '',
            'dialogs.add-service-placeholder',
            this.serviceNameValid,
            false
        );
        const name = await dialog.show(DialogAnimation.SLIDE_UP);
        if (name) {
            try {
                await TagService.addTag(name.trim(), TagType.SERVICE);
                this.loadServices();
            } catch (error) {
                Logger.error('Error in addService() on Services', { name, error });
                new NotifyUserMessage('services.error-adding-service');
            }
        }
    }

    private serviceNameValid: (serviceName: string) => true | TranslationKey = (serviceName: string) => {
        if (!serviceName || !serviceName.trim()) return 'services.enter-service';

        const value = serviceName.trim();

        const duplicate = this.servicesTagList.some(tag => tag.description.toLowerCase() === value.toLowerCase());
        if (duplicate) {
            return 'services.service-already-exists';
        }
        return true;
    };

    protected async viewServiceData(serviceData: TagData) {
        try {
            const dialog = new ServiceDataDialog(serviceData.tag._id);
            const result = await dialog.show(DialogAnimation.SCALE);
            if (result) this.loadServices();
        } catch (error) {
            Logger.error(`Error during viewServiceData in Services`, { serviceData, error });
        }
    }
}
