import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';
import type { DayOrderData } from '../DayPilot/Components/DayOrderData';
import type { IOccurrenceOrderDataItem } from '../DayPilot/Components/IOccurrenceOrderData';
import type { RouteScheduleItem } from '../DayPilot/Components/RouteScheduleItem';

export class DayOrderDataService {
    public static HOME_LEG_KEY = 'homeLeg';

    public static async getDayOrderData(day: string, user?: string) {
        return Data.firstOrDefault<DayOrderData>(
            'occurrencesbyday',
            dayOrderData => dayOrderData.day === day && dayOrderData.user === user
        );
    }

    public static async saveDayOrderData(dayOrderData: DayOrderData) {
        dayOrderData.jobsRescheduledSinceOptimisation = true;
        await Data.put<DayOrderData>(dayOrderData, true, 'lazy');
    }

    public static async saveRouteItemsDayOrderData(routeItems: Array<RouteScheduleItem>, dayOrderData: DayOrderData) {
        const before = JSON.stringify(dayOrderData);
        for (const routeItem of routeItems) {
            const orderDataKey = routeItem.jobId ? routeItem.jobId : routeItem.id;

            dayOrderData.occurrenceOrderData[orderDataKey] = routeItem.routeOrderData;
        }
        const after = JSON.stringify(dayOrderData);

        if (before !== after) await this.saveDayOrderData(dayOrderData);
    }

    public static updateOrderDataRankByField(
        routeScheduleItems: Array<RouteScheduleItem>,
        rankField: keyof IOccurrenceOrderDataItem,
        orderRank = 0
    ) {
        const notDoneItemsSortedByOrder = DayOrderDataService.getScheduleItemsByStatusSortedByOrderField(
            routeScheduleItems,
            JobOccurrenceStatus.NotDone,
            rankField
        );
        if (notDoneItemsSortedByOrder && notDoneItemsSortedByOrder.length) {
            DayOrderDataService.updateOrderDataRank(notDoneItemsSortedByOrder, orderRank);
        }

        // TODO: Clean out array
    }

    /**
     * using a starting rank and an array of ordered schedule items, updates the dictionary of the job id for the schedule and applies the rank
     * before incrementing.  used to renumber the dictionary items
     *
     * @private
     * @param {Array<ScheduleItem>} scheduleItems
     * @param {number} startingRank
     * @memberof NotDone
     */
    private static updateOrderDataRank(scheduleItems: Array<RouteScheduleItem>, startingRank: number): number {
        if (scheduleItems && scheduleItems.length) {
            for (let i = 0; i < scheduleItems.length; i++) {
                const scheduleItem = scheduleItems[i];
                startingRank += 1;
                scheduleItem.rank = startingRank;
            }
        }

        return startingRank;
    }

    /**
     * returns an array of done items ordered by the date they appeared in the dictionary
     * used when renumbering the occurrence order data dictionary
     *
     * @private
     * @returns {(Array<ScheduleItem> | undefined)}
     * @memberof NotDone
     */
    public static getScheduleItemsByStatusSortedByOrderField(
        routeScheduleItems: Array<RouteScheduleItem>,
        status: JobOccurrenceStatus,
        orderDataField: keyof IOccurrenceOrderDataItem
    ) {
        const items = routeScheduleItems.filter(rsi => rsi.scheduleItem && rsi.scheduleItem.occurrence.status === status);
        return this.sortScheduleItemsByOrderDataField(items, orderDataField);
    }

    //RANK = occurrenceDayOrder

    private static sortScheduleItemsByOrderDataField(
        scheduleItems: Array<RouteScheduleItem>,
        orderDataField: keyof IOccurrenceOrderDataItem
    ) {
        return scheduleItems.sort((doneItemA, doneItemB) => {
            const rankA: any = doneItemA.routeOrderData[orderDataField] ? doneItemA.routeOrderData[orderDataField] : 999;
            const rankB: any = doneItemA.routeOrderData[orderDataField] ? doneItemB.routeOrderData[orderDataField] : 999;
            return rankA - rankB;
        });
    }
}
