import { formatTimestampDurationAsTime, JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Utilities } from '../../Utilities';
import type { ScheduleItem } from './ScheduleItem';

export class ScheduleItemsSummaryCustomElement {
    @bindable items: ScheduleItem[];
    @bindable itemDesc = '';

    @bindable showPrice = false;
    totalCount = '';
    totalTime = '';
    totalEstimatedTime = '';
    totalActualTime = '';
    totalPrice = '';
    totalTravelDistance = '';

    summary = '';
    itemsChanged() {
        if (!this._attached) return;
        this.update();
    }
    private _attached = false;
    attached() {
        this.update();
        this._attached = true;
    }
    private update() {
        let totalPrice = 0;
        let totalEstimatedTime = 0;
        const totalActualTime = 0;
        let totalTime = 0;
        let totalTravelDistance = 0;

        this.totalCount = this.items?.length ? this.items.length.toString() : '';

        let canSeePricing = true;

        for (const scheduleItem of this.items) {
            if (scheduleItem.status === JobOccurrenceStatus.Skipped) continue; // remove skipped items from the summary
            if (this.showPrice) {
                if (scheduleItem.price === undefined) canSeePricing = false;
                else totalPrice += Number(scheduleItem.occurrence.price) || 0;
            }
            const estimatedTime = Utilities.durationToMinutes(scheduleItem.occurrence.duration);
            totalEstimatedTime += estimatedTime;

            const hasActualDuration = scheduleItem.actualDuration !== false && scheduleItem.actualDuration !== undefined;
            totalTime += hasActualDuration ? scheduleItem.actualDuration / 1000 / 60 : estimatedTime;

            scheduleItem.refreshDistanceFromMe();
            scheduleItem.refreshDistance();
            totalTravelDistance += scheduleItem.distance || 0;
        }

        if (canSeePricing && totalPrice > 0) {
            const curSym = ApplicationState.currencySymbol();
            this.totalPrice = `${curSym}${totalPrice.toFixed(2)}`;
        } else this.totalPrice = '';

        if (!totalEstimatedTime) this.totalTime = '';
        else {
            const hours = Math.floor(totalEstimatedTime / 60);
            const minutes = totalEstimatedTime % 60;
            this.totalTime = hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }

        if (!totalActualTime) this.totalActualTime = '';
        else this.totalActualTime = `(${formatTimestampDurationAsTime(totalActualTime)})`;

        if (!totalTime) this.totalTime = '';
        else {
            const hours = Math.floor(totalTime / 60);
            const minutes = Math.floor(totalTime % 60);
            this.totalTime = hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }

        if (!totalTravelDistance) this.totalTravelDistance = '';
        else {
            const distUnit = ApplicationState.distanceUnitsAbbreviated;
            this.totalTravelDistance = `${totalTravelDistance.toFixed(1)}${distUnit}`;
        }

        this.summary = [
            this.totalCount + (this.itemDesc ? ' ' + this.itemDesc : ''),
            this.totalTime,
            this.totalPrice,
            this.totalTravelDistance,
        ]
            .filter(x => x)
            .join(' | ');
    }
}
