import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { ScheduleItem } from '../Components/ScheduleItem';
import { ScheduleService } from '../ScheduleService';
import type { IScheduleItemAction } from './IScheduleItemAction';

export class Done implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {}
    public actionType = <ItemActionType>'action-done';
    private _handlerDebounce: any;
    public async handler() {
        clearTimeout(this._handlerDebounce);
        this._handlerDebounce = setTimeout(async () => {
            if (!(await ScheduleService.completeScheduleItems([this.scheduleItem])))
                //WTF? Dont remove this, it causes the list to reload a cancelled item because we might of deleteted the element. Got it? THAT MEANS YOU Jack...
                DataRefreshedEvent.emit({ [this.scheduleItem.occurrence._id]: this.scheduleItem.occurrence }, false);
        }, 500);
    }
    public tooltip: TranslationKey = 'actions.done';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker'];
}
