import { SyncMode } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { ClientArchiveService } from '../Customers/ClientArchiveService';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { ActionableEvent } from '../Notifications/ActionableEvent';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { Stopwatch } from '../Stopwatch';
import { ThemeService } from '../Theme/ThemeService';
import './Startup.scss';

export class Startup {
    protected firstEverSync: boolean;
    protected api = Api;
    private async start(): Promise<boolean> {
        console.log('Starting Squeegee');
        let tries = 3;
        const stopwatch = new Stopwatch('Startup first load');
        while (tries--) {
            try {
                await Api.init();
                await Data.init();
                await this.waitForSync();

                stopwatch.lap('Application state Data Init');

                ApplicationState.initialiseDeviceInfo();

                if (!ApplicationState.hasCachedSubscription) await ApplicationState.updateSubscription();
                else ApplicationState.updateSubscription();

                stopwatch.lap('Subscription update in progress');

                await ApplicationState.init();
                stopwatch.lap('ApplicationState Init');
                ClientArchiveService.autoArchive();

                new LoaderEvent(false);
                stopwatch.stop();

                return true;
            } catch (error) {
                Logger.info('Error in start() on Startup', { tries, error });
                stopwatch.lap(`Startup attempt ${3 - tries}`);
                if (tries === 0 || (stopwatch.duration() || 0) > 60000) {
                    new LoaderEvent(false);
                    Logger.error('Squeegee startup failed after three tries', error);

                    // Removing the session, just in case it's borked.
                    SqueegeeLocalStorage.removeItem('squeegee-session-cookie');
                    const subject = encodeURIComponent(
                        'Error during sign in for ' + ((RethinkDbAuthClient.session && RethinkDbAuthClient.session.email) || 'unknown user')
                    );
                    let body = 'Hi Support, \n\nThere was an error while Squeegee was starting up.\n\nKind regards,\n\nSqueegee User\n\n\n';

                    let errorDetails = '';
                    if (typeof error === 'string') errorDetails = error;
                    else if (error && error.message && typeof error.message === 'string') errorDetails = error.message;

                    body += errorDetails;
                    body = encodeURIComponent(body);

                    new ActionableEvent(
                        ApplicationState.localise('sign-in.failed', { error: errorDetails || '' }),
                        'sign-in.send-error-report',
                        `mailto:support@squeeg.ee?subject=${subject}&body=${body}`,
                        30000
                    );
                }
            }
        }
        return false;
    }

    private async waitForSync() {
        const key = `${ApplicationState.dataEmail}_HAS_FULLY_SYNCED_BEFORE`;
        const hasFullySyncedBefore = SqueegeeLocalStorage.getItem(key);
        // If they have synced before then allow them to continue
        if (hasFullySyncedBefore) {
            Logger.info('This user has fully synced before skipping waiting for a full sync');
            return;
        }
        Logger.info('------- This user never fully synced before waiting for a full sync --------');
        this.firstEverSync = true;
        const successBaby = await Data.fullSync(SyncMode.Full, 'none');
        if (successBaby) SqueegeeLocalStorage.setItem(key, 'true');
    }

    private loaderEventSub: Subscription;
    protected progress?: number;

    @computedFrom('progress')
    get progressAmount() {
        return this.progress ? (this.progress / 100).toFixed(2) : '0';
    }
    async attached() {
        this.loaderEventSub = BGLoaderEvent.subscribe((loaderEvent: BGLoaderEvent) => {
            if (loaderEvent.progressPercent !== 'unknown') this.progress = loaderEvent.progressPercent;
            if (loaderEvent.message) this.message = loaderEvent.message;
        });

        const startedSuccessfully = await this.start();

        ThemeService.init();

        if (startedSuccessfully) {
            ApplicationState.setDefaultSettings();

            await ApplicationState.setRootSqueegee();
        } else {
            Logger.error('Startup failed falling back to sign in screen and clearing local data.');
            await Data.clearLocalStorage();
            await ApplicationState.setRootLaunch();
        }
    }

    detached() {
        if (this.loaderEventSub) this.loaderEventSub.dispose();
    }

    protected message?: string;
    @computedFrom('firstEverSync', 'message')
    get loadingMsg() {
        if (this.message) return this.message;
        if (this.firstEverSync) return ApplicationState.localise('startup.downloading-data');
        else return ApplicationState.localise('startup.loading');
    }

    @computedFrom('api.connectionData')
    get status() {
        if (Api.connectionData === 'Offline') return 'Offline';
        return Api.connectionData.slice(0, Api.connectionData.indexOf('with'));
    }

    public async signOut() {
        await RethinkDbAuthClient.signOut(RethinkDbAuthClient.session);
        await ApplicationState.setRootLaunch();
    }
}
