import type { Transaction, TransactionStatus } from '@nexdynamic/squeegee-common';
import { Logger } from '../Logger';
import { Api } from './Api';

export class PaymentsApi {
    public static async checkAndUpdatePaymentStatus(transaction: Transaction) {
        if (transaction.paymentDetails && transaction.paymentDetails.paymentId) {
            try {
                await PaymentsApi.checkCustomerPayment(transaction._id);
            } catch (error) {
                Logger.info('Unable to check the payment status for this payment', { payment: transaction, error });
            }
        }
    }

    public static async checkCustomerPayment(paymentTransactionId: string): Promise<TransactionStatus> {
        const url = '/api/refresh-payment-status';

        const response = await Api.post<TransactionStatus>(Api.apiEndpoint, url, { paymentTransactionId }, false, false);

        if (response && response.data) {
            return response.data;
        } else {
            throw new Error(`Could not get payment status`);
        }
    }

    public static async checkCustomerPaymentStatuses({
        goCardlessTransactions,
        stripeTransactions,
    }: {
        goCardlessTransactions: string[];
        stripeTransactions: string[];
    }): Promise<TransactionStatus> {
        const url = '/api/refresh-payment-statuses';

        const response = await Api.post<TransactionStatus>(
            Api.apiEndpoint,
            url,
            { goCardlessTransactions, stripeTransactions },
            false,
            false
        );

        if (response && response.data) {
            return response.data;
        } else {
            throw new Error(`Could not get payment status`);
        }
    }

    public static async retryCustomerPayment(transaction: Transaction): Promise<{ success: boolean; error?: string }> {
        if (
            !transaction.paymentDetails ||
            !transaction.paymentDetails.paymentId ||
            transaction.paymentDetails.paymentProvider !== 'gocardless'
        )
            throw 'Retry customer payment failed: Invalid paymentDetails';

        const url = `/api/payments/gocardless/retry`;
        const paymentId = transaction.paymentDetails.paymentId;
        const transactionId = transaction._id;

        const response = await Api.put<{ success: boolean; error?: string }>(
            Api.apiEndpoint,
            url,
            { paymentId, transactionId },
            false,
            false
        );

        if (!response) throw 'Retry customer payment failed: No response';

        return response.data;
    }

    public static async cancelCustomerPayment(transaction: Transaction): Promise<{ success: boolean; error?: string }> {
        if (
            !transaction.paymentDetails ||
            !transaction.paymentDetails.paymentId ||
            transaction.paymentDetails.paymentProvider !== 'gocardless'
        )
            throw 'Cancel customer payment failed: Invalid paymentDetails';

        const url = `/api/payments/gocardless/cancel`;
        const paymentId = transaction.paymentDetails.paymentId;
        const transactionId = transaction._id;

        const response = await Api.put<{ success: boolean; error?: string }>(
            Api.apiEndpoint,
            url,
            { paymentId, transactionId },
            false,
            true
        );

        if (!response || !response.data) throw 'Cancel customer payment failed, no response from the server.';

        if (!response.data.success) throw response.data.error || 'Unknown failure while cancelling the automatic payment.';

        return response.data;
    }
}
