import type { JobOccurrence, JobScheduleCache } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus, debounce } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { JobService } from '../Jobs/JobService';
import { ScheduleService } from './ScheduleService';

export const updateProjectedJobScheduleResultCache = debounce(async () => {
    const schedule = await ScheduleService.getScheduleForJobs(
        JobService.getJobs(),
        moment(),
        {
            text: '',
            start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        },
        [JobOccurrenceStatus.NotDone]
    );

    const jobScheduleCache = ApplicationState.getSetting<JobScheduleCache>('global.projected-job-schedule-cache', {});
    let updated = false;

    const existingJobs = new Set<string>();
    const existingOccurrences = new Set<string>();
    // Add any job schedules that are not already in the cache and up to date.
    for (const scheduleItem of schedule) {
        existingJobs.add(scheduleItem.job._id);
        if (Data.get<JobOccurrence>(scheduleItem.occurrence._id)) continue;

        existingOccurrences.add(scheduleItem.occurrence._id);
        let jobSchedule = jobScheduleCache[scheduleItem.job._id];
        if (!jobSchedule) jobSchedule = jobScheduleCache[scheduleItem.job._id] = {};
        const date = jobSchedule[scheduleItem.occurrence._id];
        if (date !== scheduleItem.date) {
            jobSchedule[scheduleItem.occurrence._id] = scheduleItem.date;
            updated = true;
        }
    }

    // Remove any job schedules from the cache that are no longer in the schedule.
    for (const jobId in jobScheduleCache) {
        if (!existingJobs.has(jobId)) {
            delete jobScheduleCache[jobId];
            updated = true;
        } else {
            const jobSchedule = jobScheduleCache[jobId];
            if (!jobSchedule) continue;

            for (const occurrenceId in jobSchedule) {
                if (!existingOccurrences.has(occurrenceId)) {
                    delete jobSchedule[occurrenceId];
                    updated = true;
                }
            }
            if (Object.keys(jobSchedule).length === 0) {
                delete jobScheduleCache[jobId];
                updated = true;
            }
        }
    }

    if (updated) await ApplicationState.setSetting('global.projected-job-schedule-cache', jobScheduleCache);
}, 1000);

(window as any).updateProjectedJobScheduleResultCache = updateProjectedJobScheduleResultCache;
