import type { Transaction } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';

export class TransactionSummaryCustomElement {
    @bindable() transaction: Transaction;

    protected get paymentDescription() {
        return TransactionUtils.getSubTypeTitle(this.transaction.transactionType, this.transaction.transactionSubType);
    }
}
