import { Words } from './Words';

declare global {
    interface Array<T> {
        random(): T;
    }
}

Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
};

export class Generator {
    public static getRandomName(): string {
        const forename = Words.getWeightedRandomForename();
        const surname = Words.surnames.random();
        return forename + ' ' + surname;
    }

    public static getRandomAddress(): string {
        return (
            Math.floor(Math.random() * 100) +
            ' ' +
            Words.streetNames.random() +
            ' ' +
            Words.streetTypes.random() +
            ', ' +
            Words.towns.random()
        );
    }
}
