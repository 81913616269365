export class ScheduleItemMessageModel {
    [name: string]: string;
    addressDescription = '';
    dateFromNow = '';
    roundName = '';
    assignedTo = '';
    assignedToFirstName = '';
    assignedToLastName = '';
    duration = '';
    formattedPrice = '';
    formattedDate = '';
    serviceList = '';
    serviceListLong = '';
    time = '';
    scheduledDate = '';
}
