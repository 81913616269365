import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../../Actions/IListItemAction';
import { CustomerCreditDialog } from '../../Customers/Components/CustomerCreditDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { ScheduleItem } from '../Components/ScheduleItem';
import type { IScheduleItemAction } from './IScheduleItemAction';
export class TakePayment implements IScheduleItemAction {
    constructor(private scheduleItem: ScheduleItem) {}
    public actionType = <ItemActionType>'action-credit';
    public async handler() {
        const dialog = CustomerCreditDialog.createForScheduleItem(this.scheduleItem);
        await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) ScheduleItem.refresh(this.scheduleItem);
        return this.scheduleItem;
    }
    public tooltip: TranslationKey = 'actions.record-payment';
    public roles: Array<UserRole> = ['Owner', 'Admin', 'Worker'];
}
